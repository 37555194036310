export const CREATE_BOOKMARK_REQUEST = 'CREATE_BOOKMARK_REQUEST';
export const CREATE_BOOKMARK_SUCCESS = 'CREATE_BOOKMARK_SUCCESS';
export const CREATE_BOOKMARK_FAILURE = 'CREATE_BOOKMARK_FAILURE';

export const RENAME_BOOKMARK_REQUEST = 'RENAME_BOOKMARK_REQUEST';
export const RENAME_BOOKMARK_SUCCESS = 'RENAME_BOOKMARK_SUCCESS';
export const RENAME_BOOKMARK_FAILURE = 'RENAME_BOOKMARK_FAILURE';

export const GET_BOOKMARKS_REQUEST = 'GET_BOOKMARKS_REQUEST';
export const GET_BOOKMARKS_SUCCESS = 'GET_BOOKMARKS_SUCCESS';
export const GET_BOOKMARKS_FAILURE = 'GET_BOOKMARKS_FAILURE';

export const GET_BOOKMARK_REQUEST = 'GET_BOOKMARK_REQUEST';
export const GET_BOOKMARK_SUCCESS = 'GET_BOOKMARK_SUCCESS';
export const GET_BOOKMARK_FAILURE = 'GET_BOOKMARK_FAILURE';

export const DELETE_BOOKMARK_REQUEST = 'DELETE_BOOKMARK_REQUEST';
export const DELETE_BOOKMARK_SUCCESS = 'DELETE_BOOKMARK_SUCCESS';
export const DELETE_BOOKMARK_FAILURE = 'DELETE_BOOKMARK_FAILURE';

export const GET_BOOKMARK_BY_URL_REQUEST = 'GET_BOOKMARK_BY_URL_REQUEST';
export const GET_BOOKMARK_BY_URL_SUCCESS = 'GET_BOOKMARK_BY_URL_SUCCESS';
export const GET_BOOKMARK_BY_URL_FAILURE = 'GET_BOOKMARK_BY_URL_FAILURE';
export const RESET_BOOKMARK_BY_URL = 'RESET_BOOKMARK_BY_URL';

export const CLEAR_BOOKMARKS = 'CLEAR_BOOKMARKS';

export const CLEAR_BOOKMARK_IDS = 'CLEAR_BOOKMARK_IDS';

export const BOOKMARK_WITH_SAME_NAME_ALREADY_EXISTS =
  'BOOKMARK_WITH_SAME_NAME_ALREADY_EXISTS';
export const INVALID_BOOKMARK_NAME = 'INVALID_BOOKMARK_NAME';
