import React, { useRef, useState, useEffect } from 'react';
import { Menu, Layout } from 'antd';
import { useLocation, Link, useParams, useHistory } from 'react-router-dom';

import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Logo, IconSvg } from '../Common';
import { useActionPermission } from '../../hooks';

const { Item, SubMenu } = Menu;
const { Sider } = Layout;

const SideBarRootItemCss = css`
  height: 60px;
  margin-top: 0;
  margin-bottom: 0;
  &:hover {
    background-color: ${({ theme }) => theme.vars.secondaryColor};
    font-weight: bold;
  }
  span {
    font-size: 16px;
    line-height: 30px;
    height: 30px;
  }
`;

const SideBarRootItemHrefTagCss = css`
  padding: 0 17px;
  color: ${({ theme }) => theme.vars.secondaryColor};
  &:hover {
    color: ${({ theme }) => theme.color.white};
  }
  > span {
    display: flex;
    align-items: center;
    .anticon {
      font-size: 40px;
    }
  }
`;

const SideBarMenuWrapper = styled(Sider)`
  && {
    z-index: 6;
    width: 64px !important;
    max-width: 64px !important;
    min-width: 64px !important;
    overflow: auto;
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.vars.secondaryColor};
    .ant-menu {
      background-color: ${({ theme }) => theme.vars.secondaryColor};
      border-top-width: 0;
      span {
        color: ${({ theme }) => theme.color.white};
      }
    }
    .ant-menu-vertical {
      border-right: none !important;
    }

    .ant-menu-vertical > .ant-menu-item > a > span .anticon {
      font-size: 30px !important;
      line-height: 30px;
      height: 30px;
    }

    .ant-menu-inline-collapsed {
      width: 64px !important;
    }
    .ant-menu-vertical > .ant-menu-item,
    .ant-menu-inline > .ant-menu-item {
      ${SideBarRootItemCss}
      padding: 0 !important;
      transition: none !important;
      ::after {
        border: none;
      }
      > a {
        height: 100%;
        display: flex;
        align-items: center;
        transition: none !important;
        ${SideBarRootItemHrefTagCss}
        > span {
          > span {
            max-width: 0;
            transition: none !important;
          }
        }
      }
    }
    .ant-menu-vertical > .ant-menu-submenu-vertical,
    .ant-menu-inline > .ant-menu-submenu-inline {
      ${SideBarRootItemCss}
      .ant-menu-submenu-title {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 !important;
        margin-top: 0;
        margin-bottom: 0;
        > a {
          ${SideBarRootItemHrefTagCss}
        }
      }
      .ant-menu-submenu-arrow {
        display: none;
      }
    }

    .ant-menu-vertical .ant-menu-item:not(:last-child),
    .ant-menu-vertical-left .ant-menu-item:not(:last-child),
    .ant-menu-vertical-right .ant-menu-item:not(:last-child),
    .ant-menu-inline .ant-menu-item:not(:last-child) {
      margin-bottom: 0px;
    }

    .ant-menu-vertical > .ant-menu-item:not(:last-child),
    .ant-menu-inline > .ant-menu-item:not(:last-child) {
      border-bottom: none;
    }

    .ant-menu-vertical > .ant-menu-submenu-vertical.ant-menu-submenu-selected,
    .ant-menu-inline > .ant-menu-submenu-inline.ant-menu-submenu-selected,
    .ant-menu-vertical > .ant-menu-submenu-vertical:hover,
    .ant-menu-vertical > .ant-menu-submenu-active,
    .ant-menu-inline > .ant-menu-item.ant-menu-item-selected,
    .ant-menu-vertical > .ant-menu-item.ant-menu-item-selected {
      background-color: ${({ theme }) => theme.vars.primaryColor};
      font-weight: bold;
      span {
        color: ${({ theme }) => theme.color.white};
      }
      .anticon {
        color: ${({ theme }) => theme.color.white};
      }

      &:after {
        border-right: 0;
      }
    }
  }
`;

const SideMenuContainer = styled.div`
  .ant-menu-submenu-popup {
    border-radius: 0 !important;
    .ant-menu-submenu > .ant-menu {
      border-radius: 0 !important;
      border: thin solid ${({ theme }) => theme.color.dark};
      background-color: ${({ theme }) => theme.color.lvory} !important;
    }
    .ant-menu-vertical.ant-menu {
      background-color: ${({ theme }) => theme.color.pearl}!important;
      border-radius: 0 !important;
    }

    .ant-menu-sub.ant-menu-vertical > .ant-menu-item {
      margin: 0;
      height: 50px;
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 56px;
      a {
        color: ${props => props.theme.vars.secondaryColor} !important;
        :hover {
          color: ${props => props.theme.color.white} !important;
        }
      }
      &:hover {
        background-color: ${({ theme }) =>
          theme.vars.secondaryColor} !important;
      }
    }
    .ant-menu-sub.ant-menu-vertical > .ant-menu-item:not(:last-child) {
      border-bottom: none;
    }
    .ant-menu-vertical {
      .ant-menu-item:not(:last-child) {
        margin-bottom: 0 !important;
      }
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: ${props => props.theme.vars.secondaryColor} !important;
      a {
        color: ${({ theme }) => theme.color.white} !important;
      }
    }
  }
`;

const LogoContainer = styled.div`
  background-color: ${({ theme }) => theme.vars.secondaryColor};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 70px;
  line-height: 82px;
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.vars.sideMenuLogoInset};
`;

const StyledProductDashboardLink = styled.div`
  && {
    background-color: ${({ theme }) => theme.vars.secondaryColor};
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.color.purple};
    color: ${({ theme }) => theme.color.white};
    cursor: pointer;
  }
`;

const StyledBackButton = styled.div`
  && {
    .anticon {
      color: ${({ theme }) => theme.color.white};
    }
    text-align: center;
  }
`;

const StyledBackText = styled.div`
  && {
    font-size: 12px;
    a {
      color: ${({ theme }) => theme.color.white};
    }
    text-align: center;
  }
`;

export interface SideMenuItem {
  key: string;
  icon: string;
  to: string;
  title: string;
  actionPermission?: DTO.ActionPermission;
  subMenuItems?: SideMenuItem[];
}

interface SubLayoutSideMenuProps {
  menuItems?: SideMenuItem[];
}

interface RouteParams {
  productId: string;
  serviceName: string;
}

const SubLayoutSideMenu: React.FC<SubLayoutSideMenuProps> = ({ menuItems }) => {
  const location = useLocation();
  const history = useHistory();
  const { productId } = useParams<RouteParams>();
  const isShared = useLocation().pathname.startsWith('/shared');
  const productName = isShared
    ? location.pathname.split('/')[3]
    : location.pathname.split('/')[2];
  const serviceName = isShared
    ? location.pathname.split('/')[4]
    : location.pathname.split('/')[3];
  const subServiceName = isShared
    ? location.pathname.split('/')[5]
    : location.pathname.split('/')[4];
  const activeParentLink = isShared
    ? `/shared/products/${productName}/${serviceName}/${subServiceName ||
        'serviceDocumentation'}`
    : `/products/${productName}/${serviceName}/${subServiceName ||
        'serviceDocumentation'}`;
  const [activeParent, setActiveParent] = useState(activeParentLink);
  const { permissionCheck, showPermissionModal } = useActionPermission();
  const sidebarMenuWrapperRef = useRef<HTMLDivElement>(null);
  const isLocationMatching = menuItems?.find(m => m.to === location.pathname);

  useEffect(() => {
    setActiveParent(activeParentLink);
  }, [activeParentLink]);

  const onBackToDashboard = () => {
    history.push(`${isShared ? '/shared' : ''}/products/${productId}`);
  };

  const renderItems = (menuList: SideMenuItem[]) => {
    return menuList.map(
      ({ subMenuItems, actionPermission, key, icon, to, title }) => {
        if (subMenuItems) {
          return (
            <SubMenu
              popupClassName="product-sidebar-menu"
              key={key}
              title={
                <Link to={to || '#'} onClick={e => !to && e.preventDefault()}>
                  <span>
                    <IconSvg type={icon} />
                    <span>
                      <FormattedMessage id={title} />
                    </span>
                  </span>
                </Link>
              }
            >
              {renderItems(subMenuItems)}
            </SubMenu>
          );
        }
        return (
          <Item
            className={
              (!isLocationMatching && to.indexOf(activeParent) !== -1) ||
              (isLocationMatching && location.pathname === to)
                ? 'ant-menu-item-selected'
                : ''
            }
            key={key}
          >
            <Link
              to={to || '#'}
              onClick={e => {
                if (!to) {
                  e.preventDefault();
                  return;
                }
                setActiveParent(to);
                if (actionPermission && !permissionCheck(actionPermission)) {
                  showPermissionModal(e);
                }
              }}
            >
              <span>
                {icon && <IconSvg type={icon} />}
                <span>
                  <FormattedMessage id={title} />
                </span>
              </span>
            </Link>
          </Item>
        );
      }
    );
  };

  if (!menuItems) {
    return null;
  }
  return (
    <>
      <SideBarMenuWrapper collapsed>
        <LogoContainer>
          <Link data-testid="top-left-logo" to="/">
            <Logo type="Icon" height="30px" />
          </Link>
        </LogoContainer>
        <StyledProductDashboardLink onClick={onBackToDashboard}>
          <StyledBackButton>
            <IconSvg type="LeftArrow" />
          </StyledBackButton>
          <StyledBackText>
            <FormattedMessage id="SubLayout.dashboardLinkText" />
          </StyledBackText>
        </StyledProductDashboardLink>
        <Menu
          mode="vertical"
          inlineIndent={15}
          selectedKeys={[location.pathname]}
          getPopupContainer={() =>
            sidebarMenuWrapperRef.current || document.body
          }
        >
          {renderItems(menuItems)}
        </Menu>
      </SideBarMenuWrapper>
      <SideMenuContainer ref={sidebarMenuWrapperRef} />
    </>
  );
};

export default SubLayoutSideMenu;
