import { InsightsAction } from '../actions/types';
import { InsightsConstants } from '../constants';

export const initialState: STATES.InsightsState = {
  isLoading: false,
  counterDefinitions: [],
  counters: [],
  aggregatedData: [],
  isLoadingMetrics: false,
  foldersMeasure: null,
  servicesMeasure: null,
  drillDownMeasure: null,
};

export const insights = (
  state = initialState,
  action: InsightsAction
): STATES.InsightsState => {
  switch (action.type) {
    case InsightsConstants.GET_COUNTER_DEFINITIONS_REQUEST:
    case InsightsConstants.GET_NEXT_MEASURES_REQUEST:
    case InsightsConstants.GET_AGGREGATED_DATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case InsightsConstants.GET_COUNTER_DEFINITIONS_SUCCESS: {
      const { list } = action.payload;

      return {
        ...state,
        isLoading: false,
        counterDefinitions: list,
      };
    }
    case InsightsConstants.GET_COUNTER_DEFINITIONS_FAILURE:
    case InsightsConstants.GET_NEXT_MEASURES_FAILURE:
    case InsightsConstants.GET_AGGREGATED_DATA_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case InsightsConstants.GET_TOTAL_BY_COUNTER_REQUEST: {
      return {
        ...state,
        isLoadingMetrics: true,
        isLoading: true,
      };
    }
    case InsightsConstants.GET_TOTAL_BY_COUNTER_FAILURE: {
      return {
        ...state,
        isLoadingMetrics: false,
        isLoading: false,
      };
    }
    case InsightsConstants.GET_AGGREGATED_DATA_SUCCESS: {
      const { list } = action.payload;
      return {
        ...state,
        isLoading: false,
        aggregatedData: list,
      };
    }
    case InsightsConstants.GET_TOTAL_BY_COUNTER_SUCCESS: {
      const { list } = action.payload;
      return {
        ...state,
        isLoading: false,
        counters: list ?? [],
        isLoadingMetrics: false,
      };
    }
    case InsightsConstants.GET_NEXT_MEASURES_SUCCESS: {
      const {
        list,
        isFolderUpdate,
        isServiceUpdate,
        isDrilldownUpdate,
      } = action.payload;

      return {
        ...state,
        isLoading: false,
        foldersMeasure: isFolderUpdate ? list ?? null : state.foldersMeasure,
        servicesMeasure: isServiceUpdate ? list ?? null : state.servicesMeasure,
        drillDownMeasure: isDrilldownUpdate
          ? list ?? null
          : state.drillDownMeasure,
      };
    }
    default:
      return state;
  }
};
