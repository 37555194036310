import { ClientManagerAction } from '../actions/types';
import { ClientManagerConstants } from '../constants';

export const initialClientManagerState: STATES.ClientManagerState = {
  isLoading: false,
  userGroups: [],
  groups: [],
  selectedUserGroup: null,
  selectedUserGroupId: '',
  isLoadingUserGroup: false,
  refreshTrigger: 0,
  total: 0,
  page: 1,
  pageSize: 100,
  totalPages: 0,
};

export const clientManager = (
  state = initialClientManagerState,
  action: ClientManagerAction
): STATES.ClientManagerState => {
  switch (action.type) {
    case ClientManagerConstants.GET_USERGROUP_REQUEST: {
      const { userGroupId } = action.payload;

      return {
        ...state,
        isLoadingUserGroup: true,
        selectedUserGroupId: userGroupId,
      };
    }
    case ClientManagerConstants.GET_USERGROUP_SUCCESS: {
      const { userGroupId, userGroup } = action.payload;

      if (state.selectedUserGroupId !== userGroupId) {
        return state;
      }

      return {
        ...state,
        isLoadingUserGroup: false,
        selectedUserGroup: userGroup,
      };
    }
    case ClientManagerConstants.GET_USERGROUP_FAILURE: {
      const { userGroupId } = action.payload;

      if (state.selectedUserGroupId !== userGroupId) {
        return state;
      }

      return {
        ...state,
        isLoadingUserGroup: false,
      };
    }
    case ClientManagerConstants.SELECT_USERGROUP: {
      const { userGroup } = action.payload;

      return {
        ...state,
        isLoadingUserGroup: false,
        selectedUserGroupId: (userGroup && userGroup.userGroup) || '',
        selectedUserGroup: userGroup,
      };
    }
    case ClientManagerConstants.GET_USERGROUPS_REQUEST: {
      const { page } = action.payload;
      return {
        ...state,
        isLoading: true,
        page,
      };
    }
    case ClientManagerConstants.GET_USERGROUPS_SUCCESS: {
      const { userGroups, total, page } = action.payload;

      if (page !== state.page) {
        return state;
      }
      return {
        ...state,
        isLoading: false,
        userGroups:
          page === 1 ? userGroups : [...state.userGroups, ...userGroups],
        total,
        totalPages:
          Math.floor(total / state.pageSize) + (total % state.pageSize ? 1 : 0),
      };
    }
    case ClientManagerConstants.SAVE_USER_GROUP_SUCCESS:
    case ClientManagerConstants.EDIT_USER_GROUP_SUCCESS:
    case ClientManagerConstants.DELETE_USERGROUP_SUCCESS: {
      return {
        ...state,
        refreshTrigger: state.refreshTrigger + 1,
      };
    }
    case ClientManagerConstants.GET_GROUPS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ClientManagerConstants.GET_GROUPS_SUCCESS: {
      const { groups } = action.payload;
      return {
        ...state,
        isLoading: false,
        groups,
      };
    }
    case ClientManagerConstants.GET_GROUPS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ClientManagerConstants.SAVE_USERGROUP_SETTINGS_SUCCESS: {
      const { userGroup } = action.payload;

      return {
        ...state,
        selectedUserGroup: userGroup,
      };
    }
    default:
      return state;
  }
};
