import React, { NamedExoticComponent, memo } from 'react';
import { Row, Icon, Progress, Col } from 'antd';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { colorHexToRgba } from '../../../helpers';

interface ProgressBarProps {
  className?: string;
  progress: number;
  icon?: string;
  title?: string;
  iconColor?: string;
  textColor: string;
  progressColor: string;
  vertical?: boolean;
  count?: string;
  textColorOpacity?: number;
  showDefaultProgressIcon?: boolean;
}

const StyledProgress = styled(Progress)`
  .ant-progress-inner {
    background-color: ${({ theme }) =>
      colorHexToRgba(theme.vars.secondaryColor, 0.1)};
  }
`;

const StyledProgressContainer = styled(Row)<{
  textColor: string;
  iconColor?: string;
  progressColor: string;
  textColorOpacity: number | undefined;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ant-progress-inner,
  .ant-progress-bg {
    height: 8px !important;
  }

  progressLabel {
    color: ${({ textColor, textColorOpacity, theme }) =>
      colorHexToRgba(theme.color.dark || textColor, textColorOpacity || 1)};
    font-size: 12px;
  }

  span.count {
    margin-left: 8px;
    color: ${({ textColor }) => textColor};
    float: right;
  }

  .ant-progress-bg {
    background-color: ${({ progressColor }) => progressColor} !important;
  }

  .anticon {
    color: ${({ iconColor }) => iconColor} !important;
  }

  .ant-progress-inner {
    background-color: ${({ theme }) => colorHexToRgba(theme.color.dark, 0.1)};
  }
`;

const StyledProgressIcon = styled(Icon)`
  float: right;
  margin-top: 2px;
  margin-left: 10px;
  font-size: 18px;
`;

const ProgressContainer = styled.div`
  flex: 1;
`;

const ProgressBar: NamedExoticComponent<ProgressBarProps> = memo(
  ({
    progress,
    icon,
    title,
    textColor,
    progressColor,
    className,
    iconColor,
    vertical,
    count,
    textColorOpacity,
    showDefaultProgressIcon,
  }) => {
    return (
      <StyledProgressContainer
        className={className}
        gutter={4}
        textColor={textColor}
        progressColor={progressColor}
        iconColor={iconColor}
        textColorOpacity={textColorOpacity}
      >
        {(title || count) && (
          <>
            <Row type="flex" align="middle" justify="start">
              <span className="label progressLabel">
                <FormattedMessage id={title} />
              </span>
              {vertical && count && <span className="count">{count}</span>}
            </Row>

            <Row type="flex" align="middle" justify="center">
              <Col span={24}>
                <ProgressContainer>
                  <StyledProgress
                    strokeLinecap="square"
                    showInfo={false}
                    percent={progress}
                  />
                </ProgressContainer>
                {!progress && showDefaultProgressIcon && (
                  <StyledProgressIcon type={icon} />
                )}
                {!!progress && (
                  <StyledProgressIcon className="progressIcon" type={icon} />
                )}
              </Col>
            </Row>
          </>
        )}

        {!title && !count && (
          <>
            <Row type="flex" align="middle" justify="center">
              <ProgressContainer>
                <StyledProgress
                  strokeLinecap="square"
                  showInfo={false}
                  percent={progress}
                />
              </ProgressContainer>

              {!!progress && icon && <StyledProgressIcon type={icon} />}
            </Row>
          </>
        )}
      </StyledProgressContainer>
    );
  }
);

export { ProgressBar };
