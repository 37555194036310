import { BackgroundJobsConstants } from '../constants';
import { ApiError } from '../helpers';
import { BackgroundJobsService } from '../services';
import { createAsyncThunk } from './helpers';
import { AppAction } from './types';

const getAll = () =>
  createAsyncThunk(
    async (dispatch, getState) => {
      const {
        auth: { userId },
        backgroundJobs: { jobs },
      } = getState();

      dispatch({
        type: BackgroundJobsConstants.GET_BGJOBS_REQUEST,
      });

      const { payload } = await BackgroundJobsService.getByUserId(userId);

      payload.data.forEach(job => {
        if (
          (job.type === 'DownloadLogs' ||
            job.type === 'TestbedResultCSV' ||
            job.type === 'TestbedResultExcel') &&
          job.status === 'Success'
        ) {
          job.result = (job.data && job.data.Url) || null;
        }
      });

      const newUploadCompletedJobs = payload.data
        .filter(x => x.type === 'UploadFolder' && x.status === 'Success')
        .map(job => job.id);

      const reloadProductFolderList = newUploadCompletedJobs.some(jobId => {
        const job = jobs.find(x => x.id === jobId);

        return !job || job.status !== 'Success';
      });

      dispatch({
        type: BackgroundJobsConstants.GET_BGJOBS_SUCCESS,
        payload: {
          jobs: payload.data,
          reloadProductFolderList,
        },
      });
    },
    (_, msg, dispatch) => {
      dispatch({
        type: BackgroundJobsConstants.GET_BGJOBS_FAILURE,
        payload: { error: msg },
      });
    }
  );

const cancelJob = (jobId: string) =>
  createAsyncThunk(
    async dispatch => {
      dispatch({
        type: BackgroundJobsConstants.GET_CANCEL_JOB_REQUEST,
        payload: { jobId },
      });

      await BackgroundJobsService.cancelJob(jobId);

      dispatch({
        type: BackgroundJobsConstants.GET_CANCEL_JOB_SUCCESS,
        payload: { jobId },
      });
    },
    (_, msg, dispatch) => {
      dispatch({
        type: BackgroundJobsConstants.GET_CANCEL_JOB_FAILURE,
        payload: {
          error: msg,
          jobId,
        },
      });
    }
  );

const triggerDownloadJobs = (jobs: {
  [jobId: string]: string | null;
}): AppAction => {
  Object.entries(jobs).forEach(([__, downloadUrl]) => {
    window.open(downloadUrl || '');
  });

  return {
    type: BackgroundJobsConstants.TRIGGER_DOWNLOAD_GBJOBS,
    payload: { jobs },
  };
};

async function cancelTaskQueue(dispatch, id: string) {
  dispatch({ type: BackgroundJobsConstants.CANCEL_TASK_QUEUE_REQUEST });

  try {
    const { payload } = await BackgroundJobsService.cancelTaskQueue(id);
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: BackgroundJobsConstants.CANCEL_TASK_QUEUE_SUCCESS,
    });
  } catch (error) {
    const msg = error;

    dispatch({
      type: BackgroundJobsConstants.CANCEL_TASK_QUEUE_FAILURE,
      payload: { error: msg },
    });
  }
}

export const BackgroundJobsActions = {
  getAll,
  cancelJob,
  triggerDownloadJobs,
  cancelTaskQueue,
};
