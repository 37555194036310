import makeRequest from '../helpers/make-request';
import { getConfig } from '../helpers/config';

const getTemplates = (request: DTO.GetTemplateRequest) => {
  const { page = 1, filterByCategory = [], isStarred = 'false' } = request;
  let { pageSize = 4 } = request;
  const url = `${getConfig().excelEngineDomain}/api/v1/template/list`;
  const search: { field: string; value: string }[] = [];
  if (filterByCategory.length) {
    filterByCategory.forEach(category =>
      search.push({ field: 'category', value: category })
    );
  }
  if (isStarred && isStarred === 'true') {
    pageSize = 1000;
    search.push({ field: 'isstarred', value: isStarred });
  }
  return makeRequest<DTO.GetTemplateResponse>('POST', url, {
    page,
    pageSize,
    search,
  });
};

const deleteTemplate = (id: string) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/template/delete/${id}`;

  return makeRequest('DELETE', url);
};

const markTemplateFavorite = (templateName: string, favourite: boolean) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/template/favourite/${templateName}/${favourite}`;

  return makeRequest<unknown>('PUT', url);
};

const getTemplateDownloadUrl = (templateId: string) => {
  return `${
    getConfig().excelEngineDomain
  }/api/v1/template/download/${templateId}`;
};

const createTemplate = (template: DTO.CreateTemplateRequest) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/template/save`;

  const formData = new FormData();

  formData.append('templateName', template.templateName);
  formData.append('categories', template.categories.join());
  formData.append('description', template.description);
  if (template.file) {
    formData.append('file', template.file);
  }
  return makeRequest('POST', url, formData);
};

const updateTemplate = (
  templateId: string,
  template: DTO.UpdateTemplateRequest
) => {
  const formData = new FormData();
  formData.append('templateId', templateId);
  formData.append('templateName', template.templateName);
  formData.append('categories', template.categories.join());
  formData.append('description', template.description);
  if (template.file) {
    formData.append('file', template.file);
  }

  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/template/save/${templateId}`;

  return makeRequest('POST', url, formData);
};

export const TemplateManagerService = {
  getTemplates,
  deleteTemplate,
  markTemplateFavorite,
  getTemplateDownloadUrl,
  createTemplate,
  updateTemplate,
};
