import * as Sentry from '@sentry/react';
import { Icon, message as popUpMessage } from 'antd';
import React, { ReactNode } from 'react';
import { ArgsProps as MessageConfigProps } from 'antd/lib/message';
import { AlertConstants } from '../constants';
import { ApiError } from '../helpers';
import { AlertAction, AlertThunkAction } from './types';

popUpMessage.config({
  top: 50,
  duration: 2,
  maxCount: 3,
});

const success = (
  content: string | ReactNode,
  values?: { [key: string]: string }
): AlertThunkAction => (dispatch, getState) => {
  const {
    language: { intl },
  } = getState();

  const message = intl.formatMessage(
    {
      id: content,
      defaultMessage: content,
    },
    values
  );

  dispatch({
    type: AlertConstants.SUCCESS,
    payload: { message },
  });
  popUpMessage.success(message);
};

const iconInfo = () => <Icon type="info-circle" />;

const info = (
  content: string | ReactNode,
  values?: { [key: string]: string }
): AlertThunkAction => (dispatch, getState) => {
  const {
    language: { intl },
  } = getState();

  const message = intl.formatMessage(
    {
      id: content,
      defaultMessage: content,
    },
    values
  );

  dispatch({
    type: AlertConstants.INFO,
    payload: { message },
  });

  popUpMessage.info({ content: message, icon: iconInfo() });
};

const customConfigAlert = (
  config: MessageConfigProps
): AlertThunkAction => () => {
  switch (config.type) {
    case 'info':
      config.icon = iconInfo();
      popUpMessage.info(config);
      break;
    case 'success':
      popUpMessage.success(config);
      break;
    case 'error':
      popUpMessage.error(config);
      break;
    case 'warning':
      popUpMessage.warning(config);
      break;
    default:
      break;
  }
};

const closeGlobalModal = (): AlertAction => {
  return { type: AlertConstants.ALERT_GLOBAL_CLOSE };
};

const openGlobalAlert = (contentKey: string): AlertAction => ({
  type: AlertConstants.ALERT_GLOBAL_OPEN,
  payload: { contentKey },
});

/**
 * @param content Error code or message
 */
const error = (
  content: string | Error | ApiError | unknown
): AlertThunkAction<string> => (dispatch, getState) => {
  const {
    language: { intl },
  } = getState();
  Sentry.captureException(content);

  const defaultMessage = intl.formatMessage({
    id: 'DEFAULT_ERROR',
  });
  let messages = [defaultMessage];
  if (typeof content === 'string' && content) {
    messages = [
      intl.formatMessage({
        id: content,
        defaultMessage: content === '' ? defaultMessage : content,
      }),
    ];
  } else if (content instanceof ApiError) {
    messages = [content.message || defaultMessage];

    if (content.codes && content.codes.length > 0) {
      let idx = content.codes.indexOf('RULE_VALIDATION_FAILED');

      if (idx !== -1) {
        content.codes.splice(idx, 1);

        dispatch(openGlobalAlert('Error.permissionError'));
      }

      idx = content.codes.indexOf('NETWORK_CANCELLED');

      if (idx !== -1) {
        content.codes.splice(idx, 1);
      }

      idx = content.codes.indexOf('INTERNAL_SERVER_ERROR');

      if (idx !== -1) {
        messages = [
          content.reason
            ? `${content.message} Reason : ${content.reason}`
            : content.message,
        ];
        content.codes.splice(idx, 1);
      }

      const nonEmptyErrorCodes = content?.codes?.find(m => m.length > 0)
        ?.length;
      if (nonEmptyErrorCodes && nonEmptyErrorCodes !== 0) {
        messages = content.codes.map(code =>
          intl.formatMessage({
            id: code,
            defaultMessage: content.message || defaultMessage,
          })
        );
      }
    }
  } else if (content instanceof Error) {
    messages = [
      intl.formatMessage({
        id: content.message,
        defaultMessage,
      }),
    ];
  }

  messages.forEach(message => {
    message && popUpMessage.error(message);
  });

  const combinedMessage = messages.join('-');

  return combinedMessage;
};

const clear = (): AlertAction => {
  return { type: AlertConstants.CLEAR };
};

export const AlertActions = {
  success,
  info,
  error,
  clear,
  closeGlobalModal,
  openGlobalAlert,
  customConfigAlert,
  iconInfo,
};
