export const GET_COUNTER_DEFINITIONS_REQUEST =
  'GET_COUNTER_DEFINITIONS_REQUEST';
export const GET_COUNTER_DEFINITIONS_SUCCESS =
  'GET_COUNTER_DEFINITIONS_SUCCESS';
export const GET_COUNTER_DEFINITIONS_FAILURE =
  'GET_COUNTER_DEFINITIONS_FAILURE';

export const GET_AGGREGATED_DATA_REQUEST = 'GET_AGGREGATED_DATA_REQUEST';
export const GET_AGGREGATED_DATA_SUCCESS = 'GET_AGGREGATED_DATA_SUCCESS';
export const GET_AGGREGATED_DATA_FAILURE = 'GET_AGGREGATED_DATA_FAILURE';

export const GET_TOTAL_BY_COUNTER_REQUEST = 'GET_TOTAL_BY_COUNTER_REQUEST';
export const GET_TOTAL_BY_COUNTER_SUCCESS = 'GET_TOTAL_BY_COUNTER_SUCCESS';
export const GET_TOTAL_BY_COUNTER_FAILURE = 'GET_TOTAL_BY_COUNTER_FAILURE';

export const GET_NEXT_MEASURES_REQUEST = 'GET_NEXT_MEASURES_REQUEST';
export const GET_NEXT_MEASURES_SUCCESS = 'GET_NEXT_MEASURES_SUCCESS';
export const GET_NEXT_MEASURES_FAILURE = 'GET_NEXT_MEASURES_FAILURE';

export const SPARK_APICALL_COUNTER_DEF_NAME = 'Spark.ApiCalls';
