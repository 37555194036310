import { Select } from 'antd';
import { SelectProps, SelectValue } from 'antd/lib/select';
import React, { FC, RefObject, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const { Option, OptGroup } = Select;

interface OptionProps {
  disabled?: boolean;
  key: string;
  title: string;
  value: string | number;
  className?: string;
  data_testid?: string;
}

interface OptGroup {
  title?: string;
  options?: OptionProps[];
}

interface CustomSelectProps extends SelectProps<SelectValue> {
  forwardRef?:
    | React.Ref<Select<SelectValue>>
    | RefObject<Select<SelectValue>>
    | null;
  placeholder?: string | JSX.Element;
  onBlur?: (value) => void;
  onFocus?: () => void;
  onChange?: (
    value,
    option?: React.ReactElement | React.ReactElement[]
  ) => void;
  onDeselect?: (value) => void;
  onSearch?: (value: string) => void;
  onSelect?: (
    value,
    option?: React.ReactElement | React.ReactElement[]
  ) => void;
  isOptGroup?: boolean;
  options?: OptionProps[];
  optGroupOptions?: OptGroup[];
}

const StyledContainer = styled.div`
  .ant-select-dropdown-menu-item {
    &:hover {
      &:not(.ant-select-dropdown-menu-item-disabled) {
        color: ${({ theme }) => theme.color.white}
        background-color: ${({ theme }) => theme.color.purple};
      }
    }
  }

  .ant-select-dropdown-menu-item-selected {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.purple};
  }

  .ant-select-dropdown-menu-item-active {
    &:not(.ant-select-dropdown-menu-item-disabled) {
      color: ${({ theme }) => theme.color.white};
      background-color: ${({ theme }) => theme.color.purple};
    }
  }

  .ant-select-dropdown.ant-select-dropdown--multiple
    .ant-select-dropdown-menu-item-selected
    .ant-select-selected-icon {
    color: ${({ theme }) => theme.color.purple};
  }

  .ant-select-dropdown.ant-select-dropdown--multiple {
    .ant-select-dropdown-menu-item-selected:hover {
      .ant-select-selected-icon {
        color: ${({ theme }) => theme.color.purple};
      }
    }
  }

  .ant-select-dropdown-menu-item {
    padding: 9px 12px;
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
  .ant-select-selection--single {
    height: 36px !important;
  }

  .ant-select-selection .anticon {
    color: ${({ theme }) => theme.color.gray2} !important;
  }

  .ant-select-selection__rendered {
    height: 36px !important;
    line-height: 34px !important;
  }

  &.ant-select-sm {
    .ant-select-selection--single {
      height: 32px !important;
    }

    .ant-select-selection__rendered {
      height: 32px !important;
      line-height: 30px !important;
    }
  }

  &.ant-select-lg {
    .ant-select-selection--single {
      height: 40px !important;
    }

    .ant-select-selection__rendered {
      height: 40px !important;
      line-height: 38px !important;
    }
  }
`;

const CustomSelect: FC<CustomSelectProps> = ({
  placeholder,
  onBlur,
  onFocus,
  onChange,
  onDeselect,
  onSearch,
  onSelect,
  isOptGroup = false,
  options,
  optGroupOptions,
  ...rest
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <StyledContainer ref={containerRef} />
      <StyledSelect
        {...rest}
        placeholder={
          typeof placeholder === 'string' ? (
            <FormattedMessage id={placeholder} />
          ) : (
            placeholder
          )
        }
        getPopupContainer={() => containerRef.current || document.body}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        onSelect={onSelect}
        onDeselect={onDeselect}
        onSearch={onSearch}
      >
        {!isOptGroup &&
          options &&
          options.map(item => (
            <Option
              data-testid={item.data_testid}
              key={item.key}
              value={item.value}
              disabled={item.disabled}
            >
              {item.title}
            </Option>
          ))}
        {isOptGroup &&
          optGroupOptions &&
          optGroupOptions.map(optGroup => (
            <OptGroup key={optGroup.title} label={optGroup.title}>
              {optGroup.options &&
                optGroup.options.map(item => (
                  <Option
                    data-testid={item.data_testid}
                    key={item.key}
                    value={item.value}
                    disabled={item.disabled}
                  >
                    {item.title}
                  </Option>
                ))}
            </OptGroup>
          ))}
      </StyledSelect>
    </>
  );
};

const CustomSelectForwardRef = React.forwardRef(
  (props: CustomSelectProps, ref: React.Ref<Select<SelectValue>>) => (
    <CustomSelect forwardRef={ref} {...props} />
  )
);

export { CustomSelectForwardRef as CustomSelect };
