import { DataDictionaryAction } from '../actions/types';
import { DataDictionaryConstants } from '../constants';

export const initialDataDictionaryState: STATES.DataDictionaryState = {
  dataDictionary: [],
  searchText: '',
  isLoading: false,
  isUploadingDataDictionary: false,
  uploadProgress: 0,
  uploadError: '',
  uploadXhrRef: null,
  uploadResult: null,
  sort: 'desc',
  sortBy: null,
};

export const dataDictionaryReducer = (
  state = initialDataDictionaryState,
  action: DataDictionaryAction
): STATES.DataDictionaryState => {
  switch (action.type) {
    case DataDictionaryConstants.GET_DATADICTIONARY_REQUEST: {
      const { sortBy, sort, searchText } = action.payload;
      return {
        ...state,
        isLoading: true,
        sortBy,
        sort,
        searchText,
      };
    }
    case DataDictionaryConstants.GET_DATADICTIONARY_SUCCESS: {
      const { dataDictionary, sortBy, sort, searchText } = action.payload;
      return {
        ...state,
        dataDictionary,
        isLoading: false,
        sortBy,
        sort,
        searchText,
      };
    }

    case DataDictionaryConstants.UPLOAD_DATADICTIONARY_START: {
      return {
        ...state,
        isUploadingDataDictionary: true,
        uploadProgress: 5,
        uploadError: null,
      };
    }

    case DataDictionaryConstants.UPLOAD_DATADICTIONARY_SUCCESS: {
      const { result } = action.payload;

      return {
        ...state,
        isUploadingDataDictionary: false,
        uploadProgress: 100,
        uploadResult: result,
      };
    }
    case DataDictionaryConstants.UPLOAD_DATADICTIONARY_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        isUploadingDataDictionary: false,
        uploadError: error,
      };
    }
    case DataDictionaryConstants.UPLOAD_DATADICTIONARY_RESET: {
      return {
        ...state,
        isUploadingDataDictionary: false,
        uploadProgress: 0,
        uploadError: null,
        uploadXhrRef: null,
        uploadResult: null,
      };
    }
    case DataDictionaryConstants.UPLOAD_DATADICTIONARY_XHR_REF: {
      const { xhrRef } = action.payload;
      return {
        ...state,
        uploadXhrRef: xhrRef,
      };
    }
    case DataDictionaryConstants.DOWNLOAD_DATADICTIONARY_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case DataDictionaryConstants.DOWNLOAD_DATADICTIONARY_FAILURE:
    case DataDictionaryConstants.DOWNLOAD_DATADICTIONARY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};
