import moment, { Moment } from 'moment';

export const isEffectiveDate = (
  date: Moment,
  effectiveFrom: string | null,
  effectiveTill: string | null
) => {
  const effectiveFromDate = effectiveFrom ? moment(effectiveFrom) : null;
  const effectiveTillDate = effectiveTill ? moment(effectiveTill) : null;

  if (
    (effectiveFromDate && date < effectiveFromDate) ||
    (effectiveTillDate && date > effectiveTillDate)
  ) {
    return false;
  }

  return true;
};

const isInvalidPermission = (
  userGroupRule: DTO.UserGroupRule | null,
  actionPermission: DTO.ActionPermission
) => {
  if (!userGroupRule) {
    return false;
  }

  if (!userGroupRule.isActive) {
    return true;
  }

  const { ruleName, moduleName } = actionPermission;
  const now = moment();

  if (
    !isEffectiveDate(
      now,
      userGroupRule.effectiveFrom,
      userGroupRule.effectiveTill
    )
  ) {
    return true;
  }

  const moduleRules = userGroupRule.moduleRules.filter(
    rule => rule.moduleName === moduleName
  );

  if (moduleRules.length === 0) {
    return false;
  }

  return moduleRules.some(moduleRule => {
    const actionRules = moduleRule.rules.filter(
      rule => rule.ruleName === ruleName
    );

    if (actionRules.length === 0) {
      return false;
    }

    if (!moduleRule.isActive) {
      return true;
    }

    if (
      !isEffectiveDate(now, moduleRule.effectiveFrom, moduleRule.effectiveTill)
    ) {
      return true;
    }

    return actionRules.some(actionRule => {
      // We dont check number rule in clientside
      if (actionRule.ruleValueType === 'Number') {
        return false;
      }

      return !actionRule.ruleValue;
    });
  });
};

export { isInvalidPermission };
