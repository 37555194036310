import jwt from 'jsonwebtoken';

const getValidUserAuth = (userAuth: { id_token: string }) => {
  const currentTime = Math.round(Date.now() / 1000);
  const decodedJwt = jwt.decode(userAuth.id_token || '') as DTO.UserJWTData;
  return decodedJwt && decodedJwt.exp && decodedJwt.exp > currentTime
    ? {
        userAuth,
        decodedJwt,
      }
    : {
        userAuth: null,
        decodedJwt: null,
      };
};

export { getValidUserAuth };
