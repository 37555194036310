import { ArgsProps as MessageConfigProps } from 'antd/lib/message';
import { EngineConstants } from '../constants';
import {
  ApiError,
  arrayRemoveFirst,
  downloadBlob,
  getExcelObject,
} from '../helpers';
import { EngineService, FileManagerService } from '../services';
import { AlertActions } from './alert.actions';
import {
  EngineAction,
  EngineThunkAction,
  EngineThunkActionSync,
} from './types';

const getProductEngines = (
  productName: string,
  data: DTO.GetProductEnginesRequest
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.GET_PRODUCT_ENGINES_REQUEST,
      payload: { productName, ...data },
    });

    const { payload, status } = await EngineService.getProductEngines(
      productName,
      data
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.GET_PRODUCT_ENGINES_SUCCESS,
      payload: {
        productName,
        ...data,
        engines: payload.data,
        total: payload.count,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.GET_PRODUCT_ENGINES_FAILURE,
      payload: {
        productName,
        error: msg,
      },
    });
  }
};

const getProductEngineDetails = (
  productName: string,
  serviceName: string,
  versionId?: string,
  revision?: string,
  makeSelectedEngine = true,
  firstTimeApiTesterLoad = false
): EngineThunkAction => async (dispatch, getState) => {
  try {
    const {
      productEngines: {
        isLoadingDetails,
        selectedEngineName,
        selectedVersionId,
      },
    } = getState();

    if (
      isLoadingDetails &&
      selectedEngineName === serviceName &&
      selectedVersionId === versionId
    ) {
      return;
    }

    dispatch({
      type: EngineConstants.GET_PRODUCT_ENGINE_DETAILS_REQUEST,
      payload: {
        productName,
        serviceName,
        versionId,
        revision,
      },
    });

    const { payload, status } = await EngineService.getProductEngineDetails(
      productName,
      serviceName,
      versionId
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    if (makeSelectedEngine) {
      dispatch({
        type: EngineConstants.GET_PRODUCT_ENGINE_DETAILS_SUCCESS,
        payload: {
          productName,
          serviceName,
          engine: payload.data,
          versionId,
        },
      });
    } else {
      dispatch({
        type: EngineConstants.GET_PRODUCT_ENGINE_DETAILS_SUCCESS_NO_SELECT,
        payload: {
          productName,
          serviceName,
          engine: payload.data,
          versionId,
        },
      });
    }

    if (firstTimeApiTesterLoad) {
      const engine = payload.data;
      const result = getExcelObject(engine, productName, serviceName);
      dispatch({
        type: EngineConstants.API_TEST_EXECUTE_SUCCESS_NO_APICALL,
        payload: {
          result,
        },
      });
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.GET_PRODUCT_ENGINE_DETAILS_FAILURE,
      payload: {
        productName,
        serviceName,
        error: msg,
        versionId,
      },
    });
  }
};

const updateProperties = (
  productName: string,
  serviceName: string,
  data: DTO.UpdateEngineRequest
): EngineThunkAction<boolean | null> => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.UPDATE_ENGINE_PROPS_REQUEST,
    });

    const updateData: DTO.UpdateProductEnginePropsRequest = {
      BookProperties: data.properties,
      effectiveEndDate: data.effectiveEndDate,
      effectiveStartDate: data.effectiveStartDate,
      XlInputs: data.inputs.map(({ name, description }) => ({
        originalName: name,
        newName: name,
        description,
      })),
      XlOutputs: data.outputs.map(({ name, description }) => ({
        originalName: name,
        newName: name,
        description,
      })),
      Id: data.id,
      Version: data.version,
      referenceRangeMetadatas: data.referenceRangeMetadatas,
    };

    updateData.BookProperties = arrayRemoveFirst(
      updateData.BookProperties,
      prop => prop.name === 'ServiceName'
    );

    const { payload, status } = await EngineService.updateProductEngine(
      productName,
      serviceName,
      updateData
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.UPDATE_ENGINE_PROPS_SUCCESS,
      payload: {
        serviceName,
      },
    });

    payload.message && dispatch(AlertActions.success(payload.message));
    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.UPDATE_ENGINE_PROPS_FAILURE,
      payload: {
        serviceName,
        error: msg,
      },
    });
    return false;
  }
};

const updateEngineFavorite = (
  productName: string,
  engine: string,
  favorite: boolean
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.UPDATE_ENGINE_FAVORITE_REQUEST,
      payload: {
        serviceName: engine,
        favorite,
      },
    });

    const { status, payload } = await EngineService.markFavorite(
      productName,
      engine,
      favorite
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.UPDATE_ENGINE_FAVORITE_SUCCESS,
      payload: {
        serviceName: engine,
        favorite,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.UPDATE_ENGINE_FAVORITE_FAILURE,
      payload: {
        serviceName: engine,
        error: msg,
      },
    });
  }
};

const uploadEngine = (
  productName: string,
  file: File,
  noPublish?: boolean
): EngineThunkAction => async (dispatch, getState) => {
  try {
    dispatch({ type: EngineConstants.ADD_ENGINE_UPLOAD_START });

    const {
      status: uploadStatus,
      payload: uploadPayload,
    } = await EngineService.uploadEngine(
      productName,
      file,
      uploadProgress => {
        if (uploadProgress === 100) {
          dispatch({
            type: EngineConstants.ADD_ENGINE_EXECUTE_START,
          });

          return;
        }

        dispatch({
          type: EngineConstants.ADD_ENGINE_UPLOAD_PROGRESS,
          payload: {
            uploadProgress,
          },
        });
      },
      xhrRef => {
        dispatch({
          type: EngineConstants.ADD_ENGINE_UPLOAD_XHR_REF,
          payload: { xhrRef },
        });
      }
    );

    if (
      uploadStatus !== 200 ||
      uploadPayload.status === 'Error' ||
      uploadPayload.status == null
    ) {
      if (uploadPayload.errorCode && uploadPayload.Data) {
        const { Error, CorrelationId } = uploadPayload.Data;
        const {
          language: { intl },
        } = getState();

        let error;
        if (
          [
            'INVALID_ENGINE_CONFIGURATION',
            'REFERENCE_RANGE_ERROR',
            'PRODUCT_TUTORIAL_UPLOAD_ERROR',
            'INVALID_SOLVE_NAMERANGES',
          ].includes(uploadPayload.errorCode)
        ) {
          error = [
            ...Error.map(({ Key, Value }) =>
              intl.formatMessage({ id: Key }, { values: Value.join(', ') })
            ),
            `(${CorrelationId})`,
          ].join('<br />');
        } else if (uploadPayload.errorCode === 'CORRUPT_EXCEL_FILE_FOR_TYPE1') {
          error = [
            intl.formatMessage({ id: uploadPayload.errorCode }),
            `(${CorrelationId})`,
          ].join('<br />');
        }

        if (error) {
          dispatch({
            type: EngineConstants.ADD_ENGINE_ERROR,
            payload: {
              error,
            },
          });
          return;
        }
      }

      throw new ApiError(uploadPayload);
    }

    const { data } = uploadPayload;

    const engineExists =
      uploadPayload.status === 'Warning' &&
      uploadPayload.errorCode ===
        EngineConstants.WARNING_CODE_ENGINE_EXISTS_ALREADY;

    data.releaseNoteRequired = engineExists;

    dispatch({
      type: EngineConstants.ADD_ENGINE_SUCCESS,
      payload: { result: data, noPublish },
    });

    if (uploadPayload.message && !noPublish) {
      dispatch(AlertActions.success(uploadPayload.message));
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.ADD_ENGINE_ERROR,
      payload: {
        error: msg,
      },
    });
  }
};

const updateReleaseNote = (releaseNote: string): EngineAction => ({
  type: EngineConstants.UPDATE_RELEASE_NOTE,
  payload: {
    releaseNote,
  },
});

const publishNewEngine = (
  productName: string,
  serviceName: string,
  data?: DTO.PublishEngineRequest,
  keepOpenModal?: boolean
): EngineThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.PUBLISH_UPLOADED_ENGINE,
    });

    const { status, payload } = await EngineService.publishEngine(
      productName,
      serviceName,
      data
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.PUBLISH_UPLOADED_ENGINE_SUCCESS,
      payload: {
        keepOpenModal,
        effectiveEndDate: data?.effectiveEndDate,
        effectiveStartDate: data?.effectiveStartDate,
      },
    });

    payload.message && dispatch(AlertActions.success(payload.message));

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.PUBLISH_UPLOADED_ENGINE_FAILURE,
      payload: {
        error: msg,
      },
    });

    return false;
  }
};

const increaseExecuteProgress = (executeProgress: number) => {
  if (executeProgress >= 90) {
    return {};
  }

  return {
    type: EngineConstants.ADD_ENGINE_EXECUTE_PROGRESS,
    payload: {
      executeProgress: executeProgress + 10,
    },
  };
};

const resetAddEngine = (): EngineAction => ({
  type: EngineConstants.ADD_ENGINE_RESET,
});

const showUpdateConfirm = (data: DTO.UpdateEngineRequest): EngineAction => ({
  type: EngineConstants.SHOW_UPDATE_CONFIRM,
  payload: { data },
});

const updateEngineProps = (data: DTO.UpdateEngineRequest): EngineAction => ({
  type: EngineConstants.UPDATE_ENGINE_PROPS,
  payload: { data },
});
const cancelPublishEngine = (): EngineAction => ({
  type: EngineConstants.CANCEL_PUBLISH_ENGINE,
});

const cancelUpdateConfirm = (): EngineAction => ({
  type: EngineConstants.CANCEL_UPDATE_CONFIRM,
});

const resetProductEngines = (): EngineAction => ({
  type: EngineConstants.PRODUCT_ENGINES_RESET,
});

const resetProductEngineSelection = (): EngineAction => ({
  type: EngineConstants.PRODUCT_ENGINE_SELECTION_RESET,
});

const deleteEngine = (
  productName: string,
  serviceName: string
): EngineThunkAction => async dispatch => {
  dispatch({
    type: EngineConstants.DELETE_ENGINE_REQUEST,
    payload: { productName, serviceName },
  });

  try {
    const { status, payload } = await EngineService.deleteEngine(
      productName,
      serviceName
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.DELETE_ENGINE_SUCCESS,
      payload: {
        productName,
        serviceName,
      },
    });

    dispatch(
      AlertActions.success('ProductEngines.menu.deleteEngine.success', {
        serviceName,
      })
    );
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.DELETE_ENGINE_FAILURE,
      payload: {
        productName,
        serviceName,
        error: msg,
      },
    });
  }
};

const executeProductEngine = (
  productName: string,
  serviceName: string,
  data: DTO.ExcelEngineRequest
): EngineThunkAction => async dispatch => {
  try {
    dispatch({ type: EngineConstants.API_TEST_EXECUTE });
    const { status, payload } = await EngineService.executeProductEngine(
      productName,
      serviceName,
      data
    );

    if (status !== 200 || payload.status === 'Error') {
      if (payload.errorCode === 'NO_ACTIVE_VERSION') {
        dispatch({
          type: EngineConstants.API_TEST_SHOW_ERROR_MODAL,
        });
        dispatch({
          type: EngineConstants.API_TEST_EXECUTE_FAILURE,
          payload: {
            error: 'NO_ACTIVE_VERSION',
          },
        });
        dispatch({
          type: EngineConstants.API_TEST_SHOW_ED_WARNING_MODAL,
        });
      } else {
        throw new ApiError(payload);
      }
    } else {
      if (
        payload.status === 'Warning' &&
        payload.errorCode === 'ENGINE_INACTIVE_WARNING'
      ) {
        dispatch({
          type: EngineConstants.API_TEST_SHOW_ED_WARNING_MODAL,
        });
        dispatch({
          type: EngineConstants.API_TEST_SHOW_ERROR_MODAL,
        });
        dispatch({
          type: EngineConstants.API_TEST_EXECUTE_FAILURE,
          payload: {
            error: 'NO_ACTIVE_VERSION',
          },
        });
      } else {
        dispatch({
          type: EngineConstants.API_TEST_HIDE_ED_WARNING_MODAL,
        });
      }
      dispatch({
        type: EngineConstants.API_TEST_EXECUTE_SUCCESS,
        payload: {
          result: {
            ...payload.data,
            ServiceCategories:
              payload.data.ServiceCategories ||
              (data.ServiceCategories && data.ServiceCategories.length > 0
                ? data.ServiceCategories
                : undefined),
          },
        },
      });
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineConstants.API_TEST_EXECUTE_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const apiTesterExecuteInputsTest = (
  productName: string,
  serviceName: string,
  data: DTO.ExcelEngineRequest
): EngineThunkAction => async dispatch => {
  try {
    dispatch({ type: EngineConstants.API_TEST_EXECUTE_INPUTS });
    const { status, payload } = await EngineService.executeProductEngineInputs(
      productName,
      serviceName,
      data
    );

    if (status !== 200 || payload.status === 'Error') {
      if (payload.errorCode === 'NO_ACTIVE_VERSION') {
        dispatch({
          type: EngineConstants.API_TEST_SHOW_ERROR_MODAL,
        });
        dispatch({
          type: EngineConstants.API_TEST_EXECUTE_INPUTS_FAILURE,
          payload: {
            error: 'NO_ACTIVE_VERSION',
          },
        });
      } else {
        throw new ApiError(payload);
      }
    } else {
      dispatch({
        type: EngineConstants.API_TEST_EXECUTE_INPUTS_SUCCESS,
        payload: {
          xlInputs: payload.data.xlInputs,
        },
      });
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineConstants.API_TEST_EXECUTE_INPUTS_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const apiTesterWarningHide = () => ({
  type: EngineConstants.API_TEST_HIDE_ED_WARNING_MODAL,
});

const apiTesterWarningShow = () => ({
  type: EngineConstants.API_TEST_SHOW_ED_WARNING_MODAL,
});

const apiTesterModalHide = () => ({
  type: EngineConstants.API_TEST_HIDE_ERROR_MODAL,
});

const apiTesterReset = (): EngineAction => ({
  type: EngineConstants.API_TEST_RESET,
});

const getEngineVersions = (
  productName: string,
  serviceName: string
): EngineThunkAction<DTO.EngineVersion[]> => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.GET_ENGINE_VERSIONS_REQUEST,
    });

    const { status, payload } = await EngineService.getVersions(
      productName,
      serviceName
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.GET_ENGINE_VERSIONS_SUCCESS,
      payload: {
        serviceName,
        versions: payload.data,
      },
    });

    return payload.data;
  } catch (error) {
    dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.GET_ENGINE_VERSIONS_FAILURE,
    });

    return [];
  }
};

const downloadEngineFile = (
  productName: string,
  serviceName: string,
  version: string,
  type: string,
  fileName: string
): EngineThunkActionSync => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.DOWNLOAD_ENGINE_REQUEST,
    });
    const downloadUrl = EngineService.getDownloadEngineUrl(
      productName,
      serviceName,
      version,
      fileName,
      type
    );

    const blob = await FileManagerService.downloadBlob(downloadUrl);
    if (blob.payload.blob)
      downloadBlob(blob.payload.blob, blob.payload.blobName ?? fileName);
    dispatch({
      type: EngineConstants.DOWNLOAD_ENGINE_SUCCESS,
    });

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.DOWNLOAD_ENGINE_FAILURE,
      payload: {
        error: msg,
      },
    });
    return false;
  }
};

const downloadSwagger = (
  request: DTO.DownloadSwaggerRequest
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.DOWNLOAD_ENGINE_REQUEST,
    });
    const downloadUrl = EngineService.getSwaggerDownloadUrl(request);
    const blob = await FileManagerService.downloadBlob(downloadUrl);
    const swaggerFileName = `SwaggerFile_${request.productName}_${request.serviceName}.json`;
    if (blob.payload.blob) downloadBlob(blob.payload.blob, swaggerFileName);
    dispatch({
      type: EngineConstants.DOWNLOAD_ENGINE_SUCCESS,
    });
    dispatch(
      AlertActions.success('ApiTester.review.swaggerDownload.success', {
        swaggerFileName,
      })
    );
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.DOWNLOAD_ENGINE_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};
const downloadOutput = (
  product: string,
  engine: string,
  data: DTO.ExcelEngineRequest
) => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.GET_EXECUTE_OUTPUT_REQUEST,
    });

    const { status, payload } = await EngineService.getExecuteOutputFile(
      product,
      engine,
      data
    );
    if (status !== 200) {
      throw new ApiError(payload);
    }
    const blob = await FileManagerService.downloadBlob(payload.data.url);
    if (blob.payload.blob) {
      downloadBlob(blob.payload.blob, payload.data.fileName);
    }
    dispatch({
      type: EngineConstants.GET_EXECUTE_OUTPUT_SUCCESS,
    });
    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.GET_EXECUTE_OUTPUT_FAILURE,
      payload: {
        error: msg,
      },
    });
    return false;
  }
};

const getProxyUrl = (
  productName: string,
  serviceName: string
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.CUSTOM_URL_REQUEST,
    });

    const { status, payload } = await EngineService.getSurfixUrl(
      productName,
      serviceName
    );

    if (status !== 200 || payload.status !== 'Success') {
      if (payload.errorCode === 'PROXY_URL_NOT_FOUND') {
        dispatch({
          type: EngineConstants.CUSTOM_URL_SUCCESS,
          payload: {
            data: { urlSuffix: '', productName, serviceName },
          },
        });
        return;
      }
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.CUSTOM_URL_SUCCESS,
      payload: {
        data: payload.data,
        productName,
        serviceName,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineConstants.CUSTOM_URL_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const createProxyUrl = (
  productName: string,
  serviceName: string,
  urlSurffix: string
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.CUSTOM_URL_REQUEST,
    });
    dispatch({
      type: EngineConstants.CUSTOM_URL_EXIST_HIDE_MODAL,
    });

    const { status, payload } = await EngineService.saveProxyUrl({
      ProductName: productName,
      ServiceName: serviceName,
      UrlSuffix: urlSurffix,
    });

    if (status !== 200 || payload.status !== 'Success') {
      if (payload.errorCode === 'PROXY_URL_ALREADY_EXISTS') {
        dispatch({
          type: EngineConstants.CUSTOM_URL_EXIST,
        });
      }
    }

    dispatch(getProxyUrl(productName, serviceName));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineConstants.CUSTOM_URL_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const deleteProxyUrl = (
  proxyUrl: string,
  productName: string,
  serviceName: string,
  urlSurffix?: string
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.CUSTOM_URL_REQUEST,
    });

    await EngineService.deleteProxyUrl(proxyUrl);
    !urlSurffix &&
      dispatch({
        type: EngineConstants.CUSTOM_URL_DELETE_SUCCESS,
      });
    urlSurffix &&
      dispatch(createProxyUrl(productName, serviceName, urlSurffix));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineConstants.CUSTOM_URL_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const downloadCUrl = (
  request: DTO.DownloadCUrlRequest
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.DOWNLOAD_ENGINE_REQUEST,
    });
    const downloadUrl = EngineService.getCUrlDownloadUrl(request);
    const blob = await FileManagerService.downloadBlob(downloadUrl);
    const curlFileName = `cURL_${request.productName}_${request.serviceName}.txt`;
    if (blob.payload.blob) downloadBlob(blob.payload.blob, curlFileName);
    dispatch({
      type: EngineConstants.DOWNLOAD_ENGINE_SUCCESS,
    });
    dispatch(
      AlertActions.success('ApiTester.review.curlDownload.success', {
        curlFileName,
      })
    );
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.DOWNLOAD_ENGINE_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const getCurlPreview = (
  request: DTO.GetCurlPreviewRequest
): EngineThunkAction<Blob | undefined> => async dispatch => {
  try {
    const downloadUrl = EngineService.getCUrlDownloadUrl(request);
    const blob = await FileManagerService.downloadBlob(downloadUrl);
    if (blob.status !== 200) {
      throw new ApiError({
        errorCode: blob.status.toString(),
      });
    }
    if (blob.status === 200 && blob.payload.blob) {
      return blob.payload.blob;
    }
  } catch (error) {
    dispatch(AlertActions.error(error));
  }
};

const getSwaggerPreview = (
  request: DTO.GetSwaggerPreviewRequest
): EngineThunkAction<Blob | undefined> => async dispatch => {
  try {
    const downloadUrl = EngineService.getSwaggerDownloadUrl(request);
    const blob = await FileManagerService.downloadBlob(downloadUrl);
    if (blob.status !== 200) {
      throw new ApiError({
        errorCode: blob.status.toString(),
      });
    }
    if (blob.status === 200 && blob.payload.blob) {
      return blob.payload.blob;
    }
  } catch (error) {
    dispatch(AlertActions.error(error));
  }
};

const restoreVersion = (
  productName: string,
  serviceName: string,
  versionId: string,
  revision: string
): EngineThunkAction => async dispatch => {
  dispatch({
    type: EngineConstants.RESTORE_VERSION_REQUEST,
  });

  try {
    const { status, payload } = await EngineService.restoreVersion(
      productName,
      serviceName,
      versionId
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    const { data } = payload;

    dispatch({
      type: EngineConstants.RESTORE_VERSION_SUCCESS,
      payload: {
        latestVersionId: data,
        selectedEngineName: serviceName,
      },
    });

    dispatch(
      AlertActions.success('ProductEngines.menu.restoreVersion.success', {
        serviceName,
        revision,
      })
    );
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.RESTORE_VERSION_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const executeProductEngineWithChain = (
  productName: string,
  serviceName: string,
  data: DTO.ExcelEngineRequest
): EngineThunkAction => async dispatch => {
  try {
    dispatch({ type: EngineConstants.EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_REQUEST });
    const {
      status,
      payload,
    } = await EngineService.executeProductEngineWithChain(
      productName,
      serviceName,
      data
    );

    if (status !== 200 || payload.status === 'Error') {
      if (payload.errorCode === 'NO_ACTIVE_VERSION') {
        dispatch({
          type: EngineConstants.API_TEST_SHOW_ERROR_MODAL,
        });
        dispatch({
          type: EngineConstants.API_TEST_EXECUTE_INPUTS_FAILURE,
          payload: {
            error: 'NO_ACTIVE_VERSION',
          },
        });
      } else {
        throw new ApiError(payload);
      }
    } else {
      dispatch({
        type: EngineConstants.EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_SUCCESS,
        payload: {
          result: payload.data,
        },
      });
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineConstants.EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const generateJsSheet = (
  product: string,
  engine: string
): EngineThunkAction => async (dispatch, getState) => {
  try {
    dispatch({
      type: EngineConstants.GENERATE_JSSHEET_ENGINE_REQUEST,
      payload: { product, engine },
    });

    const { payload, status } = await EngineService.generateJsSheet(
      product,
      engine
    );

    const {
      apiTester: { cancelGenerate },
    } = getState();

    if (!cancelGenerate && (status !== 200 || payload.status === 'Error')) {
      throw new ApiError(payload);
    }

    if (!cancelGenerate) {
      dispatch({
        type: EngineConstants.GENERATE_JSSHEET_ENGINE_SUCCESS,
        payload: { engine },
      });

      dispatch(AlertActions.success('GenerateJsSheet.success.message'));
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.GENERATE_JSSHEET_ENGINE_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const downloadJsSheetFile = (
  product: string,
  engine: string,
  versionId: string
): EngineThunkAction => async () => {
  const blob = await FileManagerService.getDownloadFileByVersionUrl(
    versionId,
    `${product}_${engine}_${versionId}.c4engine`
  );
  if (blob.payload.blob)
    downloadBlob(
      blob.payload.blob,
      `${product}_${engine}_${versionId}.c4engine`
    );
};

const downloadJsSheet = (
  product: string,
  engine: string
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.DOWNLOAD_JSSHEET_ENGINE_REQUEST,
      payload: { product, engine },
    });

    const { payload, status } = await EngineService.getJsSheetEngine(
      product,
      engine
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.DOWNLOAD_JSSHEET_ENGINE_SUCCESS,
    });
    dispatch(downloadJsSheetFile(product, engine, payload.data.id));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.DOWNLOAD_JSSHEET_ENGINE_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const generateTesterApiDocumentation = (
  productName: string,
  serviceName: string,
  category: string,
  version?: string
): EngineThunkAction<unknown> => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.API_TESTER_DOCUMENTATION_REQUEST,
    });
    const data: {
      productName: string;
      serviceName: string;
      version?: string;
      category: string;
    } = {
      productName,
      serviceName,
      category,
    };

    if (version) {
      data.version = version;
    }

    const {
      payload,
      status,
    } = await EngineService.generateTesterApiDocumentation(data);

    if (status !== 200 || payload?.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.API_TESTER_DOCUMENTATION_SUCCESS,
      payload: {
        productName,
        serviceName,
        version,
        category,
        documentation: payload as string,
      },
    });
    /* eslint consistent-return: "off" */
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineConstants.API_TESTER_DOCUMENTATION_FAILED,
      payload: {
        productName,
        error: msg,
      },
    });
  }
};

const downloadTesterApiDocumentationAsPDF = (
  HtmlBase64String: string,
  Content: (value?: string) => MessageConfigProps,
  fileName: string
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.API_DOCS_DOWNLOAD_REQUEST,
    });
    const {
      status,
      payload,
    } = await EngineService.downloadTesterApiDocumentationAsPDF(
      HtmlBase64String
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    if (payload?.blob) {
      dispatch({
        type: EngineConstants.API_DOCS_DOWNLOAD_SUCCESS,
      });
      downloadBlob(payload.blob, fileName);
      dispatch(AlertActions.customConfigAlert(Content()));
    }

    /* eslint consistent-return: "off" */
  } catch (error) {
    dispatch(AlertActions.error(error));
    dispatch({
      type: EngineConstants.API_DOCS_DOWNLOAD_FAILURE,
    });
  }
};

const downloadTesterApiDocumentationAsHTML = (
  HtmlBase64String: string,
  Content: (value?: string) => MessageConfigProps,
  fileName = 'ApiDoc'
): EngineThunkAction => dispatch => {
  try {
    const blobName = fileName;
    const blob = new Blob([HtmlBase64String], { type: 'text/html' });

    if (blob) {
      downloadBlob(blob, blobName ?? fileName);
      dispatch(AlertActions.customConfigAlert(Content()));
    }

    /* eslint consistent-return: "off" */
  } catch (error) {
    dispatch(AlertActions.error(error));
  }
};

const cancelGenerateJsSheet = (): EngineThunkAction => dispatch => {
  dispatch({
    type: EngineConstants.CANCEL_GENERATE_JSSHEET,
  });
};

const resetEngineUpload = (): EngineThunkAction => dispatch => {
  dispatch({
    type: EngineConstants.RESET_ENGINE_UPLOAD,
  });
};

export const EngineActions = {
  getProductEngines,
  getProductEngineDetails,
  showUpdateConfirm,
  cancelUpdateConfirm,
  updateProperties,
  updateEngineFavorite,
  publishNewEngine,
  uploadEngine,
  cancelPublishEngine,
  resetAddEngine,
  increaseExecuteProgress,
  deleteEngine,
  resetProductEngines,
  executeProductEngine,
  apiTesterExecuteInputsTest,
  executeProductEngineWithChain,
  apiTesterReset,
  apiTesterWarningHide,
  apiTesterWarningShow,
  downloadEngineFile,
  downloadSwagger,
  downloadOutput,
  getEngineVersions,
  apiTesterModalHide,
  getProxyUrl,
  createProxyUrl,
  deleteProxyUrl,
  downloadCUrl,
  getCurlPreview,
  getSwaggerPreview,
  updateReleaseNote,
  updateEngineProps,
  restoreVersion,
  generateJsSheet,
  downloadJsSheet,
  resetProductEngineSelection,
  generateTesterApiDocumentation,
  downloadTesterApiDocumentationAsPDF,
  downloadTesterApiDocumentationAsHTML,
  cancelGenerateJsSheet,
  resetEngineUpload,
};
