let jsonConfig: DTO.GlobalConfiguration = {
  excelEngineDomain: '',
  userPilotToken: '',
  redirectUrl: '',
  defaultTenant: '',
  defaultTimeout: '',
  idpConfiguration: '',
  apiGatewayApiVersion: '',
  apiGatewaySubscriptionKey: '',
  skipLoginFlow: '',
  issuers: [],
  usersManagementUrl: '',
  reportSupportUrl: '',
};

export const getBaseUrl = () => {
  let baseUrl = `${window.location.protocol}//${window.location.host}`;
  if (
    process.env.REACT_APP_PUBLIC_URL &&
    process.env.REACT_APP_PUBLIC_URL !== ''
  ) {
    baseUrl = `${window.location.protocol}//${window.location.host}${process.env.REACT_APP_PUBLIC_URL}`;
  }

  return baseUrl;
};

export const fetchJsonConfig = async () => {
  const baseUrl = getBaseUrl();

  await fetch(`${baseUrl}/config.json`)
    .then(response => response.text())
    .then(text => {
      try {
        jsonConfig = JSON.parse(text);
        // eslint-disable-next-line no-empty
      } catch (err) {}
    });

  await fetch(`${baseUrl}/issuer.json`)
    .then(response => response.text())
    .then(text => {
      try {
        jsonConfig.issuers = JSON.parse(text);
        // eslint-disable-next-line no-empty
      } catch (err) {}
    });
};

const isValidConfigProperty = (propertyName: string | undefined): boolean => {
  return !(propertyName === '' || !propertyName);
};

export const getConfig = (): DTO.GlobalConfiguration => {
  return {
    excelEngineDomain: !isValidConfigProperty(jsonConfig.excelEngineDomain)
      ? process.env.REACT_APP_EXCELENGINE_DOMAIN
      : jsonConfig.excelEngineDomain,

    userPilotToken: !isValidConfigProperty(jsonConfig.userPilotToken)
      ? process.env.REACT_APP_USERPILOT_APP_TOKEN
      : jsonConfig.userPilotToken,

    redirectUrl: !isValidConfigProperty(jsonConfig.redirectUrl)
      ? process.env.REACT_APP_REDIRECT_URL
      : jsonConfig.redirectUrl,

    defaultTenant: !isValidConfigProperty(jsonConfig.defaultTenant)
      ? process.env.REACT_APP_DEFAULT_TENANT
      : jsonConfig.defaultTenant,

    defaultTimeout: !isValidConfigProperty(jsonConfig.defaultTimeout)
      ? process.env.REACT_APP_DEFAULT_TIMEOUT_IN_MINUTE
      : jsonConfig.defaultTimeout,

    idpConfiguration: !isValidConfigProperty(jsonConfig.idpConfiguration)
      ? process.env.REACT_APP_IDP_CONFIGURATION_FILE
      : jsonConfig.idpConfiguration,

    apiGatewayApiVersion: !isValidConfigProperty(
      jsonConfig.apiGatewayApiVersion
    )
      ? process.env.REACT_APP_APIGATEWAY_API_VERSION
      : jsonConfig.apiGatewayApiVersion,

    apiGatewaySubscriptionKey: !isValidConfigProperty(
      jsonConfig.apiGatewaySubscriptionKey
    )
      ? process.env.REACT_APP_APIGATEWAY_SUBSCRIPTION_KEY
      : jsonConfig.apiGatewaySubscriptionKey,

    skipLoginFlow: !isValidConfigProperty(jsonConfig.skipLoginFlow)
      ? process.env.REACT_APP_SKIP_LOGIN_FLOW
      : jsonConfig.skipLoginFlow,

    sentryDsn: !isValidConfigProperty(jsonConfig.sentryDsn)
      ? process.env.REACT_APP_SENTRY_DSN
      : jsonConfig.sentryDsn,

    sentryEnvironment: !isValidConfigProperty(jsonConfig.sentryEnvironment)
      ? process.env.REACT_APP_SENTRY_ENVIRONMENT
      : jsonConfig.sentryEnvironment,

    version: !isValidConfigProperty(jsonConfig.version)
      ? process.env.REACT_APP_VERSION
      : jsonConfig.version,

    issuers: jsonConfig.issuers,

    usersManagementUrl: !isValidConfigProperty(jsonConfig.usersManagementUrl)
      ? process.env.REACT_APP_USERS_MANAGEMENT_URL
      : jsonConfig.usersManagementUrl,

    reportSupportUrl: !isValidConfigProperty(jsonConfig.reportSupportUrl)
      ? process.env.REACT_APP_REPORT_URL
      : jsonConfig.reportSupportUrl,
  };
};
