import { getConfig } from '../helpers/config';
import makeRequest from '../helpers/make-request';
import uploadRequest from '../helpers/upload-request';

const getMasterReferences = (request: DTO.GetMasterReferencesRequest) => {
  const {
    page,
    pageSize,
    sortBy = 'updated',
    sort = 'desc',
    searchText = '',
  } = request;

  const url = `${getConfig().excelEngineDomain}/api/MasterReference/list`;

  const search: { field: string; value: string }[] = [];
  if (searchText) {
    search.push({ field: 'referenceId_co', value: searchText });
  }

  return makeRequest<DTO.GetMasterReferencesResponse>('POST', url, {
    page,
    pageSize,
    sort: sortBy ? `${sort === 'desc' ? '-' : ''}${sortBy}` : '',
    search,
  });
};

const getMasterFileVersions = (request: DTO.GetMasterFileVersionsRequest) => {
  const { page, pageSize, documentId } = request;

  const url = `${
    getConfig().excelEngineDomain
  }/api/MasterReference/GetAllVersions/${documentId}`;
  return makeRequest<DTO.GetMasterReferenceVersionsResponse>('POST', url, {
    page,
    pageSize,
  });
};

const upload = (
  file: File | undefined,
  documentId: string | null | undefined,
  request: DTO.UpdateMasterRefRequest,
  onUploadProgress: (precent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const { effectiveStartDate, effectiveEndDate } = request;

  const url = `${getConfig().excelEngineDomain}/api/MasterReference/add`;
  const formData = new FormData();

  file && formData.append('file', file);
  documentId && formData.append('id', documentId);
  effectiveStartDate &&
    formData.append('effectiveStartDate', effectiveStartDate);
  effectiveEndDate && formData.append('effectiveEndDate', effectiveEndDate);

  return uploadRequest<DTO.UploadReferenceFileResponse>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const publshUploadedFile = (
  pubRequest: DTO.PublishUploadRequest,
  category: 'MASTER_API_FILE' | 'MASTER_REF_FILE'
) => {
  const { draftFileId, revision, documentId, releaseNote } = pubRequest;

  const url: string =
    category === 'MASTER_REF_FILE'
      ? `${getConfig().excelEngineDomain}/api/MasterReference/publish`
      : `${getConfig().excelEngineDomain}/api/MasterApiDefinition/publish`;

  return makeRequest<DTO.PublishUploadResponse>('POST', url, {
    draftFileId,
    revision,
    documentId,
    releaseNote,
  });
};

const getDownloadReferenceFileUrl = (documentId: string, isVersion = false) =>
  `${getConfig().excelEngineDomain}/api/MasterReference/${
    isVersion ? 'DownloadVersion' : 'Download'
  }/${documentId}`;

const deleteDocument = (documentId: string, serviceName: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/MasterReference/${documentId}/${serviceName}`;

  return makeRequest('DELETE', url);
};

const syncData = (request: DTO.SyncDataRequest) => {
  const { productName, serviceName, version } = request;
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/syncdata/${serviceName}/${version}`;
  return makeRequest<DTO.SyncDataResponse>('POST', url, request);
};

const getDetails = (documentId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/MasterReference/${documentId}`;

  return makeRequest<{ data: DTO.MasterFileData }>('GET', url);
};

const getMasterAPIdefFile = () => {
  const url = `${getConfig().excelEngineDomain}/api/MasterApiDefinition/Get`;
  return makeRequest<DTO.GetMasterAPIdefFileResponse>('GET', url);
};

const getDownloadMasterAPIDefFileUrl = (
  documentName: string,
  isVersion = false
) =>
  `${getConfig().excelEngineDomain}/api/MasterApiDefinition/${
    isVersion ? 'DownloadVersion' : 'Download'
  }/${documentName}`;

const uploadMasterAPIDef = (
  file: File | undefined,
  documentId: string | null | undefined,
  onUploadProgress: (precent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url = `${getConfig().excelEngineDomain}/api/MasterApiDefinition/add`;
  const formData = new FormData();

  file && formData.append('file', file);
  documentId && formData.append('id', documentId);

  return uploadRequest<DTO.UploadReferenceFileResponse>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const deleteMasterAPIDef = (documentId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/MasterApiDefinition/${documentId}`;

  return makeRequest('DELETE', url);
};

const getDownStreamEngines = (documentId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/MasterReference/GetDownStream/${documentId}`;
  return makeRequest<{ data: DTO.DownstreamEngine[] }>('GET', url);
};

const syncAllDownStreamEngines = (documentId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/MasterReference/SyncAllDownStreamEngines/${documentId}`;
  return makeRequest<{ data: DTO.DownstreamEngine[] }>('POST', url);
};

const readReferenceRange = (
  documentId: string,
  referenceRange: string,
  isVersion = false
) => {
  const url = `${getConfig().excelEngineDomain}/api/MasterReference/${
    isVersion ? 'readVersion' : 'read'
  }/${documentId}/${referenceRange}`;
  return makeRequest<{ data: string | null }>('GET', url);
};

const writeReferenceRange = (
  documentId: string,
  referenceRange: string,
  value: object | string
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/MasterReference/write/${documentId}/${referenceRange}`;
  return makeRequest('POST', url, value);
};

const getProductTutorials = () => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/MasterApiDefinition/GetProductTutorials`;
  return makeRequest<{ data: DTO.ProductTutorial[] }>('GET', url);
};

const validateProductTutorial = (product: string, engine: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/MasterApiDefinition/ValidateProductTutorial/${product}/${engine}`;
  return makeRequest<{ data: DTO.ProductTutorialMisMatches }>('GET', url);
};

export const MasterReferenceServices = {
  getMasterReferences,
  getMasterFileVersions,
  upload,
  publshUploadedFile,
  syncData,
  getDownloadReferenceFileUrl,
  deleteDocument,
  getDetails,
  getMasterAPIdefFile,
  getDownloadMasterAPIDefFileUrl,
  uploadMasterAPIDef,
  deleteMasterAPIDef,
  getDownStreamEngines,
  syncAllDownStreamEngines,
  readReferenceRange,
  writeReferenceRange,
  getProductTutorials,
  validateProductTutorial,
};
