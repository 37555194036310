import jwt from 'jsonwebtoken';
import { store } from './store';
import { getConfig } from './config';
import { refresh } from './user-auth';
import { UserConstants } from '../constants';

const getXHRResponse = <T = {}>(xhr: XMLHttpRequest) => {
  const { status, responseText, response } = xhr;

  const text = responseText || response;
  let payload: T = text;

  if (text) {
    try {
      payload = JSON.parse(text);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  } else if (status === 0) {
    // getting status 0 when unable to connect to server
    payload = {
      error_code: UserConstants.UNABLE_TO_CONNECT,
    } as T & { error_code: string };
  }

  return {
    status,
    payload,
    headers: new Headers(),
  };
};

const uploadRequest = <T = {}>(
  method: string,
  url: string,
  body: FormData,
  onUploadProgress?: (uploadPercent: number) => void,
  xhrRef?: (xhr: XMLHttpRequest) => void
): Promise<DTO.ApiResponse<T>> => {
  const config = getConfig();

  const { userAuth } = store.getState().auth;
  const id_token = userAuth && userAuth.id_token;
  const decodedJwt = id_token && jwt.decode(id_token);
  const headerApiGatewayApiVersion = {
    apiVersion: config.apiGatewayApiVersion || '',
  };

  const headerApiGatewaySubscriptionKey = {
    'ocp-apim-subscription-key': config.apiGatewaySubscriptionKey || '',
  };

  const uploadTask = (userToken: string) =>
    new Promise<DTO.ApiResponse<T>>(resolve => {
      const xhr = new XMLHttpRequest();

      if (onUploadProgress && xhr.upload) {
        xhr.upload.onprogress = e =>
          onUploadProgress(e.total > 0 ? (e.loaded / e.total) * 100 : 0);
      }

      xhr.onerror = () => {
        resolve(getXHRResponse<T>(xhr));
      };

      xhr.onload = () => {
        if (xhr.status < 200 || xhr.status >= 300) {
          resolve(getXHRResponse<T>(xhr));

          return;
        }

        resolve(getXHRResponse<T>(xhr));
      };

      xhr.open(method, url);

      const tenant = localStorage.getItem('Tenant');
      const headerTenant = {
        'x-tenant-name': tenant || config.defaultTenant || '',
      };
      const headerAuth = {
        Authorization: `Bearer ${userToken}`,
      };

      Object.entries({
        ...headerAuth,
        ...headerTenant,
        ...headerApiGatewayApiVersion,
        ...headerApiGatewaySubscriptionKey,
      }).forEach(([key, value]) => {
        xhr.setRequestHeader(key, value);
      });

      xhr.send(body);

      xhrRef && xhrRef(xhr);
    });

  if (
    !id_token ||
    (decodedJwt && decodedJwt['exp'] && decodedJwt['exp'] * 1000 < Date.now())
  ) {
    const refreshTokenTask = async (): Promise<string> => {
      const newIdToken = await refresh(store);
      return newIdToken ?? '';
    };

    return refreshTokenTask().then(uploadTask);
  }

  return uploadTask(id_token);
};

export default uploadRequest;
