import React, { memo, NamedExoticComponent } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const StyledContainer = styled.div`
  height: 44px;
  text-align: center;
  width: 100%;

  button,
  button:hover,
  button:active,
  button:focus {
    color: ${({ theme }) => theme.vars.textColor};
    font-size: 16px;
    line-height: 1.2;
  }
`;

interface ShowMoreButtonProps {
  onClick: () => void;
  className?: string;
  title?: string;
}

const ShowMoreButton: NamedExoticComponent<ShowMoreButtonProps> = memo(
  ({ onClick, title, className }) => (
    <StyledContainer className={`show-more-button ${className}`}>
      <Button type="link" onClick={onClick}>
        <FormattedMessage id={title || 'Global.showMore'} />
      </Button>
    </StyledContainer>
  )
);

export { ShowMoreButton };
