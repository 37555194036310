import { Button } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

export interface CustomButtonProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  label: string;
  type?: 'primary' | 'default' | 'ghost' | 'dashed' | 'link';
  shape?: 'circle' | 'round';
  disabled?: boolean;
  htmlType?: 'button' | 'submit' | 'reset';
}
const ButtonWrapper = styled(Button)`
  .ant-btn {
    margin: 0 5px;
    height: 36px !important;
    &.ant-btn-primary {
      color: ${({ theme }) => theme.color.white};
      background-color: ${({ theme }) => theme.color.purpleDarkened2};
      border-color: ${({ theme }) => theme.color.purpleDarkened2};
      border: none;
      :focus,
      :hover {
        background-color: ${({ theme }) => theme.color.purpleDarkened1};
        border: none;
        color: ${({ theme }) => theme.color.white};
      }
      :active {
        background-color: ${({ theme }) => theme.color.purpleDarkened3};
      }
    }
    &.ant-btn-default {
      border-color: ${({ theme }) => theme.color.purple};
      color: ${({ theme }) => theme.color.purpleDarkened3};
      font-weight: bold;
      :focus,
      :hover {
        background-color: ${({ theme }) => theme.color.purple20};
      }
      :active {
        background-color: ${({ theme }) => theme.color.purpleDarkened3};
        color: ${({ theme }) => theme.color.white};
      }
    }
  }
`;

const CustomButton: FC<CustomButtonProps> = ({
  onClick,
  className,
  label,
  type,
  shape,
  disabled,
  htmlType,
}) => {
  return (
    <ButtonWrapper
      className={className}
      onClick={onClick}
      shape={shape ?? 'circle'}
      type={type ?? 'primary'}
      disabled={disabled}
      htmlType={htmlType}
    >
      <label>
        <FormattedMessage id={label} />
      </label>
    </ButtonWrapper>
  );
};

export { CustomButton };
