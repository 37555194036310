import { TemplateManagerAction } from '../actions/types';
import { TemplateManagerConstants } from '../constants';
import { arrayRemoveFirst } from '../helpers';

export const initialTemplateManagerState: STATES.TemplateManagerState = {
  isLoading: false,
  templates: [],
  favouriteTemplates: [],
  page: 1,
  totalPages: 0,
  pageSize: 10,
  uploadProgress: 0,
  error: null,
  selectedTemplate: null,
  selectedTemplateName: '',
  refreshTrigger: 0,
};

export const templateManager = (
  state = initialTemplateManagerState,
  action: TemplateManagerAction
): STATES.TemplateManagerState => {
  switch (action.type) {
    case TemplateManagerConstants.GET_TEMPLATES_REQUEST: {
      const { page } = action.payload;

      return {
        ...state,
        page,
        isLoading: true,
      };
    }
    case TemplateManagerConstants.GET_TEMPLATES_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case TemplateManagerConstants.GET_TEMPLATES_SUCCESS: {
      const { favouriteTemplates, isStarred, total } = action.payload;
      let { templates, page, pageSize } = action.payload;
      let totalPages;

      if (isStarred && isStarred === 'false') {
        if (page > 1) {
          templates = [...state.templates, ...templates];
        } else {
          templates = state.templates;
        }
      }

      if (isStarred && isStarred === 'true') {
        page = state.page;
        pageSize = state.pageSize;
        totalPages = state.totalPages;
      } else {
        totalPages =
          Math.floor(total / state.pageSize) + (total % state.pageSize ? 1 : 0);
      }

      return {
        ...state,
        isLoading: false,
        templates:
          !isStarred || isStarred === 'false' ? templates : state.templates,
        favouriteTemplates:
          isStarred && isStarred === 'true'
            ? favouriteTemplates
            : state.favouriteTemplates,
        totalPages,
        page,
        pageSize,
      };
    }

    case TemplateManagerConstants.CREATE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshTrigger: state.refreshTrigger + 1,
      };
    }

    case TemplateManagerConstants.DELETE_TEMPLATE_SUCCESS: {
      const { id, isStarred } = action.payload;

      if (isStarred) {
        return {
          ...state,
          favouriteTemplates: arrayRemoveFirst(
            state.favouriteTemplates,
            template => template.id === id
          ),
        };
      }
      return {
        ...state,
        templates: arrayRemoveFirst(
          state.templates,
          template => template.id === id
        ),
      };
    }

    case TemplateManagerConstants.UPDATE_TEMPLATE_FAVORITE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshTrigger: state.refreshTrigger + 1,
      };
    }

    case TemplateManagerConstants.SELECT_TEMPLATE: {
      const { template } = action.payload;

      return {
        ...state,
        selectedTemplate: template,
      };
    }

    case TemplateManagerConstants.SELECTED_TEMPLATE_RESET: {
      return {
        ...state,
        selectedTemplate: null,
      };
    }

    case TemplateManagerConstants.UPDATE_TEMPLATE_SUCCESS: {
      const { selectedTemplateName } = action.payload;
      return {
        ...state,
        selectedTemplate: null,
        selectedTemplateName,
        isLoading: false,
        refreshTrigger: state.refreshTrigger + 1,
      };
    }

    default:
      return state;
  }
};
