/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import { getConfig } from '../helpers';
import makeRequest from '../helpers/make-request';

const createKeyCloakAnonymousUser = (
  anonymousUser: DTO.KeyCloakAnonymousUser
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/userlogin/keycloak/CreateAnonymousUser`;
  return makeRequest<{
    data: string;
  }>('POST', url, anonymousUser);
};

const sendOTPToAnonymousUser = (anonymousUserId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/userlogin/keycloak/SendOTPToAnonymousUser/${anonymousUserId}`;
  return makeRequest('POST', url);
};

const generateAnonymouseUserToken = (
  anonymousUserId: string,
  linkId: string,
  code: string,
  client: string
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/userlogin/keycloak/GenerateAnonymouseUserToken/${anonymousUserId}/${linkId}/${code}/${client}`;
  return makeRequest<{ data: { targetUrl: string; access_token: string } }>(
    'POST',
    url
  );
};

const getIssuers = async (
  issuer?: string
): Promise<DTO.IssuerConfiguration | undefined> => {
  let issuerConfigs: DTO.IssuerConfiguration[] | undefined;
  const config = getConfig();
  const idpConfigFile = config.idpConfiguration;
  if (config?.issuers && config?.issuers?.length > 0) {
    issuerConfigs = config.issuers;
  } else {
    const issuerConfigFile = await require(`../assets/${idpConfigFile}`);
    issuerConfigs = issuerConfigFile.issuers;
  }

  const tenant = localStorage.getItem('Tenant') || config.defaultTenant;
  return issuerConfigs?.find(
    s =>
      (issuer && s.issuer.toLowerCase() === issuer.toLowerCase()) ||
      (!issuer && tenant && s.tenant.toLowerCase() === tenant.toLowerCase())
  );
};

const getIssuerConfiguration = async (
  issuer?: string
): Promise<DTO.IssuerConfiguration | null> => {
  const issuerConfig = await getIssuers(issuer);

  if (!issuerConfig) {
    return null;
  }

  if (issuerConfig.verifierType === 'oidc') {
    let openIdConfigStr = localStorage.getItem(issuerConfig.verifierUri);
    if (!openIdConfigStr) {
      const response = await makeRequest<DTO.OpenIdConfiguarations>(
        'GET',
        issuerConfig.verifierUri,
        null,
        'application/json',
        null,
        null,
        false,
        true
      );

      if (response && response.payload && response.payload.issuer) {
        openIdConfigStr = JSON.stringify(response.payload);
        localStorage.setItem(issuerConfig.verifierUri, openIdConfigStr);
      }
    }

    if (openIdConfigStr) {
      const openIdConfig = JSON.parse(openIdConfigStr);
      issuerConfig.tokenUri = openIdConfig.token_endpoint;
      issuerConfig.authUri = openIdConfig.authorization_endpoint;
      issuerConfig.logoutUri = openIdConfig.end_session_endpoint;
      issuerConfig.jwksUri = openIdConfig.jwks_uri;
    }
  } else {
    issuerConfig.jwksUri = issuerConfig.verifierUri;
  }

  issuerConfig.authFlow = issuerConfig.authFlow ?? 'token';

  return issuerConfig;
};

const getUserInformation = () => {
  const url = `${getConfig().excelEngineDomain}/api/v1/UserLogin/GetUserDetail`;
  return makeRequest<{ data: DTO.UserDetail }>('GET', url);
};

export const UserService = {
  createKeyCloakAnonymousUser,
  sendOTPToAnonymousUser,
  generateAnonymouseUserToken,
  getIssuerConfiguration,
  getUserInformation,
};
