import { Action, applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createTransform, persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import reducers from '../reducers';
import { initialAuthState } from '../reducers/auth.reducer';
import { createNewIntl } from './create-new-intl';
import { getValidUserAuth } from './get-valid-user-auth';

const NODE_ENV = process.env.NODE_ENV;
const middleWares = [thunkMiddleware];
const rootReducer = combineReducers(reducers);

const langTransform = createTransform<
  STATES.LanguageState,
  STATES.LanguageState
>(
  (inboundState, key) => {
    if (key === 'language') {
      return { ...inboundState, intl: null };
    }

    return { ...inboundState };
  },
  outboundState => {
    if (!outboundState.intl) {
      return {
        ...outboundState,
        init: createNewIntl(
          outboundState.currentLanguage,
          outboundState.messages
        ),
      };
    }

    return { ...outboundState };
  },
  {
    whitelist: ['lang'],
  }
);

const authTransform = createTransform<STATES.AuthState, STATES.AuthState>(
  (inboundState, key) => {
    if (key === 'auth') {
      return {
        ...inboundState,
        isLoadingRules: false,
        isLoggingOut: false,
      };
    }

    return { ...inboundState };
  },
  (outboundState, key) => {
    if (key === 'auth') {
      return outboundState.userAuth &&
        getValidUserAuth(outboundState.userAuth).userAuth // Set to logged out state if user token expired
        ? { ...outboundState, changingPassword: false }
        : { ...initialAuthState };
    }

    return { ...outboundState };
  },
  {
    whitelist: ['auth'],
  }
);

const systemTransform = createTransform<STATES.SystemState, STATES.SystemState>(
  (inboundState, key) => {
    if (key === 'system') {
      return {
        ...inboundState,
        isLoading: false,
        isLoadingReleaseDetails: false,
        isLoadingLookupData: false,
      };
    }

    return { ...inboundState };
  },
  outboundState => {
    return { ...outboundState };
  }
);

export const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'user', 'language', 'system', 'permissions'],
  transforms: [authTransform, langTransform, systemTransform],
  stateReconciler: autoMergeLevel2,
};

export const store = createStore(
  persistReducer<ReturnType<typeof rootReducer>, Action>(
    rootPersistConfig,
    rootReducer
  ),
  NODE_ENV !== 'production'
    ? composeWithDevTools(applyMiddleware(...middleWares))
    : applyMiddleware(...middleWares)
);

export const persistor = persistStore(store);
