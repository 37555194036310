import React, { FC, useState, useCallback, useEffect, useRef } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { Popover, Button, Icon, Row, Col } from 'antd';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { colorHexToRgba } from '../helpers';
import { BackgroundJobItem } from '../components/BackgroundJobs';
import { ScrollCss, IconSvg } from '../components/Common';
import { useAppDispatch, useModal } from '../hooks';
import { BackgroundJobsActions, TestbedActions } from '../actions';

const JobListCss = css`
  .ant-popover.background-jobs {
    padding-top: 0 !important;
    z-index: 999;

    .ant-popover-content > .ant-popover-arrow {
      display: none;
    }

    .ant-popover-content {
      width: 360px;
      max-height: 500px;

      .ant-popover-inner {
        background: ${({ theme }) => theme.color.white};
        border: 1px solid
          ${({ theme }) => colorHexToRgba(theme.color.dark, 0.4)};
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        font-size: 12px;

        .ant-popover-inner-content {
          color: ${({ theme }) => theme.color.dark};
          padding: 10px 0px;
        }
      }
    }
  }
`;

const JobTooltipCss = css`
  .ant-popover.background-jobs-tooltip {
    .ant-popover-content {
      .ant-popover-arrow {
        border-top-color: ${({ theme }) => theme.color.purple} !important;
        border-right-color: ${({ theme }) => theme.color.purple} !important;
      }

      .ant-popover-inner-content > div {
        display: flex;
        max-width: 220px;
        align-items: center;

        .anticon {
          font-size: 12px;
        }

        .content {
          margin-left: 10px;
        }
      }
    }
  }
`;

const PopoverStyle = createGlobalStyle<{ theme: STATES.ThemeState }>`
  ${JobListCss}
  ${JobTooltipCss}
`;

const TogglerContainer = styled.div`
  && {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-top: 4px;

    .dashed {
      float: left;
      display: inline-flex;
      margin-left: 10px;
      margin-top: 0px;

      span {
        font-size: 14px !important;
        color: ${({ theme }) => theme.color.dark60};
      }

      .job-progress-status-ic {
        &.anticon {
          font-size: 21px !important;
        }
      }
      .success {
        color: ${({ theme }) => theme.color.success} !important;
      }

      .error {
        color: ${({ theme }) => theme.color.dark80} !important;
      }
    }

    .ant-btn {
      border: 1px solid ${({ theme }) => theme.color.purple};
      border-radius: 4px;
      font-weight: normal;
      background: ${({ theme }) => theme.color.white};
      color: ${({ theme }) => theme.color.dark};
      font-size: 12px;
      height: 36px;
      padding: 0 5px 0 0;
      align-items: center;
      .status {
        margin-left: 5px;
      }

      .status-error {
        margin-left: 5px !important;
        font-weight: 700 !important;
        color: ${({ theme }) => theme.color.red} !important;
      }

      .drop-icon {
        color: ${({ theme }) => theme.color.purple} !important;
      }

      .anticon {
        font-size: 18px;
        color: ${({ theme }) => theme.color.purple};
      }
    }
  }
`;

const PopoverContent = styled.div`
  .popup-heading {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    line-height: 16px;
    align-items: center;
    padding: 10px 16px;
  }

  .job-list {
    max-height: 345px;
    overflow-y: auto;
    ${ScrollCss}
  }

  .ant-btn {
    margin-top: 2px;
  }
`;

const StyledGroupHeading = styled.div`
  background-color: ${({ theme }) => theme.color.pearl};
  padding: 5px 58px;
  font-weight: 700;
`;
const StyledLink = styled.div`
  line-heigth: 1.5;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;

  & button {
    color: ${({ theme }) => theme.color.dark} !important;
    text-decoration: italic !important;
    font-size: 13px !important;
    text-decoration: underline !important;
    font-weight: 800 !important;
  }
`;

const StledButton = styled(Button)`
  width: 88px !important;
  min-width: 88px !important;
  border-color: ${({ theme }) => theme.color.gray2} !important;
`;

interface JobGroup {
  key: string;
  value: DTO.BackgroundJob[];
}
const HeadingRow = styled(Row)`
  font-weight: 700;
`;

export const backgroundJobSelector = createSelector<
  STATES.AppState,
  boolean,
  STATES.BackgroundJobsState,
  STATES.BackgroundJobsState & { loggedIn: boolean }
>(
  ({ auth }) => auth.loggedIn,
  ({ backgroundJobs }) => backgroundJobs,
  (loggedIn, backgroundJobs) => ({ ...backgroundJobs, loggedIn })
);

const BackgroundJobsPopover: FC = () => {
  const dispatch = useAppDispatch();
  const { showConfirm } = useModal();
  const {
    jobs,
    generalPercent,
    refreshTrigger,
    intervalTime,
    loggedIn,
    currentActive,
    jobsToDownload,
  } = useSelector(backgroundJobSelector);
  const timeoutRef = useRef(0);
  const [bgListVisible, setBgListVisible] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(
    currentActive !== 'none' && currentActive !== 'downloadLog'
  );
  const [groupedTasks, setGroupedTasks] = useState<JobGroup[]>([]);

  const [failedTasks, setFailedTasks] = useState<DTO.BackgroundJob[]>([]);
  const [inprogressTasks, setInProgressTasks] = useState<DTO.BackgroundJob[]>(
    []
  );

  useEffect(() => {
    if (jobs.length > 0) {
      const jobGroups: JobGroup[] = [];
      jobs.forEach(job => {
        const found = jobGroups.some(el => el.key === job.type);
        if (!found) {
          jobGroups.push({ key: job.type, value: [job] });
        } else {
          jobGroups.forEach(jobGroup => {
            if (jobGroup.key === job.type) {
              jobGroup.value.push(job);
            }
          });
        }
      });
      setGroupedTasks(jobGroups);

      setFailedTasks(jobs.filter(job => job.status === 'Fail'));
      setInProgressTasks(
        jobs.filter(job => ['InProgress', 'Pending'].includes(job.status))
      );
    }
  }, [jobs]);

  useEffect(() => {
    if (loggedIn) {
      dispatch(BackgroundJobsActions.getAll());
      timeoutRef.current = setInterval(() => {
        dispatch(BackgroundJobsActions.getAll());
      }, intervalTime);
    }

    return () => {
      clearInterval(timeoutRef.current);
    };
  }, [dispatch, refreshTrigger, intervalTime, loggedIn]);

  useEffect(() => {
    const jobsToTriggerDownload = Object.entries(jobsToDownload).reduce<{
      [jobId: string]: string | null;
    }>((prev, [jobId, isWaiting]) => {
      if (isWaiting) {
        const job = jobs.find(
          j =>
            j.id === jobId &&
            (j.type === 'DownloadFolder' ||
              j.type === 'DownloadLogs' ||
              j.type === 'TestbedResultCSV' ||
              j.type === 'TestbedResultExcel') &&
            j.status === 'Success' &&
            j.result
        );

        if (job && job.status === 'Success') {
          prev[jobId] = job.result;
        }
      }

      return prev;
    }, {});

    dispatch(BackgroundJobsActions.triggerDownloadJobs(jobsToTriggerDownload));
  }, [jobsToDownload, jobs, dispatch]);

  useEffect(() => {
    const hasWaitingJobs = Object.keys(jobsToDownload).length !== 0;
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      // Cancel the event
      // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      e.preventDefault();

      // Chrome requires returnValue to be set
      e.returnValue = '';
    };

    hasWaitingJobs && window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [jobsToDownload]);

  useEffect(() => {
    setTooltipVisible(
      !bgListVisible &&
        currentActive !== 'none' &&
        currentActive !== 'downloadLog'
    );
  }, [currentActive, bgListVisible]);

  const onCancelJob = useCallback(
    (job: DTO.BackgroundJob) => {
      showConfirm({
        content: 'BackgroundJobsPopover.popup.cancel.confirm',
        okText: 'Global.continue',
        onOkAsync: async () => {
          if (job.type === 'DownloadFolder' || job.type === 'UploadFolder') {
            await dispatch(BackgroundJobsActions.cancelJob(job.id));
          } else if (
            job.type === 'TestRunComparer' ||
            job.type === 'GenerateTestCases' ||
            job.type === 'IngeniumAdapter' ||
            job.type === 'JsSheetGenerate' ||
            job.type === 'DownloadLogs' ||
            job.type === 'TestbedResultCSV' ||
            job.type === 'TestbedResultExcel'
          ) {
            await BackgroundJobsActions.cancelTaskQueue(dispatch, job.id);
          } else {
            await TestbedActions.cancelTestbedQueue(dispatch, job.id);
          }
        },
      });
    },
    [dispatch, showConfirm]
  );

  if (jobs.length === 0) {
    return null;
  }

  const content = (
    <PopoverContent>
      <div className="popup-heading">
        <FormattedMessage id="BackgroundJobsPopover.popup.title" />
      </div>
      <HeadingRow>
        <Col span={8} offset={1}>
          <FormattedMessage id="BackgroundJobsPopover.popup.heading.tasks" />
        </Col>
        <Col span={4} offset={11}>
          <FormattedMessage id="BackgroundJobsPopover.popup.heading.status" />
        </Col>
      </HeadingRow>
      <div className="job-list">
        {groupedTasks.length > 0 &&
          groupedTasks.map(c => (
            <>
              <StyledGroupHeading>
                <FormattedMessage
                  id={`BackgroundJobsPopover.popup.group.${c.key}`}
                />
              </StyledGroupHeading>
              {c.value.map(job => (
                <BackgroundJobItem
                  key={job.id}
                  job={job}
                  onCancel={onCancelJob}
                />
              ))}
            </>
          ))}
      </div>
      <StyledLink>
        <Button
          type="link"
          onClick={() => {
            setBgListVisible(false);
          }}
        >
          <FormattedMessage id="BackgroundJobsPopover.popup.showless" />
        </Button>
      </StyledLink>
    </PopoverContent>
  );

  const bgListVisibleOnChange = (next: boolean) => {
    setBgListVisible(next);
  };

  const tooltipVisibleOnChange = () => {
    setTooltipVisible(
      !bgListVisible &&
        currentActive !== 'none' &&
        currentActive !== 'downloadLog'
    );
  };

  const GetStatus = () => {
    if (inprogressTasks.length > 0) {
      return <span>{`${generalPercent.toFixed(0)}%`}</span>;
    }
    if (!inprogressTasks.length && failedTasks.length > 0) {
      return (
        <IconSvg className="job-progress-status-ic error" type="DangerDark" />
      );
    }
    return (
      <IconSvg
        className="job-progress-status-ic success"
        type="CircleSuccessCheck"
      />
    );
  };

  const togglerContainer = (
    <TogglerContainer>
      <StledButton type="default">
        <Row gutter={20} justify="space-around">
          <Col span={14}>
            <div className="dashed">{GetStatus()}</div>
          </Col>
          <Col span={8} className="drop-icon">
            <IconSvg
              type={bgListVisible ? 'CaretUpFilled' : 'CaretDownFilled'}
            />
          </Col>
        </Row>
      </StledButton>
    </TogglerContainer>
  );

  const togglerWrapper = (
    <Popover
      overlayClassName="background-jobs-tooltip"
      content={
        <div>
          <div>
            <Icon type="info-circle" theme="outlined" />
          </div>
          <div className="content">
            <FormattedMessage
              id={`BackgroundJobsPopover.tooltip.${currentActive}`}
            />
          </div>
        </div>
      }
      placement="left"
      trigger="hover"
      visible={tooltipVisible}
      onVisibleChange={tooltipVisibleOnChange}
    >
      {togglerContainer}
    </Popover>
  );

  return (
    <>
      <PopoverStyle />
      <Row type="flex" align="middle">
        <Popover
          overlayClassName="background-jobs"
          content={content}
          placement="bottomRight"
          visible={bgListVisible}
          trigger="click"
          onVisibleChange={bgListVisibleOnChange}
        >
          {togglerWrapper}
        </Popover>
      </Row>
    </>
  );
};

export default BackgroundJobsPopover;
