/* eslint @typescript-eslint/no-explicit-any: "off" */
/* eslint no-restricted-syntax: "off" */
/* eslint no-plusplus: "off" */
/* eslint @typescript-eslint/no-non-null-assertion: 'off' */
/* eslint consistent-return: "off" */
/* eslint no-nested-ternary: "off" */
/* eslint no-shadow: "off" */
import { camelCase, isEmpty, kebabCase } from 'lodash';
import { Map, OrderedSet } from 'immutable';
import {
  CharacterMetadata,
  ContentBlock,
  ContentState,
  DefaultDraftInlineStyle,
  EditorState,
  genKey,
} from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import {
  COLORS,
  FONTS,
  FONT_SIZES,
  MAX_LIST_DEPTH,
  defaultPreTagStyling,
} from '../constants/richTextEditor.constants';
import {
  HorizontalRule,
  Pagebreak,
  Table,
  ListItem,
} from '../components/DraftTextEditor/BlockRenderComponents';
import XmlSerializerWrapper from './xmlSerializerWrapper';

const PASTED_LIST_ITEM = 'pasted-list-item';
const ORDERED_LIST_ITEM = 'ordered-list-item';
const UNORDERED_LIST_ITEM = 'unordered-list-item';
const DOUBLE_AMPERSAND = '&nbsp;&nbsp;';

const blockTags = [
  'div',
  'p',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'table',
  'ol',
  'ul',
  'hr',
  'pre',
  'section',
  'header',
  'nav',
  'main',
  'blockquote',
];

function convertStyleStringToObject(style = '', data = {}) {
  if (!style) {
    return null;
  }
  return style
    .split(';')
    .filter(s => s.includes(':'))
    .map(s => s.split(':'))
    .reduce((map, s) => {
      const key = s.shift()?.trim() as string;
      const val = s.join(':').trim();
      map[key] = val;
      return map;
    }, data);
}

export const blockRenderMap = {
  unstyled: {
    element: 'div',
  },

  paragraph: {
    element: 'section',
  },

  [PASTED_LIST_ITEM]: {
    element: 'ol',
  },
  table: {
    element: 'div',
  },
};

export const customStyleMap = (() => {
  const styleMap = { ...DefaultDraftInlineStyle };
  ['backgroundColor', 'color'].forEach(style => {
    COLORS.forEach(color => {
      styleMap[`${style}.${color}`] = { [style]: color };
    });
  });
  FONTS.forEach(font => {
    styleMap[`fontFamily.${font}`] = { fontFamily: font };
  });
  FONT_SIZES.forEach(size => {
    styleMap[`fontSize.${size}`] = { fontSize: `${size}pt` };
  });
  return styleMap;
})();

export const customStyleFn = style => {
  const defaultStyles = style
    .intersect(['BOLD', 'CODE', 'ITALIC', 'UNDERLINE'])
    .reduce((map, v) => {
      return map.merge(customStyleMap[v]);
    }, Map());

  style = style.subtract(['BOLD', 'CODE', 'ITALIC', 'UNDERLINE']);

  // separate out any entries that are a string of multiple styles
  let groupedStyles = style.filter(v => v.includes(':'));
  style = style.subtract(groupedStyles);

  // convert string containing multiple styles to a CSS styles object
  groupedStyles = groupedStyles.reduce((map, v) => {
    v = convertStyleStringToObject(v);
    v = Map(v).mapKeys(k => camelCase(k as string));
    return map.merge(v);
  }, Map());

  // convert style strings with single style to CSS styles objects and merge with groupedStyles
  style = style
    .map(v => {
      if (v.includes('-')) {
        return v.split('-');
      }
      return v.split('.');
    })
    .filter(v => v.every(vv => vv.length))
    .reduce((map, v) => {
      const key = v.shift().trim();
      const val = v.join('.').trim();
      return map.merge({ [key === 'fontfamily' ? 'font-family' : key]: val });
    }, groupedStyles.merge(defaultStyles))
    .toJS();

  if (isEmpty(style)) {
    return null;
  }
  return style;
};

export const getBlockRendererFn = editor => block => {
  const type = block.getType();
  switch (type) {
    case 'horizontal-rule':
      return {
        component: HorizontalRule,
      };
    case 'page-break':
      return {
        component: Pagebreak,
      };
    case PASTED_LIST_ITEM:
      return {
        component: ListItem,
        editable: true,
      };
    case 'table':
      return {
        component: Table,
        editable: true,
        props: {
          editor,
        },
      };
    default:
      return null;
  }
};

let tableKey;

export const stateFromHtmlOptions = {
  customBlockFn: element => {
    const style = element.getAttribute('style') || '';
    const className = element.getAttribute('class') || '';
    let data: any = convertStyleStringToObject(style) || {};
    data = className
      .split(' ')
      .filter(c => c.length)
      .reduce((map, c) => {
        const key = c.includes('depth') ? 'depth' : c;
        const val = key === 'depth' ? +c.slice(5) : 'class';
        map[key] = val;
        return map;
      }, data);

    if (
      element.tagName === 'LI' &&
      (element.parentNode.getAttribute('start') ||
        element.style.listStyleType !== 'none') &&
      !element.className
        .split(' ')
        .find(c => [ORDERED_LIST_ITEM, UNORDERED_LIST_ITEM].includes(c))
    ) {
      const listType = element.parentNode.tagName === 'UL' ? 'ul' : 'ol';
      if (element.parentNode.firstElementChild === element) {
        data.listStyles = convertStyleStringToObject(
          element.parentNode.getAttribute('style') ?? 'margin-left: 36pt;'
        );
        data.listStart =
          element.getAttribute('start') ??
          element.parentNode.getAttribute('start') ??
          (listType === 'ul' ? 0 : 1);
        let start = data.listStart;
        for (const child of element.parentNode.children) {
          if (listType === 'ul') {
            child.setAttribute('start', 0);
          } else {
            child.setAttribute('start', start++);
          }
        }
      } else {
        data.listStart = element.getAttribute('start');
      }
      data['list-style-type'] =
        element.style.listStyleType || (listType === 'ul' ? 'disc' : 'decimal');
      return { type: PASTED_LIST_ITEM, data };
    }

    if (
      element.tagName === 'FIGURE' &&
      element.firstChild &&
      element.firstChild.tagName === 'IMG'
    ) {
      let Style = element.firstChild.getAttribute('style');
      Style = convertStyleStringToObject(Style);
      data = {
        ...data,
        ...(Style && { imgStyle: Map(Style) }),
      };
    }
    if (element.tagName === 'PRE') {
      if (!data.background) {
        data = convertStyleStringToObject(
          defaultPreTagStyling.map(v => v.join(': ')).join('; ')
        );
      }
      return { type: 'code-block', data };
    }
    if (/break-after:|break-before:/.test(element.style.cssText)) {
      return { type: 'page-break', data };
    }
    if (element.tagName === 'P') {
      const noMargin =
        element.style.margin?.startsWith('0') ||
        (element.style.marginTop?.startsWith('0') &&
          element.style.marginBottom?.startsWith('0'));
      if (noMargin) {
        return { type: 'unstyled', data };
      }
      return { type: 'paragraph', data };
    }
    if ((element.innerText || '').startsWith('---hr---')) {
      return { type: 'horizontal-rule', data };
    }
    if (['TD', 'TH'].includes(element.tagName)) {
      const tableEl = element.closest('table');
      const tHeadEl =
        element.closest('thead') ?? tableEl.querySelector('thead');
      const tBodyEl =
        element.closest('tbody') ?? tableEl.querySelector('tbody');
      const tableRows = tableEl.querySelectorAll('tr');

      if (tableEl.querySelector('table')) {
        return { type: 'unstyled', data };
      }

      if (isEmpty(element.textContent.replace(/\s/g, ''))) {
        element.innerHTML = '&nbsp;';
      }

      const prevCell = element.previousElementSibling;
      const row = element.parentNode;
      const prevRow = row.previousElementSibling;

      if (
        prevCell ||
        prevRow ||
        (tHeadEl && [tableEl, tBodyEl].includes(row.parentNode))
      ) {
        let found = false;
        for (
          let i = 0, rows = tableRows, rowCount = rows.length;
          i < rowCount;
          i++
        ) {
          for (
            let j = 0, cells = rows[i].children, colCount = cells.length;
            j < colCount;
            j++
          ) {
            if (cells[j] === element) {
              data.tableKey = tableKey;
              data.tablePosition = `${tableKey}-${i}-${j}`;
              data.colspan = cells[j].getAttribute('colspan');
              data.rowspan = cells[j].getAttribute('rowspan');
              found = true;
              break;
            }
          }
          if (found) {
            break;
          }
        }
        return { type: 'table', data };
      }

      const colgroup = tableEl.querySelector('colgroup');
      const tableShape: any[] = [];
      tableKey = genKey();
      data.tableKey = tableKey;
      data.tablePosition = `${tableKey}-0-0`;
      data.tableStyle = convertStyleStringToObject(
        tableEl.getAttribute('style')
      ) || {
        margin: '15px 0',
        width: '100%',
      };
      data.tableStyle['border-collapse'] = 'collapse';
      for (
        let i = 0, rows = tableRows, rowCount = rows.length;
        i < rowCount;
        i++
      ) {
        tableShape.push([]);
        const defaultStyle = {};
        if (i === 0) {
          if (element.tagName === 'TH') {
            defaultStyle['background-color'] = 'rgba(240, 240, 240, 0.8)';
          }
          data.rowStyle = [
            convertStyleStringToObject(rows[i].getAttribute('style')) ||
              defaultStyle,
          ];
        } else {
          data.rowStyle.push(
            convertStyleStringToObject(rows[i].getAttribute('style')) ||
              defaultStyle
          );
        }
        for (
          let j = 0, cells = rows[i].children, colCount = cells.length;
          j < colCount;
          j++
        ) {
          const defaultStyleObj = {
            border: '1px solid rgba(0, 0, 0, 0.2)',
            padding: '6px',
            'text-align': 'center',
          };
          if (cells[j].tagName === 'TH') {
            defaultStyleObj['font-weight'] = 'bold';
          }
          const cellStyle =
            convertStyleStringToObject(cells[j].getAttribute('style')) ||
            defaultStyleObj;
          tableShape[i][j] = {
            element: cells[j].tagName === 'TD' ? 'td' : 'th',
            style: cellStyle,
            colspan: cells[j].getAttribute('colspan'),
            rowspan: cells[j].getAttribute('rowspan'),
          };
        }
      }

      data.tableShape = tableShape;
      data.tableColgroup = colgroup?.outerHTML;
      return { type: 'table', data };
    }
    return { data };
  },

  customInlineFn: (element, { Style, Entity }) => {
    if (element.tagName === 'IMG') {
      // image styling is handled in the customBlockFn above
      return null;
    }
    if (element.tagName === 'A') {
      let data = {};
      if (element.hasAttribute('target')) {
        data = { target: element.getAttribute('target'), rel: 'noreferrer' };
      }
      return Entity('LINK', { ...data, url: element.getAttribute('href') });
    }
    let style = element.getAttribute('style');

    if (!style) {
      return null;
    }

    if (style.includes(';')) {
      return Style(style);
    }

    style = style.split(':');
    const key = camelCase(style.shift().trim());
    const val = style.join(':').trim();
    style = `${key}.${val}`;
    if (style === 'textDecoration.underline') {
      return null;
    }
    return Style(style);
  },
};

function getClassesAndStyles({
  block,
  blockStyles = OrderedSet(),
  classes = OrderedSet(),
}: any) {
  const data = block.getData();
  data
    .filter((v, k) => {
      if (!v) {
        return false;
      }
      return !['depth', 'listStyles', 'listStart'].includes(k);
    })
    .forEach((v, k) => {
      if (v === 'class') {
        classes = classes.add(k);
      } else {
        blockStyles = blockStyles.add(`${k}: ${v}`);
      }
    });
  const margin = block.get('depth');
  if (margin) {
    blockStyles = OrderedSet.of([`margin-left: ${margin * 2.5}em`]).union(
      blockStyles
    );
  }
  classes = (classes.size && ` class="${classes.toArray().join(' ')}"`) || '';
  blockStyles =
    (blockStyles.size && ` style="${blockStyles.toArray().join('; ')}"`) || '';
  return `${classes}${blockStyles}`;
}

function buildHtmlForBlockText(result, block, contentState) {
  if (!block) {
    return '<span>&nbsp;</span>';
  }

  block.findStyleRanges(
    () => true,
    (s, e) => {
      let close = '';
      let styles = block.getInlineStyleAt(s);
      styles = Map(customStyleFn(styles))
        .reduce((styleSet, v, k) => {
          k = kebabCase(k as string);
          if (k === 'font-size' && /^\d*$/.test(v as string)) {
            v += 'pt';
          }
          return (styleSet as OrderedSet<unknown>).add(`${k}: ${v}`);
        }, OrderedSet())
        .toArray()
        .join('; ');

      styles = styles ? ` style="${styles}"` : '';

      const startKey = block.getEntityAt(s);
      const endKey = block.getEntityAt(e - 1);
      const entity =
        startKey && startKey === endKey
          ? contentState.getEntity(startKey)
          : null;

      if (styles) {
        result += `<span${styles}>`;
        close = `</span>${close}`;
      }

      const textContent = block
        .getText()
        .slice(s, e)
        .replace(/\n/g, '<br>')
        .replace(/\s{2,}?/g, DOUBLE_AMPERSAND)
        .replace(/^\s$/g, '&nbsp;');
      if (entity && entity.get('type') === 'LINK') {
        const { url, target } = entity.getData();
        result += `<a href="${url}" ${
          target ? `target="${target}" rel="noreferrer"` : ''
        }>${textContent}</a>`;
      } else {
        result += textContent;
      }
      result += close;
    }
  );
  return result;
}

export const getStateToHtmlOptions = contentState => ({
  inlineStyles: (() => {
    const styles = {
      BOLD: { style: { fontWeight: 'bold' } },
      ITALIC: { style: { fontStyle: 'italic' } },
      UNDERLINE: { style: { textDecoration: 'underline' } },
      STRIKETHROUGH: { style: { textDecoration: 'line-through' } },
    };
    ['backgroundColor', 'color'].forEach(style => {
      COLORS.forEach(color => {
        styles[`${style}.${color}`] = { style: { [style]: color } };
      });
    });
    FONTS.forEach(font => {
      styles[`fontFamily.${font}`] = { style: { fontFamily: font } };
    });
    FONT_SIZES.forEach(size => {
      styles[`fontSize.${size}`] = { style: { fontSize: `${size}pt` } };
    });
    return styles;
  })(),

  inlineStyleFn: style => {
    style = customStyleFn(style);
    return (
      style && {
        element: 'span',
        style,
      }
    );
  },

  blockRenderers: {
    'code-block': block => {
      const blockStyles = OrderedSet(
        defaultPreTagStyling.map(v => v.join(': '))
      );
      return `<pre${getClassesAndStyles({
        block,
        blockStyles,
      })}>${buildHtmlForBlockText('', block, contentState)}</pre>`;
    },
    'page-break': () => {
      return '<div style="page-break-after: always"><br></div>';
    },

    paragraph: block => {
      if (block.getLength() === 0) {
        return `<p${getClassesAndStyles({ block })}><br></p>`;
      }

      return `<p${getClassesAndStyles({ block })}>${buildHtmlForBlockText(
        '',
        block,
        contentState
      )}</p>`;
    },
    unstyled: block => {
      if (block.getLength() === 0) {
        return `<div${getClassesAndStyles({ block })}><br></div>`;
      }

      return `<div${getClassesAndStyles({ block })}>${buildHtmlForBlockText(
        '',
        block,
        contentState
      )}</div>`;
    },
    'horizontal-rule': () => {
      return '<hr>';
    },
    atomic: block => {
      const data = block.getData();
      let figStyle: any = [];
      let imgStyle: any = [];
      let classes: any = [];
      data.forEach((v, k) => {
        if (v === 'class') {
          classes.push(k);
        } else if (k === 'imgStyle') {
          v.forEach((vv, kk) => imgStyle.push(`${kk}: ${vv}`));
        } else {
          figStyle.push(`${k}: ${v}`);
        }
      });
      const float = data.get('float');
      if (float && !data.get('margin')) {
        figStyle.push(
          float === 'right' ? 'margin: 0 8px 0 0' : 'margin: 0 0 0 8px'
        );
      }
      if (block.get('depth')) {
        figStyle.push(`margin-left: ${block.get('depth') * 2.5}em; `);
      }
      classes = classes.join(' ') && ` class="${classes.join(' ')}"`;
      figStyle = figStyle.join('; ') && ` style="${figStyle.join('; ')}"`;
      imgStyle = ` style="${imgStyle.join('; ')}"`;

      const { src } =
        (block.getEntityAt(0) &&
          contentState.getEntity(block.getEntityAt(0)).getData()) ||
        {};
      return `<figure${classes}${figStyle}><img src="${src}"${imgStyle}/></figure>`;
    },
    [PASTED_LIST_ITEM]: block => {
      const prevBlock = contentState.getBlockBefore(block.getKey());
      if (prevBlock?.getType() === block.getType()) {
        return '';
      }
      const data = block.getData();
      let start = data.get('listStart');
      start = (start && ` start="${start}"`) || '';
      let listStyles = Map(data.get('listStyles'))
        .reduce((set, v, k) => {
          return (set as OrderedSet<unknown>).add(`${k}: ${v}`);
        }, OrderedSet())
        .toArray()
        .join('; ');
      listStyles = listStyles && ` style="${listStyles}"`;
      const listItems = contentState
        .getBlockMap()
        .skipUntil(v => v === block)
        .takeWhile(v => v.getType().endsWith('list-item'))
        .toList();
      const listTag = block.getData().get('listStart') > 0 ? 'ol' : 'ul';
      let currentDepth = block.getDepth();
      return `<${listTag}${listStyles}${start}>${listItems
        .map(block => {
          const depth = block.getDepth();
          const openTag =
            depth > currentDepth
              ? `<${listTag}><li`
              : depth < currentDepth
              ? `</${listTag}><li`
              : '<li';
          currentDepth = depth;
          return `
            ${openTag}${getClassesAndStyles({ block })}>${buildHtmlForBlockText(
            '',
            block,
            contentState
          )}</li>`;
        })
        .toArray()
        .join('')}</${listTag}>`;
    },
    table: block => {
      const prevBlock = contentState.getBlockBefore(block.getKey());
      if (prevBlock && prevBlock.getType() === 'table') {
        return '';
      }
      const data = block.getData();
      const tableShape = data.get('tableShape');
      if (!tableShape) {
        return '<table><tbody><tr><td>&nbsp;</td></tr></tbody></table>';
      }
      let tableStyle = Map(data.get('tableStyle'))
        .reduce((set, v, k) => {
          return (set as OrderedSet<unknown>).add(`${k}: ${v}`);
        }, OrderedSet())
        .toArray()
        .join('; ');
      tableStyle = tableStyle && ` style="${tableStyle}"`;
      const tableKey = data.get('tableKey');
      const tableBlocks = contentState
        .getBlockMap()
        .skipUntil(
          v =>
            v.getType() === 'table' && v.getData().get('tableKey') === tableKey
        )
        .takeWhile(v => v.getType() === 'table')
        .toList();
      const colgroup = data.get('tableColgroup') ?? '';
      let cellCounter = 0;
      return `<table${tableStyle}>${colgroup}<tbody>${tableShape
        .map((row, i) => {
          let rowStyle = Map(block.getData().get('rowStyle')[i])
            .reduce((set, v, k) => {
              return (set as OrderedSet<unknown>).add(`${k}: ${v}`);
            }, OrderedSet())
            .toArray()
            .join('; ');
          rowStyle = rowStyle && ` style="${rowStyle}"`;
          return `<tr${rowStyle}>${row
            .map((cell, j) => {
              const tag = cell.element;
              let cellStyle = Map(cell.style)
                .reduce((set, v, k) => {
                  return (set as OrderedSet<unknown>).add(`${k}: ${v}`);
                }, OrderedSet())
                .toArray()
                .join('; ');
              cellStyle = cellStyle && ` style="${cellStyle}"`;
              let cellBlock = tableBlocks.get(cellCounter);
              let colspan = cellBlock.getData().get('colspan');
              colspan = colspan ? ` colspan=${colspan}` : '';
              let rowspan = cellBlock.getData().get('rowspan');
              rowspan = rowspan ? ` rowspan=${rowspan}` : '';

              const [, rowNum, colNum] =
                cellBlock
                  ?.getData()
                  .get('tablePosition')
                  .split('-') ?? [];
              if (i !== +rowNum || j !== +colNum) {
                cellBlock = null;
              } else {
                cellCounter++;
              }
              return `<${tag}${cellStyle}${colspan}${rowspan}>${buildHtmlForBlockText(
                '',
                cellBlock,
                contentState
              )}</${tag}>`;
            })
            .join('')}</tr>`;
        })
        .join('')}</tbody></table>`;
    },
  },

  blockStyleFn: block => {
    const type = block.getType();
    const depth = block.getDepth();
    const data = block.getData();
    const attributes: { [key: string]: any } = {};
    let styles: any = OrderedSet();
    let classes: any = OrderedSet();

    data.forEach((v, k) => {
      if (v === 'class') {
        classes = classes.add(k);
      } else if (!['depth', 'listStyles', 'listStart'].includes(k)) {
        styles = styles.add(`${k}: ${v}`);
      }
    });

    if (depth > 0 && !type.includes('list-item')) {
      styles = styles.add(`margin-left:${2.5 * depth}em`);
    } else if (type.includes(UNORDERED_LIST_ITEM) && depth <= MAX_LIST_DEPTH) {
      classes.remove(ORDERED_LIST_ITEM);
      classes = OrderedSet.of(
        'list',
        UNORDERED_LIST_ITEM,
        `depth${depth}`
      ).union(classes);
      styles = OrderedSet.of(
        `margin-left:${1.5 + (depth === 0 ? 1 : 0)}em`,
        `list-style-type: ${
          depth === 0 ? 'disc' : depth === 1 ? 'circle' : 'square'
        }`,
        'position: relative'
      ).union(styles);
    } else if (type.includes(ORDERED_LIST_ITEM) && depth <= MAX_LIST_DEPTH) {
      classes.remove(UNORDERED_LIST_ITEM);
      classes = OrderedSet.of('list', ORDERED_LIST_ITEM, `depth${depth}`).union(
        classes
      );
      styles = OrderedSet.of(
        `margin-left: ${1.5 + (depth === 0 ? 1 : 0)}em`,
        'list-style-type: none',
        'position: relative'
      ).union(styles);
    }

    if (type === 'blockquote') {
      styles = styles.add(
        "color: #999999; font-family: 'Hoefler Text', Georgia, serif; font-style: italic; line-height: 1.15em; border: none; border-left: 5px solid rgba(100, 100, 100, 0.5); margin: 0 2em; padding-left: 1em;"
      );
    }
    if (classes.size) {
      attributes.class = classes.toArray().join(' ');
    }
    if (styles.size) {
      attributes.style = styles.toArray().join(';');
    }
    return { attributes };
  },

  defaultBlockTag: 'div',

  entityStyleFn: entity => {
    const entityType = entity.get('type').toLowerCase();
    if (entityType === 'video') {
      const { src } = entity.getData();
      return {
        element: 'video',
        attributes: {
          src,
        },
      };
    }
    return undefined;
  },
});

const supplementalCustomBlockStyleFn = (
  newEditorState: EditorState
): EditorState => {
  const contentState = newEditorState.getCurrentContent();
  const selectionState = newEditorState.getSelection();
  let blockMap = contentState.getBlockMap();
  const blocks = blockMap.map(block => {
    if (!block) {
      return block;
    }
    let depth = block.getData().get('depth');
    const margin = block.getData().get('margin-left');
    const marginVal = margin?.replace(/rem|em/, '') / 2.5;
    // eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with
    if ((margin && /em$/.test(margin)) || depth) {
      depth =
        depth !== undefined
          ? depth
          : parseInt((marginVal || '').toString(), 10);
      block.set('depth', depth);
    }

    block.findEntityRanges(
      v => {
        const key = v.getEntity();
        return key !== null && contentState.getEntity(key).getType() === 'LINK';
      },
      (start, end) => {
        const characterList = block.getCharacterList().map((v, k) => {
          if (v && k) {
            if (start <= k && k < end) {
              v = CharacterMetadata.applyStyle(v, 'UNDERLINE');
              v = CharacterMetadata.applyStyle(v, 'color.#0088FF');
              return v;
            }
          }
          return v;
        });
        block.set('characterList', characterList);
      }
    );
    return block;
  });
  if (blocks) {
    blockMap = blockMap.merge(blocks as ContentBlock);
    const newContentState = contentState.merge({
      blockMap,
      selectionBefore: selectionState,
      selectionAfter: selectionState,
    });
    newEditorState = EditorState.push(
      newEditorState,
      newContentState as ContentState,
      'adjust-depth'
    );
  }
  return newEditorState;
};

/**
 * This code:
 * 1. removes <style> tags which are sometime present from pasted word content.
 * 2. Some content created in summernote or otherwise has text that's not wrapped in any html
 * tag mixed with other content that is in tags. In the draft-js richEditor all the unwrapped
 * text gets lumped together in one div at the start of the document. This code goes through and wraps
 * those text nodes in <div> tags separately and in order with the rest of the content.
 * 3. Finds tags that contain style white-space: pre-wrap and substitutes non-breaking space characters
 * for spaces and <br> tags for newline characters so they get preserved when converted to draft.js state.
 * 4. Finds block-level tags (div, p) inside <li> list items and <td> table cells and converts them to inline <span> elements,
 * otherwise the div & p tags would take precedence and the list or table structure gets lost in the conversion.
 * @param  {string} htmlData The html to be converted
 */
export const convertHtmlToEditorState = (htmlData: string): EditorState => {
  let inputHtml = '';
  if (typeof htmlData === 'string') {
    inputHtml = htmlData.replace(/<hr\/?>/g, '<div>---hr---</div>');
  } else {
    inputHtml = htmlData;
  }
  const domParser = new DOMParser();
  const tempDoc = domParser.parseFromString(inputHtml, 'text/html');
  const parsedHTML = tempDoc.querySelector('body');

  if (!parsedHTML) {
    return EditorState.createWithContent(
      stateFromHTML(htmlData, stateFromHtmlOptions)
    );
  }
  let child = parsedHTML.firstElementChild;
  if (parsedHTML.children.length === 1 && child?.tagName === 'TABLE') {
    child = document.createElement('br');
    parsedHTML.insertBefore(child, parsedHTML.firstChild);
    parsedHTML.appendChild(document.createElement('br'));
  }
  while (child) {
    // remove Style tags
    if (child.tagName === 'STYLE') {
      const nextChild = child.nextElementSibling;
      parsedHTML.removeChild(child);
      child = nextChild;
      // eslint-disable-next-line no-continue
      continue;
    }
    // handle text content that is not within block elements
    if (!blockTags.includes(child.tagName?.toLowerCase())) {
      const wrapper = tempDoc.createElement('div');
      let nextChild = child.nextElementSibling;
      wrapper.appendChild(child);
      while (
        nextChild &&
        !blockTags.includes(nextChild.tagName?.toLowerCase())
      ) {
        const currentChild = nextChild;
        nextChild = currentChild.nextElementSibling;
        wrapper.appendChild(currentChild);
      }
      parsedHTML.insertBefore(wrapper, nextChild);
      child = nextChild;
    }

    if (child) {
      child = child.nextElementSibling;
    }
  }

  // function used within traverse() for converting block elements to inline span elements
  function changeTag(element, tag) {
    // prepare the elements
    const newElem = document.createElement(tag);
    const clone = element.cloneNode(true);
    // move the children from the clone to the new element
    while (clone.firstChild) {
      newElem.appendChild(clone.firstChild);
    }
    // copy the attributes
    for (const attr of clone.attributes) {
      if (attr.name === 'value') {
        newElem.textContent = attr.value;
      } else {
        newElem.setAttribute(attr.name, attr.value);
      }
    }
    return newElem;
  }

  // recursive function to walk the full DOM tree, making modifications as needed
  // to preserve formatting during conversion to internal state for draft.js
  const traverse = (node?: Element | null, isNestedBlock?: unknown) => {
    if (!node) {
      return;
    }
    // elements formatted with spacing and soft line-breaks
    const hasStle = node.getAttribute('style');
    if (hasStle && /white-space:\s*(pre|pre-wrap);?/.test(hasStle)) {
      node.innerHTML = node.innerHTML
        .replace(/\n/g, '<br>')
        .replace(/\s{2}/g, DOUBLE_AMPERSAND)
        .replace(/&nbsp;\s/g, DOUBLE_AMPERSAND);
      let style = node.getAttribute('style');
      if (style) {
        style = style.replace(/white-space:\s*(pre|pre-wrap);?/, '');
        node.setAttribute('style', style);
      }
    }
    // replace block elements inside lists with inline <span> elements
    if (isNestedBlock && ['DIV', 'P', 'INPUT'].includes(node.tagName)) {
      const newNode = changeTag(node, 'span');
      node.replaceWith(newNode);
      node = newNode;
    }
    // If a nested table has a single row and cell, switch it to a span as a single cell's contents of the outer table
    if (isNestedBlock && node && node.tagName === 'TABLE') {
      if (node.firstElementChild?.tagName === 'TBODY') {
        const numRows = node.firstElementChild.children.length;
        if (numRows === 1) {
          const numCells =
            node.firstElementChild.firstElementChild?.children.length;
          if (numCells === 1) {
            let cell =
              node.firstElementChild.firstElementChild?.firstElementChild;
            if (cell && cell.firstElementChild) {
              if (['DVI', 'P'].includes(cell.firstElementChild.tagName)) {
                cell = cell.firstElementChild;
              }
              const newNode = changeTag(cell, 'span');
              node.replaceWith(newNode);
            }
          }
        }
      }
    }
    traverse(node?.nextElementSibling, isNestedBlock);
    isNestedBlock =
      isNestedBlock ||
      node?.tagName === 'LI' ||
      node?.tagName === 'TD' ||
      node?.tagName === 'TH';
    traverse(node?.firstElementChild, isNestedBlock);
  };

  traverse(parsedHTML.firstElementChild);

  const s = new XmlSerializerWrapper();
  const newContent = s.serializeToString(parsedHTML);

  // end special parsing
  let newEditorState = EditorState.createWithContent(
    stateFromHTML(newContent, stateFromHtmlOptions)
  );
  newEditorState = EditorState.moveSelectionToEnd(newEditorState);
  return supplementalCustomBlockStyleFn(newEditorState);
};

/* eslint consistent-return: "off" */
export const blockStyleFn = block => {
  const type = block.getType();
  const depth = block.getDepth();
  const data = block.getData();
  const classes: string[] = [];
  if (depth > 0 && !type.includes('list-item')) {
    classes.push(`indent${depth}`);
  }
  if (type === PASTED_LIST_ITEM) {
    const indent = data.get('margin-left') ?? '00';
    if (indent.slice(0, 2) > 36) {
      classes.push('indent1');
    }
  }
  data.forEach((v, k) => {
    switch (k) {
      case 'text-align':
        if (v === 'left') {
          classes.push('rdw-left-aligned-block');
        } else if (v === 'justify') {
          classes.push('rdw-justify-aligned-block');
        } else if (v === 'center') {
          classes.push('rdw-center-aligned-block');
        } else if (v === 'right') {
          classes.push('rdw-right-aligned-block');
        } else {
          classes.push(`${k}-${v}`);
        }
        break;
      case 'float':
        classes.push(`${k}-${v}`);
        break;
      default:
        return null;
    }
  });
  if (classes.length) {
    return classes.join(' ');
  }
  return undefined;
};
