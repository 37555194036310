import makeRequest from '../helpers/make-request';
import uploadRequest from '../helpers/upload-request';
import { getConfig } from '../helpers/config';

const generateTemplate = (
  testbedTemplateRequest: DTO.TestbedTemplateRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbedtemplate/generate`;

  return makeRequest<DTO.GenerateTestCasesResponse>(
    'POST',
    url,
    testbedTemplateRequest
  );
};

const generateTemplateById = (testbedTemplateId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbedtemplate/generatebyid/${testbedTemplateId}`;
  return makeRequest<DTO.GenerateTestCasesResponse>('POST', url);
};

const saveTemplate = (testbedTemplateRequest: DTO.TestbedTemplateRequest) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/testbedtemplate/save`;

  return makeRequest<DTO.GenerateTestCasesResponse>(
    'POST',
    url,
    testbedTemplateRequest
  );
};

const getTestbeds = (testbedPagedRequest: DTO.TestbedPagedRequest) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/testbed/list`;

  const search: { field: string; value: string }[] = [];

  if (
    testbedPagedRequest.startDate &&
    testbedPagedRequest.startDate.length > 0
  ) {
    search.push({ field: 'startDate', value: testbedPagedRequest.startDate });
  }

  if (testbedPagedRequest.endDate && testbedPagedRequest.endDate.length > 0) {
    search.push({ field: 'endDate', value: testbedPagedRequest.endDate });
  }

  const sort = `${testbedPagedRequest.sort === 'desc' ? '-' : ''}${
    testbedPagedRequest.sortBy
  }`;

  return makeRequest<DTO.TestbedPagedResponse>('POST', url, {
    page: testbedPagedRequest.page,
    pageSize: testbedPagedRequest.pageSize,
    sort,
    search,
  });
};

const getTestbedVersions = (
  testbedId: string,
  testbedPagedRequest: DTO.TestbedPagedRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbed/listversions/${testbedId}`;
  return makeRequest<DTO.TestbedPagedResponse>('POST', url, {
    page: testbedPagedRequest.page,
    pageSize: testbedPagedRequest.pageSize,
  });
};

const getTestbedTemplates = (testbedPagedRequest: DTO.TestbedPagedRequest) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/testbedtemplate/list`;

  const search: { field: string; value: string }[] = [];
  if (
    testbedPagedRequest.startDate &&
    testbedPagedRequest.startDate.length > 0
  ) {
    search.push({ field: 'startDate', value: testbedPagedRequest.startDate });
  }

  if (testbedPagedRequest.endDate && testbedPagedRequest.endDate.length > 0) {
    search.push({ field: 'endDate', value: testbedPagedRequest.endDate });
  }

  if (testbedPagedRequest.engine && testbedPagedRequest.engine.length > 0) {
    search.push({ field: 'engine', value: testbedPagedRequest.engine });
  }

  if (testbedPagedRequest.version && testbedPagedRequest.version.length > 0) {
    search.push({ field: 'version', value: testbedPagedRequest.version });
  }

  const sort = `${testbedPagedRequest.sort === 'desc' ? '-' : ''}${
    testbedPagedRequest.sortBy
  }`;

  return makeRequest<DTO.TestbedTemplatePagedResponse>('POST', url, {
    page: testbedPagedRequest.page,
    pageSize: testbedPagedRequest.pageSize,
    sort,
    search,
  });
};

const getTestbedTemplateVersions = (
  testbedTemplateId: string,
  testbedPagedRequest: DTO.TestbedPagedRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbedtemplate/listversions/${testbedTemplateId}`;
  return makeRequest<DTO.TestbedTemplatePagedResponse>('POST', url, {
    page: testbedPagedRequest.page,
    pageSize: testbedPagedRequest.pageSize,
  });
};

const getTestbedResults = (testbedPagedRequest: DTO.TestbedPagedRequest) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/testbedqueue/getresults`;

  const search: { field: string; value: string }[] = [];

  if (
    testbedPagedRequest.startDate &&
    testbedPagedRequest.startDate.length > 0
  ) {
    search.push({ field: 'startDate', value: testbedPagedRequest.startDate });
  }

  if (testbedPagedRequest.endDate && testbedPagedRequest.endDate.length > 0) {
    search.push({ field: 'endDate', value: testbedPagedRequest.endDate });
  }

  if (testbedPagedRequest.engine && testbedPagedRequest.engine.length > 0) {
    search.push({ field: 'engine', value: testbedPagedRequest.engine });
  }

  if (testbedPagedRequest.version && testbedPagedRequest.version.length > 0) {
    search.push({ field: 'version', value: testbedPagedRequest.version });
  }

  const sort = `${testbedPagedRequest.sort === 'desc' ? '-' : ''}${
    testbedPagedRequest.sortBy
  }`;

  return makeRequest<DTO.TestbedResultPagedResponse>('POST', url, {
    page: testbedPagedRequest.page,
    pageSize: testbedPagedRequest.pageSize,
    sort,
    search,
  });
};

const getTestbedQueue = (testbedQueueId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbedqueue/get/${testbedQueueId}`;
  return makeRequest<{
    data: DTO.TestbedQueueProgress;
  }>('GET', url);
};

const getTestbedTemplate = (testbedTemplateVersionId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbedtemplate/get/${testbedTemplateVersionId}`;
  return makeRequest<{
    data: DTO.TestbedTemplate;
  }>('GET', url);
};

const saveTestbedQueue = (testbedQueueRequest: DTO.TestbedQueueRequest) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/testbedqueue/save`;

  return makeRequest<{
    data: string;
  }>('POST', url, testbedQueueRequest);
};

const getTestbedResult = (testbedQueueId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbedqueue/getresult/${testbedQueueId}`;
  return makeRequest<{
    data: DTO.TestbedResultResponse;
  }>('GET', url);
};

const downloadTestResult = (testRunId: string, fileType: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbedqueue/DownloadTestbedResult/${testRunId}/${fileType}`;
  return makeRequest<{
    data: string;
  }>('GET', url);
};

const removeTestbedTemplate = (testbedTemplateId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbedtemplate/delete/${testbedTemplateId}`;
  return makeRequest('DELETE', url);
};

const removeTestbed = (testbedId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbed/delete/${testbedId}`;
  return makeRequest('DELETE', url);
};

const getTestbed = (testbedVersionId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbed/GetByTestbedVersionId/${testbedVersionId}`;
  return makeRequest<{
    data: DTO.Testbed;
  }>('GET', url);
};

const getTestbedItems = (request: DTO.TestbedItemsPagedRequest) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbed/GetTestBedItems/${request.testbedVersionId}`;
  return makeRequest<DTO.GetTestbedItemsResponse>('POST', url, {
    page: request.page,
    pagesize: request.pageSize,
  });
};

const uploadTestbed = (
  file: File | undefined,
  testbedRequest: DTO.TestBedRequest,
  onUploadProgress: (precent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/testbed/AddTestbed`;
  const formData = new FormData();

  file && formData.append('testbedfile', file);
  testbedRequest.name && formData.append('name', testbedRequest.name);
  testbedRequest.description &&
    formData.append('description', testbedRequest.description);
  testbedRequest.testbedId &&
    formData.append('testbedId', testbedRequest.testbedId);

  return uploadRequest<DTO.UploadTestBedFileResponse>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const queueCompareRun = (sourceTestRunId: string, targetTestRunId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testrun/QueueCompareRun/${sourceTestRunId}/${targetTestRunId}`;

  return makeRequest<{
    data: string;
  }>('POST', url);
};

const getTestRunsByTesbedVersionId = (
  testbedVersionId: string,
  productId: string
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testrun/GetTestRunsByTesbedVersionId/${testbedVersionId}/${productId}`;

  return makeRequest<{
    data: DTO.TestbedResult[];
  }>('GET', url);
};

const generateCompareResult = (
  sourceTestRunId: string,
  targetTestRunId: string
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testrun/DownloadCompareResult/${sourceTestRunId}/${targetTestRunId}/${new Date()
    .getTimezoneOffset()
    .toString()}`;
  return makeRequest<DTO.GenerateTestCasesResponse>('GET', url);
};

const getCompareStatus = (taskQueueId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testrun/GetCompareStatus/${taskQueueId}`;
  return makeRequest<{ data: DTO.TestbedQueueProgress }>('GET', url);
};

const getCompareResult = (sourceTestRunId: string, targetTestRunId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testrun/GetCompareResult/${sourceTestRunId}/${targetTestRunId}`;
  return makeRequest<{
    data: DTO.TestCompareResultResponse;
  }>('GET', url);
};

const updateTestbed = (
  testbedVersionId: string,
  request: DTO.UpdateTestbedItemRequest[]
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbed/updatetestbed/${testbedVersionId}`;

  return makeRequest<{
    data: string;
  }>('POST', url, request);
};

const getAllTestbeds = () => {
  const url = `${getConfig().excelEngineDomain}/api/v1/testbed/GetTestBeds`;
  return makeRequest<{ data: DTO.Testbed[] }>('GET', url);
};

const queueGenerateTestCases = (
  productName: string,
  serviceName: string,
  versionId: string,
  data: DTO.GenerateTestCasesRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbed/generate/${productName}/${serviceName}/${versionId}/queue`;

  return makeRequest<{
    data: string;
  }>('POST', url, data);
};

const getGenerateTestcasesStatus = (taskQueueId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbed/generate/getstatus/${taskQueueId}`;
  return makeRequest<{ data: DTO.TestbedQueueProgress }>('GET', url);
};

const getTestbedByName = (testbedName: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbed/GetTestbedByName/${testbedName}`;
  return makeRequest<{
    data: DTO.Testbed;
  }>('GET', url);
};

const downloadTestBedTestCases = (testbedVersionId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbed/downloadtestbed/${testbedVersionId}`;
  return makeRequest<DTO.GenerateTestCasesResponse>('POST', url);
};

const cancelTestbedQueue = (testbedQueueId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbedqueue/cancel/${testbedQueueId}`;
  return makeRequest('GET', url);
};

export const TestbedService = {
  generateTemplate,
  saveTemplate,
  getTestbeds,
  getTestbedQueue,
  saveTestbedQueue,
  getTestbedResult,
  getTestbedTemplates,
  getTestbedResults,
  getTestbedTemplate,
  generateTemplateById,
  removeTestbedTemplate,
  removeTestbed,
  getTestbedTemplateVersions,
  getTestbedVersions,
  getTestbed,
  getTestbedItems,
  uploadTestbed,
  queueCompareRun,
  getTestRunsByTesbedVersionId,
  generateCompareResult,
  getCompareStatus,
  getCompareResult,
  updateTestbed,
  getAllTestbeds,
  queueGenerateTestCases,
  getGenerateTestcasesStatus,
  getTestbedByName,
  downloadTestBedTestCases,
  cancelTestbedQueue,
  downloadTestResult,
};
