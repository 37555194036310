import { getConfig } from './config';
import makeRequest from './make-request';

let sparkConfig: DTO.SparkConfiguration = {
  GitBookUrl: '',
  ShowUsageDetails: '',
  ShowSDKGenerator: '',
  ShowIngeniumConfiguration: '',
  ShowConfigurationFiles: '',
  ShowTemplates: '',
  ShowCategories: '',
  ShowDataDictionary: '',
  ShowInsights: '',
};

export const fetchSparkConfig = async () => {
  if (sparkConfig.ShowUsageDetails === '') {
    const url = `${
      getConfig().excelEngineDomain
    }/api/v1/config/GetSparkConfiguration`;
    const response = await makeRequest<{ data: DTO.SparkConfiguration }>(
      'GET',
      url
    );
    sparkConfig = response.payload.data;
  }
};

export const getSparkConfig = (): DTO.SparkConfiguration => {
  return sparkConfig;
};
