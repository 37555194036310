import { EntityPermissionConstant } from '../constants';
import { ApiError } from '../helpers';
import { EntityPermissionService } from '../services';
import { AlertActions } from './alert.actions';
import { EntityPermissionThunkAction } from './types';

const setEntityPermission = (
  entityPermissionRequest: DTO.EntityPermissionRequest,
  totalPrincipals: number,
  isUpdate = false
): EntityPermissionThunkAction => async dispatch => {
  dispatch({
    type: EntityPermissionConstant.SET_ENTITY_PERMISSION_REQUEST,
  });

  try {
    const {
      status,
      payload,
    } = await EntityPermissionService.setEntityPermissions(
      entityPermissionRequest
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EntityPermissionConstant.SET_ENTITY_PERMISSION_SUCCESS,
      payload: {
        totalPrincipalsAdded: totalPrincipals,
      },
    });

    if (isUpdate) {
      dispatch(AlertActions.success('EntityPermission.update.success'));
    } else {
      dispatch(AlertActions.success('EntityPermission.create.success'));
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EntityPermissionConstant.SET_ENTITY_PERMISSION_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const getEntityPermission = (
  entityId: string
): EntityPermissionThunkAction => async dispatch => {
  dispatch({
    type: EntityPermissionConstant.GET_ENTITY_PERMISSION_REQUEST,
  });

  try {
    const {
      status,
      payload,
    } = await EntityPermissionService.getEntityPermissions(entityId);

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EntityPermissionConstant.GET_ENTITY_PERMISSION_SUCCESS,
      payload: {
        entityPermissions: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EntityPermissionConstant.GET_ENTITY_PERMISSION_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const resetEntityPermission = (): EntityPermissionThunkAction => dispatch => {
  dispatch({
    type: EntityPermissionConstant.RESET_ENTITY_PERMISSION,
  });
};

export const EntityPermissionActions = {
  setEntityPermission,
  getEntityPermission,
  resetEntityPermission,
};
