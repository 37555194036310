import React, { FC } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { IconSvg } from '../Icons/IconSvg';

const StyledButton = styled(Button)<{ checked: boolean }>`
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: 22px;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 16px !important;
  cursor: pointer;
  transition: all 0.36s;
  -webkit-user-select: none;
  user-select: none;
  height: 22px !important;
  width: 44px !important;
  min-width: 44px !important;
  background-color: ${({ theme, checked }) =>
    checked ? theme.color.purple : theme.color.gray2} !important;
  border: ${({ theme, checked }) =>
    checked ? theme.color.purple : theme.color.gray2} !important;
  padding-left: ${({ checked }) => (checked ? '23px' : '2px')} !important;

  .dot-wrapper {
    background-color: ${({ theme }) => theme.color.white};
    height: 18px;
    width: 18px;
    border-radius: 16px;
    padding: 0;
    padding-left: 23px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 10px;
      color: ${({ checked, theme }) =>
        checked ? theme.color.purple : theme.color.gray2};
    }
  }
`;

interface CustomSwitchProps {
  checked: boolean;
  onChange: () => void;
}

const FavoritesSwitch: FC<CustomSwitchProps> = ({ checked, onChange }) => {
  return (
    <StyledButton
      checked={checked}
      onClick={onChange}
      data-testid="favorite-toggle-btn"
    >
      <div className="dot-wrapper">
        <IconSvg type="Star" />
      </div>
    </StyledButton>
  );
};

export { FavoritesSwitch };
