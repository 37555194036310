const MainDefaultTheme = {
  appName: 'Spark',
  logo: './assets/img/logo.svg',
  logoIcon: './assets/img/logo-icon.svg',
  logoOnWhite: './assets/img/logo.svg',
  logoOnWhiteWithoutText: './assets/img/logo-without-text.svg',
  logoOnColoured: './assets/img/logo-on-coloured.svg',
  logoOnColouredWithoutText: './assets/img/logo-on-coloured-without-text.svg',
  logoOnDarkWithWhiteText: './assets/img/logo-on-dark-with-white-text.svg',
  fontLink: '',
  fontFaces: [
    {
      family: 'GT Walsheim',
      weight: '300',
      style: 'nomal',
      fileName: 'GT-Walsheim-Light',
    },
    {
      family: 'GT Walsheim',
      weight: 'normal',
      style: 'nomal',
      fileName: 'GT-Walsheim-Regular',
    },
    {
      family: 'GT Walsheim',
      weight: '500',
      style: 'nomal',
      fileName: 'GT-Walsheim-Medium',
    },
    {
      family: 'GT Walsheim',
      weight: 'bold',
      style: 'nomal',
      fileName: 'GT-Walsheim-Bold',
    },
    {
      family: 'Hiragino Kaku Gothic Pro',
      weight: 'normal',
      style: 'nomal',
      fileName: 'hiragino-kaku-gothic-pro-w3',
    },
  ],
  vars: {
    textColor: '#201A3D',
    primaryColor: '#9C43FF',
    secondaryColor: '#201A3D',
    dangerColor: '#DC5034',
    fontFamily: 'GT Walsheim',
    shadow: '#888',
    white: '#ffffff',
    border: '#CCCCCC',
    tabContentBg: '#f4f4f3',
    switchBg: '#f4f4f3',
    expiredPasswordBg: '#E17FC3',
    formbuilderContentBg: '#FBFBFA',
    folderCardSelectedBg: '#EAFAFA',
    editFileMetaInputBg: '#F4F4F4',
    fileUploadModalBg: '#D3F3F5',
    mainHeaderBorderColor: '#003363',
    sideMenuLogoInset: '#4D4864',
    curlCommandError: '#DC5034',
    textEditorGray: '#BDBDBD',
    bookmarkColor: '#833BD8',
    typographyDanger: '#EB5757',
    scrollBarColor: '#D2D1D8',
    closeIconColor: '#262626',
  },
  color: {
    white: '#ffffff',
    black: '#000000',
    pearl: '#ECECEB',
    dark: '#201A3D',
    dark40: '#A6A3B1',
    dark60: '#79768B',
    light: '#757286',
    gray1: '#333333',
    gray4: '#d9d9d9',
    gray3: '#828282',
    gray6: '#F2F2F2',
    gray2: '#A6A3B1',
    gray5: '#E0E0E0',
    gray11: '#E5E5E5',
    pearl40: '#F7F7F7',
    gray9: '#A6A3B196',
    gray10: '#ECECEB',
    gray91: '#E8E8E8',
    grayDC: '#DCDCDC',
    dark20: '#D2D1D8',
    grey8: '#C2C2C2',
    dark80: '#4D4864',
    darkOverlay: '#908D9E',
    aqua: '#24C4CC',
    lightAqua: '#D3F3F5',
    aqua40: '#A7E7EB',
    aqua60: '#7CDCE0',
    aquaDarkened1: '#23A2AF',
    azure: '#3B8CFF',
    azure2: '#A0C6F6',
    red: '#DC5034',
    attention20: '#FAE5E1',
    lightRed: '#f5222d',
    success: '#3ECB0C',
    success2: '#52C41A',
    warning: '#FFD00D',
    reddark: '#FF0000',
    warning20: '#FFF6CF',
    boxShadow: '#c4c4c4',
    success15: '#e2f7db',
    blue: '#003363',
    lvory: '#F6F6F5',
    lightgray: '#727272',
    magenta: '#201a3d',
    lightblue: '#24c4cc',
    blue1: '#d3f3f5',
    violet: '#4d4864',
    flamingo: '#eb5757',
    purple: '#9C43FF',
    purple20: '#EBD9FF',
    purple40: '#D7B4FF',
    purple60: '#C48EFF',
    purpleDarkened1: '#833BD8',
    purpleDarkened2: '#6B32B1',
    purpleDarkened3: '#512B8B',
    yellow: '#FFA800',
    pearl60: '#F4F4F3',
    azureBlue20: '#D8E8FF',
    azureBlue60: '#89BAFF',
    azureBlueDarkened1: '#3575D8',
    blue6: '#1890FF',
  },
};

export default MainDefaultTheme;
