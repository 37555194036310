import { InsightsConstants } from '../constants';
import { ApiError } from '../helpers';
import { InsightsService } from '../services';
import { AlertActions } from './alert.actions';
import { InsightsThunkAction } from './types';

const getCounterDefinitions = (
  retrieveClusterCounters: boolean
): InsightsThunkAction => async dispatch => {
  dispatch({ type: InsightsConstants.GET_COUNTER_DEFINITIONS_REQUEST });

  try {
    const { payload } = await InsightsService.getCounterDefinitions(
      retrieveClusterCounters
    );
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    const { data } = payload;
    dispatch({
      type: InsightsConstants.GET_COUNTER_DEFINITIONS_SUCCESS,
      payload: {
        list: data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: InsightsConstants.GET_COUNTER_DEFINITIONS_FAILURE,
      payload: { error: msg },
    });
  }
};

const getAggregatedData = (
  request: DTO.InsightFilter
): InsightsThunkAction => async dispatch => {
  dispatch({ type: InsightsConstants.GET_AGGREGATED_DATA_REQUEST });

  try {
    const { payload } = await InsightsService.getAggregatedData(request);
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    const { data } = payload;
    dispatch({
      type: InsightsConstants.GET_AGGREGATED_DATA_SUCCESS,
      payload: {
        list: data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: InsightsConstants.GET_AGGREGATED_DATA_FAILURE,
      payload: { error: msg },
    });
  }
};

const getTotalByCounter = (
  request: DTO.InsightFilter
): InsightsThunkAction => async dispatch => {
  dispatch({ type: InsightsConstants.GET_TOTAL_BY_COUNTER_REQUEST });

  try {
    const { payload } = await InsightsService.getTotalByCounterName(request);
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    const { data } = payload;
    dispatch({
      type: InsightsConstants.GET_TOTAL_BY_COUNTER_SUCCESS,
      payload: {
        list: data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: InsightsConstants.GET_TOTAL_BY_COUNTER_FAILURE,
      payload: { error: msg },
    });
  }
};

const getNextMeasures = (
  request: DTO.InsightNextMeasure,
  isFolderUpdate: boolean,
  isServiceUpdate: boolean,
  isDrilldownUpdate: boolean
): InsightsThunkAction => async dispatch => {
  dispatch({ type: InsightsConstants.GET_NEXT_MEASURES_REQUEST });

  try {
    const { payload } = await InsightsService.getNextMeasures(request);
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    const { data } = payload;
    dispatch({
      type: InsightsConstants.GET_NEXT_MEASURES_SUCCESS,
      payload: {
        list: data,
        isFolderUpdate,
        isServiceUpdate,
        isDrilldownUpdate,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: InsightsConstants.GET_NEXT_MEASURES_FAILURE,
      payload: { error: msg },
    });
  }
};

export const InsightsActions = {
  getCounterDefinitions,
  getAggregatedData,
  getTotalByCounter,
  getNextMeasures,
};
