import { EngineLogsAction } from '../actions/types';
import { EngineLogsConstant } from '../constants';

export const initialState: STATES.ViewLogsState = {
  isLoading: false,
  logs: [],
  page: 1,
  totalPages: 0,
  pageSize: 20,
  searchText: '',
  startDate: '',
  endDate: '',
  totalCount: 0,
};

export const engineViewLogs = (
  state = initialState,
  action: EngineLogsAction
): STATES.ViewLogsState => {
  switch (action.type) {
    case EngineLogsConstant.GET_ENGINE_VIEW_LOGS: {
      const {
        page,
        searchText = '',
        startDate = '',
        endDate = '',
      } = action.payload;

      return {
        ...state,
        isLoading: true,
        page,
        searchText,
        startDate,
        endDate,
      };
    }
    case EngineLogsConstant.GET_ENGINE_VIEW_LOGS_SUCCESS: {
      const { logs, total } = action.payload;

      return {
        ...state,
        isLoading: false,
        logs,
        totalCount: total,
        totalPages:
          Math.floor(total / state.pageSize) + (total % state.pageSize ? 1 : 0),
      };
    }
    case EngineLogsConstant.DOWNLOAD_ENGINE_LOGS_SUCCESS:
    case EngineLogsConstant.DOWNLOAD_ENGINE_LOGS_FAILURE:
    case EngineLogsConstant.GET_ENGINE_VIEW_LOGS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case EngineLogsConstant.DOWNLOAD_ENGINE_LOGS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case EngineLogsConstant.ENGINE_LOGS_RESET: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};
