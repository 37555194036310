import moment from 'moment';
import { EngineConstants } from '../constants';
import { EngineAction } from '../actions/types';

export const initialState: STATES.ApiTesterState = {
  isExecuting: false,
  result: null,
  showErrorModal: false,
  customEndpoint: '',
  apiTesterInputLoading: false,
  showApiExistErrorModal: false,
  showWarningMsg: false,
  ApiTesterDocumentation: {
    productName: '',
    serviceName: '',
    version: '',
    category: '',
    documentation: '',
    isLoadingDocs: false,
  },
  jsSheetGenerateError: null,
  jsSheetGenerateProgress: 0,
  cancelGenerate: false,
};

export const apiTester = (
  state = initialState,
  action: EngineAction
): STATES.ApiTesterState => {
  switch (action.type) {
    case EngineConstants.API_TEST_EXECUTE:
    case EngineConstants.EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_REQUEST:
    case EngineConstants.DOWNLOAD_JSSHEET_ENGINE_REQUEST:
    case EngineConstants.GET_EXECUTE_OUTPUT_REQUEST: {
      return {
        ...state,
        isExecuting: true,
      };
    }
    case EngineConstants.GENERATE_JSSHEET_ENGINE_REQUEST: {
      return {
        ...state,
        isExecuting: true,
        cancelGenerate: false,
        jsSheetGenerateError: null,
        jsSheetGenerateProgress: 20,
      };
    }
    case EngineConstants.GENERATE_JSSHEET_ENGINE_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        isExecuting: false,
        jsSheetGenerateError: error,
      };
    }
    case EngineConstants.GENERATE_JSSHEET_ENGINE_SUCCESS: {
      return {
        ...state,
        isExecuting: false,
        jsSheetGenerateError: null,
        jsSheetGenerateProgress: 100,
      };
    }
    case EngineConstants.CANCEL_GENERATE_JSSHEET: {
      return {
        ...state,
        isExecuting: false,
        jsSheetGenerateError: null,
        jsSheetGenerateProgress: 0,
        cancelGenerate: true,
      };
    }
    case EngineConstants.EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_SUCCESS:
    case EngineConstants.API_TEST_EXECUTE_SUCCESS: {
      const { result } = action.payload;

      const formattedResult = {
        ...result,
        FetchTimeUTC: moment(result.FetchTimeUTC).format(
          'YYYY-MM-DD, HH:mm:ss [GMT]Z'
        ),
        ReleaseDate: moment(result.ReleaseDate).format(
          'YYYY-MM-DD, HH:mm:ss [GMT]Z'
        ),
      };
      return {
        ...state,
        result: formattedResult,
        isExecuting: false,
      };
    }
    case EngineConstants.API_TEST_EXECUTE_SUCCESS_NO_APICALL: {
      const { result } = action.payload;
      return {
        ...state,
        result,
      };
    }
    case EngineConstants.API_TEST_EXECUTE_FAILURE:
    case EngineConstants.GET_EXECUTE_OUTPUT_SUCCESS:
    case EngineConstants.EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_FAILURE:
    case EngineConstants.DOWNLOAD_JSSHEET_ENGINE_SUCCESS:
    case EngineConstants.DOWNLOAD_JSSHEET_ENGINE_FAILURE:
    case EngineConstants.GET_EXECUTE_OUTPUT_FAILURE: {
      return {
        ...state,
        isExecuting: false,
      };
    }
    case EngineConstants.API_TEST_SHOW_ERROR_MODAL: {
      return {
        ...state,
        showErrorModal: true,
      };
    }
    case EngineConstants.API_TEST_SHOW_ED_WARNING_MODAL: {
      return {
        ...state,
        showWarningMsg: true,
      };
    }
    case EngineConstants.API_TEST_HIDE_ED_WARNING_MODAL: {
      return {
        ...state,
        showWarningMsg: false,
      };
    }
    case EngineConstants.API_TEST_HIDE_ERROR_MODAL: {
      return {
        ...state,
        showErrorModal: false,
      };
    }
    case EngineConstants.API_TEST_RESET: {
      return { ...initialState };
    }
    case EngineConstants.CUSTOM_URL_REQUEST: {
      return {
        ...state,
        apiTesterInputLoading: true,
      };
    }
    case EngineConstants.CUSTOM_URL_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        apiTesterInputLoading: false,
        customEndpoint: data.urlSuffix,
      };
    }
    case EngineConstants.CUSTOM_URL_DELETE_SUCCESS: {
      return {
        ...state,
        apiTesterInputLoading: false,
        customEndpoint: '',
      };
    }
    case EngineConstants.CUSTOM_URL_FAILURE: {
      return {
        ...state,
        apiTesterInputLoading: false,
      };
    }
    case EngineConstants.CUSTOM_URL_EXIST: {
      return {
        ...state,
        showApiExistErrorModal: true,
      };
    }
    case EngineConstants.CUSTOM_URL_EXIST_HIDE_MODAL: {
      return {
        ...state,
        showApiExistErrorModal: false,
      };
    }

    case EngineConstants.API_TESTER_DOCUMENTATION_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        ApiTesterDocumentation: {
          productName: '',
          serviceName: '',
          version: '',
          category: '',
          documentation: '',
          isLoadingDocs: false,
          ...payload,
        },
      };
    }

    case EngineConstants.API_TESTER_DOCUMENTATION_REQUEST:
    case EngineConstants.API_DOCS_DOWNLOAD_REQUEST: {
      return {
        ...state,
        ApiTesterDocumentation: {
          ...state.ApiTesterDocumentation,
          isLoadingDocs: true,
        },
      };
    }

    case EngineConstants.API_TESTER_DOCUMENTATION_FAILED:
    case EngineConstants.API_DOCS_DOWNLOAD_FAILURE:
    case EngineConstants.API_DOCS_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        ApiTesterDocumentation: {
          ...state.ApiTesterDocumentation,
          isLoadingDocs: false,
        },
      };
    }

    default:
      return state;
  }
};
