let timer = 0;

const autoScroll = (event: DragEvent | MouseEvent, edgeSize: number) => {
  const viewportX = event.clientX;
  const viewportY = event.clientY;
  const viewportWidth = document.documentElement.clientWidth;
  const viewportHeight = document.documentElement.clientHeight;
  const edgeTop = edgeSize;
  const edgeLeft = edgeSize;
  const edgeBottom = viewportHeight - edgeSize;
  const edgeRight = viewportWidth - edgeSize;

  const isInLeftEdge = viewportX < edgeLeft;
  const isInRightEdge = viewportX > edgeRight;
  const isInTopEdge = viewportY < edgeTop;
  const isInBottomEdge = viewportY > edgeBottom;

  if (!(isInLeftEdge || isInRightEdge || isInTopEdge || isInBottomEdge)) {
    clearTimeout(timer);
    return;
  }
  const documentWidth = Math.max(
    document.body.scrollWidth,
    document.body.offsetWidth,
    document.body.clientWidth,
    document.documentElement.scrollWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
  const documentHeight = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.body.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  );

  const maxScrollX = documentWidth - viewportWidth;
  const maxScrollY = documentHeight - viewportHeight;

  const adjustWindowScroll = () => {
    const currentScrollX = window.pageXOffset;
    const currentScrollY = window.pageYOffset;

    const canScrollUp = currentScrollY > 0;
    const canScrollDown = currentScrollY < maxScrollY;
    const canScrollLeft = currentScrollX > 0;
    const canScrollRight = currentScrollX < maxScrollX;

    let nextScrollX = currentScrollX;
    let nextScrollY = currentScrollY;
    let intensity: number;

    const maxStep = 50;

    if (isInLeftEdge && canScrollLeft) {
      intensity = (edgeLeft - viewportX) / edgeSize;

      nextScrollX -= maxStep * intensity;
    } else if (isInRightEdge && canScrollRight) {
      intensity = (viewportX - edgeRight) / edgeSize;

      nextScrollX += maxStep * intensity;
    }

    if (isInTopEdge && canScrollUp) {
      intensity = (edgeTop - viewportY) / edgeSize;

      nextScrollY -= maxStep * intensity;
    } else if (isInBottomEdge && canScrollDown) {
      intensity = (viewportY - edgeBottom) / edgeSize;

      nextScrollY += maxStep * intensity;
    }

    nextScrollX = Math.min(maxScrollX, nextScrollX);
    nextScrollY = Math.min(maxScrollY, nextScrollY);
    if (nextScrollX !== currentScrollX || nextScrollY !== currentScrollY) {
      window.scrollTo(nextScrollX, nextScrollY);
      return true;
    }
    return false;
  };

  (function checkForWindowScroll() {
    clearTimeout(timer);
    if (adjustWindowScroll()) {
      timer = setTimeout(checkForWindowScroll, 30);
    }
  })();
};

export { autoScroll };
