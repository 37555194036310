import React, { FC } from 'react';
import { Menu, Dropdown, Button, Radio } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { LanguageConstants } from '../../constants';
import { useLanguage } from '../../hooks';
import { IconSvg } from '../Common';

const StyledMenuTogglerTransparentCss = css`
  color: ${({ theme }) => theme.color.white} !important;
  background-color: transparent;

  .anticon {
    color: ${({ theme }) => theme.color.white};
  }

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.color.white} !important;
    background-color: transparent;

    .anticon {
      color: ${({ theme }) => theme.color.white};
    }
  }
`;

const StyledMenuToggler = styled(Button)<{
  text?: boolean;
  transparent?: boolean;
}>`
  && {
    border: none;
    margin-left: 0px;
    font-size: 24px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.gray3} !important;
    padding-top: 8px;

    .anticon {
      margin-left: 00px;
      font-size: 20px !important;
      color: ${({ theme }) => theme.color.purple};
    }

    display: flex;
    align-items: center;

    &:hover,
    &:active,
    &:focus {
      color: ${({ theme }) => theme.color.dark} !important;

      .anticon {
        color: ${({ theme }) => theme.color.dark};
      }
    }

    ${({ transparent }) => transparent && StyledMenuTogglerTransparentCss}
  }
`;

const StyledMenu = styled(Menu)`
  background-color: ${({ theme }) => theme.color.white} !important;

  .ant-radio-inner::after {
    background-color: ${({ theme }) => theme.color.purple} !important;
  }

  .ant-radio-checked > .ant-radio-inner {
    border-color: ${({ theme }) => theme.color.purple} !important;
  }
`;

interface LanguageDropdownProps {
  transparent?: boolean;
  className?: string;
}

const LanguageDropdown: FC<LanguageDropdownProps> = ({
  transparent,
  className,
}) => {
  const { language, setLanguage } = useLanguage();

  const onChange = (e, lang) => {
    e.preventDefault();
    setLanguage(lang);
  };

  const menu = (
    <StyledMenu className="code-name-tomatoes" selectedKeys={[language]}>
      {LanguageConstants.SUPPORTED_LANGUAGES.map(lang => (
        <Menu.Item key={lang}>
          <Link
            to="/"
            key={lang}
            onClick={e => {
              onChange(e, lang);
            }}
          >
            <Radio checked={language === lang} name={lang} value={lang} />
            <FormattedMessage
              id={`LanguageDropdown.${lang}`}
              defaultMessage={lang}
            />
          </Link>
        </Menu.Item>
      ))}
    </StyledMenu>
  );

  return (
    <div className={className}>
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        trigger={['click']}
        overlayClassName="languageMenu"
      >
        <StyledMenuToggler
          className="language-toggler"
          transparent={transparent}
          data-testid="language-menu"
        >
          <IconSvg type="Language" />
        </StyledMenuToggler>
      </Dropdown>
    </div>
  );
};

export { LanguageDropdown };
