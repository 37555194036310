/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { Table, Icon, Row, Dropdown, Menu } from 'antd';
import {
  TableProps,
  TableStateFilters,
  SortOrder,
  ColumnProps,
} from 'antd/lib/table';
import styled, { createGlobalStyle } from 'styled-components';
// eslint-disable-next-line import/no-cycle
import { IconSvg } from '..';

const CustomSortContainer = styled(({ supportOrder: boolean, ...rest }) => (
  <Row {...rest} />
))`
  ${({ supportOrder }) => supportOrder && 'padding-right: 35px;'}
  position: relative;
`;

const CustomDropDownSortContainer = styled(({ ...rest }) => <Row {...rest} />)`
  position: relative;
`;

const StyledSortContainer = styled(({ ...rest }) => <Row {...rest} />)`
  display: table;
`;

const StyledIconSpan = styled.span`
  display: table-cell;
  vertical-align: middle;
`;

const CustomSortCaretIconContainer = styled.div`
  margin-left: 0.6rem;
`;

const CustomUpSortIcon = styled.div`
  height: 0.5rem;
  font-size: 6px;
  .anticon {
    color: ${({ theme }) => theme.color.dark40};
    &.fillColor {
      color: ${({ theme }) => theme.color.purple};
    }
  }
`;

const CustomDownSortIcon = styled.div`
  font-size: 6px;
  .anticon {
    color: ${({ theme }) => theme.color.dark40};
    &.fillColor {
      color: ${({ theme }) => theme.color.purple};
    }
  }
`;

const CustomSortIconContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin-left: 15px;
  width: 20px;

  .drop-down-icon {
    margin-left: 22px !important;
    cursor: pointer;
    &.fillColor {
      color: ${({ theme }) => theme.color.purple};
    }
  }
`;

const StyledMenu = createGlobalStyle<{ theme: STATES.ThemeState }>`
  .menu-wrapper {
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.9);
  }
  .main-layout-popup {
    font-size: 16px;
    line-height: 20px;
    & .custom-dropdown {
      width: 140px;
      text-align: left;
      background:${({ theme }) => theme.color.gray9};
      margin-top: 0px;
      margin-bottom: 0px;
      &:first-child {
        border-bottom: 1px solid ${({ theme }) => theme.color.grey8}
      }
    }

    & .ant-menu-vertical .ant-menu-item:not(:last-child) {
      margin-bottom: 0px;
    }
    & .ant-menu-vertical .ant-menu-item {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    & .ant-menu-vertical {
      border-right: none;
    }

    & .ant-menu-item {
      background: ${({ theme }) => theme.color.pearl40};
    }

    & .ant-menu-item:hover {
      background-color: ${({ theme }) => theme.color.purple};
      color: ${({ theme }) => theme.color.white}
    }

    .ant-menu-item-selected {
      color: ${({ theme }) => theme.color.black}
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background:${({ theme }) => theme.color.purple};
      color: ${({ theme }) => theme.color.black}
    }
  }
`;

const StyledUpDownIconWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  align-items: center;
  justify-content: center;

  .colored {
    color: ${({ theme }) => theme.color.purple};
  }

  i {
    font-size: 5px;
    line-height: 1.5 !important;
  }
`;

export const StyledDefaultSortTitle = styled.div`
  display: flex;
  align-items: center;

  i {
    color: ${({ theme }) => theme.color.dark40};
  }
`;

export const getColumnTitle = (sortOrder: string, title: string) => (
  <StyledDefaultSortTitle>
    {title}
    <StyledUpDownIconWrap>
      <IconSvg
        type="UpCaret"
        className={sortOrder === 'ascend' ? 'colored' : ''}
      />
      <IconSvg
        type="DownCaret"
        className={sortOrder === 'descend' ? 'colored' : ''}
      />
    </StyledUpDownIconWrap>
  </StyledDefaultSortTitle>
);

export const CustomSortTableOnHeaderCell = <
  T extends {},
  TSortBy extends string | undefined | null
>(
  sortBy: TSortBy | undefined,
  sortDir: SortOrder | undefined,
  setSort: (sortBy: TSortBy | undefined, sortDir: SortOrder | undefined) => void
): ColumnProps<T>['onHeaderCell'] => {
  return (column: ColumnProps<T>) => {
    return {
      onClick: () => {
        if (sortBy === column.key) {
          const sortOptions: (SortOrder | undefined)[] = [
            'ascend',
            'descend',
            undefined,
            'ascend',
          ];
          const nextSortDir = sortOptions[sortOptions.indexOf(sortDir) + 1];
          const nextSortBy = nextSortDir ? sortBy : undefined;

          setSort(nextSortBy, nextSortDir);

          return;
        }

        setSort(
          (typeof column.key === 'number'
            ? column.key.toString()
            : column.key) as TSortBy,
          'ascend'
        );
      },
    };
  };
};

export const sortIconJSX = (columnTitle, column) => (
  <StyledSortContainer type="flex" align="middle">
    <StyledIconSpan>{columnTitle}</StyledIconSpan>
    <StyledIconSpan>
      <CustomSortCaretIconContainer>
        <CustomUpSortIcon>
          {column?.sorter && (
            <IconSvg
              type="UpCaret"
              className={`sort-up-caret-icon ${
                column.sortOrder === 'ascend' ? 'fillColor' : ''
              }`}
            />
          )}
        </CustomUpSortIcon>
        <CustomDownSortIcon>
          {column?.sorter && (
            <IconSvg
              type="DownCaret"
              className={`sort-down-caret-icon ${
                column.sortOrder === 'descend' ? 'fillColor' : ''
              }`}
            />
          )}
        </CustomDownSortIcon>
      </CustomSortCaretIconContainer>
    </StyledIconSpan>
  </StyledSortContainer>
);

export const dropDownJSX = (columnTitle, column, menu) => (
  <CustomDropDownSortContainer type="flex" align="middle">
    <div>{columnTitle}</div>
    <CustomSortIconContainer>
      {
        <Dropdown
          overlay={menu}
          placement="bottomRight"
          className="drop-down-icon"
        >
          <IconSvg
            type="DownCaret"
            className={`filter-icon ${column.sortOrder ? 'fillColor' : ''}`}
          />
        </Dropdown>
      }
    </CustomSortIconContainer>
  </CustomDropDownSortContainer>
);

const CustomSortIconTable = <T extends {}>(props: TableProps<T>) => {
  const { columns } = props;

  const modifedColumns = useMemo(() => {
    if (!columns || columns.length === 0) {
      return [];
    }

    return columns.map(column => {
      if (!column.title) {
        return column;
      }

      const title: ColumnProps<T>['title'] = (options: {
        filters: TableStateFilters;
        sortOrder?: SortOrder;
        sortColumn?: ColumnProps<T> | null;
      }) => {
        const { sortOrder, sortColumn } = options;

        const columnTitle =
          typeof column.title === 'function'
            ? column.title(options)
            : column.title;

        return (
          <CustomSortContainer
            type="flex"
            align="middle"
            supportOrder={
              !!sortColumn &&
              sortColumn.dataIndex === column.dataIndex &&
              !!sortOrder
            }
          >
            <div>{columnTitle}</div>
            <CustomSortIconContainer>
              {sortColumn &&
                sortColumn.dataIndex === column.dataIndex &&
                sortOrder && (
                  <Icon type={sortOrder === 'ascend' ? 'up' : 'down'} />
                )}
            </CustomSortIconContainer>
          </CustomSortContainer>
        );
      };

      return {
        ...column,
        title,
      };
    });
  }, [columns]);

  return <Table {...props} columns={modifedColumns} />;
};

const CustomSortCaretIconTable = <T extends {}>(props: TableProps<T>) => {
  const { columns } = props;

  const modifedColumns = useMemo(() => {
    if (!columns || columns.length === 0) {
      return [];
    }

    return columns.map(column => {
      if (!column.title) {
        return column;
      }

      const title: ColumnProps<T>['title'] = (options: {
        filters: TableStateFilters;
        sortOrder?: SortOrder;
        sortColumn?: ColumnProps<T> | null;
      }) => {
        const columnTitle =
          typeof column.title === 'function'
            ? column.title(options)
            : column.title;

        return sortIconJSX(columnTitle, column);
      };
      return {
        ...column,
        title,
      };
    });
  }, [columns]);

  return <Table {...props} columns={modifedColumns} />;
};

interface CustomTableProps<T extends {}> extends TableProps<T> {
  CustomColumn?: DTO.CustomSortDropColumnProps[];
  rowCickAction?: (record) => void;
  showCustomTitle?: boolean;
  showDefaultSortIcons?: boolean;
  showDropDown?: boolean;
}

const CustomSortDropDownTable = <T extends {}>(
  props: CustomTableProps<any>
) => {
  const {
    CustomColumn,
    dataSource,
    rowCickAction,
    columns,
    showDefaultSortIcons,
    showCustomTitle = true,
    showDropDown = true,
  } = props;

  const modifedColumns = useMemo(() => {
    if (!CustomColumn || CustomColumn.length === 0) {
      if (columns?.length) {
        return columns;
      }
      return [];
    }

    return CustomColumn.map((column, index) => {
      let title: ColumnProps<T>['title'] = column.title;
      if (
        showDefaultSortIcons &&
        column.title &&
        typeof column.title === 'string'
      ) {
        title = (
          <StyledDefaultSortTitle>
            {column.title}
            <IconSvg type="CaretUpDownFilled" />
          </StyledDefaultSortTitle>
        );
      }

      if (!showDefaultSortIcons && column.title && showCustomTitle) {
        const menu = (
          <div className="menu-wrapper">
            <StyledMenu />
            <Menu
              selectedKeys={
                column.sortOrder !== undefined
                  ? [`${column.key}${column.sortOrder}`]
                  : []
              }
            >
              {column.menuItem &&
                column.menuItem.map((item, i) => (
                  <Menu.Item
                    key={`${column.key}${i === 0 ? 'ascend' : 'descend'}`}
                    className="custom-dropdown"
                    onClick={() => {
                      item.onChangeSorting(
                        column.key as DTO.GetProductsRequestSortBy,
                        item.sortDir
                      );
                    }}
                  >
                    {item.text}
                  </Menu.Item>
                ))}
            </Menu>
          </div>
        );

        title = () => {
          const columnTitle = column.title;
          return showDropDown
            ? dropDownJSX(columnTitle, column, menu)
            : sortIconJSX(columnTitle, column);
        };
      }

      return {
        ...column,
        title,
        onCell: record => {
          if (index !== CustomColumn.length - 1) {
            return {
              onClick: () => rowCickAction && rowCickAction(record),
            };
          }
          return null;
        },
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CustomColumn, dataSource]);

  return <Table {...props} columns={modifedColumns as any} />;
};

export {
  CustomSortIconTable,
  CustomSortDropDownTable,
  CustomSortCaretIconTable,
};
