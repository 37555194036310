import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { LanguageActions } from '../actions';

const mapStateToProps = ({
  language: { currentLanguage, intl },
}: STATES.AppState) => ({
  currentLanguage,
  intl,
});

const useLanguage = () => {
  const dispatch = useDispatch();

  const { currentLanguage, intl } = useSelector(mapStateToProps);

  const setLanguage = useCallback(
    lang => {
      if (lang === currentLanguage) {
        return;
      }

      dispatch(LanguageActions.loadMessages(lang));
    },
    [dispatch, currentLanguage]
  );

  return { setLanguage, language: currentLanguage, intl };
};

export { useLanguage };
