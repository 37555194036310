export const GET_ENGINE_VIEW_LOGS = 'GET_ENGINE_VIEW_LOGS';
export const GET_ENGINE_VIEW_LOGS_SUCCESS = 'GET_ENGINE_VIEW_LOGS_SUCCESS';
export const GET_ENGINE_VIEW_LOGS_FAILURE = 'GET_ENGINE_VIEW_LOGS_FAILURE';
export const ENGINE_LOGS_RESET = 'ENGINE_LOGS_RESET';
export const DOWNLOAD_ENGINE_LOGS_REQUEST = 'DOWNLOAD_ENGINE_LOGS_REQUEST';
export const DOWNLOAD_ENGINE_LOGS_SUCCESS = 'DOWNLOAD_ENGINE_LOGS_SUCCESS';
export const DOWNLOAD_ENGINE_LOGS_FAILURE = 'DOWNLOAD_ENGINE_LOGS_FAILURE';
export const DOWNLOAD_MULTIPLE_LOGS_REQUEST = 'DOWNLOAD_MULTIPLE_LOGS_REQUEST';
export const DOWNLOAD_MULTIPLE_LOGS_SUCCESS = 'DOWNLOAD_MULTIPLE_LOGS_SUCCESS';
export const DOWNLOAD_MULTIPLE_LOGS_FAILURE = 'DOWNLOAD_MULTIPLE_LOGS_FAILURE';
export const ADD_DOWNLOAD_LOGS_IN_QUEUE = 'ADD_DOWNLOAD_LOGS_IN_QUEUE';
