import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useCallback, useRef, useState, useMemo } from 'react';
import { createSelector } from 'reselect';
import { isEqual } from 'lodash-es';
import { AlertActions, PermissionsActions } from '../actions';
import { isInvalidPermission } from '../helpers';
import { PermissionConstants } from '../constants';

export enum ActionPermissionResults {
  InvalidPermission,
  NoOfProducts,
  NoOfEngines,
  ValidPermission,
}

const permissionsSelector = createSelector<
  STATES.AppState,
  STATES.PermissionsState,
  STATES.AuthState,
  STATES.PermissionsState & STATES.AuthState
>(
  ({ permissions }) => permissions,
  ({ auth }) => auth,
  (permissions, auth) => ({ ...permissions, ...auth })
);

let CACHED_ACTION_PERMISSIONS: {
  [actionKey: string]: boolean;
} = {};

const useActionPermission = () => {
  const dispatch = useDispatch();
  const [, forceRender] = useState<object>({});
  const {
    userGroupRules,
    groups,
    noOfEnginesOfProduct,
    noOfProducts,
    maxOfEnginesRule,
    maxOfProductsRule,
  } = useSelector(permissionsSelector);
  const userGroupRuleRef = useRef(userGroupRules);

  // Reset cached check result when auth status or userGroupRules changes
  useEffect(() => {
    if (userGroupRuleRef.current !== userGroupRules) {
      CACHED_ACTION_PERMISSIONS = {};
      userGroupRuleRef.current = userGroupRules;
      forceRender({});
    }
  }, [userGroupRules]);

  useEffect(() => {
    forceRender({});
  }, [noOfProducts, noOfEnginesOfProduct]);

  useEffect(() => {
    if (!userGroupRules) {
      dispatch(PermissionsActions.loadUserGroupRules(groups));
    }
    // Only run on didMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, groups]);

  const permissionModals = useMemo(() => {
    const preventEventAndShowMsg = (
      e: React.SyntheticEvent<HTMLElement, Event> | undefined,
      message: string
    ) => {
      e && e.preventDefault();
      dispatch(AlertActions.openGlobalAlert(message));
    };

    return {
      [ActionPermissionResults.InvalidPermission]: (
        e: React.SyntheticEvent<HTMLElement, Event> | undefined
      ) => {
        preventEventAndShowMsg(e, 'Error.permissionError');
      },
      [ActionPermissionResults.NoOfProducts]: (
        e: React.SyntheticEvent<HTMLElement, Event> | undefined
      ) => {
        preventEventAndShowMsg(e, 'Error.noOfProductExceeded');
      },
      [ActionPermissionResults.NoOfEngines]: (
        e: React.SyntheticEvent<HTMLElement, Event> | undefined
      ) => {
        preventEventAndShowMsg(e, 'Error.noOfEnginesExceeded');
      },
    };
  }, [dispatch]);

  const permissionCheck = useCallback(
    ({ ruleName, moduleName }: DTO.ActionPermission) => {
      if (
        typeof CACHED_ACTION_PERMISSIONS[`${moduleName}_${ruleName}`] ===
        'undefined'
      ) {
        const isValid =
          !userGroupRules ||
          Object.values(userGroupRules).length === 0 ||
          Object.values(userGroupRules).some(
            userGroupRule =>
              !isInvalidPermission(userGroupRule, { ruleName, moduleName })
          );

        CACHED_ACTION_PERMISSIONS[`${moduleName}_${ruleName}`] = isValid;
      }

      return CACHED_ACTION_PERMISSIONS[`${moduleName}_${ruleName}`];
    },
    [userGroupRules]
  );

  const getActionPermissionResult = useCallback(
    (actionPermission: DTO.ActionPermission): ActionPermissionResults => {
      const isValid = permissionCheck(actionPermission);

      if (!isValid) {
        return ActionPermissionResults.InvalidPermission;
      }

      if (
        (isEqual(actionPermission, PermissionConstants.PRODUCT_MAX) ||
          isEqual(actionPermission, PermissionConstants.PRODUCT_CLONE)) &&
        maxOfProductsRule <= noOfProducts
      ) {
        return ActionPermissionResults.NoOfProducts;
      }

      if (
        (isEqual(actionPermission, PermissionConstants.ENGINE_MAX) ||
          isEqual(actionPermission, PermissionConstants.ENGINE_ADD)) &&
        maxOfEnginesRule <= noOfEnginesOfProduct
      ) {
        return ActionPermissionResults.NoOfEngines;
      }

      return ActionPermissionResults.ValidPermission;
    },
    [
      permissionCheck,
      maxOfEnginesRule,
      noOfEnginesOfProduct,
      maxOfProductsRule,
      noOfProducts,
    ]
  );

  const eventHandlerWithPermission = useCallback(
    (
      actionPermission: DTO.ActionPermission,
      eventHandler?: React.ReactEventHandler<HTMLElement> | (() => void)
    ) => {
      const result = getActionPermissionResult(actionPermission);

      if (result === ActionPermissionResults.ValidPermission) {
        return eventHandler;
      }

      return permissionModals[result];
    },
    [permissionModals, getActionPermissionResult]
  );

  return {
    getActionPermissionResult,
    showPermissionModal:
      permissionModals[ActionPermissionResults.InvalidPermission],
    permissionModals,
    permissionCheck,
    eventHandlerWithPermission,
  };
};

export { useActionPermission };
