export const GET_USERGROUPS_REQUEST = 'ADMIN_GET_USERGROUPS_REQUEST';
export const GET_USERGROUPS_SUCCESS = 'ADMIN_GET_USERGROUPS_SUCCESS';
export const GET_USERGROUPS_FAILURE = 'ADMIN_GET_USERGROUPS_FAILURE';

export const GET_USERGROUP_REQUEST = 'ADMIN_GET_USERGROUP_REQUEST';
export const GET_USERGROUP_SUCCESS = 'ADMIN_GET_USERGROUP_SUCCESS';
export const GET_USERGROUP_FAILURE = 'ADMIN_GET_USERGROUP_FAILURE';

export const SAVE_USERGROUP_SETTINGS_REQUEST =
  'ADMIN_SAVE_USERGROUP_SETTINGS_REQUEST';
export const SAVE_USERGROUP_SETTINGS_SUCCESS =
  'ADMIN_SAVE_USERGROUP_SETTINGS_SUCCESS';
export const SAVE_USERGROUP_SETTINGS_FAILURE =
  'ADMIN_SAVE_USERGROUP_SETTINGS_FAILURE';

export const DELETE_USERGROUP_REQUEST = 'ADMIN_DELETE_USERGROUP_REQUEST';
export const DELETE_USERGROUP_SUCCESS = 'ADMIN_DELETE_USERGROUP_SUCCESS';
export const DELETE_USERGROUP_FAILURE = 'ADMIN_DELETE_USERGROUP_FAILURE';

export const GET_GROUPS_REQUEST = 'ADMIN_GET_GROUPS_REQUEST';
export const GET_GROUPS_SUCCESS = 'ADMIN_GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAILURE = 'ADMIN_GET_GROUPS_FAILURE';

export const SAVE_USER_GROUP_REQUEST = 'ADMIN_SAVE_USER_GROUP_REQUEST';
export const SAVE_USER_GROUP_SUCCESS = 'ADMIN_SAVE_USER_GROUP_SUCCESS';
export const SAVE_USER_GROUP_FAILURE = 'ADMIN_SAVE_USER_GROUP_FAILURE';

export const EDIT_USER_GROUP_REQUEST = 'ADMIN_EDIT_USER_GROUP_REQUEST';
export const EDIT_USER_GROUP_SUCCESS = 'ADMIN_EDIT_USER_GROUP_SUCCESS';
export const EDIT_USER_GROUP_FAILURE = 'ADMIN_EDIT_USER_GROUP_FAILURE';

export const SELECT_USERGROUP = 'SELECT_USERGROUP';
