import { getConfig } from '../helpers/config';
import makeRequest from '../helpers/make-request';
import uploadRequest from '../helpers/upload-request';
import { CurlSwaggerConstants } from '../constants';

const getProductEngines = (
  productName: string,
  request: DTO.GetProductEnginesRequest
) => {
  const {
    page = 1,
    pageSize = 11,
    sortBy = 'updated',
    sort = 'desc',
    searchText = '',
    fakeSortingPage = null,
    fakeSortingPageSize = null,
  } = request;

  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines`;
  return makeRequest<DTO.GetProductEnginesResponse>('POST', url, {
    page: fakeSortingPage || page,
    pageSize: fakeSortingPageSize || pageSize,
    search:
      searchText === ''
        ? []
        : [
            {
              field: 'name1_co',
              value: searchText,
            },
          ],
    sort: sortBy ? `${sort === 'desc' ? '-' : ''}${sortBy}` : '',
  });
};

const updateProductEngine = (
  productName: string,
  serviceName: string,
  request: DTO.UpdateProductEnginePropsRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/updateengine/${serviceName}`;

  return makeRequest('PUT', url, request);
};

const getProductEngineDetails = (
  productName: string,
  serviceName: string,
  versionId?: string
) => {
  let url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/get/${serviceName}`;

  if (versionId) {
    url += `/${versionId}`;
  }

  return makeRequest<DTO.GetProductEngineDetailsResponse>('GET', url);
};

const deleteEngine = (productName: string, serviceName: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/delete/${serviceName}`;

  return makeRequest('DELETE', url);
};

const uploadRegressionTestInput = (
  productName: string,
  file: File,
  onUploadProgress: (percent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/regression`;
  const formData = new FormData();

  formData.append('file', file);

  return uploadRequest<DTO.RegressionTestResponse>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const uploadEngine = (
  productName: string,
  file: File,
  onUploadProgress: (percent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/add`;
  const formData = new FormData();

  formData.append('file', file);

  return uploadRequest<DTO.AddProductEngineResponse>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const publishEngine = (
  productName: string,
  serviceName: string,
  data?: DTO.PublishEngineRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/publish/${serviceName}`;
  return makeRequest<unknown>('PUT', url, data);
};

const markFavorite = (
  productName: string,
  serviceName: string,
  isFavorite: boolean
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/markFavorite/${serviceName}/${isFavorite}`;

  return makeRequest<unknown>('POST', url);
};

const getProductEngineLogs = (
  productName: string,
  serviceName: string,
  versionId: string,
  request: DTO.GetProductEngineLogsRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/Product/${productName}/engines/${serviceName}/logs/${versionId}`;

  const {
    page = 1,
    pageSize = 20,
    sortBy = 'timestamp',
    sort = 'desc',
    searchText,
    startDate,
    endDate,
  } = request;

  const search: { field: string; value: string }[] = [];

  if (startDate) {
    search.push({ field: 'StartDate', value: startDate });
  }

  if (endDate) {
    search.push({ field: 'EndDate', value: endDate });
  }

  if (searchText) {
    search.push({ field: 'Search', value: searchText });
  }

  return makeRequest<DTO.GetProductEngineLogsResponse>('POST', url, {
    page,
    pageSize,
    sort: sortBy ? `${sort === 'desc' ? '-' : ''}${sortBy}` : '',
    search,
  });
};

const executeProductEngine = (
  productName: string,
  serviceName: string,
  data: DTO.ExcelEngineRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/Execute/${serviceName}`;
  return makeRequest<DTO.ExecuteProductEngineResponse>('POST', url, data);
};

const generateJsSheet = (productName: string, serviceName: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/generatejssheetengine/${serviceName}`;
  return makeRequest<DTO.JsSheetResponse>('POST', url);
};

const getJsSheetEngine = (productName: string, serviceName: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/getjssheetengine/${serviceName}`;
  return makeRequest<DTO.JsSheetResponse>('POST', url);
};

const executeProductEngineInputs = (
  productName: string,
  serviceName: string,
  data: DTO.ExcelEngineRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/ExecuteInputs/${serviceName}`;
  return makeRequest<DTO.ExecuteEngineInputsResponse>('POST', url, data);
};

const executeProductEngineWithChain = (
  productName: string,
  serviceName: string,
  data: DTO.ExcelEngineRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/ExecuteChain/${serviceName}`;
  return makeRequest<DTO.ExecuteProductEngineResponse>('POST', url, data);
};

const getDownloadMatchesEngineLogsFileUrl = (
  productName: string,
  serviceName: string,
  versionId: string,
  startDate: string,
  endDate: string
) => {
  const timeZoneOffset = new Date().getTimezoneOffset();
  if (startDate && endDate) {
    return `${
      getConfig().excelEngineDomain
    }/api/v1/product/${productName}/engines/${serviceName}/logs/DownloadAll/${versionId}/${timeZoneOffset}/${startDate}/${endDate}`;
  }
  return `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/${serviceName}/logs/DownloadAll/${versionId}/${timeZoneOffset}`;
};

const getDownloadEngineLogFileUrl = (
  productName: string,
  serviceName: string,
  startDate: string | undefined,
  endDate: string | undefined,
  engineCallId: string
) => {
  if (startDate && endDate) {
    return `${
      getConfig().excelEngineDomain
    }/api/v1/product/${productName}/engines/${serviceName}/logs/Download/${engineCallId}/${startDate}/${endDate}`;
  }
  return `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/${serviceName}/logs/Download/${engineCallId}`;
};

const getDownloadEngineUrl = (
  productName: string,
  serviceName: string,
  version: string,
  fileName: string,
  type: string
) => {
  return `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/${serviceName}/Download/${version}?fileName=${fileName}&type=${type}`;
};

const getFormSpec = (productName: string, serviceName: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/${serviceName}/GetFormSpec`;

  return makeRequest<DTO.GetFormSpecResponse>('POST', url);
};

const getFormState = (
  productName: string,
  serviceName: string,
  fields: DTO.FormStateInputField[],
  sectionId = ''
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/${serviceName}/GetFormState/${sectionId}`;

  return makeRequest<DTO.GetFormStateResponse>('POST', url, fields);
};

const getTutorialFormSpec = (productName: string, serviceName: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/${serviceName}/GetFormSpecProductTutorial`;

  return makeRequest<DTO.GetFormSpecResponse>('POST', url);
};

const getTutorialFormState = (
  productName: string,
  serviceName: string,
  fields: DTO.FormStateInputField[]
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/${serviceName}/GetFormStateProductTutorial`;

  return makeRequest<DTO.GetFormStateResponse>('POST', url, fields);
};

const getVersions = (productName: string, serviceName: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/getversions/${serviceName}`;

  return makeRequest<DTO.GetEngineVersionsResponse>('GET', url);
};

const getSwaggerDownloadUrl = (request: DTO.DownloadSwaggerRequest) => {
  const { productName, serviceName, category, version } = request;
  const uri = request.isFormData
    ? 'downloadswaggerformdata'
    : 'downloadswagger';
  return `${
    getConfig().excelEngineDomain
  }/api/v1/Product/${productName}/engines/${serviceName}/${uri}/${category ||
    'Default'}/${version ? `${version}/` : ''}`;
};

const getExecuteOutputFile = (
  productName: string,
  engine: string,
  data: DTO.ExcelEngineRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/Downloadoutput/${engine}`;
  return makeRequest<DTO.DownloadExecuteOutputResponse>('POST', url, data);
};

const getCurlApiUri = (cUrlRequestType: string) => {
  let uri = '';
  if (cUrlRequestType === CurlSwaggerConstants.CURL || cUrlRequestType === '') {
    uri = 'downloadcurl';
  } else if (cUrlRequestType === CurlSwaggerConstants.CURLFORMDATA) {
    uri = 'downloadcurlformdata';
  } else if (cUrlRequestType === CurlSwaggerConstants.CURLPOSIX) {
    uri = 'downloadposixcurl';
  } else if (cUrlRequestType === CurlSwaggerConstants.CURLWIN) {
    uri = 'downloadwincurl';
  } else {
    uri = 'downloadcurl';
  }
  return uri;
};

const getCUrlDownloadUrl = (request: DTO.DownloadCUrlRequest) => {
  const { productName, serviceName, category, version, requestType } = request;
  const uri = getCurlApiUri(requestType);
  return `${
    getConfig().excelEngineDomain
  }/api/v1/Product/${productName}/engines/${serviceName}/${uri}/${category ||
    'Default'}/${version ? `${version}/` : ''}`;
};

const getPerformanceMetrics = (
  productName: string,
  data: DTO.PerformanceMetricsRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/metrics/get`;

  data.Engines.forEach(engine => delete engine.Revision);

  return makeRequest<DTO.EnginePerformanceMetricsResponse>('POST', url, data);
};

const getSurfixUrl = (productName: string, engineName: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/proxy/GetProxyRule/${productName}/${engineName}`;

  return makeRequest<DTO.GetProxyUrlResponse>('GET', url);
};

const saveProxyUrl = (data: DTO.SaveProxyUrlRequest) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/proxy/SaveProxyRule`;

  return makeRequest<DTO.SaveProxyUrlResponse>('POST', url, data);
};

const deleteProxyUrl = (proxyUrl: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/proxy/DeleteProxyRule/${proxyUrl}`;

  return makeRequest('DELETE', url);
};

const restoreVersion = (
  productName: string,
  engineName: string,
  versionId: string
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/restoreversion/${engineName}/${versionId}`;

  return makeRequest<{
    data: string;
  }>('POST', url);
};

const getEngineChain = (
  productName: string,
  engineName: string,
  revision: string
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/${engineName}/chain/${revision}`;

  return makeRequest<DTO.GetEngineChainResponse>('GET', url);
};

const getUnchainedEngines = (
  productName: string,
  engineName: string,
  revision: string
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/${engineName}/unchained/${revision}`;

  return makeRequest<{
    data: string[];
  }>('GET', url);
};

const createEngineChain = (
  data: DTO.CreateEngineChainRequest,
  serviceName: string
) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/product/${
    data.Product
  }/engines/${serviceName}/chain`;

  return makeRequest('POST', url, data);
};

const deleteEngineChain = (
  productName: string,
  engineName: string,
  selectedEngine: string,
  revision: string
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/${engineName}/chain/${revision}/${selectedEngine}`;

  return makeRequest<{
    data: string;
  }>('DELETE', url);
};

const DownloadMultipleLogs = (
  productName: string,
  serviceName: string,
  versionId: string,
  request: DTO.DownloadMultipleLogsRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/${serviceName}/logs/${versionId}/Download`;

  return makeRequest<DTO.DownloadMultipleLogsResponse>('POST', url, request);
};

const generateTesterApiDocumentation = ({
  productName,
  serviceName,
  category,
  version,
}: DTO.GenerateApiDocumentationRequest) => {
  let body: {
    productName: string;
    serviceName: string;
    category: string;
    version?: string;
  } = {
    productName,
    serviceName,
    category,
  };
  if (version) {
    body = {
      ...body,
      version,
    };
  }
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/apidocumentation/generateapidoc`;

  return makeRequest<unknown>('POST', url, body);
};

const downloadTesterApiDocumentationAsPDF = (HtmlBase64String: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/apidocumentation/exportaspdf`;

  return makeRequest<{ blob: Blob; blobName: string }>('POST', url, {
    HtmlBase64String,
  });
};

export const EngineService = {
  deleteEngineChain,
  createEngineChain,
  getEngineChain,
  getUnchainedEngines,
  getProductEngines,
  getProductEngineDetails,
  updateProductEngine,
  markFavorite,
  deleteEngine,
  uploadEngine,
  uploadRegressionTestInput,
  publishEngine,
  getProductEngineLogs,
  executeProductEngine,
  executeProductEngineInputs,
  executeProductEngineWithChain,
  getDownloadMatchesEngineLogsFileUrl,
  getDownloadEngineLogFileUrl,
  getDownloadEngineUrl,
  getFormSpec,
  getFormState,
  getSwaggerDownloadUrl,
  getVersions,
  getPerformanceMetrics,
  getTutorialFormSpec,
  getTutorialFormState,
  getSurfixUrl,
  saveProxyUrl,
  deleteProxyUrl,
  getCUrlDownloadUrl,
  getExecuteOutputFile,
  restoreVersion,
  generateJsSheet,
  getJsSheetEngine,
  DownloadMultipleLogs,
  generateTesterApiDocumentation,
  downloadTesterApiDocumentationAsPDF,
};
