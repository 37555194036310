import { TestbedAction } from '../actions/types';
import { TestbedConstants } from '../constants';

export const initialState: STATES.TestbedState = {
  savingTemplate: false,
  generatingTestTemplate: false,
  templateFileName: null,
  templateSuccess: false,
  templateUrl: null,
  saveTemplateSuccess: false,
  fetchingTestbeds: false,
  testbedList: [],
  totalTestbeds: 0,
  testbedQueueProgress: null,
  testbedResult: null,
  testbedQueueId: null,
  savingTesbedQueue: false,
  fetchingTestbedQueue: false,
  fetchingTestbedResult: false,
  generatingTestResult: false,
  testbedResultUrl: null,
  testbedResultFileName: null,
  testbedTemplateList: [],
  testbedResultList: [],
  totalTestbedResults: 0,
  totalTestbedTemplates: 0,
  selectedTestbedTemplate: null,
  deletingTestbedRecord: false,
  deleteTestTemplateSuccess: false,
  deleteTestbedSuccesss: false,
  testbedTemplateVersions: {},
  testbedVersions: {},
  selectedTestbed: null,
  executeProgress: 0,
  uploadError: null,
  uploadProgress: 0,
  xhrRef: null,
  noOfTest: 0,
  testbedVersionId: '',
  progressOutside: false,
  fetchingTestbedItems: false,
  savingTaskQueue: false,
  taskQueueId: null,
  taskQueueProgress: null,
  fetchingTaskQueue: false,
  testCompareResult: null,
  latestTestbedVersionId: null,
  savingTestbed: false,
  downloadingTestbedTestCases: false,
  downloadTestbedUrl: null,
  downloadTestbedFileName: null,
};

export const testbeds = (
  state = initialState,
  action: TestbedAction
): STATES.TestbedState => {
  switch (action.type) {
    case TestbedConstants.GENERATED_TEST_TEMPLATE_REQUEST: {
      return {
        ...state,
        generatingTestTemplate: true,
      };
    }
    case TestbedConstants.GENERATED_TEST_TEMPLATE_SUCCESS: {
      const { url, fileName } = action.payload;

      return {
        ...state,
        generatingTestTemplate: false,
        templateUrl: url,
        templateFileName: fileName,
      };
    }
    case TestbedConstants.GENERATED_TEST_TEMPLATE_FAILURE: {
      return {
        ...state,
        generatingTestTemplate: false,
      };
    }
    case TestbedConstants.SAVE_TEST_TEMPLATE_REQUEST: {
      return {
        ...state,
        savingTemplate: true,
      };
    }
    case TestbedConstants.SAVE_TEST_TEMPLATE_SUCCESS: {
      return {
        ...state,
        savingTemplate: false,
        saveTemplateSuccess: true,
      };
    }
    case TestbedConstants.SAVE_TEST_TEMPLATE_FAILURE: {
      return {
        ...state,
        savingTemplate: false,
      };
    }
    case TestbedConstants.GET_ALL_TEST_BED_REQUEST:
    case TestbedConstants.GET_TEST_BEDS_REQUEST: {
      return {
        ...state,
        fetchingTestbeds: true,
      };
    }
    case TestbedConstants.GET_TEST_BEDS_SUCCESS: {
      const { testbedList, totalTestbeds } = action.payload;
      return {
        ...state,
        fetchingTestbeds: false,
        testbedList,
        totalTestbeds,
      };
    }
    case TestbedConstants.GET_TEST_BED_SUCCESS: {
      const { testbed } = action.payload;
      return {
        ...state,
        fetchingTestbeds: false,
        selectedTestbed: testbed,
      };
    }
    case TestbedConstants.GET_TEST_BED_VERSIONS_SUCCESS: {
      const { testbedVersions, testbedId } = action.payload;
      return {
        ...state,
        fetchingTestbeds: false,
        testbedVersions: {
          ...state.testbedVersions,
          [testbedId]: {
            versions: testbedVersions,
          },
        },
      };
    }
    case TestbedConstants.GET_ALL_TEST_BED_FAILURE:
    case TestbedConstants.GET_TEST_BEDS_FAILURE: {
      return {
        ...state,
        fetchingTestbeds: false,
      };
    }
    case TestbedConstants.GET_TEST_BED_QUEUE_REQUEST: {
      return {
        ...state,
        fetchingTestbedQueue: true,
      };
    }
    case TestbedConstants.GET_TEST_BED_QUEUE_SUCCESS: {
      const { testbedQueueProgress } = action.payload;
      return {
        ...state,
        testbedQueueProgress,
        fetchingTestbedQueue: false,
      };
    }
    case TestbedConstants.GET_TEST_BED_QUEUE_FAILURE: {
      return {
        ...state,
        fetchingTestbedQueue: false,
      };
    }
    case TestbedConstants.SAVE_TEST_BED_QUEUE_REQUEST: {
      return {
        ...state,
        savingTesbedQueue: true,
        testbedQueueId: null,
        testbedQueueProgress: null,
        testbedResult: null,
      };
    }
    case TestbedConstants.SAVE_TEST_BED_QUEUE_SUCCESS: {
      const { testbedQueueId } = action.payload;
      return {
        ...state,
        testbedQueueId,
        savingTesbedQueue: false,
      };
    }
    case TestbedConstants.SAVE_TEST_BED_QUEUE_FAILURE: {
      return {
        ...state,
        savingTesbedQueue: false,
      };
    }
    case TestbedConstants.GET_TEST_BED_RESULT_REQUEST: {
      return {
        ...state,
        fetchingTestbedResult: true,
      };
    }
    case TestbedConstants.GET_TEST_BED_RESULT_SUCCESS: {
      const { testbedResult } = action.payload;
      return {
        ...state,
        testbedResult,
        fetchingTestbedResult: false,
      };
    }
    case TestbedConstants.GET_TEST_BED_RESULT_FAILURE: {
      return {
        ...state,
        fetchingTestbedResult: false,
      };
    }
    case TestbedConstants.RESET_TEST_TEMPLATE: {
      return {
        ...initialState,
      };
    }
    case TestbedConstants.GET_TEST_BED_TEMPLATES_REQUEST: {
      return {
        ...state,
        fetchingTestbedItems: true,
      };
    }
    case TestbedConstants.GET_TEST_BED_TEMPLATES_SUCCESS: {
      const { testbedTemplateList, totalTestbedTemplates } = action.payload;
      return {
        ...state,
        fetchingTestbedItems: false,
        testbedTemplateList,
        totalTestbedTemplates,
      };
    }
    case TestbedConstants.GET_TEST_BED_TEMPLATE_VERSIONS_SUCCESS: {
      const { testbedTemplateVersions, testbedTemplateId } = action.payload;
      return {
        ...state,
        fetchingTestbedItems: false,
        testbedTemplateVersions: {
          ...state.testbedTemplateVersions,
          [testbedTemplateId]: {
            versions: testbedTemplateVersions,
          },
        },
      };
    }
    case TestbedConstants.GET_TEST_BED_TEMPLATES_FAILURE: {
      return {
        ...state,
        fetchingTestbedItems: false,
      };
    }
    case TestbedConstants.GET_TEST_BED_RESULTS_REQUEST: {
      return {
        ...state,
        fetchingTestbeds: true,
      };
    }
    case TestbedConstants.GET_TEST_BED_RESULTS_SUCCESS: {
      const { testbedResultList, totalTestbedResults } = action.payload;
      return {
        ...state,
        fetchingTestbeds: false,
        testbedResultList,
        totalTestbedResults,
      };
    }
    case TestbedConstants.GET_TEST_BED_RESULTS_FAILURE: {
      return {
        ...state,
        fetchingTestbeds: false,
      };
    }
    case TestbedConstants.GET_TEST_BED_TEMPLATE_REQUEST: {
      return {
        ...state,
        fetchingTestbeds: true,
      };
    }
    case TestbedConstants.GET_TEST_BED_TEMPLATE_SUCCESS: {
      const { selectedTestbedTemplate } = action.payload;
      return {
        ...state,
        fetchingTestbeds: false,
        selectedTestbedTemplate,
      };
    }
    case TestbedConstants.GET_TEST_BED_TEMPLATE_FAILURE: {
      return {
        ...state,
        fetchingTestbeds: false,
      };
    }
    case TestbedConstants.REMOVE_TEST_BED_TEMPLATE_REQUEST:
    case TestbedConstants.REMOVE_TEST_BED_REQUEST: {
      return {
        ...state,
        deletingTestbedRecord: true,
        deleteTestTemplateSuccess: false,
        deleteTestbedSuccesss: false,
      };
    }
    case TestbedConstants.REMOVE_TEST_BED_TEMPLATE_SUCCESS: {
      return {
        ...state,
        deletingTestbedRecord: false,
        deleteTestTemplateSuccess: true,
      };
    }
    case TestbedConstants.REMOVE_TEST_BED_SUCCESS: {
      return {
        ...state,
        deletingTestbedRecord: false,
        deleteTestbedSuccesss: true,
      };
    }
    case TestbedConstants.REMOVE_TEST_BED_TEMPLATE_FAILURE:
    case TestbedConstants.REMOVE_TEST_BED_FAILURE: {
      return {
        ...state,
        deletingTestbedRecord: false,
      };
    }
    case TestbedConstants.TEST_BED_UPLOAD_START: {
      return {
        ...state,
        uploadProgress: 5,
      };
    }
    case TestbedConstants.TEST_BED_UPLOAD_XHR_REF: {
      const { xhrRef } = action.payload;
      return {
        ...state,
        xhrRef,
      };
    }
    case TestbedConstants.TEST_BED_UPLOAD_PROGRESS: {
      const { uploadProgress } = action.payload;

      return {
        ...state,
        uploadProgress,
      };
    }
    case TestbedConstants.TEST_BED_UPLOAD_SUCCESS: {
      const { noOfTest, testbedVersionId } = action.payload;
      return {
        ...state,
        uploadProgress: 100,
        noOfTest,
        testbedVersionId,
      };
    }
    case TestbedConstants.TEST_BED_UPLOAD_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        uploadError: error,
      };
    }
    case TestbedConstants.TEST_BED_UPLOAD_RESET: {
      return {
        ...initialState,
      };
    }
    case TestbedConstants.GET_TEST_BED_ITEMS_REQUEST: {
      return {
        ...initialState,
        fetchingTestbedItems: true,
      };
    }

    case TestbedConstants.GET_TEST_BED_ITEMS_SUCCESS: {
      const { testbedItems, count } = action.payload;

      return {
        ...initialState,
        fetchingTestbedItems: false,
        testbedItemPagedList: testbedItems,
        testbedItemCount: count,
      };
    }
    case TestbedConstants.GET_TEST_BED_ITEMS_FAILURE: {
      return {
        ...state,
        fetchingTestbedItems: false,
      };
    }
    case TestbedConstants.QUEUE_COMPARE_RUN_REQUEST: {
      return {
        ...state,
        savingTaskQueue: true,
        taskQueueId: null,
        taskQueueProgress: null,
        testCompareResult: null,
      };
    }
    case TestbedConstants.QUEUE_COMPARE_RUN_SUCCESS: {
      const { taskQueueId } = action.payload;
      return {
        ...state,
        taskQueueId,
        savingTaskQueue: false,
      };
    }
    case TestbedConstants.QUEUE_COMPARE_RUN_FAILURE: {
      return {
        ...state,
        savingTesbedQueue: false,
      };
    }
    case TestbedConstants.GET_TEST_RUN_BY_TESTBEDID_REQUEST: {
      return {
        ...state,
        fetchingTestbedResult: true,
      };
    }
    case TestbedConstants.GET_TEST_RUN_BY_TESTBEDID_SUCCESS: {
      const { testbedResultList } = action.payload;
      return {
        ...state,
        testbedResultList,
        fetchingTestbedResult: false,
      };
    }
    case TestbedConstants.GET_TEST_RUN_BY_TESTBEDID_FAILURE: {
      return {
        ...state,
        fetchingTestbedResult: false,
      };
    }
    case TestbedConstants.GENERATED_TEST_COMPARE_REQUEST: {
      return {
        ...state,
        generatingTestResult: true,
      };
    }
    case TestbedConstants.GENERATED_TEST_COMPARE_SUCCESS: {
      const { url, fileName } = action.payload;

      return {
        ...state,
        generatingTestResult: false,
        testbedResultUrl: url,
        testbedResultFileName: fileName,
      };
    }
    case TestbedConstants.GENERATED_TEST_COMPARE_FAILURE: {
      return {
        ...state,
        generatingTestResult: false,
      };
    }
    case TestbedConstants.GET_TEST_COMPARE_STATUS_REQUEST: {
      return {
        ...state,
        fetchingTaskQueue: true,
      };
    }
    case TestbedConstants.GET_TEST_COMPARE_STATUS_SUCCESS: {
      const { taskQueueProgress } = action.payload;
      return {
        ...state,
        taskQueueProgress,
        fetchingTaskQueue: false,
      };
    }
    case TestbedConstants.GET_TEST_COMPARE_STATUS_FAILURE: {
      return {
        ...state,
        fetchingTaskQueue: false,
      };
    }
    case TestbedConstants.GET_TEST_COMPARE_RESULT_REQUEST: {
      return {
        ...state,
        fetchingTestbedResult: true,
      };
    }
    case TestbedConstants.GET_TEST_COMPARE_RESULT_SUCCESS: {
      const { testCompareResult } = action.payload;
      return {
        ...state,
        testCompareResult,
        fetchingTestbedResult: false,
      };
    }
    case TestbedConstants.GET_TEST_COMPARE_RESULT_FAILURE: {
      return {
        ...state,
        fetchingTestbedResult: false,
      };
    }
    case TestbedConstants.UPDATE_TEST_BED_REQUEST: {
      return {
        ...state,
        savingTestbed: true,
        latestTestbedVersionId: null,
      };
    }
    case TestbedConstants.UPDATE_TEST_BED_SUCCESS: {
      const { latestTestbedVersionId } = action.payload;
      return {
        ...state,
        latestTestbedVersionId,
        savingTestbed: false,
      };
    }
    case TestbedConstants.UPDATE_TEST_BED_FAILURE: {
      return {
        ...state,
        savingTestbed: false,
      };
    }

    case TestbedConstants.GET_ALL_TEST_BED_SUCCESS: {
      const { testbedList } = action.payload;
      return {
        ...state,
        fetchingTestbeds: false,
        testbedList,
      };
    }
    case TestbedConstants.GET_GENERATE_TESTCASE_STATUS_REQUEST: {
      return {
        ...state,
        fetchingTaskQueue: true,
      };
    }
    case TestbedConstants.GET_GENERATE_TESTCASE_STATUS_SUCCESS: {
      const { taskQueueProgress } = action.payload;
      return {
        ...state,
        taskQueueProgress,
        fetchingTaskQueue: false,
      };
    }
    case TestbedConstants.GET_GENERATE_TESTCASE_STATUS_FAILURE: {
      return {
        ...state,
        fetchingTaskQueue: false,
      };
    }
    case TestbedConstants.QUEUE_GENERATE_TESTCASES_REQUEST: {
      return {
        ...state,
        savingTaskQueue: true,
        taskQueueId: null,
        taskQueueProgress: null,
        testCompareResult: null,
      };
    }
    case TestbedConstants.QUEUE_GENERATE_TESTCASES_SUCCESS: {
      const { taskQueueId } = action.payload;
      return {
        ...state,
        taskQueueId,
        savingTaskQueue: false,
      };
    }
    case TestbedConstants.QUEUE_GENERATE_TESTCASES_FAILURE: {
      return {
        ...state,
        savingTaskQueue: false,
      };
    }
    case TestbedConstants.RESET_TASK_QUEUE: {
      return {
        ...state,
        taskQueueId: null,
        taskQueueProgress: null,
        savingTaskQueue: false,
        selectedTestbed: null,
        fetchingTaskQueue: false,
      };
    }
    case TestbedConstants.RESET_TEST_BED_RUN: {
      return {
        ...state,
        testbedQueueId: null,
        testbedQueueProgress: null,
        savingTestbed: false,
        testbedResult: null,
        testbedResultFileName: null,
      };
    }
    case TestbedConstants.DOWNLOAD_TESTBED_TESTCASES_REQUEST: {
      return {
        ...state,
        downloadingTestbedTestCases: true,
      };
    }
    case TestbedConstants.DOWNLOAD_TESTBED_TESTCASES_SUCCESS: {
      const { url, fileName } = action.payload;

      return {
        ...state,
        downloadingTestbedTestCases: false,
        downloadTestbedUrl: url,
        downloadTestbedFileName: fileName,
      };
    }
    case TestbedConstants.DOWNLOAD_TESTBED_TESTCASES_FAILURE: {
      return {
        ...state,
        downloadingTestbedTestCases: false,
      };
    }
    default:
      return state;
  }
};
