import React, {
  memo,
  useRef,
  MouseEventHandler,
  NamedExoticComponent,
} from 'react';
import styled from 'styled-components';
import { Button, Dropdown, Menu, Icon } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ButtonGroup from 'antd/lib/button/button-group';
import { IconSvg } from '../Icons/IconSvg';

interface MenuItem {
  icon: string;
  onClick: () => void;
}

export interface ExpandableMenuItem {
  title: string;
  to?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  subMenu?: ExpandableMenuItem[];
  disabled?: boolean;
  hidden?: boolean;
}

interface ExpandableMenuProps {
  height?: number;
  menuItems: MenuItem[];
  expandableItems?: (ExpandableMenuItem | null)[];
  dropdownMenuIconType?: 'ellipsis' | 'download';
  placement?:
    | 'bottomRight'
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | undefined;
}

const SimpletText = styled.div`
  padding: 5px 0px;
`;

const StyledButtonGroup = styled(ButtonGroup)<{ height: number }>`
  && {
    height: ${({ height }) => height}px;

    .ant-btn {
      height: ${({ height }) => height}px;
      width: ${({ height }) => height}px;
      border-radius: 0 !important;
      padding: 0;
      color: ${({ theme }) => theme.color.dark};

      .anticon {
        font-size: 30px;
        color: ${({ theme }) => theme.color.dark};
      }

      &:hover,
      &:active,
      &:focus,
      &.ant-dropdown-open {
        background: ${({ theme }) => theme.color.purple};
      }
    }
  }
`;

const ExpandableMenu: NamedExoticComponent<ExpandableMenuProps> = memo(
  ({
    height = 44,
    menuItems,
    expandableItems,
    placement = 'bottomRight',
    dropdownMenuIconType = 'ellipsis',
  }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const expandMenu = expandableItems && expandableItems.length > 0 && (
      <Menu data-testid="expandableMenu">
        {expandableItems.map(expandableItem => {
          if (!expandableItem) {
            return null;
          }

          const subItems = expandableItem.subMenu;

          if (subItems && subItems.length > 0) {
            return (
              <Menu.SubMenu
                key={expandableItem.title}
                title={<FormattedMessage id={expandableItem.title} />}
              >
                {subItems.map(
                  subItem =>
                    !subItem.hidden && (
                      <Menu.Item key={subItem.title}>
                        <Link to={subItem.to || '#'} onClick={subItem.onClick}>
                          <FormattedMessage id={subItem.title} />
                        </Link>
                      </Menu.Item>
                    )
                )}
              </Menu.SubMenu>
            );
          }

          return (
            <Menu.Item
              key={expandableItem.title}
              disabled={expandableItem.disabled}
              data-testid="expandableMenuItem"
            >
              {!expandableItem.disabled ? (
                <Link
                  to={expandableItem.to || '#'}
                  onClick={
                    expandableItem.disabled
                      ? e => e.preventDefault()
                      : expandableItem.onClick
                  }
                >
                  <FormattedMessage id={expandableItem.title} />
                </Link>
              ) : (
                <SimpletText>
                  <FormattedMessage id={expandableItem.title} />
                </SimpletText>
              )}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    const StopPropogation = e => {
      e.stopPropagation();
    };

    return (
      <div className="expandable-menu">
        <div className="expandable-menu-container" ref={containerRef} />
        <StyledButtonGroup
          className="expandable-menu-btn-group"
          height={height}
        >
          {menuItems &&
            menuItems.map(menuItem => (
              <Button
                key={menuItem.icon}
                data-testid={menuItem.icon}
                type="link"
                onClick={menuItem.onClick}
              >
                <IconSvg type={menuItem.icon} />
              </Button>
            ))}

          {expandMenu && (
            <Dropdown
              overlay={expandMenu}
              placement={placement}
              trigger={['click']}
              getPopupContainer={() => containerRef.current || document.body}
            >
              <Button
                type="link"
                data-testid="expand-menu-toggler"
                onClick={StopPropogation}
              >
                <Icon type={dropdownMenuIconType} />
              </Button>
            </Dropdown>
          )}
        </StyledButtonGroup>
      </div>
    );
  }
);

export { ExpandableMenu };
