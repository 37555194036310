export const getSubString = (
  name: string,
  showExtention = false,
  truncateLength = 65
) => {
  const splitFileName = name.split('.');
  const fileType = splitFileName[splitFileName.length - 1];
  const truncateName = name?.substring(0, truncateLength);
  if (showExtention) {
    if (name.length > truncateLength) {
      return `${truncateName}..., .${fileType}`;
    }
    return name;
  }
  if (name.length > truncateLength) {
    return `${truncateName}...`;
  }
  return name;
};
