import { getConfig } from '../helpers/config';
import makeRequest from '../helpers/make-request';

const runutility = (
  product: string,
  engine: string,
  version: string,
  smartLevel: string
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/dml/${product}/${engine}/${version}`;
  const formData = new FormData();
  smartLevel && formData.append('smartLevel', smartLevel);
  return makeRequest<{ data: string }>('POST', url, formData);
};

const getDownloadDmlFileUrl = (dmlFileName: string) => {
  return `${
    getConfig().excelEngineDomain
  }/api/v1/dml/download/${encodeURIComponent(dmlFileName)}`;
};

export const DmlFileService = {
  runutility,
  getDownloadDmlFileUrl,
};
