type FileCategoryTypes =
  | 'Excel'
  | 'Html'
  | 'Image'
  | 'PDF'
  | 'PPT'
  | 'Doc'
  | string;

export const getDocFileExtensions = (
  fileCategory?: FileCategoryTypes
): string[] => {
  const fileType = fileCategory?.toLowerCase();
  switch (fileType) {
    case 'excel':
      return ['.xlsx', '.xlsm', '.xlsb', '.xltx', '.xltm', '.xls'];
    case 'html':
      return ['.xhtml', '.html', '.xml'];
    case 'image':
      return ['.jpg', '.jpeg', '.png', '.gif', '.tiff'];
    case 'pdf':
      return ['.pdf'];
    case 'ppt':
      return ['.ppt', '.pptm', '.pptx', '.potx', '.pot', '.ppsx', '.pps'];
    case 'doc':
      return ['.doc', '.docx'];
    default:
      return [];
  }
};

export const ALL_DEFINED_DOC_TYPES =
  '.xlsx, .xlsm, .xlsb, .xltx, .xltm, .xls, .xhtml, .html, .xml, .jpg, .jpeg, .png, .gif, .tiff, .pdf, .ppt, .pptm, .pptx, .potx, .pot, .ppsx, .pps, .doc, .docx';

type ExtensionTypes =
  | '.xlsx'
  | '.xlsm'
  | '.xlsb'
  | '.xltx'
  | '.xltm'
  | '.xls'
  | '.xhtml'
  | '.html'
  | '.xml'
  | '.jpg'
  | '.jpeg'
  | '.png'
  | '.gif'
  | '.tiff'
  | '.ppt'
  | '.pptm'
  | '.pptx'
  | '.potx'
  | '.pot'
  | '.ppsx'
  | '.pps'
  | '.pdf'
  | '.doc'
  | '.docx'
  | string;

export const getExtensionName = (extension: ExtensionTypes): string => {
  if (
    ['.xlsx', '.xlsm', '.xlsb', '.xltx', '.xltm', '.xls'].includes(extension)
  ) {
    return 'Excel';
  }
  if (['.xhtml', '.html', '.xml'].includes(extension)) {
    return 'Html';
  }
  if (['.jpg', '.jpeg', '.png', '.gif', '.tiff'].includes(extension)) {
    return 'Image';
  }
  if (['.pdf'].includes(extension)) {
    return 'PDF';
  }
  if (
    ['.ppt', '.pptm', '.pptx', '.potx', '.pot', '.ppsx', '.pps'].includes(
      extension
    )
  ) {
    return 'PPT';
  }
  if (['.doc', '.docx'].includes(extension)) {
    return 'Doc';
  }

  return '';
};

export const vaidateAcceptableFile = (
  fileName?: string,
  targetAcceptableTypes?: string[],
  acceptedSourceTypes?: string[]
): boolean => {
  if (!targetAcceptableTypes) {
    return false;
  }
  if (!fileName) {
    return !!acceptedSourceTypes?.every(type =>
      targetAcceptableTypes.includes(type)
    );
  }
  const nameSplitted = fileName?.split('.');
  const fileExt = `.${nameSplitted[nameSplitted.length - 1]}`;
  const ExtName = getExtensionName(fileExt);
  return targetAcceptableTypes.includes(ExtName);
};
