export const WARNING_CODE_ENGINE_EXISTS_ALREADY = 'ENGINE_EXISTS_ALREADY';

export const ADD_ENGINE_UPLOAD_START = 'ADD_ENGINE_UPLOAD_START';
export const ADD_ENGINE_UPLOAD_XHR_REF = 'ADD_ENGINE_UPLOAD_XHR_REF';
export const ADD_ENGINE_UPLOAD_PROGRESS = 'ADD_ENGINE_UPLOAD_PROGRESS';
export const ADD_ENGINE_EXECUTE_START = 'ADD_ENGINE_EXECUTE_START';
export const ADD_ENGINE_EXECUTE_PROGRESS = 'ADD_ENGINE_EXECUTE_PROGRESS';
export const ADD_ENGINE_SUBMIT_RELEASE_NOTE = 'ADD_ENGINE_SUBMIT_RELEASE_NOTE';
export const UPDATE_ACTIVE_PERIOD = 'UPDATE_ACTIVE_PERIOD';
export const UPDATE_RELEASE_NOTE = 'UPDATE_RELEASE_NOTE';
export const ADD_ENGINE_SUCCESS = 'ADD_ENGINE_SUCCESS';
export const ADD_ENGINE_ERROR = 'ADD_ENGINE_ERROR';
export const ADD_ENGINE_RESET = 'ADD_ENGINE_RESET';
export const CANCEL_UPLOAD_ENGINE = 'CANCEL_UPLOAD_ENGINE';
export const PRODUCT_ENGINES_RESET = 'PRODUCT_ENGINES_RESET';
export const PRODUCT_ENGINE_SELECTION_RESET = 'PRODUCT_ENGINE_SELECTION_RESET';

export const UPDATE_ENGINE_FAVORITE_REQUEST = 'UPDATE_ENGINE_FAVORITE_REQUEST';
export const UPDATE_ENGINE_FAVORITE_SUCCESS = 'UPDATE_ENGINE_FAVORITE_SUCCESS';
export const UPDATE_ENGINE_FAVORITE_FAILURE = 'UPDATE_ENGINE_FAVORITE_FAILURE';

export const GET_PRODUCT_ENGINES_REQUEST = 'GET_PRODUCT_ENGINES_REQUEST';
export const GET_PRODUCT_ENGINES_SUCCESS = 'GET_PRODUCT_ENGINES_SUCCESS';
export const GET_PRODUCT_ENGINES_FAILURE = 'GET_PRODUCT_ENGINES_FAILURE';

export const GET_PRODUCT_ENGINE_DETAILS_REQUEST =
  'GET_PRODUCT_ENGINE_DETAILS_REQUEST';
export const GET_PRODUCT_ENGINE_DETAILS_SUCCESS =
  'GET_PRODUCT_ENGINE_DETAILS_SUCCESS';
export const GET_PRODUCT_ENGINE_DETAILS_FAILURE =
  'GET_PRODUCT_ENGINE_DETAILS_FAILURE';
export const GET_PRODUCT_ENGINE_DETAILS_SUCCESS_NO_SELECT =
  'GET_PRODUCT_ENGINE_DETAILS_SUCCESS_NO_SELECT';

export const UPDATE_ENGINE_PROPS_REQUEST = 'UPDATE_ENGINE_PROPS_REQUEST';
export const UPDATE_ENGINE_PROPS_SUCCESS = 'UPDATE_ENGINE_PROPS_SUCCESS';
export const UPDATE_ENGINE_PROPS_FAILURE = 'UPDATE_ENGINE_PROPS_FAILURE';

export const UPDATE_ENGINE_PROPS = 'UPDATE_ENGINE_PROPS';
export const SHOW_UPDATE_CONFIRM = 'SHOW_UPDATE_CONFIRM';
export const CANCEL_UPDATE_CONFIRM = 'CANCEL_UPDATE_CONFIRM';

export const SHOW_ENGINE_UPLOAD_SUMMARY = 'SHOW_ENGINE_UPLOAD_SUMMARY';
export const CANCEL_PUBLISH_ENGINE = 'CANCEL_PUBLISH_ENGINE';
export const PUBLISH_UPLOADED_ENGINE = 'PUBLISH_UPLOADED_ENGINE';
export const PUBLISH_UPLOADED_ENGINE_SUCCESS =
  'PUBLISH_UPLOADED_ENGINE_SUCCESS';
export const PUBLISH_UPLOADED_ENGINE_FAILURE =
  'PUBLISH_UPLOADED_ENGINE_FAILURE';

export const DELETE_ENGINE_REQUEST = 'DELETE_ENGINE_REQUEST';
export const DELETE_ENGINE_SUCCESS = 'DELETE_ENGINE_SUCCESS';
export const DELETE_ENGINE_FAILURE = 'DELETE_ENGINE_FAILURE';

export const API_TEST_EXECUTE = 'API_TEST_EXECUTE';
export const API_TEST_EXECUTE_SUCCESS = 'API_TEST_EXECUTE_SUCCESS';
export const API_TEST_EXECUTE_SUCCESS_NO_APICALL =
  'API_TEST_EXECUTE_SUCCESS_NO_APICALL';
export const API_TEST_EXECUTE_FAILURE = 'API_TEST_EXECUTE_FAILURE';
export const API_TEST_SHOW_ERROR_MODAL = 'API_TEST_SHOW_ERROR_MODAL';
export const API_TEST_HIDE_ERROR_MODAL = 'API_TEST_HIDE_ERROR_MODAL';
export const API_TEST_RESET = 'API_TEST_RESET';
export const API_TEST_SHOW_ED_WARNING_MODAL = 'API_TEST_SHOW_ED_WARNING_MODAL';
export const API_TEST_HIDE_ED_WARNING_MODAL = 'API_TEST_HIDE_ED_WARNING_MODAL';

export const API_TEST_EXECUTE_INPUTS = 'API_TEST_EXECUTE_INPUTS';
export const API_TEST_EXECUTE_INPUTS_SUCCESS =
  'API_TEST_EXECUTE_INPUTS_SUCCESS';
export const API_TEST_EXECUTE_INPUTS_FAILURE =
  'API_TEST_EXECUTE_INPUTS_FAILURE';

export const GET_EXECUTE_OUTPUT_REQUEST = 'GET_EXECUTE_OUTPUT_REQUEST';
export const GET_EXECUTE_OUTPUT_SUCCESS = 'GET_EXECUTE_OUTPUT_SUCCESS';
export const GET_EXECUTE_OUTPUT_FAILURE = 'GET_EXECUTE_OUTPUT_FAILURE';

export const GET_ENGINE_VERSIONS_REQUEST = 'GET_ENGINE_VERSIONS_REQUEST';
export const GET_ENGINE_VERSIONS_SUCCESS = 'GET_ENGINE_VERSIONS_SUCCESS';
export const GET_ENGINE_VERSIONS_FAILURE = 'GET_ENGINE_VERSIONS_FAILURE';

export const CUSTOM_URL_REQUEST = 'CUSTOM_URL_REQUEST';
export const CUSTOM_URL_SUCCESS = 'CUSTOM_URL_SUCCESS';
export const CUSTOM_URL_FAILURE = 'CUSTOM_URL_FAILURE';

export const CUSTOM_URL_DELETE_SUCCESS = 'CUSTOM_URL_DELETE_SUCCESS';

export const CUSTOM_URL_EXIST = 'CUSTOM_URL_EXIST';
export const CUSTOM_URL_EXIST_HIDE_MODAL = 'CUSTOM_URL_EXIST_HIDE_MODAL';

export const RESTORE_VERSION_REQUEST = 'RESTORE_VERSION_REQUEST';
export const RESTORE_VERSION_SUCCESS = 'RESTORE_VERSION_SUCCESS';
export const RESTORE_VERSION_FAILURE = 'RESTORE_VERSION_FAILURE';

export const CREATE_ENGINE_CHAIN_REQUEST = 'CREATE_ENGINE_CHAIN_REQUEST';
export const CREATE_ENGINE_CHAIN_SUCCESS = 'CREATE_ENGINE_CHAIN_SUCCESS';
export const CREATE_ENGINE_CHAIN_FAILURE = 'CREATE_ENGINE_CHAIN_FAILURE';

export const DELETE_ENGINE_CHAIN_REQUEST = 'DELETE_ENGINE_CHAIN_REQUEST';
export const DELETE_ENGINE_CHAIN_SUCCESS = 'DELETE_ENGINE_CHAIN_SUCCESS';
export const DELETE_ENGINE_CHAIN_FAILURE = 'DELETE_ENGINE_CHAIN_FAILURE';

export const GET_ENGINE_CHAIN_REQUEST = 'GET_ENGINE_CHAIN_REQUEST';
export const GET_ENGINE_CHAIN_SUCCESS = 'GET_ENGINE_CHAIN_SUCCESS';
export const GET_ENGINE_CHAIN_FAILURE = 'GET_ENGINE_CHAIN_FAILURE';

export const GET_ENGINE_UNCHAIN_CHAIN_REQUEST =
  'GET_ENGINE_UNCHAIN_CHAIN_REQUEST';
export const GET_ENGINE_UNCHAIN_CHAIN_SUCCESS =
  'GET_ENGINE_UNCHAIN_CHAIN_SUCCESS';
export const GET_ENGINE_UNCHAIN_CHAIN_FAILURE =
  'GET_ENGINE_UNCHAIN_CHAIN_FAILURE';

export const ENGINE_CHAIN_MISMATCH_ERROR = 'ENGINE_CHAIN_MISMATCH_ERROR';

export const EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_SUCCESS =
  'EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_SUCCESS';
export const EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_REQUEST =
  'EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_REQUEST';
export const EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_FAILURE =
  'EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_FAILURE';

export const DEFAULT_VALUE_WARNING = 'Input parameter missing.';

export const GENERATE_JSSHEET_ENGINE_REQUEST =
  'GENERATE_JSSHEET_ENGINE_REQUEST';
export const GENERATE_JSSHEET_ENGINE_SUCCESS =
  'GENERATE_JSSHEET_ENGINE_SUCCESS';
export const GENERATE_JSSHEET_ENGINE_FAILURE =
  'GENERATE_JSSHEET_ENGINE_FAILURE';

export const DOWNLOAD_JSSHEET_ENGINE_REQUEST =
  'DOWNLOAD_JSSHEET_ENGINE_REQUEST';
export const DOWNLOAD_JSSHEET_ENGINE_SUCCESS =
  'DOWNLOAD_JSSHEET_ENGINE_SUCCESS';
export const DOWNLOAD_JSSHEET_ENGINE_FAILURE =
  'DOWNLOAD_JSSHEET_ENGINE_FAILURE';

export const CANCEL_GENERATE_JSSHEET = 'CANCEL_GENERATE_JSSHEET';

export const DOWNLOAD_ENGINE_REQUEST = 'DOWNLOAD_ENGINE_REQUEST';
export const DOWNLOAD_ENGINE_SUCCESS = 'DOWNLOAD_ENGINE_SUCCESS';
export const DOWNLOAD_ENGINE_FAILURE = 'DOWNLOAD_ENGINE_FAILURE';

export const API_TESTER_DOCUMENTATION_SUCCESS =
  'API_TESTER_DOCUMENTATION_SUCCESS';
export const API_TESTER_DOCUMENTATION_FAILED =
  'API_TESTER_DOCUMENTATION_FAILED';
export const API_TESTER_DOCUMENTATION_REQUEST =
  'API_TESTER_DOCUMENTATION_FAILED';

export const API_DOCS_DOWNLOAD_SUCCESS = 'API_DOCS_DOWNLOAD_SUCCESS';
export const API_DOCS_DOWNLOAD_REQUEST = 'API_DOCS_DOWNLOAD_REQUEST';
export const API_DOCS_DOWNLOAD_FAILURE = 'API_DOCS_DOWNLOAD_FAILURE';

export const RESET_ENGINE_UPLOAD = 'RESET_ENGINE_UPLOAD';

export const MANUAL_API_TESTER = 'Spark - API Tester';
