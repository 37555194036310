import { getConfig } from '../helpers/config';
import makeRequest from '../helpers/make-request';

const generateSDK = (product: string, engine: string, apikey: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/JsSheetSDK/generatejssheetsdk/${product}/${engine}/${apikey}`;
  return makeRequest<{ data: string }>('POST', url, {});
};

export const SDKGeneratorService = {
  generateSDK,
};
