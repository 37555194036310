import { ProductDocsConstant } from '../constants';
import {
  ProductDocsAction,
  ProductDocsThunkAction,
  ProductDocsThunkActionSync,
} from './types';
import { FileManagerService, ProductService } from '../services';
import { AlertActions } from './alert.actions';
import { ApiError, downloadBlob, vaidateAcceptableFile } from '../helpers';

const getProductDocs = (
  page: number,
  pageSize: number,
  productName: string,
  documentType: string,
  searchTerm: string,
  filterCategory?: string | string[]
): ProductDocsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductDocsConstant.GET_PRODUCT_DOCS_REQUEST,
      payload: {
        productName,
        documentType,
      },
    });
    const { payload, status } = await ProductService.getProductDocs(
      page,
      pageSize,
      productName,
      documentType,
      searchTerm,
      filterCategory
    );
    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductDocsConstant.GET_PRODUCT_DOCS_SUCCESS,
      payload: {
        page,
        total: payload.count,
        docs: payload.data,
        documentType,
        searchTerm,
        filterCategory,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.GET_PRODUCT_DOCS_FAILURE,
      payload: {
        error: msg,
        documentType,
      },
    });
  }
};

const deleteSection = (
  productName: string,
  documentType: string
): ProductDocsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductDocsConstant.DELETE_SECTION_REQUEST,
    });

    const { status, payload } = await ProductService.deleteSection(
      productName,
      documentType
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductDocsConstant.DELETE_SECTION_SUCCESS,
      payload: {
        productName,
        documentType,
      },
    });
    dispatch(AlertActions.success('ProductEngines.section.delete.success'));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.DELETE_SECTION_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const moveDocs = (
  moveRequestProps: DTO.MoveProductDocsRequest,
  successAlertContent?: React.ReactNode | string
): ProductDocsThunkAction => async (dispatch, getState) => {
  const {
    targetDocumentType,
    productName,
    FileName,
    sourceDocumentType,
    deleteSectionOnSuccess,
  } = moveRequestProps;
  if (
    targetDocumentType === sourceDocumentType ||
    (targetDocumentType === '' && sourceDocumentType === 'noFolder')
  ) {
    return;
  }

  if (targetDocumentType && targetDocumentType !== 'noFolder') {
    const {
      products: { selectedProduct },
    } = getState();
    const sections = selectedProduct?.sections;
    const section = sections?.find(
      intance => intance.name === targetDocumentType
    );
    const sourceSection = sections?.find(
      intance => intance.name === sourceDocumentType
    );
    const acceptedTypes = section?.fileTypes;
    const acceptedSourceTypes = sourceSection?.fileTypes;
    const isAcceptable = vaidateAcceptableFile(
      FileName,
      acceptedTypes,
      acceptedSourceTypes
    );

    if (!isAcceptable) {
      dispatch(AlertActions.error('INVALID_FILE_TYPE'));
      return;
    }
  }

  try {
    dispatch({
      type: ProductDocsConstant.MOVE_PRODUCT_DOCS_REQUEST,
      payload: {
        targetDocumentType: targetDocumentType || '',
        sourceDocumentType: sourceDocumentType || '',
      },
    });

    const { payload, status } = await ProductService.moveProductDocs({
      productName,
      FileName,
      sourceDocumentType,
      targetDocumentType,
    });

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductDocsConstant.MOVE_PRODUCT_DOCS_SUCCESS,
      payload: {
        targetDocumentType,
        sourceDocumentType,
      },
    });

    if (deleteSectionOnSuccess && sourceDocumentType && productName) {
      dispatch(deleteSection(productName, sourceDocumentType));
    }
    if (successAlertContent) {
      dispatch(AlertActions.success(successAlertContent));
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.MOVE_PRODUCT_DOCS_FAILURE,
      payload: {
        error: msg,
        targetDocumentType,
        sourceDocumentType,
      },
    });
  }
};

const uploadDocument = (
  documentType: string,
  productName: string,
  file: File,
  metadata: {},
  content: React.ReactNode
): ProductDocsThunkAction => async dispatch => {
  try {
    dispatch({ type: ProductDocsConstant.ADD_DOCUMENT_UPLOAD_START });

    const { status, payload } = await ProductService.uploadProductDoc(
      documentType,
      productName,
      file,
      metadata,
      uploadProgress => {
        if (uploadProgress === 100) {
          return;
        }

        dispatch({
          type: ProductDocsConstant.ADD_DOCUMENT_UPLOAD_PROGRESS,
          payload: {
            uploadProgress: Math.max(5, uploadProgress - 5),
          },
        });
      },
      xhrRef => {
        dispatch({
          type: ProductDocsConstant.ADD_DOCUMENT_UPLOAD_XHR_REF,
          payload: { xhrRef },
        });
      }
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductDocsConstant.ADD_DOCUMENT_SUCCESS,
      payload: {
        documentType,
        filename: file.name,
        folderName: documentType || 'noFolder',
      },
    });

    dispatch(AlertActions.success(content));
    dispatch({
      type: ProductDocsConstant.ADD_DOCUMENT_RESET,
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.ADD_DOCUMENT_ERROR,
      payload: {
        error: msg,
      },
    });
  }
};

const deleteDoc = (
  productName: string,
  fileName: string,
  documentType: string
): ProductDocsThunkAction => async dispatch => {
  dispatch({
    type: ProductDocsConstant.DELETE_DOC_REQUEST,
    payload: {
      productName,
      fileName,
      documentType,
    },
  });

  try {
    const { status, payload } = await ProductService.deleteDocs(
      productName,
      fileName,
      documentType
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductDocsConstant.DELETE_DOC_SUCCESS,
      payload: {
        productName,
        fileName,
        documentType,
      },
    });

    dispatch(
      AlertActions.success('ProductDocs.menu.delete.success', { fileName })
    );
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.DELETE_DOC_FAILURE,
      payload: {
        productName,
        fileName,
        documentType,
        error: msg,
      },
    });
  }
};

const downloadDocument = (
  request: DTO.DownloadDocumentRequest,
  documentType: string
): ProductDocsThunkActionSync => async dispatch => {
  try {
    dispatch({
      type: ProductDocsConstant.DOWNLOAD_PRODUCT_DOCS_REQUEST,
      payload: {
        documentType,
      },
    });

    const downloadUrl = ProductService.getDocumentDownloadUrl(
      request,
      documentType
    );

    const blob = await FileManagerService.downloadBlob(downloadUrl);
    if (blob.payload.blob) {
      downloadBlob(blob.payload.blob, blob.payload.blobName);
    }
    dispatch({
      type: ProductDocsConstant.DOWNLOAD_PRODUCT_DOCS_SUCCESS,
      payload: {
        documentType,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.DOWNLOAD_PRODUCT_DOCS_FAILURE,
      payload: {
        documentType,
        error: msg,
      },
    });
  }
};

const resetAddDocument = (): ProductDocsAction => ({
  type: ProductDocsConstant.ADD_DOCUMENT_RESET,
});

const resetProductDocList = (type: string): ProductDocsAction => ({
  type: ProductDocsConstant.DOCUMENT_LIST_RESET,
  payload: { type },
});

const updateDocFavourite = (
  productName: string,
  fileName: string,
  documentType: string,
  favorite: boolean
): ProductDocsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductDocsConstant.UPDATE_DOC_FAVORITE_REQUEST,
    });

    const { status, payload } = await ProductService.markDocFavorite(
      productName,
      fileName,
      documentType,
      favorite
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductDocsConstant.UPDATE_DOC_FAVORITE_SUCCESS,
      payload: {
        fileName,
        documentType,
        favorite,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.UPDATE_DOC_FAVORITE_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const createSection = (
  productName: string,
  documentType: string,
  position: number,
  fileTypes: string[]
): ProductDocsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductDocsConstant.CREATE_SECTION_REQUEST,
    });

    const { status, payload } = await ProductService.createSection(
      productName,
      documentType,
      position,
      fileTypes
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductDocsConstant.CREATE_SECTION_SUCCESS,
      payload: {
        productName,
        documentType,
        position,
        fileTypes,
      },
    });

    dispatch(AlertActions.success('ProductEngines.section.create.success'));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.CREATE_SECTION_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const updateSectionPosition = (
  productName: string,
  oldDocumentType: string,
  newDocumentType: string,
  position: number,
  fileTypes: string[],
  sections: DTO.Section[]
): ProductDocsThunkAction => async dispatch => {
  try {
    const { status, payload } = await ProductService.updateSection(
      productName,
      oldDocumentType,
      newDocumentType,
      position,
      fileTypes
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductDocsConstant.UPDATE_PRODUCT_SECTIONS,
      payload: {
        productName,
        sections,
      },
    });

    dispatch(AlertActions.success('ProductEngines.section.update.success'));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.UPDATE_SECTION_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const updateSection = (
  productName: string,
  oldDocumentType: string,
  newDocumentType: string,
  position: number,
  fileTypes: string[]
): ProductDocsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductDocsConstant.UPDATE_SECTION_REQUEST,
    });

    const { status, payload } = await ProductService.updateSection(
      productName,
      oldDocumentType,
      newDocumentType,
      position,
      fileTypes
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductDocsConstant.UPDATE_SECTION_SUCCESS,
      payload: {
        productName,
        oldDocumentType,
        newDocumentType,
        position,
        fileTypes,
      },
    });
    dispatch(AlertActions.success('ProductEngines.section.update.success'));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.UPDATE_SECTION_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const downloadSwagger = (
  productName: string,
  fileName: string,
  documentType: string
): ProductDocsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductDocsConstant.DOWNLOAD_PRODUCT_DOCS_REQUEST,
      payload: {
        documentType,
      },
    });
    const downloadUrl = ProductService.getSwaggerDownloadUrl(
      productName,
      fileName,
      documentType
    );
    const blob = await FileManagerService.downloadBlob(downloadUrl);
    if (blob.payload.blob) {
      downloadBlob(blob.payload.blob, blob.payload.blobName);
    }
    dispatch({
      type: ProductDocsConstant.DOWNLOAD_PRODUCT_DOCS_SUCCESS,
      payload: {
        documentType,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.DOWNLOAD_PRODUCT_DOCS_FAILURE,
      payload: {
        documentType,
        error: msg,
      },
    });
  }
};

const downloadCurl = (
  productName: string,
  fileName: string,
  documentType: string
): ProductDocsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductDocsConstant.DOWNLOAD_PRODUCT_DOCS_REQUEST,
      payload: {
        documentType,
      },
    });
    const downloadUrl = ProductService.getCurlDownloadUrl(
      productName,
      fileName,
      documentType
    );

    const blob = await FileManagerService.downloadBlob(downloadUrl);
    if (blob.payload.blob) {
      downloadBlob(blob.payload.blob, blob.payload.blobName);
    }
    dispatch({
      type: ProductDocsConstant.DOWNLOAD_PRODUCT_DOCS_SUCCESS,
      payload: {
        documentType,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.DOWNLOAD_PRODUCT_DOCS_FAILURE,
      payload: {
        documentType,
        error: msg,
      },
    });
  }
};

const getDocumentMetadata = (
  productName: string,
  fileName: string,
  documentType: string
): ProductDocsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductDocsConstant.GET_PRODUCT_DOCUMENT_CONTENT_REQUEST,
    });

    const { status, payload } = await ProductService.getDocumentContent(
      productName,
      fileName,
      documentType
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }
    dispatch({
      type: ProductDocsConstant.GET_PRODUCT_DOCUMENT_CONTENT_SUCCESS,
      payload: {
        productName,
        fileName,
        documentType,
        metadata: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.GET_PRODUCT_DOCUMENT_CONTENT_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const updateDocumentMetadata = (
  productName: string,
  fileName: string,
  documentType: string,
  metadata: object
): ProductDocsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductDocsConstant.UPDATE_PRODUCT_DOCUMENT_METADATA_REQUEST,
    });

    const { status, payload } = await ProductService.updateDocumentMetadata(
      productName,
      fileName,
      documentType,
      metadata
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductDocsConstant.UPDATE_PRODUCT_DOCUMENT_METADATA_SUCCESS,
    });

    dispatch(AlertActions.success('ProductDocument.message.update.success'));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductDocsConstant.UPDATE_PRODUCT_DOCUMENT_METADATA_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const resetUpdateDocumentMetadata = (): ProductDocsThunkAction => dispatch => {
  dispatch({
    type: ProductDocsConstant.UPDATE_PRODUCT_DOCUMENT_METADATA_RESET,
  });
};

const resetLastUploadedFileName = (
  sectionName: string
): ProductDocsThunkAction => dispatch => {
  dispatch({
    type: ProductDocsConstant.RESET_LAST_UPLOADED_REQUEST,
    payload: {
      sectionName,
    },
  });
};

const resetDocUpload = (): ProductDocsThunkAction => dispatch => {
  dispatch({
    type: ProductDocsConstant.RESET_DOC_UPLOAD,
  });
};

export const ProductDocsActions = {
  getProductDocs,
  moveDocs,
  uploadDocument,
  resetAddDocument,
  resetProductDocList,
  downloadDocument,
  deleteDoc,
  updateDocFavourite,
  createSection,
  updateSection,
  deleteSection,
  downloadSwagger,
  downloadCurl,
  getDocumentMetadata,
  updateDocumentMetadata,
  resetUpdateDocumentMetadata,
  updateSectionPosition,
  resetLastUploadedFileName,
  resetDocUpload,
};
