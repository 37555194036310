import { UserManagerConstants, UserConstants } from '../constants';
import { UserManagerService } from '../services';
import { AlertActions } from './alert.actions';
import { UserManagerAction, UserManagerThunkAction } from './types';
import { ApiError } from '../helpers';

const getUsers = (): UserManagerThunkAction => async dispatch => {
  try {
    dispatch({ type: UserManagerConstants.GET_USERS_REQUEST });

    const { payload, status } = await UserManagerService.getUsers();

    if (status !== 200 || payload.status !== 'OK') {
      if (payload.detail === 'Token decoding error') {
        payload.errorCode = 'DEFAULT_ERROR';
      }

      throw new ApiError(payload);
    }

    dispatch({
      type: UserManagerConstants.GET_USERS_SUCCESS,
      payload: {
        users: payload.users,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserManagerConstants.GET_USERS_FAILURE,
      payload: { error: msg },
    });
  }
};

const getUser = (userId: string): UserManagerThunkAction => async dispatch => {
  try {
    dispatch({
      type: UserManagerConstants.GET_USER_REQUEST,
      payload: { userId },
    });

    const { payload, status } = await UserManagerService.getUser(userId);

    if (status !== 200 || payload.status !== 'OK') {
      throw new ApiError(payload);
    }

    dispatch({
      type: UserManagerConstants.GET_USER_SUCCESS,
      payload: {
        userId,
        user: payload.user,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserManagerConstants.GET_USER_FAILURE,
      payload: {
        userId,
        error: msg,
      },
    });
  }
};

const selectUser = (user: DTO.User | null): UserManagerAction => ({
  type: UserManagerConstants.SELECT_USER,
  payload: { user },
});

const addUser = (
  email: string,
  fullName: string,
  role: string,
  isActive: boolean,
  position: string
): UserManagerThunkAction<boolean> => async dispatch => {
  try {
    dispatch({ type: UserManagerConstants.ADD_USER_REQUEST });

    const groupPath = UserConstants.GROUPS_FOR_ROLES[role];

    const { status, payload } = await UserManagerService.addUser(
      email,
      groupPath,
      {
        FullName: fullName,
        email,
        group: groupPath,
        Position: position,
      }
    );

    if (status !== 200 || payload.status !== 'OK') {
      throw new ApiError(payload);
    }

    if (role === UserConstants.ROLE_SUPERVISOR) {
      await UserManagerService.addUserToGroup(
        email,
        UserConstants.GROUPS_FOR_ROLES[UserConstants.ROLE_USER]
      );
    }

    await UserManagerService.changeUserStatus(
      payload.user_id,
      isActive ? 'Enabled' : 'Disabled'
    );

    await UserManagerService.notifyUser({
      email,
      URL: `${window.location.origin +
        process.env.REACT_APP_PUBLIC_URL}/forgot/password`,
      FULLNAME: fullName,
    });

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserManagerConstants.ADD_USER_FAILURE,
      payload: { error: msg },
    });

    return false;
  }
};

const updateUser = (
  userId: string,
  email: string,
  fullName: string,
  role: string,
  isActive: boolean,
  position: string
): UserManagerThunkAction<boolean> => async dispatch => {
  try {
    dispatch({ type: UserManagerConstants.UPDATE_USER_REQUEST });

    const groupPath = UserConstants.GROUPS_FOR_ROLES[role];

    const { payload, status } = await UserManagerService.updateUser(
      userId,
      email,
      groupPath,
      {
        FullName: fullName,
        email,
        group: groupPath,
        Position: position,
      }
    );

    if (status !== 200 || payload.status !== 'OK') {
      throw new ApiError(payload);
    }

    if (role === UserConstants.ROLE_SUPERVISOR) {
      await UserManagerService.addUserToGroup(
        email,
        UserConstants.GROUPS_FOR_ROLES[UserConstants.ROLE_USER]
      );
    }

    await UserManagerService.changeUserStatus(
      userId,
      isActive ? 'Enabled' : 'Disabled'
    );

    dispatch({
      type: UserManagerConstants.UPDATE_USER_SUCCESS,
    });

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserManagerConstants.UPDATE_USER_FAILURE,
      payload: { error: msg },
    });

    return false;
  }
};

export const UserManagerActions = {
  getUsers,
  addUser,
  selectUser,
  updateUser,
  getUser,
};
