import React, { useMemo, useEffect } from 'react';
import { Prompt } from 'react-router-dom';

const useHistoryPrompt = (
  shouldPrompt: boolean,
  promptId: string,
  onPrompt: (callback: (ok: boolean) => void) => void
) => {
  useEffect(() => {
    if (shouldPrompt) {
      window[promptId] = onPrompt;

      return () => {
        delete window[promptId];
      };
    }

    return () => {};
  }, [shouldPrompt, promptId, onPrompt]);

  return useMemo(() => {
    return {
      prompt: <Prompt when={shouldPrompt} message={promptId} />,
      cancelPrompt: () => delete window[promptId],
    };
  }, [shouldPrompt, promptId]);
};

export { useHistoryPrompt };
