export const RUN_DML_UTILITY_REQUEST = 'RUN_DML_UTILITY_REQUEST';
export const RUN_DML_UTILITY_SUCCESS = 'RUN_DML_UTILITY_SUCCESS';
export const RUN_DML_UTILITY_FAILURE = 'RUN_DML_UTILITY_FAILURE';

export const GET_RUN_STATUS_REQUEST = 'GET_RUN_STATUS_REQUEST';
export const GET_RUN_STATUS_SUCCESS = 'GET_RUN_STATUS_SUCCESS';
export const GET_RUN_STATUS_FAILURE = 'GET_RUN_STATUS_FAILURE';

export const CLEAR_DML_JOB = 'CLEAR_DML_JOB';

export const INTERVAL_TIME = 3000;

export const EXPORT_ERROR_PREFIX = 'ERROR';
export const EXPORT_PROCESS_ERROR_PREFIX = 'Import Processing Error';
export const EXPORT_INGENIUM_PROCESS_SUCCESS = 'file uploaded successfully';
export const EXPORT_WARNIG_PREFIX = 'WARNING';

export const RESET_DML_STATE = 'RESET_DML_STATE';
