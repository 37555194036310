import {
  BackgroundJobsConstants,
  EngineConstants,
  ProductsConstants,
  EngineLogsConstant,
  TestbedConstants,
} from '../constants';
import { AppAction } from '../actions/types';
import { arrayReplaceFirst } from '../helpers';

export const backgroundJobsInitialState: STATES.BackgroundJobsState = {
  jobs: [],
  generalPercent: 0,
  intervalTime: BackgroundJobsConstants.INTERVAL_NORMAL,
  refreshTrigger: 0,
  currentActive: 'none',
  jobsToDownload: {},
};

const getBgCurrentActive = (
  jobs: DTO.BackgroundJob[]
): STATES.BackgroundJobsState['currentActive'] => {
  const runningJobs = jobs.filter(job =>
    ['InProgress', 'Pending'].includes(job.status)
  );

  const hasDownloadJobs = runningJobs.some(
    job => job.type === 'DownloadFolder'
  );
  const hasUploadJobs = runningJobs.some(job => job.type === 'UploadFolder');

  const hasDownloadLogsJobs = runningJobs.some(
    job => job.type === 'DownloadLogs'
  );

  const hasTestbedResultCSV = runningJobs.some(
    job => job.type === 'TestbedResultCSV'
  );

  const hasTestbedResultExcel = runningJobs.some(
    job => job.type === 'TestbedResultExcel'
  );

  if (hasDownloadJobs && hasUploadJobs) {
    return 'downloadAndUpload';
  }

  if (hasDownloadJobs) {
    return 'download';
  }

  if (hasUploadJobs) {
    return 'upload';
  }

  if (hasDownloadLogsJobs) {
    return 'downloadLog';
  }

  if (hasTestbedResultCSV) {
    return 'TestbedResultCSV';
  }

  if (hasTestbedResultExcel) {
    return 'TestbedResultExcel';
  }

  return 'none';
};

const calculateGeneralPercent = (jobs: DTO.BackgroundJob[]) => {
  const runningJobs = jobs.filter(
    job => !['Cancelled', 'Fail', 'Success'].includes(job.status)
  );

  return runningJobs.length === 0
    ? 100
    : runningJobs.reduce((prev, { progress }) => {
        return prev + progress;
      }, 0) / runningJobs.length;
};

export const backgroundJobsReducer = (
  state = backgroundJobsInitialState,
  action: AppAction
): STATES.BackgroundJobsState => {
  switch (action.type) {
    case BackgroundJobsConstants.GET_BGJOBS_SUCCESS: {
      const { jobs } = action.payload;

      const currentActive = getBgCurrentActive(jobs);
      return {
        ...state,
        jobs,
        currentActive,
        generalPercent: calculateGeneralPercent(jobs),
        intervalTime:
          currentActive !== 'none'
            ? BackgroundJobsConstants.INTERVAL_HAS_RUNNING
            : BackgroundJobsConstants.INTERVAL_NORMAL,
      };
    }
    case BackgroundJobsConstants.TRIGGER_REFRESH_GBJOBS: {
      return {
        ...state,
        refreshTrigger: state.refreshTrigger + 1,
      };
    }
    case BackgroundJobsConstants.GET_CANCEL_JOB_SUCCESS: {
      const { jobId } = action.payload;

      const jobs = arrayReplaceFirst(
        state.jobs,
        job => job.id === jobId,
        job => ({
          ...job,
          status: 'Cancelled',
        })
      );

      return {
        ...state,
        jobs,
        generalPercent: calculateGeneralPercent(jobs),
        refreshTrigger: state.refreshTrigger + 1,
      };
    }
    case ProductsConstants.DOWNLOAD_PRODUCT_SUCCESS:
    case TestbedConstants.GENERATED_TESTBED_RESULT_SUCCESS: {
      const { jobId } = action.payload;

      return {
        ...state,
        jobsToDownload: {
          ...state.jobsToDownload,
          [jobId]: true,
        },
      };
    }
    case EngineLogsConstant.ADD_DOWNLOAD_LOGS_IN_QUEUE: {
      const { jobId } = action.payload;

      return {
        ...state,
        jobsToDownload: {
          ...state.jobsToDownload,
          [jobId]: true,
        },
      };
    }

    case EngineConstants.GENERATE_JSSHEET_ENGINE_SUCCESS: {
      const { engine } = action.payload;
      return {
        ...state,
        jobs: [
          {
            status: 'InProgress',
            createdAt: '',
            id: '',
            progress: 0,
            type: 'JsSheetGenerate',
            userId: '',
            result: null,
            completedAt: null,
            data: {
              Name: '',
              downloadFileName: '',
              logFileName: '',
              output: '',
              Engine: engine,
            },
          },
          ...state.jobs,
        ],
      };
    }
    case BackgroundJobsConstants.TRIGGER_DOWNLOAD_GBJOBS: {
      const { jobs } = action.payload;

      const entries = Object.entries(jobs);

      if (entries.length === 0) {
        return state;
      }

      const jobsToDownload = { ...state.jobsToDownload };

      return {
        ...state,
        jobsToDownload: entries.reduce((prev, [jobId]) => {
          delete prev[jobId];

          return prev;
        }, jobsToDownload),
      };
    }
    default:
      return state;
  }
};
