import { isEqual } from 'lodash-es';
import { IngeniumAction } from '../actions/types';
import { IngeniumConstant } from '../constants';

export const initialIngeniumState: STATES.IngeniumState = {
  taskQueueId: '',
  taskQueue: null,
  ingeniumLogs: [],
  totalPages: 0,
  total: 0,
  logRequest: {
    page: 1,
    pageSize: 20,
    searchText: '',
    sort: 'desc',
    sortBy: 'updated',
    fromDate: undefined,
    toDate: undefined,
    statuses: [],
  },
  refreshTrigger: 0,
  fetchingLogs: false,
};

const getTotalPages = (total: number, pageSize: number): number =>
  Math.floor(total / pageSize) + (total % pageSize ? 1 : 0);

export const ingenium = (
  state = initialIngeniumState,
  action: IngeniumAction
): STATES.IngeniumState => {
  switch (action.type) {
    case IngeniumConstant.RUN_INGENIUM_UTILITY_REQUEST: {
      return {
        ...state,
        taskQueueId: '',
        taskQueue: null,
      };
    }
    case IngeniumConstant.RUN_INGENIUM_UTILITY_SUCCESS: {
      const { taskQueueId } = action.payload;

      return {
        ...state,
        taskQueueId,
      };
    }
    case IngeniumConstant.RUN_INGENIUM_UTILITY_FAILURE: {
      return {
        ...state,
      };
    }
    case IngeniumConstant.GET_INGENIUM_RUN_STATUS_REQUEST: {
      return {
        ...state,
      };
    }
    case IngeniumConstant.GET_INGENIUM_RUN_STATUS_SUCCESS: {
      const { taskQueue } = action.payload;

      return {
        ...state,
        taskQueue,
      };
    }
    case IngeniumConstant.GET_INGENIUM_RUN_STATUS_FAILURE: {
      const tempTaskQueue = state.taskQueue;
      if (tempTaskQueue) {
        return {
          ...state,
          taskQueue: {
            ...tempTaskQueue,
            progress: 100,
            status: 'FAILED',
          },
        };
      }
      return {
        ...state,
      };
    }
    case IngeniumConstant.CLEAR_INGENIUM_JOB: {
      return {
        ...state,
        taskQueue: null,
      };
    }
    case IngeniumConstant.RESET_INGENIUM_STATE: {
      return {
        ...initialIngeniumState,
      };
    }
    case IngeniumConstant.GET_INGENIUM_LOGS_REQUEST: {
      const { logRequest } = action.payload;

      return {
        ...state,
        logRequest,
        fetchingLogs: true,
      };
    }
    case IngeniumConstant.GET_INGENIUM_LOGS_SUCCESS: {
      const { logRequest, total, ingeniumLogs } = action.payload;

      if (!isEqual(logRequest, state.logRequest)) {
        return state;
      }

      return {
        ...state,
        fetchingLogs: false,
        ingeniumLogs,
        total,
        totalPages: getTotalPages(total, state.logRequest.pageSize),
      };
    }
    case IngeniumConstant.GET_INGENIUM_LOGS_FAILURE: {
      return {
        ...state,
        fetchingLogs: false,
      };
    }
    default:
      return state;
  }
};
