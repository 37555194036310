import { BackgroundJobsConstants, TestbedConstants } from '../constants';
import { ApiError } from '../helpers';
import { TestbedService } from '../services';
import { AlertActions } from './alert.actions';
import { TestbedAction, TestbedThunkAction } from './types';

const generateTemplate = (
  testbedTemplateRequest: DTO.TestbedTemplateRequest
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GENERATED_TEST_TEMPLATE_REQUEST,
    });

    const { payload, status } = await TestbedService.generateTemplate(
      testbedTemplateRequest
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GENERATED_TEST_TEMPLATE_SUCCESS,
      payload: {
        url: payload.data.url,
        fileName: payload.data.fileName,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GENERATED_TEST_TEMPLATE_FAILURE,
      payload: { error: msg },
    });
  }
};

const generateTemplateById = (
  testbedTemplateId: string
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GENERATED_TEST_TEMPLATE_REQUEST,
    });

    const { payload, status } = await TestbedService.generateTemplateById(
      testbedTemplateId
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GENERATED_TEST_TEMPLATE_SUCCESS,
      payload: {
        url: payload.data.url,
        fileName: payload.data.fileName,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GENERATED_TEST_TEMPLATE_FAILURE,
      payload: { error: msg },
    });
  }
};

const saveTemplate = (
  testbedTemplateRequest: DTO.TestbedTemplateRequest
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.SAVE_TEST_TEMPLATE_REQUEST,
    });

    const { payload, status } = await TestbedService.saveTemplate(
      testbedTemplateRequest
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.SAVE_TEST_TEMPLATE_SUCCESS,
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.SAVE_TEST_TEMPLATE_FAILURE,
      payload: { error: msg },
    });
  }
};

const getTestbeds = (
  testbedPagedRequest: DTO.TestbedPagedRequest
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_TEST_BEDS_REQUEST,
    });

    const { payload, status } = await TestbedService.getTestbeds(
      testbedPagedRequest
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_TEST_BEDS_SUCCESS,
      payload: {
        testbedList: payload.data,
        totalTestbeds: payload.count,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_TEST_BEDS_FAILURE,
      payload: { error: msg },
    });
  }
};

const getTestbedVersions = (
  testbedId: string,
  testbedPagedRequest: DTO.TestbedPagedRequest
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_TEST_BEDS_REQUEST,
    });

    const { payload, status } = await TestbedService.getTestbedVersions(
      testbedId,
      testbedPagedRequest
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_TEST_BED_VERSIONS_SUCCESS,
      payload: {
        testbedVersions: payload.data,
        testbedId,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_TEST_BEDS_FAILURE,
      payload: { error: msg },
    });
  }
};

const getTestbedQueue = (
  testbedQueueId: string
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_TEST_BED_QUEUE_REQUEST,
    });

    const { payload, status } = await TestbedService.getTestbedQueue(
      testbedQueueId
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_TEST_BED_QUEUE_SUCCESS,
      payload: {
        testbedQueueProgress: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_TEST_BED_QUEUE_FAILURE,
      payload: { error: msg },
    });
  }
};

const getTestbedResult = (
  testbedQueueId: string
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_TEST_BED_RESULT_REQUEST,
    });

    const { payload, status } = await TestbedService.getTestbedResult(
      testbedQueueId
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_TEST_BED_RESULT_SUCCESS,
      payload: {
        testbedResult: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_TEST_BED_RESULT_FAILURE,
      payload: { error: msg },
    });
  }
};

const saveTestbedQueue = (
  testbedQueueRequest: DTO.TestbedQueueRequest
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.SAVE_TEST_BED_QUEUE_REQUEST,
    });

    const { payload, status } = await TestbedService.saveTestbedQueue(
      testbedQueueRequest
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.SAVE_TEST_BED_QUEUE_SUCCESS,
      payload: {
        testbedQueueId: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.SAVE_TEST_BED_QUEUE_FAILURE,
      payload: { error: msg },
    });
  }
};

const generateTestbedResult = (
  testRunId: string,
  fileType: string
) => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GENERATED_TESTBED_RESULT_REQUEST,
    });

    const { payload, status } = await TestbedService.downloadTestResult(
      testRunId,
      fileType
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GENERATED_TESTBED_RESULT_SUCCESS,
      payload: {
        jobId: payload.data,
      },
    });

    dispatch({
      type: BackgroundJobsConstants.TRIGGER_REFRESH_GBJOBS,
    });
    await dispatch(AlertActions.info('TestCenter.download.successMessage'));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GENERATED_TESTBED_RESULT_FAILURE,
      payload: { error: msg },
    });
  }
};

const resetTestTemplate = (): TestbedAction => ({
  type: TestbedConstants.RESET_TEST_TEMPLATE,
});

const getTestbedTemplates = (
  testbedPagedRequest: DTO.TestbedPagedRequest
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_TEST_BED_TEMPLATES_REQUEST,
    });

    const { payload, status } = await TestbedService.getTestbedTemplates(
      testbedPagedRequest
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_TEST_BED_TEMPLATES_SUCCESS,
      payload: {
        testbedTemplateList: payload.data,
        totalTestbedTemplates: payload.count,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_TEST_BED_TEMPLATES_FAILURE,
      payload: { error: msg },
    });
  }
};

const getTestbedTemplateVersions = (
  testbedTemplateId: string,
  testbedPagedRequest: DTO.TestbedPagedRequest
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_TEST_BED_TEMPLATES_REQUEST,
    });

    const { payload, status } = await TestbedService.getTestbedTemplateVersions(
      testbedTemplateId,
      testbedPagedRequest
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_TEST_BED_TEMPLATE_VERSIONS_SUCCESS,
      payload: {
        testbedTemplateVersions: payload.data,
        testbedTemplateId,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_TEST_BED_TEMPLATES_FAILURE,
      payload: { error: msg },
    });
  }
};

const getTestbedResults = (
  testbedPagedRequest: DTO.TestbedPagedRequest
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_TEST_BED_RESULTS_REQUEST,
    });

    const { payload, status } = await TestbedService.getTestbedResults(
      testbedPagedRequest
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_TEST_BED_RESULTS_SUCCESS,
      payload: {
        testbedResultList: payload.data,
        totalTestbedResults: payload.count,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_TEST_BED_RESULTS_FAILURE,
      payload: { error: msg },
    });
  }
};

const getTestbedTemplate = (
  testbedTemplateVersionId: string
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_TEST_BED_TEMPLATE_REQUEST,
    });

    const { payload, status } = await TestbedService.getTestbedTemplate(
      testbedTemplateVersionId
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_TEST_BED_TEMPLATE_SUCCESS,
      payload: {
        selectedTestbedTemplate: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_TEST_BED_TEMPLATE_FAILURE,
      payload: { error: msg },
    });
  }
};

const removeTestbedTemplate = (
  testbedTemplateId: string
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.REMOVE_TEST_BED_TEMPLATE_REQUEST,
    });

    const { payload, status } = await TestbedService.removeTestbedTemplate(
      testbedTemplateId
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.REMOVE_TEST_BED_TEMPLATE_SUCCESS,
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.REMOVE_TEST_BED_TEMPLATE_FAILURE,
      payload: { error: msg },
    });
  }
};

const removeTestbed = (
  testbedId: string
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.REMOVE_TEST_BED_REQUEST,
    });

    const { payload, status } = await TestbedService.removeTestbed(testbedId);

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.REMOVE_TEST_BED_SUCCESS,
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.REMOVE_TEST_BED_FAILURE,
      payload: { error: msg },
    });
  }
};

const getTestbed = (
  testbedId: string
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_TEST_BEDS_REQUEST,
    });

    const { payload, status } = await TestbedService.getTestbed(testbedId);

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_TEST_BED_SUCCESS,
      payload: {
        testbed: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_TEST_BEDS_FAILURE,
      payload: { error: msg },
    });
  }
};

const getTestbedItems = (
  request: DTO.TestbedItemsPagedRequest
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_TEST_BED_ITEMS_REQUEST,
      payload: { request },
    });
    const { payload, status } = await TestbedService.getTestbedItems(request);
    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }
    dispatch({
      type: TestbedConstants.GET_TEST_BED_ITEMS_SUCCESS,
      payload: {
        testbedItems: payload.data,
        count: payload.count,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_TEST_BED_ITEMS_FAILURE,
      payload: { error: msg },
    });
  }
};

const resetUploadTestBed = () => ({
  type: TestbedConstants.TEST_BED_UPLOAD_RESET,
});

const uploadTestBed = (
  file: File | undefined,
  request: DTO.TestBedRequest
): TestbedThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.TEST_BED_UPLOAD_START,
    });
    const response = await TestbedService.uploadTestbed(
      file,
      request,
      uploadProgress => {
        if (uploadProgress === 100) {
          return;
        }

        // 5% for server processing time
        dispatch({
          type: TestbedConstants.TEST_BED_UPLOAD_PROGRESS,
          payload: {
            uploadProgress: Math.max(5, uploadProgress - 5),
          },
        });
      },
      xhrRef => {
        dispatch({
          type: TestbedConstants.TEST_BED_UPLOAD_XHR_REF,
          payload: { xhrRef },
        });
      }
    );
    const { status, payload } = response;
    if (status !== 200 || payload.status !== 'Success') {
      if (payload.errorCode) {
        dispatch({
          type: TestbedConstants.TEST_BED_UPLOAD_ERROR,
          payload: {
            error: payload.errorCode,
          },
        });
      }
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.TEST_BED_UPLOAD_SUCCESS,
      payload: {
        noOfTest: payload.data.testCount,
        testbedVersionId: payload.data.testbedVersionId,
      },
    });

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.TEST_BED_UPLOAD_ERROR,
      payload: { error: msg },
    });

    return false;
  }
};

const queueCompareRun = (
  sourceTestRunId: string,
  targetTestRunId: string
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.QUEUE_COMPARE_RUN_REQUEST,
    });

    const { payload, status } = await TestbedService.queueCompareRun(
      sourceTestRunId,
      targetTestRunId
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.QUEUE_COMPARE_RUN_SUCCESS,
      payload: {
        taskQueueId: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.QUEUE_COMPARE_RUN_FAILURE,
      payload: { error: msg },
    });
  }
};

const getTestRunsByTesbedVersionId = (
  testbedVersionId: string,
  productId: string
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_TEST_RUN_BY_TESTBEDID_REQUEST,
    });

    const {
      payload,
      status,
    } = await TestbedService.getTestRunsByTesbedVersionId(
      testbedVersionId,
      productId
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_TEST_RUN_BY_TESTBEDID_SUCCESS,
      payload: {
        testbedResultList: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_TEST_RUN_BY_TESTBEDID_FAILURE,
      payload: { error: msg },
    });
  }
};

const generateTestCompare = (
  sourceTestRunId: string,
  targetTestRunId: string
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GENERATED_TEST_COMPARE_REQUEST,
    });

    const { payload, status } = await TestbedService.generateCompareResult(
      sourceTestRunId,
      targetTestRunId
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GENERATED_TEST_COMPARE_SUCCESS,
      payload: {
        url: payload.data.url,
        fileName: payload.data.fileName,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GENERATED_TEST_COMPARE_FAILURE,
      payload: { error: msg },
    });
  }
};

const getCompareStatus = (
  taskQueueId: string
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_TEST_COMPARE_STATUS_REQUEST,
    });

    const { payload, status } = await TestbedService.getCompareStatus(
      taskQueueId
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_TEST_COMPARE_STATUS_SUCCESS,
      payload: {
        taskQueueProgress: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_TEST_COMPARE_STATUS_FAILURE,
      payload: { error: msg },
    });
  }
};

const getCompareResult = (
  sourceTestRunId: string,
  targetTestRunId: string
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_TEST_COMPARE_RESULT_REQUEST,
    });

    const { payload, status } = await TestbedService.getCompareResult(
      sourceTestRunId,
      targetTestRunId
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_TEST_COMPARE_RESULT_SUCCESS,
      payload: {
        testCompareResult: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_TEST_COMPARE_RESULT_FAILURE,
      payload: { error: msg },
    });
  }
};

const updateTestbed = (
  testbedVersionId: string,
  request: DTO.UpdateTestbedItemRequest[]
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.UPDATE_TEST_BED_REQUEST,
    });
    const { payload, status } = await TestbedService.updateTestbed(
      testbedVersionId,
      request
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }
    dispatch(AlertActions.success('TestCenter.testeditor.save.success'));
    dispatch({
      type: TestbedConstants.UPDATE_TEST_BED_SUCCESS,
      payload: {
        latestTestbedVersionId: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.UPDATE_TEST_BED_FAILURE,
      payload: { error: msg },
    });
  }
};

const getAllTestbeds = (): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_ALL_TEST_BED_REQUEST,
    });

    const { payload, status } = await TestbedService.getAllTestbeds();

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_ALL_TEST_BED_SUCCESS,
      payload: {
        testbedList: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_ALL_TEST_BED_FAILURE,
      payload: { error: msg },
    });
  }
};

const queueGenerateTestCases = (
  productName: string,
  serviceName: string,
  versionId: string,
  data: DTO.GenerateTestCasesRequest
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.QUEUE_GENERATE_TESTCASES_REQUEST,
      payload: { productName, serviceName },
    });

    const { payload, status } = await TestbedService.queueGenerateTestCases(
      productName,
      serviceName,
      versionId,
      data
    );

    if (status !== 200 || payload.status === 'Error') {
      if (payload?.errorCode !== undefined) {
        await dispatch(AlertActions.error(payload?.errorCode));
        dispatch({
          type: TestbedConstants.QUEUE_GENERATE_TESTCASES_FAILURE,
          payload: { error: payload?.errorCode },
        });
        return;
      }
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.QUEUE_GENERATE_TESTCASES_SUCCESS,
      payload: {
        taskQueueId: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.QUEUE_GENERATE_TESTCASES_FAILURE,
      payload: { error: msg },
    });
  }
};

const getGenerateTestcasesStatus = (
  taskQueueId: string
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_GENERATE_TESTCASE_STATUS_REQUEST,
    });

    const { payload, status } = await TestbedService.getGenerateTestcasesStatus(
      taskQueueId
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_GENERATE_TESTCASE_STATUS_SUCCESS,
      payload: {
        taskQueueProgress: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_GENERATE_TESTCASE_STATUS_FAILURE,
      payload: { error: msg },
    });
  }
};

const getTestbedByName = (
  testbedName: string
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_TEST_BEDS_REQUEST,
    });

    const { payload, status } = await TestbedService.getTestbedByName(
      testbedName
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_TEST_BED_SUCCESS,
      payload: {
        testbed: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_TEST_BEDS_FAILURE,
      payload: { error: msg },
    });
  }
};

const resetTaskQueue = () => ({
  type: TestbedConstants.RESET_TASK_QUEUE,
});

const resetTestbedRun = () => ({
  type: TestbedConstants.RESET_TEST_BED_RUN,
});

const downloadTestBedTestCases = (
  testbedVersionId: string
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.DOWNLOAD_TESTBED_TESTCASES_REQUEST,
    });

    const { payload, status } = await TestbedService.downloadTestBedTestCases(
      testbedVersionId
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.DOWNLOAD_TESTBED_TESTCASES_SUCCESS,
      payload: {
        url: payload.data.url,
        fileName: payload.data.fileName,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.DOWNLOAD_TESTBED_TESTCASES_FAILURE,
      payload: { error: msg },
    });
  }
};

async function cancelTestbedQueue(dispatch, id: string) {
  dispatch({ type: TestbedConstants.CANCEL_TESTBED_QUEUE_REQUEST });

  try {
    const { payload } = await TestbedService.cancelTestbedQueue(id);
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.CANCEL_TESTBED_QUEUE_SUCCESS,
    });
  } catch (error) {
    const msg = error;

    dispatch({
      type: TestbedConstants.CANCEL_TESTBED_QUEUE_FAILURE,
      payload: { error: msg },
    });
  }
}

export const TestbedActions = {
  generateTemplate,
  saveTemplate,
  resetTestTemplate,
  getTestbeds,
  getTestbedQueue,
  getTestbedResult,
  saveTestbedQueue,
  getTestbedTemplates,
  getTestbedResults,
  getTestbedTemplate,
  generateTemplateById,
  removeTestbedTemplate,
  removeTestbed,
  getTestbedTemplateVersions,
  getTestbedVersions,
  getTestbed,
  uploadTestBed,
  resetUploadTestBed,
  queueCompareRun,
  getTestRunsByTesbedVersionId,
  generateTestCompare,
  getCompareStatus,
  getCompareResult,
  updateTestbed,
  getTestbedItems,
  getAllTestbeds,
  queueGenerateTestCases,
  getGenerateTestcasesStatus,
  getTestbedByName,
  resetTaskQueue,
  resetTestbedRun,
  downloadTestBedTestCases,
  cancelTestbedQueue,
  generateTestbedResult,
};
