import { AlertAction } from '../actions/types';
import { AlertConstants } from '../constants';

export const initialState: STATES.AlertMessageState = {
  showAlertModal: false,
  contentKey: '',
};

export const alertMessage = (
  state = initialState,
  action: AlertAction
): STATES.AlertMessageState => {
  switch (action.type) {
    case AlertConstants.ALERT_GLOBAL_OPEN: {
      const { contentKey } = action.payload;
      return {
        ...state,
        showAlertModal: true,
        contentKey,
      };
    }
    case AlertConstants.ALERT_GLOBAL_CLOSE: {
      return {
        ...state,
        showAlertModal: false,
        contentKey: '',
      };
    }
    default:
      return state;
  }
};
