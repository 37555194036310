import React from 'react';
import { useRouteMatch, generatePath, Link } from 'react-router-dom';
import { Breadcrumb, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { TooltipPlacement } from 'antd/lib/tooltip';

export interface BreadcrumbRoutesConfig {
  [routePath: string]:
    | string
    | ((params: { [name: string]: string }) => string);
}

interface BreadcrumbRoutesProps {
  routes?: BreadcrumbRoutesConfig;
  fixedRoutes?: { to: string; name: string; disable?: boolean }[];
  disabled?: boolean;
}

interface EllipsisBreadcrumbData {
  to: string;
  name: string;
  disable?: boolean;
}

const Container = styled.div`
  && {
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
  }
`;

const StyledBreadcrumb = styled(Breadcrumb).attrs({
  'data-testid': 'breadcrumbs-container',
})`
  && {
    a:hover {
      color: ${({ theme }) => theme.color.purple};
      text-decoration: underline;
    }

    > span:last-child a,
    > span:last-child a:hover {
      color: ${({ theme }) => theme.color.dark};
      text-decoration: none;
      cursor: default;
    }

    .cursor-pointer {
      cursor: pointer;
    }
  }
`;

const BreadcrumbRoutes: React.FC<BreadcrumbRoutesProps> = ({
  routes = {},
  fixedRoutes = [],
  disabled,
  // disabledIndexof,
}) => {
  const intl = useIntl();
  const match = useRouteMatch<{ [name: string]: string }>({
    path: Object.keys(routes),
    exact: true,
  });
  const matches: { to: string; name: string; disable?: boolean }[] = [];
  if (match) {
    const paths = match.path.split(/\//g);

    let lastKey = '';
    let name = '';
    let to = '';

    paths.forEach(path => {
      lastKey += path ? `/${path}` : path;

      const route = routes[lastKey];

      name = typeof route === 'function' ? route(match.params) : route;
      to = lastKey;

      if (name) {
        if (name.startsWith(':')) {
          name = match.params[name.substring(1)] || name;
        }

        to = generatePath(to, match.params);

        matches.push({ to, name });
      }
    });
  }

  const tooltip = (
    name,
    tooltipData,
    tooltipPlacement: TooltipPlacement | undefined = 'bottom'
  ) => {
    return (
      <Tooltip
        placement={tooltipPlacement}
        title={tooltipData}
        overlayClassName="breadcrumb-width"
      >
        {name}
      </Tooltip>
    );
  };

  const breadcrumbItem = (
    to,
    name,
    disable,
    isEllipsisBreadcrumbItem = false,
    isTooltipRequired = true,
    tooltipData,
    tooltipPlacement: TooltipPlacement | undefined = 'bottom'
  ) => (
    <Breadcrumb.Item key={to}>
      {disable || disabled || isEllipsisBreadcrumbItem ? (
        <span className={isEllipsisBreadcrumbItem ? 'cursor-pointer' : ''}>
          {isTooltipRequired
            ? tooltip(name, tooltipData, tooltipPlacement)
            : name}
        </span>
      ) : (
        <Link to={to}>
          {isTooltipRequired
            ? tooltip(name, tooltipData, tooltipPlacement)
            : name}
        </Link>
      )}
    </Breadcrumb.Item>
  );

  const breadcrumb = (
    to,
    name,
    disable = false,
    isEllipsisBreadcrumbItem = false
  ) => {
    const formattedName = intl.formatMessage({ id: name });
    const isTooltipRequired = formattedName.length > 24;
    const displayName =
      formattedName.length > 24
        ? `${formattedName.slice(0, 24)}...`
        : formattedName;
    const hasTooltipLong = formattedName.length > 30;
    return breadcrumbItem(
      to,
      displayName,
      disable,
      isEllipsisBreadcrumbItem,
      isTooltipRequired,
      formattedName,
      hasTooltipLong
        ? ('bottomLeft' as TooltipPlacement)
        : ('bottom' as TooltipPlacement)
    );
  };

  const ellipsisBreadcrumb = routeData => {
    const breadcrumbData: JSX.Element[] = [];
    const ellipsisBreadcrumbData: EllipsisBreadcrumbData[] = [];
    const excludeRouteIndex = [
      0,
      1,
      routeData.length - 2,
      routeData.length - 1,
    ];

    let hasTooltipLong = false;
    routeData.forEach((route, index) => {
      if (excludeRouteIndex.includes(index)) {
        breadcrumbData.push(breadcrumb(route.to, route.name, route.disable));
      }

      if (!excludeRouteIndex.includes(index)) {
        const displayName = intl.formatMessage({ id: route.name });
        if (displayName.length > 30) {
          hasTooltipLong = true;
        }
        const routeDetail: EllipsisBreadcrumbData = {
          to: route.to,
          name:
            index === routeData.length - 3 ? displayName : `${displayName} /`,
        };
        ellipsisBreadcrumbData.push(routeDetail);
      }
    });

    const tooltipJSX = ellipsisBreadcrumbData.map(data => (
      <div>
        <Link to={data.to}>{data.name}</Link>
      </div>
    ));
    breadcrumbData.splice(
      2,
      0,
      breadcrumbItem(
        '#',
        '...',
        false,
        true,
        true,
        tooltipJSX,
        hasTooltipLong
          ? ('bottomLeft' as TooltipPlacement)
          : ('bottom' as TooltipPlacement)
      )
    );
    return breadcrumbData;
  };

  const renderContent = () => {
    const routeData = matches.length > 0 ? matches : fixedRoutes;
    if (routeData.length <= 5) {
      return routeData.map(({ to, name, disable = false }) =>
        breadcrumb(to, name, disable)
      );
    }
    return ellipsisBreadcrumb(routeData);
  };

  return (
    <Container>
      <StyledBreadcrumb separator="/">{renderContent()}</StyledBreadcrumb>
    </Container>
  );
};

export { BreadcrumbRoutes };
