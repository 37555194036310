import makeRequest from '../helpers/make-request';
import { getConfig } from '../helpers/config';

const saveCategory = (data: DTO.SaveCategoryRequest) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/lookup/savecategory`;

  return makeRequest<DTO.GetLookupDataResponse>('POST', url, data);
};

const deleteCategory = (key: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/lookup/deletecategory/${key}`;

  return makeRequest<DTO.GetLookupDataResponse>('DELETE', url);
};

const getCategories = () => {
  const url = `${getConfig().excelEngineDomain}/api/v1/lookup/getCategories`;

  return makeRequest<DTO.GetProductCategoryResponse>('GET', url);
};

export const CategoryManagerService = {
  saveCategory,
  deleteCategory,
  getCategories,
};
