import React, { FC } from 'react';
import { SelectValue } from 'antd/lib/select';
import { useIntl } from 'react-intl';
import { CustomSelect } from '../CustomSelect';

interface EngineTypeDropdownProps {
  shouldShowType4?: boolean;
  hideDefaultOption?: boolean;
  onChange?: (value: SelectValue) => void;
  value?: SelectValue;
  suffixIcon?: JSX.Element;
  className?: string;
  size?: 'small' | 'default' | 'large';
}

export const EngineTypeDropdown: FC<EngineTypeDropdownProps> = ({
  shouldShowType4,
  hideDefaultOption = false,
  ...rest
}) => {
  const intl = useIntl();
  const optionData = [
    {
      key: '1',
      value: 'Type1',
      data_testid: 'engineTypeOption1',
      title: 'ApiDetailsForm.select.type1',
    },
    {
      key: '2',
      value: 'Type2',
      data_testid: 'engineTypeOption2',
      title: 'ApiDetailsForm.select.type2',
    },
    {
      key: '3',
      value: 'Type3',
      data_testid: 'engineTypeOption2b',
      title: 'ApiDetailsForm.select.type3',
    },
  ];
  if (!hideDefaultOption) {
    optionData.splice(0, 0, {
      key: '0',
      value: '',
      data_testid: 'engineTypeOption0',
      title: 'ApiDetailsForm.Select.engineType',
    });
  }

  if (shouldShowType4) {
    optionData.push({
      key: '4',
      value: 'Type4',
      data_testid: 'engineTypeOption4',
      title: 'ApiDetailsForm.select.type4',
    });
  }
  return (
    <CustomSelect
      {...rest}
      placeholder="ApiDetailsForm.requestedServiceCategory.placeholder"
      options={optionData.map(option => {
        return {
          key: option.key,
          value: option.value,
          title: intl.formatMessage({
            id: option.title,
          }),
          data_testid: option.data_testid,
        };
      })}
    />
  );
};
