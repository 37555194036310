export const BOARD_GET_PRODUCTS_REQUEST = 'BOARD_GET_PRODUCTS_REQUEST';
export const BOARD_GET_PRODUCTS_SUCCESS = 'BOARD_GET_PRODUCTS_SUCCESS';
export const BOARD_GET_PRODUCTS_FAILURE = 'BOARD_GET_PRODUCTS_FAILURE';

export const BOARD_GET_STATUS_COLS_REQUEST = 'BOARD_GET_STATUS_COLS_REQUEST';
export const BOARD_GET_STATUS_COLS_SUCCESS = 'BOARD_GET_STATUS_COLS_SUCCESS';
export const BOARD_GET_STATUS_COLS_FAILURE = 'BOARD_GET_STATUS_COLS_FAILURE';

export const BOARD_MOVE_COLUMN_REQUEST = 'BOARD_MOVE_COLUMN_REQUEST';
export const BOARD_MOVE_COLUMN_SUCCESS = 'BOARD_MOVE_COLUMN_SUCCESS';
export const BOARD_MOVE_COLUMN_FAILURE = 'BOARD_MOVE_COLUMN_FAILURE';

export const BOARD_MOVE_CARD_BW_COLS_REQUEST =
  'BOARD_MOVE_CARD_BW_COLS_REQUEST';
export const BOARD_MOVE_CARD_BW_COLS_SUCCESS =
  'BOARD_MOVE_CARD_BW_COLS_SUCCESS';
export const BOARD_MOVE_CARD_BW_COLS_FAILURE =
  'BOARD_MOVE_CARD_BW_COLS_FAILURE';

export const BOARD_ADD_COLUMN_REQUEST = 'BOARD_ADD_COLUMN_REQUEST';
export const BOARD_ADD_COLUMN_SUCCESS = 'BOARD_ADD_COLUMN_SUCCESS';
export const BOARD_ADD_COLUMN_FAILURE = 'BOARD_ADD_COLUMN_FAILURE';

export const BOARD_EDIT_COLUMN_REQUEST = 'BOARD_EDIT_COLUMN_REQUEST';
export const BOARD_EDIT_COLUMN_SUCCESS = 'BOARD_EDIT_COLUMN_SUCCESS';
export const BOARD_EDIT_COLUMN_FAILURE = 'BOARD_EDIT_COLUMN_FAILURE';

export const BOARD_DELETE_COLUMN_REQUEST = 'BOARD_DELETE_COLUMN_REQUEST';
export const BOARD_DELETE_COLUMN_SUCCESS = 'BOARD_DELETE_COLUMN_SUCCESS';
export const BOARD_DELETE_COLUMN_FAILURE = 'BOARD_DELETE_COLUMN_FAILURE';
