import { SdkGeneratorConstant } from '../constants';
import { SDKGeneratorService } from '../services/sdkgenerator.service';
import { SDKGeneratorThunkAction } from './types';
import { ApiError } from '../helpers';
import { AlertActions } from './alert.actions';

const generateSDK = (
  product: string,
  engine: string,
  apikey: string
): SDKGeneratorThunkAction => async dispatch => {
  try {
    dispatch({
      type: SdkGeneratorConstant.GENERATE_SDK_REQUEST,
    });

    const { payload, status } = await SDKGeneratorService.generateSDK(
      product,
      engine,
      apikey
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: SdkGeneratorConstant.GENERATE_SDK_SUCCESS,
      payload: {
        sdkUrl: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: SdkGeneratorConstant.GENERATE_SDK_FAILURE,
      payload: { error: msg },
    });
  }
};

export const GenerateSDKActions = {
  generateSDK,
};
