import { SystemAction, UserAction } from '../actions/types';
import {
  SystemConstants,
  UserConstants,
  CategoryManagerConstants,
} from '../constants';

export const initialState: STATES.SystemState = {
  isLoading: false,
  diagnoseDetails: null,
  isLoadingReleaseDetails: false,
  releaseDetails: null,
  isLoadingLookupData: false,
  lookupData: null,
  productDocMetadata: [],
  isLoadingProductDocMetadata: false,
};

export const system = (
  state = initialState,
  action: SystemAction | UserAction
): STATES.SystemState => {
  switch (action.type) {
    case SystemConstants.GET_LOOKUP_DATA_REQUEST:
    case CategoryManagerConstants.DELETE_CATEGORY_REQUEST:
    case CategoryManagerConstants.SAVE_CATEGORY_REQUEST: {
      return {
        ...state,
        isLoadingLookupData: true,
      };
    }
    case CategoryManagerConstants.SAVE_CATEGORY_SUCCESS:
    case CategoryManagerConstants.DELETE_CATEGORY_SUCCESS:
    case SystemConstants.GET_LOOKUP_DATA_SUCCESS: {
      const { lookupData } = action.payload;

      return {
        ...state,
        lookupData,
        isLoadingLookupData: false,
      };
    }
    case SystemConstants.GET_LOOKUP_DATA_FAILURE: {
      return {
        ...state,
        isLoadingLookupData: false,
      };
    }

    case SystemConstants.GET_PRODUCT_DOCUMENT_METADATA_REQUEST: {
      return {
        ...state,
        isLoadingProductDocMetadata: true,
      };
    }
    case SystemConstants.GET_PRODUCT_DOCUMENT_METADATA_SUCCESS: {
      const { metadata } = action.payload;

      return {
        ...state,
        productDocMetadata: metadata,
        isLoadingProductDocMetadata: false,
      };
    }
    case SystemConstants.GET_PRODUCT_DOCUMENT_METADATA_FAILURE: {
      return {
        ...state,
        isLoadingProductDocMetadata: false,
      };
    }

    case SystemConstants.GET_RELEASE_DETAILS_REQUEST: {
      return {
        ...state,
        isLoadingReleaseDetails: true,
      };
    }
    case SystemConstants.GET_RELEASE_DETAILS_SUCCESS: {
      const { releaseDetails } = action.payload;
      return {
        ...state,
        releaseDetails,
        isLoadingReleaseDetails: false,
      };
    }
    case SystemConstants.GET_RELEASE_DETAILS_FAILURE: {
      return {
        ...state,
        isLoadingReleaseDetails: false,
      };
    }
    case SystemConstants.CLEAR_CACHE_MEMORY_REQUEST:
    case SystemConstants.CLEAR_APPLICATION_MEMORY_REQUEEST:
    case SystemConstants.GET_DIAGNOSE_DETAILS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SystemConstants.CLEAR_CACHE_MEMORY_SUCCESS:
    case SystemConstants.CLEAR_APPLICATION_MEMORY_SUCCESS:
    case SystemConstants.GET_DIAGNOSE_DETAILS_SUCCESS: {
      const { diagnoseDetails } = action.payload;
      return {
        ...state,
        isLoading: false,
        diagnoseDetails,
      };
    }
    case SystemConstants.CLEAR_CACHE_MEMORY_FAILURE:
    case SystemConstants.CLEAR_APPLICATION_MEMORY_FAILURE:
    case SystemConstants.GET_DIAGNOSE_DETAILS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case UserConstants.LOGGED_OUT:
    case UserConstants.LOGIN_SUCCESS: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};
