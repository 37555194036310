export const TEST_BED_QUEUE_INTERVAL_TIME = 5000;
export const TEST_BED_PAGE_SIZE = 10;

export const GENERATED_TEST_TEMPLATE_REQUEST =
  'GENERATED_TEST_TEMPLATE_REQUEST';
export const GENERATED_TEST_TEMPLATE_SUCCESS =
  'GENERATED_TEST_TEMPLATE_SUCCESS';
export const GENERATED_TEST_TEMPLATE_FAILURE =
  'GENERATED_TEST_TEMPLATE_FAILURE';

export const SAVE_TEST_TEMPLATE_REQUEST = 'SAVE_TEST_TEMPLATE_REQUEST';
export const SAVE_TEST_TEMPLATE_SUCCESS = 'SAVE_TEST_TEMPLATE_SUCCESS';
export const SAVE_TEST_TEMPLATE_FAILURE = 'SAVE_TEST_TEMPLATE_FAILURE';

export const GET_TEST_BEDS_REQUEST = 'GET_TEST_BEDS_REQUEST';
export const GET_TEST_BEDS_SUCCESS = 'GET_TEST_BEDS_SUCCESS';
export const GET_TEST_BED_SUCCESS = 'GET_TEST_BED_SUCCESS';
export const GET_TEST_BED_VERSIONS_SUCCESS = 'GET_TEST_BED_VERSIONS_SUCCESS';
export const GET_TEST_BEDS_FAILURE = 'GET_TEST_BEDS_FAILURE';

export const GET_TEST_BED_QUEUE_REQUEST = 'GET_TEST_BED_QUEUE_REQUEST';
export const GET_TEST_BED_QUEUE_SUCCESS = 'GET_TEST_BED_QUEUE_SUCCESS';
export const GET_TEST_BED_QUEUE_FAILURE = 'GET_TEST_BED_QUEUE_FAILURE';

export const SAVE_TEST_BED_QUEUE_REQUEST = 'SAVE_TEST_BED_QUEUE_REQUEST';
export const SAVE_TEST_BED_QUEUE_SUCCESS = 'SAVE_TEST_BED_QUEUE_SUCCESS';
export const SAVE_TEST_BED_QUEUE_FAILURE = 'SAVE_TEST_BED_QUEUE_FAILURE';

export const GET_TEST_BED_RESULT_REQUEST = 'GET_TEST_BED_RESULT_REQUEST';
export const GET_TEST_BED_RESULT_SUCCESS = 'GET_TEST_BED_RESULT_SUCCESS';
export const GET_TEST_BED_RESULT_FAILURE = 'GET_TEST_BED_RESULT_FAILURE';

export const GENERATED_TESTBED_RESULT_REQUEST =
  'GENERATED_TESTBED_RESULT_REQUEST';
export const GENERATED_TESTBED_RESULT_SUCCESS =
  'GENERATED_TESTBED_RESULT_SUCCESS';
export const GENERATED_TESTBED_RESULT_FAILURE =
  'GENERATED_TESTBED_RESULT_FAILURE';

export const GET_TEST_BED_TEMPLATES_REQUEST = 'GET_TEST_BED_TEMPLATES_REQUEST';
export const GET_TEST_BED_TEMPLATES_SUCCESS = 'GET_TEST_BED_TEMPLATES_SUCCESS';
export const GET_TEST_BED_TEMPLATE_VERSIONS_SUCCESS =
  'GET_TEST_BED_TEMPLATE_VERSIONS_SUCCESS';
export const GET_TEST_BED_TEMPLATES_FAILURE = 'GET_TEST_BED_TEMPLATES_FAILURE';

export const GET_TEST_BED_RESULTS_REQUEST = 'GET_TEST_BED_RESULTS_REQUEST';
export const GET_TEST_BED_RESULTS_SUCCESS = 'GET_TEST_BED_RESULTS_SUCCESS';
export const GET_TEST_BED_RESULTS_FAILURE = 'GET_TEST_BED_RESULTS_FAILURE';

export const GET_TEST_BED_TEMPLATE_REQUEST = 'GET_TEST_BED_TEMPLATE_REQUEST';
export const GET_TEST_BED_TEMPLATE_SUCCESS = 'GET_TEST_BED_TEMPLATE_SUCCESS';
export const GET_TEST_BED_TEMPLATE_FAILURE = 'GET_TEST_BED_TEMPLATE_FAILURE';

export const GET_TEST_BED_ITEMS_REQUEST = 'GET_TEST_BED_ITEMS_REQUEST';
export const GET_TEST_BED_ITEMS_SUCCESS = 'GET_TEST_BED_ITEMS_SUCCESS';
export const GET_TEST_BED_ITEMS_FAILURE = 'GET_TEST_BED_ITEMS_FAILURE';

export const UPDATE_TEST_BED_REQUEST = 'UPDATE_TEST_BED_REQUEST';
export const UPDATE_TEST_BED_SUCCESS = 'UPDATE_TEST_BED_SUCCESS';
export const UPDATE_TEST_BED_FAILURE = 'UPDATE_TEST_BED_FAILURE';

export const REMOVE_TEST_BED_TEMPLATE_REQUEST =
  'REMOVE_TEST_BED_TEMPLATE_REQUEST';
export const REMOVE_TEST_BED_TEMPLATE_SUCCESS =
  'REMOVE_TEST_BED_TEMPLATE_SUCCESS';
export const REMOVE_TEST_BED_TEMPLATE_FAILURE =
  'REMOVE_TEST_BED_TEMPLATE_FAILURE';

export const REMOVE_TEST_BED_REQUEST = 'REMOVE_TEST_BED_REQUEST';
export const REMOVE_TEST_BED_SUCCESS = 'REMOVE_TEST_BED_SUCCESS';
export const REMOVE_TEST_BED_FAILURE = 'REMOVE_TEST_BED_FAILURE';

export const TEST_BED_UPLOAD_START = 'TEST_BED_UPLOAD_START';
export const TEST_BED_UPLOAD_PROGRESS = 'TEST_BED_UPLOAD_PROGRESS';
export const TEST_BED_UPLOAD_XHR_REF = 'TEST_BED_UPLOAD_XHR_REF';
export const TEST_BED_UPLOAD_ERROR = 'TEST_BED_UPLOAD_ERROR';
export const TEST_BED_UPLOAD_SUCCESS = 'TEST_BED_UPLOAD_SUCCESS';
export const TEST_BED_UPLOAD_RESET = 'TEST_BED_UPLOAD_RESET';

export const RESET_TEST_TEMPLATE = 'RESET_TEST_TEMPLATE';

export const QUEUE_COMPARE_RUN_REQUEST = 'QUEUE_COMPARE_RUN_REQUEST';
export const QUEUE_COMPARE_RUN_SUCCESS = 'QUEUE_COMPARE_RUN_SUCCESS';
export const QUEUE_COMPARE_RUN_FAILURE = 'QUEUE_COMPARE_RUN_FAILURE';

export const GET_TEST_RUN_BY_TESTBEDID_REQUEST =
  'GET_TEST_RUN_BY_TESTBEDID_REQUEST';
export const GET_TEST_RUN_BY_TESTBEDID_SUCCESS =
  'GET_TEST_RUN_BY_TESTBEDID_SUCCESS';
export const GET_TEST_RUN_BY_TESTBEDID_FAILURE =
  'GET_TEST_RUN_BY_TESTBEDID_FAILURE';

export const GENERATED_TEST_COMPARE_REQUEST = 'GENERATED_TEST_COMPARE_REQUEST';
export const GENERATED_TEST_COMPARE_SUCCESS = 'GENERATED_TEST_COMPARE_SUCCESS';
export const GENERATED_TEST_COMPARE_FAILURE = 'GENERATED_TEST_COMPARE_FAILURE';

export const GET_TEST_COMPARE_STATUS_REQUEST =
  'GET_TEST_COMPARE_STATUS_REQUEST';
export const GET_TEST_COMPARE_STATUS_SUCCESS =
  'GET_TEST_COMPARE_STATUS_SUCCESS';
export const GET_TEST_COMPARE_STATUS_FAILURE =
  'GET_TEST_COMPARE_STATUS_FAILURE';

export const GET_TEST_COMPARE_RESULT_REQUEST =
  'GET_TEST_COMPARE_RESULT_REQUEST';
export const GET_TEST_COMPARE_RESULT_SUCCESS =
  'GET_TEST_COMPARE_RESULT_SUCCESS';
export const GET_TEST_COMPARE_RESULT_FAILURE =
  'GET_TEST_COMPARE_RESULT_FAILURE';

export const GET_ALL_TEST_BED_REQUEST = 'GET_ALL_TEST_BED_REQUEST';
export const GET_ALL_TEST_BED_SUCCESS = 'GET_ALL_TEST_BED_SUCCESS';
export const GET_ALL_TEST_BED_FAILURE = 'GET_ALL_TEST_BED_FAILURE';

export const QUEUE_GENERATE_TESTCASES_REQUEST =
  'QUEUE_GENERATE_TESTCASES_REQUEST';
export const QUEUE_GENERATE_TESTCASES_SUCCESS =
  'QUEUE_GENERATE_TESTCASES_SUCCESS';
export const QUEUE_GENERATE_TESTCASES_FAILURE =
  'QUEUE_GENERATE_TESTCASES_FAILURE';

export const GET_GENERATE_TESTCASE_STATUS_REQUEST =
  'GET_GENERATE_TESTCASE_REQUEST';
export const GET_GENERATE_TESTCASE_STATUS_SUCCESS =
  'GET_GENERATE_TESTCASE_STATUS_SUCCESS';
export const GET_GENERATE_TESTCASE_STATUS_FAILURE =
  'GET_GENERATE_TESTCASE_STATUS_FAILURE';

export const RESET_TASK_QUEUE = 'RESET_TASK_QUEUE';
export const RESET_TEST_BED_RUN = 'RESET_TEST_BED_RUN';

export const DOWNLOAD_TESTBED_TESTCASES_REQUEST =
  'DOWNLOAD_TESTBED_TESTCASES_REQUEST';
export const DOWNLOAD_TESTBED_TESTCASES_SUCCESS =
  'DOWNLOAD_TESTBED_TESTCASES_SUCCESS';
export const DOWNLOAD_TESTBED_TESTCASES_FAILURE =
  'DOWNLOAD_TESTBED_TESTCASES_FAILURE';

export const CANCEL_TESTBED_QUEUE_REQUEST = 'CANCEL_TESTBED_QUEUE_REQUEST';
export const CANCEL_TESTBED_QUEUE_SUCCESS = 'CANCEL_TESTBED_QUEUE_SUCCESS';
export const CANCEL_TESTBED_QUEUE_FAILURE = 'CANCEL_TESTBED_QUEUE_FAILURE';
