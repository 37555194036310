import { getConfig } from '../helpers/config';
import makeRequest from '../helpers/make-request';

const getCounterDefinitions = (retrieveClusterCounters: boolean) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/useranalytics/counterdefinitions/${retrieveClusterCounters}`;
  return makeRequest<{ data: DTO.CounterDefinition[] }>('GET', url);
};

const getTotalByCounterName = (request: DTO.InsightFilter) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/useranalytics/getTotalByCounterName`;
  return makeRequest<{ data: DTO.InsightMetric[] }>('POST', url, request);
};

const getAggregatedData = (request: DTO.InsightFilter) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/useranalytics/AggregateData`;
  return makeRequest<{ data: DTO.CounterAggregateData[] }>(
    'POST',
    url,
    request
  );
};

const getNextMeasures = (request: DTO.InsightNextMeasure) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/useranalytics/GetNextMeasures`;
  return makeRequest<{ data: DTO.InsightNextMeasureResponse }>(
    'POST',
    url,
    request
  );
};

export const InsightsService = {
  getCounterDefinitions,
  getTotalByCounterName,
  getAggregatedData,
  getNextMeasures,
};
