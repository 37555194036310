import Select, { SelectProps, SelectValue } from 'antd/es/select';
import { Checkbox } from 'antd';
import React, { FC, RefObject, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { IconSvg } from '../Icons/IconSvg';
import { CategoryIcon } from '../ProductCategoryIcon';

const { Option } = Select;

interface DropdownSelectOption {
  key: string;
  value: string | number;
  text: string;
  listIcon?: string;
  selectedIcon?: string;
  selectedIconBgColor?: string;
  selectedIconSquare?: boolean;
  categoryIcon?: string;
  selected?: boolean;
}

interface DropdownSelectProps extends SelectProps<SelectValue> {
  forwardRef?:
    | React.Ref<Select<SelectValue>>
    | RefObject<Select<SelectValue>>
    | null;
  width?: number;
  options: DropdownSelectOption[];
  categoryIcons?: object;
  productCategories?: DTO.ProductCategory[];
  showCheckbox?: boolean;
}

const CssSelectMenuItem = css`
  display: flex !important;
  align-items: center;
  color: ${({ theme }) => theme.color.dark};
  font-size: 16px;

  span[role='img'] {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 25px;
    height: 25px;
    border-radius: 15px;
    text-align: center;
    margin-right: 5px;
    margin-left: 10px;

    .anticon {
      color: ${({ theme }) => theme.color.dark};
      font-size: 19px;
    }

    &.square {
      width: 40px;
      height: 38px;
      border-radius: 0;
      margin-left: 0;

      .anticon {
        font-size: 23px;
      }
    }

    &.select-icon-All {
      background-color: transparent;
    }

    &.only-selected {
      display: flex;
    }

    &.only-list {
      display: none;
    }
  }
`;

const StyledFilterSelect = styled(
  (props: React.PropsWithChildren<SelectProps<SelectValue>>) => (
    <Select {...props} />
  )
)<{
  width?: number;
  ref?: React.Ref<Select<SelectValue>> | RefObject<Select<SelectValue>> | null;
}>`
  ${({ width = 150 }) => (width ? `width: ${width}px` : '')};

  .ant-select-selection__rendered {
    margin-left: 0;
    padding-left: 0;

    .ant-select-selection-selected-value,
    .ant-select-selection__placeholder {
      padding-left: 10px;
    }
  }

  .ant-select-selection {
    border: 1px solid ${({ theme }) => theme.color.gray2};
    overflow: hidden;

    .anticon {
      color: ${({ theme }) => theme.color.dark};
    }
  }

  .ant-select-selection-selected-value {
    span:first-child {
      margin-left: 10px;
    }
  }

  .ant-select-selection-selected-value,
  .ant-select-dropdown-menu-item {
    ${CssSelectMenuItem}
  }
`;

const StyledContainer = styled.div`
  .ant-select-dropdown-menu-item {
    height: 50px;

    ${CssSelectMenuItem}

    span[role='img'] {
      margin-left: 0px;

      &.only-selected {
        display: none;
      }

      &.only-list {
        display: flex;
      }
    }
  }

  .ant-select-dropdown--multiple {
    width: fit-content !important;

    .ant-checkbox-wrapper {
      margin-right: 8px;
    }
  }

  .ant-select-dropdown-menu-item:focus,
  .ant-select-dropdown-menu-item:active,
  .ant-select-dropdown-menu-item:hover {
    &:not(.ant-select-dropdown-menu-item-disabled) {
      background-color: ${({ theme }) => theme.vars.primaryColor};

      span[role='img'] {
        &.select-icon-All {
          background-color: transparent;
        }
      }
    }
  }
  .ant-select-dropdown-menu-item-active {
    &:not(.ant-select-dropdown-menu-item-disabled) {
      background-color: ${({ theme }) => theme.vars.primaryColor};
      color: ${({ theme }) => theme.color.white};
    }
  }
`;

const StatusFilterIconWrapper = styled.span<{ color?: string }>`
  background-color: ${({ color, theme }) =>
    color ? theme.color[color] : 'transparent'};

  .anticon {
    color: ${({ theme, color }) =>
      color ? theme.color.white : theme.color.dark} !important;
  }
`;

const TextContainer = styled.span`
  padding-left: 5px;
`;

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.color.purple};
    border-color: ${({ theme }) => theme.color.purple};
  }
`;

const DropdownSelect: FC<DropdownSelectProps> = ({
  placeholder,
  getPopupContainer,
  options,
  forwardRef,
  categoryIcons,
  productCategories,
  showCheckbox,
  ...rest
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <StyledContainer
        className="dropdown-select-popup-container"
        ref={containerRef}
      />
      <StyledFilterSelect
        {...rest}
        ref={forwardRef}
        placeholder={
          typeof placeholder === 'string' ? (
            <FormattedMessage id={placeholder} />
          ) : (
            placeholder
          )
        }
        getPopupContainer={() => containerRef.current || document.body}
      >
        {options.map(
          ({
            key,
            value,
            text,
            listIcon,
            selectedIcon,
            selectedIconBgColor,
            selectedIconSquare,
            categoryIcon,
            selected,
          }) => (
            <Option key={key} value={value}>
              {showCheckbox && <StyledCheckbox checked={selected} />}
              {selectedIcon && (
                <StatusFilterIconWrapper
                  role="img"
                  aria-label={key}
                  className={`only-selected ${
                    selectedIconSquare ? 'square' : ''
                  }`}
                  color={selectedIconBgColor}
                >
                  <IconSvg type={selectedIcon} />
                </StatusFilterIconWrapper>
              )}
              {listIcon && (
                <span
                  role="img"
                  aria-label={key}
                  className={`only-list select-icon-${key}`}
                >
                  <IconSvg type={listIcon} />
                </span>
              )}
              {categoryIcon && productCategories && (
                <CategoryIcon
                  width="25px"
                  height="25px"
                  icon={categoryIcon}
                  categoryIcons={categoryIcons}
                  productCategories={productCategories}
                />
              )}
              <TextContainer>
                <FormattedMessage id={text} />
              </TextContainer>
            </Option>
          )
        )}
      </StyledFilterSelect>
    </>
  );
};

const DropdownSelectForwardRef = React.forwardRef(
  (props: DropdownSelectProps, ref: React.Ref<Select<SelectValue>>) => (
    <DropdownSelect forwardRef={ref} {...props} />
  )
);

export { DropdownSelectForwardRef as DropdownSelect };
