import React, { FC, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { IconSvg } from '../Icons/IconSvg';

export interface AddButtonProps {
  onClick?: (event: SyntheticEvent<HTMLElement>) => void;
  className?: string;
  buttonLabel: string;
}

export const ButtonWrapper = styled.div`
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.purple};
  color: ${({ theme }) => theme.color.purpleDarkened3};
  box-sizing: border-box;
  border-radius: 20px;
  height: 40px;
  display: flex;
  width: 200px;
  margin-bottom: 12px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  padding-left: 10px;

  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.color.purple20};
    border: 1px solid ${({ theme }) => theme.color.purple};

    svg {
      color: ${({ theme }) => theme.color.purple};
    }
  }

  :active {
    background: ${({ theme }) => theme.color.purpleDarkened1};
    border: 1px solid ${({ theme }) => theme.color.purpleDarkened1};

    p {
      color: white;
    }

    svg {
      color: ${({ theme }) => theme.color.purple60};
    }
  }

  .anticon {
    color: ${({ theme }) => theme.color.purple};
  }

  i {
    margin-right: 7px;
  }
`;

export const StyledText = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.textColor};
`;

const AddButton: FC<AddButtonProps> = ({ onClick, className, buttonLabel }) => {
  const intl = useIntl();

  return (
    <ButtonWrapper
      className={className}
      onClick={onClick}
      data-testid="add-btn"
    >
      <IconSvg type="PlusIconCircularFill" />
      <StyledText>
        {intl.formatMessage({
          id: buttonLabel,
        })}
      </StyledText>
    </ButtonWrapper>
  );
};

export { AddButton };
