import React, {
  NamedExoticComponent,
  memo,
  useCallback,
  ChangeEventHandler,
  RefObject,
} from 'react';
import { Input, Icon } from 'antd';
import { InputProps } from 'antd/lib/input';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { colorHexToRgba } from '../../../helpers';

interface SearchInputProps extends Omit<InputProps, 'onChange'> {
  searchInputRef?: RefObject<Input>;
  onChange?: (value: string) => void;
  prefix?: boolean;
  customPrefix?: React.ReactNode;
  hideClearAll?: boolean;
  alwaysShowPrefix?: boolean;
}

const StyledInput = styled(Input)`
  && {
    width: 300px;
    margin-right: 10px;

    .ant-input {
      border: 1px solid ${({ theme }) => theme.color.purple};
    }

    &.ant-input-affix-wrapper .ant-input:not(:last-child) {
      padding-right: 50px;
      padding-left: 38px;
    }

    .anticon {
      font-size: 20px;
      color: ${({ theme }) => colorHexToRgba(theme.color.purple, 0.4)};

      &.close-circle {
        svg {
          color: ${({ theme }) => theme.color.dark40} !important;
        }
      }
    }
  }
`;

const SearchInput: NamedExoticComponent<SearchInputProps> = memo(
  ({
    onChange,
    value,
    placeholder,
    searchInputRef,
    customPrefix,
    hideClearAll,
    alwaysShowPrefix = false,
    prefix,
    ...rest
  }) => {
    const intl = useIntl();
    const onClear = useCallback(() => {
      onChange && onChange('');
      searchInputRef &&
        searchInputRef.current &&
        searchInputRef.current.focus();
    }, [onChange, searchInputRef]);

    const onInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
      e => {
        onChange && onChange(e.target.value);
      },
      [onChange]
    );

    const suffixRender = suffixValue => {
      if (suffixValue) {
        if (!hideClearAll) {
          return (
            <Icon
              className="close-circle"
              type="close-circle"
              theme="filled"
              onClick={onClear}
            />
          );
        }
      }
      if (!prefix) {
        return <Icon type="search" />;
      }
      return <></>;
    };

    return (
      <StyledInput
        {...rest}
        ref={searchInputRef}
        placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''}
        onChange={onInputChange}
        value={value}
        data-testid="search-files-folders"
        allowClear={false}
        prefix={
          prefix &&
          (!value || alwaysShowPrefix) &&
          (customPrefix || <Icon type="search" />)
        }
        suffix={suffixRender(value)}
      />
    );
  }
);

export { SearchInput };
