import { Button, Col, Drawer, Layout, Menu, Row, Input } from 'antd';
import { startsWith } from 'lodash-es';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ShareProductActions, UserActions } from '../../actions';
import AboutModal from '../../containers/AboutModal';
import BackgroundJobsPopover from '../../containers/BackgroundJobsPopover';
import {
  colorHexToRgba,
  copyToClipboard,
  getConfig,
  getInitials,
} from '../../helpers';
import { getSparkConfig } from '../../helpers/spark-config';
import { useModal } from '../../hooks';
import { CustomModal, IconSvg, Logo } from '../Common';
import { LanguageDropdown } from '../Language';

const { Header } = Layout;

interface RouteParams {
  productId: string;
}

const mapStateToProps = ({
  user: { userInfo },
  auth: { isAdmin, isSuperuser, tenant, issuerConfig },
}: STATES.AppState) => ({
  displayName: (userInfo && userInfo.displayName) || '...',
  userEmailID: userInfo && userInfo.userEmailID,
  isAdmin,
  isSuperuser,
  tenant,
  issuerConfig,
});

type MainHeaderProps = { sideMenuVisible?: boolean; headerTitle?: string };

const StyledHeader = styled(Header)`
  position: sticky;
  top: 0;
  z-index: 5;
  height: 86px !important;
  padding: 0 0px !important;

  .header-wrapper {
    padding: 0px 40px !important;
    span {
      line-height: 22px;
      word-break: break-all;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  height: 86px !important;
  background-color: ${({ theme }) => theme.color.white};
  padding-top: 30px;
  display: grid;
  grid-template-rows: 1fr;

  .header-title {
    font-size: 20px;
    line-height: 23px;
    margin: 0;
    text-align: center;
  }

  @media (max-width: 768px) {
    grid-template-columns: 30% 23% 47%;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    grid-template-columns: 21% 26% 34%;
  }
`;

const StyledMenuToggler = styled(Button)`
  && {
    border: none !important;
    margin-left: 30px;
    color: ${({ theme }) => theme.vars.priminparyColor} !important;
    margin: 0 5px;
    padding-top: 0px;
    border-radius: 4px;
    height: 36px;
    width: 36px;
    min-width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

    .anticon {
      font-size: 18px;
      color: ${({ theme }) => theme.vars.primaryColor};
    }

    :hover {
      background: ${({ theme }) => theme.color.purple};
      .anticon {
        color: ${({ theme }) => theme.color.white};
      }
    }
  }
`;

const StyledMenuContainer = styled.div`
  display: flex;
  align-items: center;
  grid-column: 3/4;
  grid-row: 1/2;
  justify-content: flex-end;
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-mask {
    background-color: ${({ theme }) => colorHexToRgba(theme.color.dark, 0.4)};
  }
  .ant-drawer-content-wrapper {
    width: 360px !important;
    height: auto !important;
    .ant-drawer-header {
      background: ${({ theme }) => theme.color.gray6};
      padding: 27px 23px 22px 10px;
    }
    .ant-drawer-content {
      border-radius: 4px;
    }
    .ant-drawer-body {
      padding: 0;
      .ant-menu-item-divider {
        margin: 0;
      }
      .ant-menu-item {
        margin: 0;
        padding: 0;
        height: 36px;
        font-size: 16px;

        b {
          color: ${({ theme }) => theme.color.dark};
          font-size: 16px !important;
          line-height: 20px;
          font-weight: 400 !important;
        }

        a {
          line-height: 2.5;
          padding: 9px 9px 9px 9px;
          display: flex;
          align-items: center;
          height: 36px;

          .anticon {
            align-self: center;
            margin-right: 10px;
            margin-bottom: 5px;
            font-size: 20px;
            opacity: 1;
            position: relative;
            top: 4px;
          }
        }
      }

      .ant-menu li {
        color: ${({ theme }) => theme.color.dark} !important;
        background: ${({ theme }) => theme.color.white};
        font-weight: 400 !important;
      }

      .ant-menu li :hover {
        background-color: ${({ theme }) => theme.color.purpleDarkened2};
        color: ${({ theme }) => theme.color.white};
        font-weight: bold !important;

        b {
          color: ${({ theme }) => theme.color.white};
          font-weight: bold !important;
        }

        .anticon {
          color: ${({ theme }) => theme.color.white};
          background-color: ${({ theme }) =>
            colorHexToRgba(theme.color.purple, 0)};
        }
        .anticon svg {
          background-color: ${({ theme }) =>
            colorHexToRgba(theme.color.purple, 0)};
        }
      }

      .ant-menu-item-selected > a {
        color: ${({ theme }) => theme.color.dark};
        background: ${({ theme }) => theme.color.white};
      }

      .ant-menu-item > a {
        color: ${({ theme }) => theme.color.dark};
        background: ${({ theme }) => theme.color.white};
      }
    }
  }
`;

const StyledProfileIcon = styled.div`
  display: inline-block;
  align-items: center;
  padding: 0px 10px;
  padding-right: 0;

  .initials {
    width: 36px;
    cursor: pointer;
    height: 36px;
    background-color: ${({ theme }) => theme.color.purpleDarkened2};
    border-radius: 18px;
    margin-right: 0px;
    color: ${({ theme }) => theme.vars.white};
    display: inline-block;
    text-align: center;
    font-size: 15px;
    text-transform: uppercase;
    line-height: 36px;
  }

  .name {
    font-weight: bold;
    font-size: 18px;
    text-transform: capitalize;
    height: 36px;

    b {
      line-height: 2opx;
    }
  }
`;

const StyledProfileInfo = styled.div`
  align-items: center;
  padding: 0px;

  .initials {
    width: 60px;
    cursor: pointer;
    height: 60px;
    background-color: ${({ theme }) => theme.color.purpleDarkened2};
    border-radius: 30px;
    margin-right: 0px;
    color: ${({ theme }) => theme.vars.white};
    padding: 0px;
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 60px;
    display: inline-block;
    margin-top: 20%;
  }

  .name {
    font-weight: bold;
    font-size: 20px;
    text-transform: capitalize;
    height: 35px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    b {
      line-height: 2.25;
    }
  }

  .emailAddress {
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    overflow: hidden;
  }

  .tenant {
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 300;
    overflow: hidden;
  }
`;

const StyledLanguageDropdown = styled(LanguageDropdown)`
  button {
    padding-top: 0px !important;
    height: 36px;
    width: 36px;
    padding: 0;
    justify-content: center;
    align-items: center;
    border-radius: 4px !important;

    i {
      color: ${({ theme }) => theme.color.purple} !important;
    }

    :hover {
      background: ${({ theme }) => theme.color.purple};

      i {
        color: ${({ theme }) => theme.color.white} !important;
      }
    }
  }
`;

const StyledCustomModal = styled(CustomModal)`
  display: flex;
  justify-content: center;

  .title {
    display: flex;
    justify-content: center;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.color.dark};
  }

  input {
    width: 330px;
    height: 36px;
    margin-right: 8px;
    border: 1px solid ${({ theme }) => theme.color.purple};
    color: ${({ theme }) => theme.color.dark};
    font-size: 12px;
  }

  .copy-text {
    display: flex;
    justify-content: center;
    color: ${({ theme }) => theme.color.dark};
  }

  .ant-modal-footer {
    display: none;
  }

  .action-wrap {
    display: flex;
    margin: 20px 0;

    button {
      color: ${({ theme }) => theme.color.white} !important;
      border-radius: 20px;
      background-color: ${({ theme }) =>
        theme.color.purpleDarkened2} !important;
      color: ${({ theme }) => theme.color.white};
      font-size: 14px !important;
      font-weight: bold;
      width: 106px !important;
      height: 36px !important;
      min-width: unset !important;
    }
  }

  .copy-success {
    display: flex;
    justify-content: center;

    i {
      margin-top: 4px;
      margin-right: 5px;
      color: ${({ theme }) => theme.color.success};
    }

    .copy-text {
      margin-left: 5px;
    }
  }

  .anticon-close {
    color: ${({ theme }) => theme.color.dark} !important;
  }
`;

const MainHeader: React.FC<MainHeaderProps> = ({ children, headerTitle }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isShared = useLocation().pathname.startsWith('/shared');
  const { productId } = useParams<RouteParams>();
  const [aboutModdalVisible, setAboutModalVisible] = useState(false);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [shareLink, setShareLink] = useState<string>(
    `${window.location.origin}${
      isShared ? '/shared' : ''
    }/products/${productId}`
  );
  const { showConfirm } = useModal();
  const location = useLocation();
  const { displayName, userEmailID, issuerConfig } = useSelector(
    mapStateToProps
  );
  const [showDrawer, setShowDrawer] = useState(false);
  const tenant = localStorage.getItem('Tenant') || getConfig().defaultTenant;

  const gitBookUrl = getSparkConfig().GitBookUrl;
  const onLogOut: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();

    showConfirm({
      content: 'MainHeader.logout.confirm',
      okText: 'Global.yes',
      cancelText: 'Global.no',
      onOk: () => {
        dispatch(UserActions.logout(issuerConfig));
      },
    });
  };

  const showAboutModal: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();

    setAboutModalVisible(true);
  };

  const hideAboutModal = useCallback(() => {
    setAboutModalVisible(false);
  }, []);

  const onShare: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    e => {
      e.preventDefault();
      setShowShareModal(true);
      dispatch(ShareProductActions.getProductShareState(productId));
    },
    [productId, dispatch]
  );

  const onClose = () => {
    setShowDrawer(false);
  };

  const onOpen = () => setShowDrawer(true);

  const onLinkCopy = () =>
    copyToClipboard(shareLink, intl.formatMessage({ id: 'Global.copied' }));

  const userMenu = (
    <Menu onClick={onClose}>
      {startsWith(location.pathname, '/admin') && (
        <Menu.Item>
          <Link to="/">
            <IconSvg type="LeftArrow" />
            <b>
              <FormattedMessage id="MainHeader.menu.catalogue" />
            </b>
          </Link>
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item>
        <Link to="/admin/explorer/home" data-testid="options-menu-item">
          <IconSvg type="setting" />
          <b>
            <FormattedMessage id="MainHeader.menu.options" />
          </b>
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <Link to="/" onClick={showAboutModal} data-testid="about-menu-item">
          <IconSvg type="info-circle" />
          <FormattedMessage id="MainHeader.menu.about" />
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a target="_blank" href={gitBookUrl} rel="noopener noreferrer">
          <IconSvg type="UserGuide" />
          <b>
            <FormattedMessage id="MainHeader.menu.userGuide" />
          </b>
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a
          target="_blank"
          href={`${getConfig().reportSupportUrl}`}
          rel="noopener noreferrer"
        >
          <IconSvg type="SupportIcon" />
          <FormattedMessage id="MainHeader.menu.support" />
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <Link to="/" onClick={onLogOut} data-testid="logout-menu-item">
          <IconSvg type="Logout" />
          <b>
            <FormattedMessage id="MainHeader.menu.logout" />
          </b>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <StyledHeader className="main-header">
        <Wrapper className="header-wrapper">
          {children || (
            <Link to="/">
              <Logo type="OnWhite" height="50px" />
            </Link>
          )}
          <div className="header-title-wrap">
            {headerTitle && <p className="header-title">{headerTitle}</p>}
          </div>
          <StyledMenuContainer className="header-menu">
            <BackgroundJobsPopover />
            {productId && !location.pathname.startsWith('/share') && (
              <StyledMenuToggler
                shape="circle"
                icon="share-alt"
                onClick={onShare}
                data-testid="share-product-btn"
              />
            )}
            <StyledLanguageDropdown />
            <StyledProfileIcon onClick={onOpen} data-testid="user-menu-toggler">
              <b className="initials">{getInitials(displayName)}</b>
            </StyledProfileIcon>
          </StyledMenuContainer>
          <StyledDrawer
            title={
              <StyledProfileInfo>
                <Row gutter={0}>
                  <Col span={5}>
                    <b className="initials">{getInitials(displayName)}</b>
                  </Col>
                  <Col span={19}>
                    <div className="name">
                      <b>{displayName}</b>
                    </div>
                    <div className="emailAddress">{userEmailID}</div>
                    <div className="tenant">{tenant}</div>
                  </Col>
                </Row>
              </StyledProfileInfo>
            }
            placement="right"
            closable={false}
            onClose={onClose}
            visible={showDrawer}
          >
            {userMenu}
          </StyledDrawer>
        </Wrapper>
      </StyledHeader>
      <AboutModal visible={aboutModdalVisible} onClose={hideAboutModal} />
      <StyledCustomModal
        visible={showShareModal}
        onCancel={() => setShowShareModal(false)}
        title={<FormattedMessage id="MainHeader.share.title" />}
        content={
          <>
            <div className="action-wrap">
              <Input
                value={shareLink}
                className="input-val"
                onChange={e => setShareLink(e.target.value)}
              />
              <Button onClick={onLinkCopy}>
                <FormattedMessage id="Global.copy" />
              </Button>
            </div>

            <p className="copy-text">
              <FormattedMessage id="MainHeader.share.text" />
            </p>
          </>
        }
      />
    </>
  );
};

export default MainHeader;
