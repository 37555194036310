export const GET_DIAGNOSE_DETAILS_REQUEST = 'GET_DIAGNOSE_DETAILS_REQUEST';
export const GET_DIAGNOSE_DETAILS_SUCCESS = 'GET_DIAGNOSE_DETAILS_SUCCESS';
export const GET_DIAGNOSE_DETAILS_FAILURE = 'GET_DIAGNOSE_DETAILS_FAILURE';

export const GET_RELEASE_DETAILS_REQUEST = 'GET_RELEASE_DETAILS_REQUEST';
export const GET_RELEASE_DETAILS_SUCCESS = 'GET_RELEASE_DETAILS_SUCCESS';
export const GET_RELEASE_DETAILS_FAILURE = 'GET_RELEASE_DETAILS_FAILURE';

export const GET_LOOKUP_DATA_REQUEST = 'GET_LOOKUP_DATA_REQUEST';
export const GET_LOOKUP_DATA_SUCCESS = 'GET_LOOKUP_DATA_SUCCESS';
export const GET_LOOKUP_DATA_FAILURE = 'GET_LOOKUP_DATA_FAILURE';

export const GET_PRODUCT_DOCUMENT_METADATA_REQUEST =
  'GET_PRODUCT_DOCUMENT_METADATA_REQUEST';
export const GET_PRODUCT_DOCUMENT_METADATA_SUCCESS =
  'GET_PRODUCT_DOCUMENT_METADATA_SUCCESS';
export const GET_PRODUCT_DOCUMENT_METADATA_FAILURE =
  'GET_PRODUCT_DOCUMENT_METADATA_FAILURE';

export const CLEAR_CACHE_MEMORY_REQUEST = 'CLEAR_CACHE_MEMORY_REQUEST';
export const CLEAR_CACHE_MEMORY_SUCCESS = 'CLEAR_CACHE_MEMORY_SUCCESS';
export const CLEAR_CACHE_MEMORY_FAILURE = 'CLEAR_CACHE_MEMORY_FAILURE';

export const CLEAR_APPLICATION_MEMORY_REQUEEST =
  'CLEAR_APPLICATION_MEMORY_REQUEEST';
export const CLEAR_APPLICATION_MEMORY_SUCCESS =
  'CLEAR_APPLICATION_MEMORY_SUCCESS';
export const CLEAR_APPLICATION_MEMORY_FAILURE =
  'CLEAR_APPLICATION_MEMORY_FAILURE';

export const SUPPORTED_EXCEL_FUNCTION_LINK =
  'https://coherent.gitbook.io/spark/-MdGP2-SjkULHy_UN61T/excel/supported-excel-functions';
