import { TutorialConstants } from '../constants';
import { TutorialAction, TutorialThunkAction } from './types';
import { EngineService } from '../services';
import { AlertActions } from './alert.actions';
import { ApiError } from '../helpers';

const getFormSpec = (
  productName: string,
  serviceName: string
): TutorialThunkAction => async (dispatch, getState) => {
  const {
    tutorial: { serviceName: tutorialServiceName, isLoadingFormSpec },
  } = getState();

  if (isLoadingFormSpec && tutorialServiceName === serviceName) {
    return;
  }

  try {
    dispatch({
      type: TutorialConstants.GET_FORMSPEC_REQUEST,
      payload: { serviceName },
    });

    const { payload, status } = await EngineService.getTutorialFormSpec(
      productName,
      serviceName
    );

    if (status !== 200 || payload.status === 'Error') {
      if (
        payload.Data &&
        payload.errorCode === 'PRODUCT_TUTORIAL_UPLOAD_ERROR'
      ) {
        const { Error, CorrelationId } = payload.Data;
        const {
          language: { intl },
        } = getState();

        dispatch({
          type: TutorialConstants.GET_FORMSPEC_FAILURE,
          payload: {
            error: [
              ...Error.map(({ Key, Value }) =>
                intl.formatMessage({ id: Key }, { values: Value.join(', ') })
              ),
              `(${CorrelationId})`,
            ].join('<br />'),
            serviceName,
          },
        });

        return;
      }

      throw new ApiError(payload);
    }

    dispatch({
      type: TutorialConstants.GET_FORMSPEC_SUCCESS,
      payload: {
        formSpec: payload.data,
        serviceName,
      },
    });
  } catch (error) {
    await dispatch(AlertActions.error(error));
  }
};

const getFormState = (
  productName: string,
  serviceName: string,
  fields: DTO.FormStateInputField[]
): TutorialThunkAction<DTO.FormState> => async dispatch => {
  try {
    dispatch({
      type: TutorialConstants.GET_FORMSTATE_REQUEST,
    });

    const { payload, status } = await EngineService.getTutorialFormState(
      productName,
      serviceName,
      fields
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TutorialConstants.GET_FORMSTATE_SUCCESS,
      payload: { formstate: payload.data },
    });

    return payload.data;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TutorialConstants.GET_FORMSTATE_FAILURE,
      payload: { error: msg },
    });

    return { fields: {} };
  }
};

const resetFormBuilder = (): TutorialAction => ({
  type: TutorialConstants.RESET_FORMBUILDER,
});

const constructFormSpec = (formSpec: DTO.TutorialFormSpec): TutorialAction => ({
  type: TutorialConstants.CONSTRUCT_TUTORIAL_FORMSPEC_ACTION,
  payload: { formSpec },
});

export const TutorialActions = {
  getFormSpec,
  getFormState,
  resetFormBuilder,
  constructFormSpec,
};
