import { EngineLogsAction } from '../actions/types';
import { EngineLogsConstant } from '../constants';

export const initialState: STATES.DownloadLogsState = {
  isLoading: false,
  list: '',
};

export const downloadLogs = (
  state = initialState,
  action: EngineLogsAction
): STATES.DownloadLogsState => {
  switch (action.type) {
    case EngineLogsConstant.DOWNLOAD_MULTIPLE_LOGS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case EngineLogsConstant.DOWNLOAD_MULTIPLE_LOGS_SUCCESS: {
      const { list } = action.payload;

      return {
        ...state,
        isLoading: false,
        list,
      };
    }

    case EngineLogsConstant.DOWNLOAD_MULTIPLE_LOGS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};
