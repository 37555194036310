import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Prompt, useLocation } from 'react-router-dom';
import { CustomModal } from '../Common';

interface Props {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation?: (location: Location) => boolean;
  children?: (props: {
    [key: string]: () => void;
  }) => React.ReactNode | React.ReactNode[];
}
const RouteLeavingGuard = ({
  when,
  navigate,
  shouldBlockNavigation,
  children,
}: Props) => {
  const pathname = useLocation().pathname;
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const closeModal = () => {
    setModalVisible(false);
  };
  useEffect(() => {
    setLastLocation(null);
    setConfirmedNavigation(false);
  }, [pathname]);
  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (
      !confirmedNavigation &&
      shouldBlockNavigation &&
      shouldBlockNavigation(nextLocation)
    ) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  const AlertAction = e => {
    e.preventDefault();
    e.returnValue = '';
  };
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
    window.addEventListener('beforeunload', AlertAction);
    return () => {
      window.removeEventListener('beforeunload', AlertAction);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation]);
  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <CustomModal
        wrapClassName="RouteLeavingGuard"
        centered
        visible={modalVisible}
        closable={false}
        onOk={handleConfirmNavigationClick}
        onCancel={closeModal}
        content={
          children &&
          children({
            handleConfirmNavigationClick,
            closeModal,
          })
        }
      />
    </>
  );
};
export { RouteLeavingGuard };
