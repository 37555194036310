import { SDKGeneratorAction } from '../actions/types';
import { SdkGeneratorConstant } from '../constants';

export const initialSDKState: STATES.SDKState = {
  downloadSDKUrl: '',
  error: '',
};

export const sdkGenerator = (
  state = initialSDKState,
  action: SDKGeneratorAction
): STATES.SDKState => {
  switch (action.type) {
    case SdkGeneratorConstant.GENERATE_SDK_REQUEST: {
      return {
        ...state,
        error: '',
        downloadSDKUrl: '',
      };
    }
    case SdkGeneratorConstant.GENERATE_SDK_SUCCESS: {
      const { sdkUrl } = action.payload;

      return {
        ...state,
        downloadSDKUrl: sdkUrl,
      };
    }
    case SdkGeneratorConstant.GENERATE_SDK_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        error,
      };
    }
    default:
      return state;
  }
};
