import React from 'react';
import styled from 'styled-components';
import { colorHexToRgba } from '../../../helpers';
// eslint-disable-next-line import/no-cycle
import { CustomSortIconTable } from '..';

const StyledCustomSortIconTable = styled(props => (
  <CustomSortIconTable {...props} />
))`
  && {
    .ant-table-header {
      padding-right: 20px;
      background-color: var(--table-header-bg-color);
    }

    .ant-table-body {
      overflow-x: auto !important;
      overflow-y: hidden;
      background: ${({ theme }) => theme.color.white};
    }
    .ant-table-expanded-row > td {
      padding: 15px;
    }
    .ant-table-tbody tr td:last-child {
      background: ${({ theme }) => colorHexToRgba(theme.color.pearl, 0.8)};
    }
    .ant-table-row-cell-break-word > a {
      color: ${({ theme }) => theme.color.purple};
    }
    .rowPearl {
      background: ${({ theme }) => theme.color.pearl};
    }
    .rowWhite {
      background: ${({ theme }) => theme.color.white};
    }
    .ant-table-expanded-row {
      background: ${({ theme }) => theme.color.purple40};
    }
    .ant-table-expanded-row > td {
      border: 0px !important;
    }
    .ant-table-expanded-row > td:first-child {
      background: ${({ theme }) => theme.color.white};
    }
    .ant-table-expanded-row > td:first-child:hover {
      background: ${({ theme }) => theme.color.purple20};
    }
    .ant-table-row > td:first-child {
      border-right: 0px;
      padding-left: 0px;
      padding-right: 0px;
    }
    .ant-table-expand-icon-th {
      border-right: 0px !important;
    }
    .ant-table-row-expand-icon-cell {
      border-bottom: 0;
      height: inherit;

      i {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .anticon-right {
        border-bottom: 1px solid var(--table-border-color);
      }
    }
    .ant-table-footer {
      border: 0 !important;
    }
  }
`;

const StyledExpandedTable = styled(props => <CustomSortIconTable {...props} />)`
  && {
    margin-top: 10px;

    table {
      margin-left: 1px;
      border: 0px !important;
      thead {
        display: none;
      }
    }
    .ant-table-tbody {
      background: ${({ theme }) => theme.color.white};
    }
    .ant-table-body {
      overflow: inherit !important;
      background: ${({ theme }) => theme.color.purple20};
    }
    .ant-table-footer {
      padding: 0px;
      background: ${({ theme }) => theme.color.purple20};
    }
    .ant-table-footer::before {
      display: none;
    }

    .ant-table-row > td:first-child {
      border-right: 1px solid var(--table-border-color);
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`;

const StyledFooterRow = styled.div`
  && {
    display: grid;
    grid-template-columns: 1fr repeat(3, auto) 1fr;
    grid-column-gap: 5px;
    justify-items: center;
  }

  button {
    grid-column-start: 2;
    margin: 5px 0px 5px 0px;
    border-radius: 50px;
    border-color: ${({ theme }) => theme.color.purple};
  }

  .footer-action {
    grid-column-start: none;
    margin-left: auto;
    width: 150px;
    background: ${({ theme }) => theme.color.pearl};
  }

  @media only screen and (max-width: 1400px) {
    .footer-action {
      width: 90px;
    }
  }
  @media (max-width: 1600px) and (min-width: 1400px) {
    .footer-action {
      width: 80px;
    }
  }
  @media (max-width: 2000px) and (min-width: 1600px) {
    .footer-action {
      width: 100px;
    }
  }
  @media (max-width: 2500px) and (min-width: 2000px) {
    .footer-action {
      width: 130px;
    }
  }
`;

export { StyledCustomSortIconTable, StyledExpandedTable, StyledFooterRow };
