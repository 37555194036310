import { getConfig } from '../helpers/config';
import makeRequest from '../helpers/make-request';

const getStatuses = () => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/productkanban/GetStatuses`;

  return makeRequest<{ data: string[] }>('GET', url);
};

const updateColumn = (
  columnName: string,
  fromColumnName: string,
  columnIndex: number
) => {
  const columnIndexPath = columnIndex + 1 ? `/${columnIndex + 1}` : '';
  const url =
    `${getConfig().excelEngineDomain}/api/v1/productkanban/updatestatus` +
    `/${fromColumnName}/${columnName}${columnIndexPath}`;

  return makeRequest('PUT', url);
};

const addColumn = (columnName: string, columnIndex: number) => {
  const url =
    `${getConfig().excelEngineDomain}/api/v1/productkanban/createstatus` +
    `/${columnName}/${columnIndex + 1}`;

  return makeRequest('POST', url);
};

const deleteColumn = (status: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/productkanban/deletestatus/${status}`;

  return makeRequest('DELETE', url);
};

const updateKanbanStatus = (productId: string, status: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/productkanban/updateproductstatus/${status}/${productId}`;

  return makeRequest('PUT', url);
};

export const ProductsBoardServices = {
  getStatuses,
  updateColumn,
  addColumn,
  deleteColumn,
  updateKanbanStatus,
};
