import moment from 'moment';

export const isJsonParsable = string => {
  try {
    JSON.parse(string).map(input => {
      const newKey = Object.keys(input)[0];
      return { [newKey]: '' };
    });
  } catch (e) {
    return false;
  }
  return true;
};

export const convertListToDictionary = (
  engineInputs: DTO.XSingelCellNameRange[]
): {} => {
  const inputs: {
    [xInputs: string]: string | number | boolean | object | object[];
  } = {};
  engineInputs?.map(i => {
    if (i.name.indexOf('.') === -1) {
      if (isJsonParsable(i.value)) {
        inputs[i.name] = JSON.parse(i.value);
      } else {
        inputs[i.name] = i.value;
      }
    }
  });
  return inputs;
};

export const getExcelObject = (
  engine: DTO.ExecuteExcelResult,
  productName: string,
  serviceName: string
): DTO.XlObj => {
  const result: DTO.XlObj = {
    Inputs: convertListToDictionary(engine.xlInputs),
    Outputs: convertListToDictionary(engine.xOutput),
    ImageInputs:
      engine.imageInputs
        ?.filter(i => i.imageName.indexOf('.') === -1)
        ?.map(w => {
          return {
            ImageName: w.imageName,
            FileName: w.fileName,
          };
        }) ?? [],
    SolveInputs:
      engine.solveInputs
        ?.filter(i => i.solveName.indexOf('.') === -1)
        ?.map(w => {
          return {
            SolveName: w.solveName,
            TargetValue: w.targetValue,
            MaxChange: w.maxChange,
            MaxIteration: w.maxIteration,
            IntialGuess: w.intialGuess,
            RunIfTrue: w.runIfTrue,
          };
        }) ?? [],
    ReportInputs:
      engine.reportInputs
        ?.filter(i => i.reportName.indexOf('.') === -1)
        ?.map(w => {
          return {
            ReportName: w.reportName,
            FileName: w.fileName,
          };
        }) ?? [],
    Errors: engine.validationErrors?.map(v => {
      return {
        SourcePath: v.sourcePath,
        Message: v.message,
        AdditionalDetails: v.additionalDetails,
        ErrorType: v.errorType,
      };
    }),
    Warnings:
      engine.engineWarnings?.map(w => {
        return {
          Key: w.key,
          Value: w.value,
        };
      }) ?? [],
    FetchTimeUTC: moment(engine.fetchTimeUTC).format(
      'YYYY-MM-DD, HH:mm:ss [GMT]Z'
    ),
    EngineID: engine.engineID,
    ServiceName: serviceName,
    Revision: engine.bookSummary?.bookRevision ?? '',
    Version: engine.bookSummary?.versionId ?? '',
    ReleaseDate: moment(engine.bookSummary?.bookReleaseDate ?? '').format(
      'YYYY-MM-DD, HH:mm:ss [GMT]Z'
    ),
    ServiceCategories: engine.serviceCategories ?? undefined,
    Description: engine.bookSummary?.bookDescription ?? '',
    EngineHash: engine.engineHash,
    CalcTime: engine.lastCalcTime,
    TotalTime: engine.totalTime,
    ProductName: productName,
    ReferenceUpdateRequired: engine.referenceUpdateRequired,
    ReferenceRangeMetadatas: engine.referenceRangeMetadatas ?? [],
    EffectiveEndDate: engine.effectiveEndDate ?? null,
    EffectiveStartDate: engine.effectiveStartDate ?? null,
    EngineType: engine.engineType,
  };
  return result;
};
