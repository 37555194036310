export const SAVE_CATEGORY_REQUEST = 'SAVE_CATEGORY_REQUEST';
export const SAVE_CATEGORY_SUCCESS = 'SAVE_CATEGORY_SUCCESS';
export const SAVE_CATEGORY_FAILURE = 'SAVE_CATEGORY_FAILURE';

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';

export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE';

export const SETUP_CATEGORY_EDIT_SCREEN = 'SETUP_CATEGORY_EDIT_SCREEN';
export const SET_LIST_CATEGORY_FLAG_ACTION = 'SET_LIST_CATEGORY_FLAG_ACTION';
