import jwt from 'jsonwebtoken';
import { store } from './store';
import { ApiError } from './errors';
import fetchNetworkErrorWrap from './fetchNetworkErrorWrap';
import { getConfig } from './config';
import { refresh } from './user-auth';
import { UserConstants } from '../constants';

interface ErrorConfig<T = {}> {
  isErrorStatus: (status: number) => boolean;
  isPayloadStatusError: (payload: DTO.ApiResponse<T>['payload']) => boolean;
  /**
   * By default it's True, throw an ApiError if response doesn't match isErrorStatus & isPayloadStatusError
   */
  throwAPIError: boolean;
  onError: (status: number, payload: DTO.ApiResponse<T>['payload']) => void;
}

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

interface HttpClientOptions<T = {}> {
  method: HttpMethod;
  /**
   * By default, it's EXCELENGINE_DOMAIN
   */
  domain?: string;
  path: string;
  // Accept all types for body
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
  contentType?: string;
  query?;
  options?: RequestInit;
  /**
   * Set this to True if you want to return blob type instead of json data
   */
  returnBlob?: boolean;
  errorConfig?: Partial<ErrorConfig<T>>;
}

const defaultErrorConfig: ErrorConfig = {
  isErrorStatus: status => status !== 200,
  isPayloadStatusError: payload =>
    !['Success', 'OK', 'Ok'].includes(payload.status || ''),
  throwAPIError: true,
  onError: () => {},
};
/**
 * New http request wrapper, by default it throws ApiError when get an error (status !== 200 or payload.status is not Success/OK/Ok)
 * but it's configurable
 * @param param0
 */
const httpClient = async <T = {}>({
  method,
  domain,
  path,
  body,
  contentType = 'application/json',
  options,
  returnBlob = false,
  errorConfig = {},
}: HttpClientOptions): Promise<DTO.ApiResponse<T>> => {
  const config = getConfig();
  const url = `${domain ?? config.excelEngineDomain}${path}`;
  const { userAuth } = store.getState().auth;
  const headerContentType = {
    'Content-Type': contentType,
  };
  const tenant = localStorage.getItem('Tenant');
  const headerTenant = {
    'x-tenant-name': tenant || config.defaultTenant || '',
  };
  const headerAuth =
    userAuth && userAuth.id_token
      ? {
          Authorization: `Bearer ${userAuth.id_token}`,
        }
      : null;
  const headerApiGatewayApiVersion = {
    apiVersion: config.apiGatewayApiVersion || '',
  };

  const headerApiGatewaySubscriptionKey = {
    'ocp-apim-subscription-key': config.apiGatewaySubscriptionKey || '',
  };

  const requestHeaders = {
    ...headerContentType,
    ...headerAuth,
    ...headerTenant,
    ...headerApiGatewayApiVersion,
    ...headerApiGatewaySubscriptionKey,
  };
  const decodedJwt =
    (userAuth && userAuth.id_token && jwt.decode(userAuth.id_token)) ||
    undefined;

  if (
    decodedJwt &&
    decodedJwt['exp'] &&
    decodedJwt['exp'] * 1000 < Date.now()
  ) {
    const newIdToken = await refresh(store);
    if (newIdToken) {
      requestHeaders.Authorization = `Bearer ${newIdToken}`;
    }
  }

  if (body) {
    if (body instanceof FormData) {
      delete requestHeaders['Content-Type'];
    } else {
      body = JSON.stringify(body);
    }
  }

  const requestOptions: RequestInit = {
    method,
    headers: requestHeaders,
    body,
    ...options,
  };

  let res: Response | null = null;
  try {
    res = await fetchNetworkErrorWrap(url, requestOptions);
  } catch (err) {
    // in case of network error or unable to connect to server
    res = null;
  }

  if (res) {
    const { status, headers } = res;

    const { isErrorStatus, isPayloadStatusError, onError, throwAPIError } = {
      ...defaultErrorConfig,
      ...errorConfig,
    };

    if (
      returnBlob ||
      requestHeaders['Content-Type'] === 'application/octet-stream'
    ) {
      const blob = await res.blob();

      if (throwAPIError && isErrorStatus(status)) {
        onError(status, { errorCode: '' });

        throw new ApiError({ errorCode: '' });
      }

      return {
        status,
        payload: { blob } as T & { blob: Blob },
        headers,
      };
    }

    const jsonDataPayload: T = await res.json();

    const response = {
      status,
      payload: jsonDataPayload,
      headers,
    };

    if (
      throwAPIError &&
      (isErrorStatus(status) || isPayloadStatusError(response.payload))
    ) {
      throw new ApiError(response.payload);
    }

    return response;
  }

  throw new ApiError({
    error_code: UserConstants.UNABLE_TO_CONNECT,
  });
};

export default httpClient;
