import { BookmarkConstants } from '../constants';
import { BookmarkAction } from '../actions/types';

export const initialState: STATES.BookmarkState = {
  isLoading: false,
  page: 1,
  pageSize: 10,
  totalPages: 0,
  error: '',
  selectedBookmark: null,
  selectedBookmarkId: '',
  selectedBookmarkIds: [],
  bookmarks: [],
  sort: 'name',
  search: [],
  bookmarkUrl: '',
  bookmarkByUrl: [],
};

export const bookmark = (
  state = initialState,
  action: BookmarkAction
): STATES.BookmarkState => {
  switch (action.type) {
    case BookmarkConstants.GET_BOOKMARKS_REQUEST: {
      const { page = 1, pageSize, sort, search } = action.payload;

      return {
        ...state,
        isLoading: true,
        page,
        pageSize,
        sort,
        search,
      };
    }
    case BookmarkConstants.GET_BOOKMARKS_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        isLoading: false,
        error,
      };
    }
    case BookmarkConstants.GET_BOOKMARKS_SUCCESS: {
      const { bookmarks } = action.payload;

      return {
        ...state,
        isLoading: false,
        error: '',
        bookmarks,
      };
    }
    case BookmarkConstants.DELETE_BOOKMARK_SUCCESS:
    case BookmarkConstants.CREATE_BOOKMARK_REQUEST:
    case BookmarkConstants.RENAME_BOOKMARK_FAILURE:
    case BookmarkConstants.RENAME_BOOKMARK_SUCCESS:
    case BookmarkConstants.DELETE_BOOKMARK_FAILURE:
    case BookmarkConstants.GET_BOOKMARK_REQUEST:
    case BookmarkConstants.GET_BOOKMARK_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case BookmarkConstants.GET_BOOKMARK_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        selectedBookmark: action.payload.bookmark,
      };
    }
    case BookmarkConstants.CREATE_BOOKMARK_SUCCESS: {
      const { id } = action.payload;

      return {
        ...state,
        isLoading: false,
        selectedBookmarkId: id,
        selectedBookmarkIds: [...state.selectedBookmarkIds, id],
      };
    }
    case BookmarkConstants.DELETE_BOOKMARK_REQUEST: {
      const { id } = action.payload;

      return {
        ...state,
        isLoading: true,
        selectedBookmarkId: id,
      };
    }
    case BookmarkConstants.RENAME_BOOKMARK_REQUEST: {
      const { id } = action.payload;

      return {
        ...state,
        isLoading: true,
        selectedBookmarkId: id,
      };
    }
    case BookmarkConstants.GET_BOOKMARK_BY_URL_REQUEST: {
      const { bookmarkUrl } = action.payload;
      return {
        ...state,
        isLoading: true,
        bookmarkUrl,
        bookmarkByUrl: [],
      };
    }
    case BookmarkConstants.GET_BOOKMARK_BY_URL_SUCCESS: {
      const { bookmarkByUrl } = action.payload;

      return {
        ...state,
        isLoading: false,
        error: '',
        bookmarkByUrl,
      };
    }
    case BookmarkConstants.GET_BOOKMARK_BY_URL_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        isLoading: false,
        error,
      };
    }
    case BookmarkConstants.RESET_BOOKMARK_BY_URL: {
      return {
        ...state,
        isLoading: false,
        bookmarkByUrl: [],
      };
    }
    case BookmarkConstants.CLEAR_BOOKMARKS: {
      return {
        ...state,
        bookmarks: [],
      };
    }

    case BookmarkConstants.CLEAR_BOOKMARK_IDS: {
      return {
        ...state,
        selectedBookmarkIds: [],
      };
    }

    default:
      return state;
  }
};
