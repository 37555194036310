import { PermissionConstants } from '../constants';
import { ApiError } from '../helpers';
import { ClientManagerService } from '../services';
import { AlertActions } from './alert.actions';
import { PermissionsThunkAction } from './types';

const loadUserGroupRules = (groups: string[]): PermissionsThunkAction => async (
  dispatch,
  getState
) => {
  try {
    const {
      auth: { loggedIn },
      permissions: { isLoadingRules },
    } = getState();

    if (isLoadingRules || !loggedIn) {
      return;
    }

    dispatch({
      type: PermissionConstants.LOAD_USER_GROUP_RULES_REQUEST,
      payload: { groups },
    });

    const requests = groups.map(async group => {
      const { payload, status } = await ClientManagerService.getUserGroup(
        group
      );

      if (status !== 200 || payload.status !== 'Success') {
        throw new ApiError(payload);
      }

      return payload.data;
    });

    const responses = await Promise.all(requests);

    dispatch({
      type: PermissionConstants.LOAD_USER_GROUP_RULES_SUCCESS,
      payload: {
        userGroupRules: responses
          .filter(response => !!response)
          .reduce<{
            [group: string]: DTO.UserGroupRule;
          }>(
            (prev, curr) => ({
              ...prev,
              [curr.userGroup]: curr,
            }),
            {}
          ),
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: PermissionConstants.LOAD_USER_GROUP_RULES_FAILURE,
      payload: { error: msg },
    });
  }
};

export const PermissionsActions = {
  loadUserGroupRules,
};
