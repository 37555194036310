import { ReactComponent as ProductStatusDesign } from './ic_product_st_design.svg';
import { ReactComponent as ProductStatusArchive } from './ic_product_st_archive.svg';
import { ReactComponent as ProductStatusProduction } from './ic_product_st_production.svg';
import { ReactComponent as SideMenuCalculations } from './ic_sidemenu_calculations.svg';
import { ReactComponent as SideMenuProduct } from './ic_sidemenu_product.svg';
import { ReactComponent as SideMenuTesting } from './ic_sidemenu_testing.svg';
import { ReactComponent as SideMenuCatalogue } from './ic_sidemenu_catalogue.svg';
import { ReactComponent as ModalOpen } from './ic_modal_open.svg';
import { ReactComponent as Refresh } from './ic_refresh.svg';
import { ReactComponent as ExcelFile } from './ic_excel.svg';
import { ReactComponent as PdfFile } from './ic_pdf.svg';
import { ReactComponent as WordFile } from './ic_word.svg';
import { ReactComponent as EngineFile } from './ic_engine.svg';
import { ReactComponent as ImageFile } from './ic_image.svg';
import { ReactComponent as TutorialFile } from './ic_tutorial.svg';
import { ReactComponent as HtmlFile } from './ic_html.svg';
import { ReactComponent as PowerPointFile } from './ic_powerpoint.svg';
import { ReactComponent as ProductStatusProductionOutlined } from './ic_status_production_outlined.svg';
import { ReactComponent as ProductStatusArchiveOutlined } from './ic_status_archive_outlined.svg';
import { ReactComponent as ProductStatusDesignOutlined } from './ic_status_design_outlined.svg';
import { ReactComponent as FolderOpen } from './ic_folder_open.svg';
import { ReactComponent as FolderDefault } from './ic_folder_default.svg';
import { ReactComponent as Language } from './ic_language.svg';
import { ReactComponent as Logout } from './ic_logout.svg';
import { ReactComponent as Sync } from './ic_sync.svg';
import { ReactComponent as Desktop } from './ic_desktop.svg';
import { ReactComponent as Tablet } from './ic_tablet.svg';
import { ReactComponent as Edit } from './ic_edit.svg';
import { ReactComponent as PlusColumn } from './ic_plus_column.svg';
import { ReactComponent as DoubleLeftArrow } from './ic_double_left_arrow.svg';
import { ReactComponent as Cockpit } from './ic_cockpit.svg';
import { ReactComponent as Tester } from './ic_tester.svg';
import { ReactComponent as Alian } from './ic_alian.svg';
import { ReactComponent as LeftArrow } from './left-arrow.svg';
import { ReactComponent as ServiceDocIcon } from './service_doc_icon.svg';
import { ReactComponent as TestingCentreIcon } from './testing_centre_icon.svg';
import { ReactComponent as VersionOverviewIcon } from './version_overview_icon.svg';
import { ReactComponent as ViewLogsIcon } from './view_logs_icon.svg';
import { ReactComponent as BookmarkIcon } from './bookmark_icon.svg';
import { ReactComponent as GridView } from './grid_view.svg';
import { ReactComponent as ListView } from './list_view.svg';
import { ReactComponent as PlusIconCircularFill } from './plus_circular.svg';
import { ReactComponent as PlusIconCircularOutlined } from './plus_circular_outlined.svg';
import { ReactComponent as CloseIcon } from './closeCircle.svg';
import { ReactComponent as InfoCircle } from './ic_info.svg';
import { ReactComponent as CaretDownFilled } from './caret_down_filled.svg';
import { ReactComponent as CaretUpFilled } from './caret_up_filled.svg';
import { ReactComponent as CaretUpDownFilled } from './caret_up_down.svg';
import { ReactComponent as Pencil } from './ic_pencil.svg';
import { ReactComponent as Star } from './ic_star.svg';
import { ReactComponent as SixDotLine } from './six_dot_icon.svg';
import { ReactComponent as Check } from './ic_check.svg';
import { ReactComponent as WarningIcon } from './warning_icon.svg';
import { ReactComponent as CircleCheck } from './circle_check.svg';
import { ReactComponent as Warning } from './ic_warning.svg';
import { ReactComponent as FullscreenExpand } from './ic_full_screen_expand.svg';
import { ReactComponent as FullscreenExit } from './ic_full_screen_exit.svg';
import { ReactComponent as CurlCommandError } from './ic_curl_command_error.svg';
import { ReactComponent as Link } from './ic_link.svg';
import { ReactComponent as Empty } from './ic_empty.svg';
import { ReactComponent as Calender } from './ic_calender.svg';
import { ReactComponent as Expanded } from './ic_expand.svg';
import { ReactComponent as WarmIconRed } from './warning_icon_red.svg';
import { ReactComponent as Reduce } from './ic_reduce.svg';
import { ReactComponent as InfoCircleFill } from './ic_InfoCircle_fill.svg';
import { ReactComponent as Left } from './ic_left.svg';
import { ReactComponent as Right } from './ic_right.svg';
import { ReactComponent as Preview } from './ic_preview.svg';
import { ReactComponent as ViewEye } from './ic_view_eye.svg';
import { ReactComponent as UpCaret } from './ic_table_up_caret.svg';
import { ReactComponent as DownCaret } from './ic_table_down_caret.svg';
import { ReactComponent as FontSize } from './ic_fontsize.svg';
import { ReactComponent as Bold } from './ic_bold.svg';
import { ReactComponent as Italic } from './ic_italic.svg';
import { ReactComponent as Underline } from './ic_underline.svg';
import { ReactComponent as FontColor } from './ic_fontcolor.svg';
import { ReactComponent as AlignLeft } from './ic_align_left.svg';
import { ReactComponent as AlignCenter } from './ic_align_center.svg';
import { ReactComponent as AlignRight } from './ic_align_right.svg';
import { ReactComponent as Code } from './ic_code.svg';
import { ReactComponent as LinkUrl } from './ic_link_url.svg';
import { ReactComponent as UnLinkUrl } from './ic_unlink_url.svg';
import { ReactComponent as OrderedList } from './ic_orderedlist.svg';
import { ReactComponent as UnOrderedList } from './ic_unorderedlist.svg';
import { ReactComponent as Undo } from './ic_undo.svg';
import { ReactComponent as Error } from './ic_error.svg';
import { ReactComponent as SuccessChecked } from './ic_success_checked.svg';
import { ReactComponent as DeleteIcon } from './delete.svg';
import { ReactComponent as CautionIcon } from './ic_caution.svg';
import { ReactComponent as Search } from './ic_search.svg';
import { ReactComponent as DefaultCoverImage } from './default_coverimage.svg';
import { ReactComponent as DangerDark } from './ic_danger_dark.svg';
import { ReactComponent as CircleSuccessCheck } from './ic_circle_sucess_check.svg';
import { ReactComponent as Download } from './ic_download.svg';
import { ReactComponent as Bug } from './ic_bug.svg';
import { ReactComponent as SupportIcon } from './icon_support.svg';
import { ReactComponent as InfoCircleOutlined } from './ic_info_circle_outlined.svg';
import { ReactComponent as UserGuide } from './ic_question_circle.svg';

export default {
  ProductStatusDesign,
  ProductStatusArchive,
  ProductStatusProduction,
  SideMenuCalculations,
  SideMenuProduct,
  SideMenuTesting,
  SideMenuCatalogue,
  ModalOpen,
  Refresh,
  ExcelFile,
  PdfFile,
  WordFile,
  PowerPointFile,
  EngineFile,
  TutorialFile,
  HtmlFile,
  ImageFile,
  ProductStatusProductionOutlined,
  ProductStatusArchiveOutlined,
  ProductStatusDesignOutlined,
  FolderOpen,
  FolderDefault,
  Language,
  Logout,
  Sync,
  Desktop,
  Tablet,
  Edit,
  PlusColumn,
  DoubleLeftArrow,
  Cockpit,
  Tester,
  Alian,
  LeftArrow,
  ServiceDocIcon,
  TestingCentreIcon,
  VersionOverviewIcon,
  ViewLogsIcon,
  BookmarkIcon,
  GridView,
  ListView,
  PlusIconCircularFill,
  PlusIconCircularOutlined,
  CloseIcon,
  InfoCircle,
  CaretDownFilled,
  CaretUpFilled,
  CaretUpDownFilled,
  Pencil,
  Star,
  Check,
  WarningIcon,
  CircleCheck,
  FullscreenExpand,
  FullscreenExit,
  CurlCommandError,
  Link,
  Empty,
  Calender,
  Expanded,
  WarmIconRed,
  Reduce,
  InfoCircleFill,
  Right,
  Left,
  SixDotLine,
  Preview,
  ViewEye,
  UpCaret,
  DownCaret,
  FontSize,
  Bold,
  Italic,
  Underline,
  FontColor,
  AlignLeft,
  AlignCenter,
  AlignRight,
  Code,
  LinkUrl,
  UnLinkUrl,
  OrderedList,
  UnOrderedList,
  Undo,
  Warning,
  Error,
  SuccessChecked,
  DeleteIcon,
  CautionIcon,
  Search,
  DefaultCoverImage,
  DangerDark,
  CircleSuccessCheck,
  Download,
  Bug,
  SupportIcon,
  InfoCircleOutlined,
  UserGuide,
};
