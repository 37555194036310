import { getConfig } from '../helpers';
import makeRequest from '../helpers/make-request';

const getBookmarks = (request: DTO.GetBookmarksActionPropType) => {
  const { page, pageSize, sort, search } = request;

  const url = `${getConfig().excelEngineDomain}/api/v1/bookmark/List`;

  return makeRequest<DTO.GetBookmarksResponse>('POST', url, {
    page,
    pageSize,
    sort,
    search,
  });
};

const getBookmark = (id: string) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/bookmark/Get/${id}`;

  return makeRequest<DTO.GetBookmarkResponse>('GET', url);
};

const createBookmark = (request: DTO.CreateBookmarkActionPropType) => {
  const { bookmarkUrl, data, name, productName } = request;

  const url = `${getConfig().excelEngineDomain}/api/v1/bookmark/SaveBookMark`;

  return makeRequest<DTO.CreateBookmarkResponse>('POST', url, {
    bookmarkUrl,
    data,
    name,
    productName,
  });
};

const renameBookmark = (request: DTO.RenameBookmarkRequestProp) => {
  const { id, name } = request;

  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/bookmark/UpdateBookMarkName`;

  return makeRequest<DTO.CreateBookmarkResponse>('PUT', url, {
    id,
    name,
  });
};

const deleteBookmark = (id: string) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/bookmark/Delete/${id}`;

  return makeRequest<DTO.CreateBookmarkResponse>('DELETE', url);
};

const getBookmarksByUrl = (request: DTO.GetBookmarkByUrlActionPropTypes) => {
  const { bookmarkUrl } = request;
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/bookmark/GetBookMarkByUrl`;

  return makeRequest<DTO.GetBookmarksResponse>('POST', url, {
    bookmarkUrl,
  });
};

export const BookmarkService = {
  getBookmarks,
  getBookmark,
  createBookmark,
  renameBookmark,
  deleteBookmark,
  getBookmarksByUrl,
};
