import { addDocument } from './adddocument.reducer';
import { addEngine } from './addengine.reducer';
import { addFile } from './addFile.reducer';
import { alertMessage } from './alert.reducers';
import { anonymousUser } from './anonymousUser.reducer';
import { apiTester } from './apiTester.reducer';
import { auth } from './auth.reducer';
import { backgroundJobsReducer } from './backgroundJobs.reducer';
import { categoryManager } from './categoryManager.reducer';
import { bookmark } from './bookmark.reducer';
import { clientManager } from './clientManager.reducer';
import { dataDictionaryReducer } from './dataDictionary.reducer';
import { dmlFile } from './dmlFile.reducer';
import { downloadLogs } from './downloadLogs.reducer';
import { engineViewLogs } from './engineViewLogs.reducer';
import { entityPermission } from './entityPermission.reducer';
import { fileManager } from './fileManager.reducer';
import { formBuilder } from './formBuilder.reducer';
import { ingenium } from './ingenium.reducer';
import { insights } from './insights.reducer';
import { language } from './language.reducer';
import { masterReferences } from './masterReferences.reducer';
import { performanceMetrics } from './performanceMetrics.reducer';
import { permissionsReducer } from './permissions.reducer';
import { productChanges } from './productChanges.reducer';
import { productDocs } from './productDocs.reducer';
import { productEngines } from './productEngines.reducer';
import { productRoadMap } from './productRoadMap.reducer';
import { productsState as products } from './products.reducer';
import { productsBoard } from './productsBoard.reducer';
import { sdkGenerator } from './sdkgenerator.reducer';
import { shareProducts } from './shareProducts.reducer';
import { system } from './system.reducer';
import { templateManager } from './templateManager.reducer';
import { testbeds } from './testBed.reducer';
import { theme } from './theme.reducer';
import { tutorial } from './tutorial.reducer';
import { userState as user } from './user.reducer';
import { userManager } from './userManager.reducer';

const rootReducer = {
  auth,
  user,
  language,
  theme,
  addEngine,
  userManager,
  products,
  shareProducts,
  productEngines,
  engineViewLogs,
  apiTester,
  formBuilder,
  addDocument,
  productDocs,
  productChanges,
  fileManager,
  addFile,
  references: masterReferences,
  system,
  performanceMetrics,
  tutorial,
  productsBoard,
  clientManager,
  productRoadMap,
  alertMessage,
  permissions: permissionsReducer,
  anonymousUser,
  backgroundJobs: backgroundJobsReducer,
  dmlFile,
  dataDictionaryReducer,
  categoryManager,
  testbeds,
  templateManager,
  sdkGenerator,
  bookmark,
  ingenium,
  downloadLogs,
  entityPermission,
  insights,
};

export default rootReducer;
