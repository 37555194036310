import React, { FC, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { colorHexToRgba } from '../../../helpers';

export const ScrollCss = css`
  ::-webkit-scrollbar {
    background-color: ${({ theme }) => theme.color.white};
    width: 15px;
    height: 15px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.dark20};
    border-radius: 20px;
    border: 4px solid transparent;
    background-clip: content-box;
    min-height: 32px;
    min-width: 32px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.color.dark20};
    border-radius: 20px;
    border: 4px solid transparent;
    background-clip: content-box;
  }
`;

export const StyledLowerBoxShadow = styled.div<{
  absolute?: boolean;
  bottomPosition?: string;
}>`
  && {
    position: ${({ absolute }) => (absolute ? 'absolute' : 'sticky')};
    bottom: ${({ bottomPosition }) => bottomPosition || '0'};
    width: 100%;
    height: 23px;
    background: linear-gradient(
        180deg,
        ${({ theme }) => theme.color.white} 0%,
        ${({ theme }) => colorHexToRgba(theme.color.white, 0)} 100%
      ),
      ${({ theme }) => theme.color.boxShadow};
    mix-blend-mode: multiply;
    opacity: 0.4;
    border-radius: 0px 0px 4px 4px;
    border: 1px solid ${({ theme }) => theme.color.dark20} !important;
    border-top: none !important;
  }
`;

export const StyledUpperBoxShadow = styled.div`
  && {
    position: absolute;
    top: 0;
    width: 100%;
    background: linear-gradient(
        180deg,
        ${({ theme }) => theme.color.white} 0%,
        ${({ theme }) => colorHexToRgba(theme.color.white, 0)} 100%
      ),
      ${({ theme }) => theme.color.boxShadow};
    opacity: 0.2;
    height: 12px;
    mix-blend-mode: multiply;
    border-radius: 0px 0px 4px 4px;
    transform: rotate(-180deg);
  }

  &.relative {
    position: relative;
  }
`;

const Container = styled.div`
  overflow: auto;
  ${ScrollCss}
  padding: 10px;
  &.flex-fill {
    flex: 1;
  }
`;

const ScrollContainer: FC<{
  className?: string;
  flexFill?: boolean;
  onScroll?: (bottomOffset: number) => void;
}> = ({ children, className, onScroll, flexFill = false }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!onScroll || !containerRef.current) {
      return () => {};
    }

    const containerElement = containerRef.current;

    const onContainerScroll = () => {
      const { clientHeight, scrollTop, scrollHeight } = containerElement;

      onScroll(scrollHeight - (clientHeight + scrollTop));
    };

    containerElement.addEventListener('scroll', onContainerScroll);

    return () => {
      onScroll &&
        containerElement &&
        containerElement.removeEventListener('scroll', onContainerScroll);
    };
  }, [onScroll]);

  return (
    <Container
      data-testid="scroll-container"
      ref={containerRef}
      className={`scroll-container ${className}${flexFill ? ' flex-fill' : ''}`}
    >
      {children}
    </Container>
  );
};

export { ScrollContainer };
