import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Action } from 'redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThunkDispatch } from 'redux-thunk';
import { LanguageActions } from './actions';
import App from './containers/App';
import { persistor, store } from './helpers/store';
import GlobalProvider from './providers/GlobalProvider';
import { getConfig } from './helpers';

const history = createBrowserHistory();
const config = getConfig();

Sentry.init({
  dsn: config.sentryDsn,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 1.0,
  attachStacktrace: true,
  release: config.version,
  environment: config.sentryEnvironment,
  ignoreErrors: ['top.GLOBALS', 'Object.dispatch'],
  denyUrls: ['localhost:'],
});

const Root = () => {
  const onBeforeLift = () => {
    const {
      language: { currentLanguage },
    } = store.getState();

    const appDispatch: ThunkDispatch<STATES.AppState, null, Action> =
      store.dispatch;

    return appDispatch(LanguageActions.loadMessages(currentLanguage));
  };

  return (
    <Provider store={store}>
      <PersistGate
        onBeforeLift={onBeforeLift}
        loading={<div>&nbsp;</div>}
        persistor={persistor}
      >
        <GlobalProvider>
          <App />
        </GlobalProvider>
      </PersistGate>
    </Provider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
