import React, { memo, NamedExoticComponent, useEffect } from 'react';
import styled from 'styled-components';
import { IconSvg } from './Icons/IconSvg';

interface AlertProps {
  icon?: string;
  iconTheme?: 'filled' | 'outlined' | 'twoTone';
  type?: 'success' | 'info' | 'warning' | 'error';
  message?: string;
  description?: string;
  className?: string;
  visible?: boolean;
  duration?: number;
  handleClose?: () => void;
}

const StyledAlert = styled.div`
  && {
    background: ${({ theme }) => theme.color.white};
    text-align: center;
    padding: 7px 15px 7px 64px;
    margin-bottom: 20px;
    border: 2px solid #000;
    position: relative;

    .alert-icon {
      position: absolute;
      left: 20px;
      top: 13px;
      font-size: 35px;
      line-height: 35px;
      color: inherit;
    }

    .alert-message {
      font-weight: normal;
    }

    .alert-description {
      font-weight: normal;
    }

    span {
      line-height: 23px;
      margin-bottom: 0px;
    }
  }
`;

const CommonAlert: NamedExoticComponent<AlertProps> = memo(
  ({
    icon,
    iconTheme,
    type,
    message,
    description,
    className,
    visible = true,
    duration,
    handleClose,
  }) => {
    useEffect(() => {
      if (visible && duration) {
        setTimeout(() => {
          handleClose && handleClose();
        }, duration);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    return (
      <>
        {visible && (
          <StyledAlert className={className} data-type={type}>
            <div className="alert-icon">
              <IconSvg type={icon} theme={iconTheme} />
            </div>
            <div className="alert-content">
              <div className="alert-message">{message}</div>
              <div className="alert-description">{description}</div>
            </div>
          </StyledAlert>
        )}
      </>
    );
  }
);

const StyledCustomAlert = styled(CommonAlert)`
  border: 1px solid ${({ theme }) => theme.color.success} !important;
  background: ${({ theme }) => theme.color.white};
  border-radius: 4px;
  position: absolute !important;
  top: 90px;
  right: 40% !important;
  z-index: 1000;
  width: 325px !important;
  display: flex;
  padding: 0 !important;

  .alert-icon {
    position: unset !important;
    left: unset !important;
    right: unset !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    background: ${({ theme }) => theme.color.success};
    padding-left: 11px;
    padding-right: 11px;

    i {
      font-size: 14px;
      color: ${({ theme }) => theme.color.white};
    }
  }

  .alert-content {
    padding: 10px 8px !important;
  }

  .alert-message {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    color: ${({ theme }) => theme.color.dark80};
  }
`;

export { CommonAlert, StyledCustomAlert };
