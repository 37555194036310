import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import React, { memo, NamedExoticComponent } from 'react';
import styled from 'styled-components';

const StyledCheckbox = styled(Checkbox.Group)`
  .ant-checkbox-wrapper-checked {
    background-color: ${({ theme }) => theme.color.purple20} !important;
    border: 1px solid ${({ theme }) => theme.color.purple} !important;
    border-radius: 4px;
  }
  .ant-checkbox-wrapper {
    padding: 10px 20px;
    border: 1px solid transparent;
    .ant-checkbox-checked .ant-checkbox-inner {
      border-color: ${({ theme }) => theme.color.purple} !important;
    }

    .ant-checkbox-inner::after {
      background-color: ${({ theme }) => theme.color.purple} !important;
    }
  }
`;

export interface CustomCheckboxItem {
  label: string;
  value: string;
}

export interface CustomCheckboxProps {
  className?: string;
  items: CustomCheckboxItem[];
  values?: string[];
  onChange?: (key: CheckboxValueType[]) => void;
}

const CustomCheckbox: NamedExoticComponent<CustomCheckboxProps> = memo(
  ({ className, items, onChange, values }) => {
    return (
      <StyledCheckbox
        className={className}
        options={items}
        value={values}
        onChange={onChange}
      />
    );
  }
);

export { CustomCheckbox };
