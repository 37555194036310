import { getConfig } from '../helpers/config';
import makeRequest from '../helpers/make-request';

const getReleaseDetails = () => {
  const url = `${getConfig().excelEngineDomain}/api/v1/release`;
  return makeRequest<DTO.ReleaseDetailsResponse>('GET', url);
};

const getLookupData = (isCached = true) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/lookup/${
    isCached ? 'GetLookupData' : 'GetLookupDataNonCached'
  }`;
  return makeRequest<DTO.GetLookupDataResponse>('GET', url);
};

const getProductDocumentMetadata = () => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/config/GetProductDocumentMetadata`;
  return makeRequest<DTO.GetProductDocMetadataResponse>('GET', url);
};

const getDiagnoseDetails = () => {
  const url = `${getConfig().excelEngineDomain}/api/Diagnostic/get`;
  return makeRequest<DTO.DiagnoseDetailsResponse>('GET', url);
};

const clearCacheMemory = () => {
  const url = `${getConfig().excelEngineDomain}/api/Diagnostic/ClearCache`;
  return makeRequest<DTO.DiagnoseDetailsResponse>('POST', url);
};

const clearApplicationMemory = () => {
  const url = `${getConfig().excelEngineDomain}/api/Diagnostic/ClearGC`;
  return makeRequest<DTO.DiagnoseDetailsResponse>('POST', url);
};

export const SystemService = {
  getDiagnoseDetails,
  getReleaseDetails,
  getLookupData,
  clearCacheMemory,
  clearApplicationMemory,
  getProductDocumentMetadata,
};
