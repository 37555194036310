const restructureMasterRefPayloadData = (
  data: DTO.MasterReferenceVersionDetail[]
): DTO.MasterReferenceDetail[] => {
  // This restructures the master ref versions payload data
  // to fit the structure of data displayed on the MasterReferenceTable
  return data.map(fileVersion => ({
    id: fileVersion.id,
    createdDate: fileVersion.createdDate,
    lastModifiedDate: fileVersion.lastModifiedDate,
    owner: fileVersion.metadata.owner,
    effectiveStartDate: fileVersion.metadata.effectiveStartDate,
    effectiveEndDate: fileVersion.metadata.effectiveEndDate,
    originalFileName: fileVersion.metadata.originalFileName,
    serviceName: fileVersion.metadata.serviceName,
    revision: fileVersion.metadata.revision,
    referenceList: fileVersion.metadata.referenceList,
    downStreamEngines: null,
  }));
};

export { restructureMasterRefPayloadData };
