import moment from 'moment';
import { IntlShape } from 'react-intl';

const formatToLongDateTime = (inp: moment.MomentInput) =>
  moment(inp)
    .utc()
    .format('YYYY-MM-DD HH:mm UTC');

const formatToDateTime = (inp: moment.MomentInput, format?: string) =>
  moment(inp)
    .utc()
    .format(format || 'YYYY-MM-DD HH:mm');

const formatToShortDateTime = (inp?: moment.MomentInput, format?: string) =>
  moment(inp, format)
    .utc()
    .format('YYYY-MM-DD');

const formatToLongDateTimeMoment = (inp: Date) =>
  moment.utc(inp, 'YYYY-MM-DD, HH:mm:ss UTC');

const customFormatToDateTime = (
  inp?: moment.MomentInput,
  outputFormat?: string,
  format?: string
) =>
  moment(inp, format)
    .utc()
    .format(outputFormat);

const validateMasterReferenceEffectiveDate = (
  tmpActivePeriod: DTO.ActivePeriod,
  tmpWarnings: string[],
  intl: IntlShape
) => {
  if (
    tmpActivePeriod?.referenceRangeMetadatas &&
    tmpActivePeriod?.dateTypeOption &&
    (tmpActivePeriod?.referenceRangeMetadatas.length ?? 0) > 0
  ) {
    let smallestMasterDate: Date | undefined;
    let highestMasterDate: Date | undefined;

    tmpActivePeriod?.referenceRangeMetadatas.forEach(masterList => {
      if (
        (smallestMasterDate &&
          masterList.effectiveStartDate &&
          moment(smallestMasterDate).isAfter(masterList.effectiveStartDate)) ||
        !smallestMasterDate
      ) {
        smallestMasterDate = moment(masterList.effectiveEndDate).toDate();
      }
      if (
        (highestMasterDate &&
          masterList.effectiveStartDate &&
          moment(highestMasterDate).isBefore(masterList.effectiveEndDate)) ||
        !highestMasterDate
      ) {
        highestMasterDate = moment(masterList.effectiveEndDate).toDate();
      }
    });

    if (
      tmpActivePeriod?.dateTypeOption === 'EDIT_EFFECTIVE_DATE' &&
      (!moment(tmpActivePeriod.effectiveEndDate).isSame(
        highestMasterDate,
        'minutes'
      ) ||
        !moment(tmpActivePeriod.effectiveStartDate).isSame(
          smallestMasterDate,
          'minutes'
        ))
    ) {
      if (
        (moment(tmpActivePeriod.effectiveStartDate).isAfter(
          smallestMasterDate,
          'minutes'
        ) ||
          moment(tmpActivePeriod.effectiveStartDate).isSame(
            smallestMasterDate,
            'minutes'
          )) &&
        (moment(tmpActivePeriod.effectiveEndDate).isBefore(
          highestMasterDate,
          'minutes'
        ) ||
          moment(tmpActivePeriod.effectiveEndDate).isSame(
            highestMasterDate,
            'minutes'
          ))
      ) {
        tmpWarnings.push(
          intl.formatMessage({
            id: 'EngineUploader.form.masterReference.warning.engine',
          })
        );
      } else if (
        (moment(tmpActivePeriod.effectiveStartDate).isBefore(
          smallestMasterDate,
          'minutes'
        ) ||
          moment(tmpActivePeriod.effectiveStartDate).isSame(
            smallestMasterDate,
            'minutes'
          )) &&
        (moment(tmpActivePeriod.effectiveEndDate).isAfter(
          highestMasterDate,
          'minutes'
        ) ||
          moment(tmpActivePeriod.effectiveEndDate).isSame(
            highestMasterDate,
            'minutes'
          ))
      ) {
        tmpWarnings.push(
          intl.formatMessage({
            id: 'EngineUploader.form.masterReference.warning.master',
          })
        );
      } else if (
        moment(tmpActivePeriod.effectiveEndDate).isBefore(
          smallestMasterDate,
          'minutes'
        )
      ) {
        tmpWarnings.push(
          intl.formatMessage({
            id: 'EngineUploader.form.masterReference.warning.notmatch',
          })
        );
      } else {
        tmpWarnings.push(
          intl.formatMessage({
            id: 'EngineUploader.form.masterReference.warning.common',
          })
        );
      }
    }
  }
};
export {
  formatToDateTime,
  formatToLongDateTime,
  formatToLongDateTimeMoment,
  formatToShortDateTime,
  validateMasterReferenceEffectiveDate,
  customFormatToDateTime,
};
