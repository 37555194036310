import React, { FC, useEffect, memo, useRef } from 'react';
import GanttJS, { Gantt } from 'frappe-gantt';
import styled from 'styled-components';
import { colorHexToRgba } from '../../helpers';
import { TimerConstants } from '../../constants';

interface CustomGantt extends Gantt.Task {
  customBar?: string;
  barLabelPadding?: number;
}

export interface FrappeGanttProps {
  tasks: CustomGantt[];
  onViewChange?: (mode: Gantt.viewMode) => void;
  onDateChange?: (task: Gantt.Task, start: string, end: string) => void;
  customPopupHtml?: (task: Gantt.Task) => string;
  viewMode: Gantt.viewMode;
  barHeight?: number;
  enablePadding?: boolean;
  chartStart?: Date;
  chartEnd?: Date;
  dateFormat?: string;
  language?: string;
  showYear?: boolean;
}

const getColumnWidth = (mode?: Gantt.viewMode): number => {
  switch (mode) {
    case 'Week':
      return 140;
    case 'Quarter':
      return 430;
    case 'Day':
    case 'Half Day':
    case 'Quarter Day':
      return 38;
    case 'Month':
    case 'Year':
      return 200;
    default:
      return 200;
  }
};

const GanttChart = styled.svg<{ viewMode?: Gantt.viewMode }>`
  && {
    background: ${({ theme }) => colorHexToRgba(theme.color.pearl, 0.4)};
    .date {
      .lower-text {
        font-size: 16px;
        fill: ${({ theme }) => theme.color.dark};
      }
      .upper-text {
        font-size: 16px;
        fill: ${({ theme }) => theme.color.dark};
      }
    }

    .grid-row {
      fill: none !important;
    }

    .grid-header {
      fill: none !important;
    }

    .inactive-version {
      .bar-progress {
        fill: none !important;
      }
      .bar {
        fill: ${({ theme }) => theme.color.purple40} !important;
        transform: translateY(4px);

        :hover,
        :visited,
        :active,
        :focus {
          fill: ${({ theme }) => theme.color.purple40} !important;
        }
      }
      .active {
        fill: ${({ theme }) => theme.color.purple40} !important;
      }
      .bar-label {
        font-weight: bold;
        font-size: 14px;
        transform: translateY(4px);
        fill: ${({ theme }) => theme.color.dark};

        &.big {
          fill: ${({ theme }) => theme.color.dark} !important;
        }
      }
      .menu-toggler-dot {
        fill: ${({ theme }) => theme.color.dark};
      }
      .grid-header {
        fill: none;
      }
      .grid-row {
        fill: none;
      }
    }

    .grid-header-stroke {
      stroke-width: 7px !important;
      stroke: ${({ theme }) => theme.color.purple};
      fill: none;
    }

    .grid-header-stroke-light {
      stroke-width: 7px !important;
      stroke: ${({ theme }) => theme.color.purple};
      fill: none;
      stroke-dasharray: ${({ viewMode }) => getColumnWidth(viewMode)};
      /* stroke-width: 5;  */
    }

    .active-version {
      .bar-progress {
        fill: none !important;
      }
      .bar {
        fill: ${({ theme }) => theme.color.purple} !important;
        transform: translateY(4px);

        :hover,
        :visited,
        :active,
        :focus {
          fill: ${({ theme }) => theme.color.purple} !important;
        }
      }
      .active {
        fill: ${({ theme }) => theme.color.purple} !important;
      }
      .bar-label {
        font-weight: bold;
        transform: translateY(4px);
        font-size: 14px;
        fill: ${({ theme }) => theme.color.dark};

        &.big {
          fill: ${({ theme }) => theme.color.dark} !important;
        }
      }
      .menu-toggler-dot {
        fill: ${({ theme }) => theme.color.dark};
      }
      .grid-header {
        fill: none;
      }
      .grid-row {
        fill: none;
      }
    }

    .gant-style {
      .bar-progress {
        fill: none !important;
      }
      .bar {
        fill: ${({ theme }) => theme.color.azure};
        transform: translateY(4px);

        :hover,
        :active,
        :focus {
          fill: ${({ theme }) => theme.color.dark};
        }
      }
      .bar-label {
        font-weight: bold;
        transform: translateY(4px);
        font-size: 14px;
        fill: ${({ theme }) => theme.color.white};

        &.big {
          fill: ${({ theme }) => theme.color.dark} !important;
        }
      }
      .menu-toggler-dot {
        fill: ${({ theme }) => theme.color.white};
      }
      .grid-header {
        fill: none;
      }
      .grid-row {
        fill: none;
      }
    }
    .gant-style-nth-child {
      .bar-progress {
        fill: none !important;
      }
      .bar {
        fill: ${({ theme }) => theme.color.azure2};
        transform: translateY(4px);

        :hover,
        :active,
        :focus {
          fill: ${({ theme }) => theme.color.dark};
        }
      }
      .bar-label {
        font-weight: bold;
        font-size: 14px;
        transform: translateY(4px);

        fill: ${({ theme }) => theme.color.dark};
      }
      .menu-toggler-dot {
        fill: ${({ theme }) => theme.color.dark};
      }
      .grid-header {
        fill: none;
      }
      .grid-row {
        fill: none;
      }
      :hover,
      &.active {
        .bar-label,
        .menu-toggler,
        .menu-toggler-dot {
          fill: ${({ theme }) => theme.color.white} !important;

          &.big {
            fill: ${({ theme }) => theme.color.dark} !important;
          }
        }
      }
    }
  }
`;

const FrappeGantt: FC<FrappeGanttProps> = memo(
  ({
    onViewChange,
    viewMode,
    tasks,
    barHeight,
    onDateChange,
    customPopupHtml,
    enablePadding,
    chartStart,
    chartEnd,
    language = 'en',
    dateFormat = TimerConstants.DATE_FORMAT,
    showYear,
  }) => {
    const gantt = useRef<GanttJS | null>(null);
    const tasksRef = useRef<Gantt.Task[]>(tasks);
    const viewModeRef = useRef<Gantt.viewMode>(viewMode);

    useEffect(() => {
      gantt.current = new GanttJS('#gantt', tasksRef.current, {
        on_view_change: onViewChange,
        bar_height: barHeight,
        on_date_change: onDateChange,
        bar_corner_radius: 8,
        padding: 4,
        header_height: 70,
        custom_popup_html: customPopupHtml,
        column_width: 20,
        step: 2,
        enable_padding: enablePadding,
        chart_start: chartStart,
        chart_end: chartEnd,
        date_format: dateFormat,
        language,
        view_mode: viewModeRef.current,
        show_year: showYear,
      });
    }, [
      viewMode,
      onViewChange,
      barHeight,
      onDateChange,
      customPopupHtml,
      enablePadding,
      chartStart,
      chartEnd,
      dateFormat,
      language,
      showYear,
    ]);

    useEffect(() => {
      gantt.current && gantt.current.refresh(tasks);
      tasksRef.current = tasks;
    }, [tasks]);

    useEffect(() => {
      gantt.current && gantt.current.change_view_mode(viewMode);
      viewModeRef.current = viewMode;
    }, [viewMode]);

    return <GanttChart viewMode={viewMode} id="gantt" />;
  }
);

export { FrappeGantt };
