import queryString from 'query-string';
import ClientOAuth2 from 'client-oauth2';
import jwt from 'jsonwebtoken';
import jwksClient, { CertSigningKey, RsaSigningKey } from 'jwks-rsa';
import { UserConstants } from '../constants';
import { getConfig } from './config';
import { ApiError } from './errors';
import { getValidRoleAndGroup } from './get-valid-role-group';

export const getRedirectUrl = (issuerConfig: DTO.IssuerConfiguration) => {
  const params = new URLSearchParams(window.location.search);
  const redirectUrlParam = params.get('return_url');
  let redirectUri = `${window.location.origin}${getConfig().redirectUrl}`;
  if (issuerConfig.authType !== 'AD') {
    redirectUri += `?return_url=${(
      redirectUrlParam ??
      window.location.href.replace(window.location.origin, '')
    ).replace(/ /g, '@!@')}`;
  }
  return redirectUri;
};

export const getUserAuth = (issuerConfiguration: DTO.IssuerConfiguration) => {
  const redirectUrl = getRedirectUrl(issuerConfiguration);
  const oAuthSetting = {
    clientId: issuerConfiguration.clientId,
    clientSecret: undefined,
    authorizationUri: issuerConfiguration.authUri,
    accessTokenUri: issuerConfiguration.tokenUri,
    redirectUri: redirectUrl,
    scopes: issuerConfiguration.scope?.split(' ') ?? [],
  };
  return new ClientOAuth2(oAuthSetting);
};

export const decodeToken = async (
  issuerConfig: DTO.IssuerConfiguration,
  token: string,
  verify = false
) => {
  if (verify) {
    // Use the jwks_uri to get public key
    // https://keycloak.dev.coherent.com.hk/auth/realms/coherent/.well-known/openid-configuration
    const client = jwksClient({
      jwksUri: issuerConfig.jwksUri ?? '',
    });

    const getPublicKey = (header, callback) => {
      client.getSigningKey(
        header.kid,
        (_, key: CertSigningKey | RsaSigningKey) => {
          const signingKey =
            (key as CertSigningKey)?.publicKey ||
            (key as RsaSigningKey)?.rsaPublicKey;
          callback(null, signingKey);
        }
      );
    };

    const decodedJwt = await new Promise((resolve, reject) => {
      jwt.verify(
        token,
        getPublicKey,
        {
          algorithms: ['RS256'],
          ignoreExpiration: false,
        },
        (decodeError, decodedValue) => {
          if (decodeError) {
            reject(decodeError);
          }
          resolve(decodedValue);
        }
      );
    });

    return decodedJwt as DTO.UserJWTData;
  }

  return jwt.decode(token) as DTO.UserJWTData;
};

export const isAdmin = (decodedJwt: DTO.UserJWTData) => {
  const role =
    decodedJwt &&
    decodedJwt.groups &&
    getValidRoleAndGroup(decodedJwt.groups).role;

  return (!!role && role === UserConstants.ROLE_SUPERVISOR) || true;
};

export const isSuperuser = (
  decodedJwt: DTO.UserJWTData,
  issuerConfig: DTO.IssuerConfiguration | null
) => {
  return (
    decodedJwt &&
    ((issuerConfig &&
      issuerConfig.superUserKey &&
      decodedJwt[issuerConfig.superUserKey]) ||
      decodedJwt.is_realm_admin)
  );
};

export const getDisplayName = (
  decodedJwt: DTO.UserJWTData,
  issuerConfig: DTO.IssuerConfiguration | null
) => {
  return (
    decodedJwt &&
    ((issuerConfig &&
      issuerConfig.displayNameKey &&
      decodedJwt[issuerConfig.displayNameKey]) ||
      decodedJwt.name)
  );
};

export const getUserName = (
  decodedJwt: DTO.UserJWTData,
  issuerConfig: DTO.IssuerConfiguration | null
) => {
  return (
    decodedJwt &&
    ((issuerConfig &&
      issuerConfig.userNameKey &&
      decodedJwt[issuerConfig.userNameKey]) ||
      decodedJwt.preferred_username)
  );
};

export const getUserEmailId = (
  decodedJwt: DTO.UserJWTData,
  issuerConfig: DTO.IssuerConfiguration | null
) => {
  return (
    decodedJwt &&
    ((issuerConfig &&
      issuerConfig.emailKey &&
      decodedJwt[issuerConfig.emailKey]) ||
      decodedJwt.preferred_username)
  );
};

export const getLogoutUrl = (
  issuerConfig: DTO.IssuerConfiguration | null,
  returnUrl?: string
) => {
  if (!returnUrl && window.location.search) {
    const qss = queryString.parse(window.location.search);
    returnUrl = qss.return_url as string;
  }
  if (!returnUrl) {
    returnUrl = window.location.href.replace(window.location.origin, '');
  }
  if (issuerConfig && issuerConfig.logoutUri) {
    const tenant = localStorage.getItem('Tenant') || getConfig().defaultTenant;
    if (issuerConfig.authType === 'AD') {
      return `${issuerConfig.logoutUri}?redirectUrl=${window.location.origin +
        process.env.REACT_APP_PUBLIC_URL}`;
    }
    return `${issuerConfig.logoutUri}?redirect_uri=${encodeURIComponent(
      `${window.location.origin + process.env.REACT_APP_PUBLIC_URL}?${
        tenant ? `tenant=${tenant}&` : ''
      }return_url=${`${returnUrl ||
        window.location.href
          .replace(
            window.location.origin + process.env.REACT_APP_PUBLIC_URL,
            ''
          )
          .replace(/ /g, '@!@')}`}`
    )}`;
  }
  return undefined;
};

export const refresh = async (store): Promise<string | undefined> => {
  const state = store.getState();
  const { userAuth } = store.getState().auth;
  const refreshToken = userAuth && userAuth.refresh_token;
  const issuerConfig = state.auth.issuerConfig;
  if (refreshToken && issuerConfig && userAuth) {
    const userOAuth = getUserAuth(issuerConfig);
    const token = userOAuth.createToken(userAuth.id_token, refreshToken, {});
    let updatedUser: ClientOAuth2.Token | null = null;
    try {
      updatedUser = await token.refresh();
    } catch (error) {
      // in case of error when refresh token is already used (happens when after token expiration UI calls multiple APIs at the same time.)
      updatedUser = null;
    }
    if (!updatedUser) {
      const logOutUrl = getLogoutUrl(issuerConfig);
      localStorage.removeItem('Tenant');
      store.dispatch({
        type: UserConstants.LOGOUT,
        payload: {
          logOutUrl: logOutUrl ?? '',
        },
      });

      throw new ApiError({
        error_code: UserConstants.REFRESH_TOKEN_LIMIT_REACHED,
      });
    }
    const newIdToken = updatedUser.accessToken;
    const newRefreshToken = updatedUser.refreshToken;
    store.dispatch({
      type: UserConstants.SET_ID_TOKEN,
      payload: {
        id_token: newIdToken,
        refresh_token: newRefreshToken,
      },
    });

    return newIdToken;
  }

  return undefined;
};
