import { Radio, Row, Tag } from 'antd';
import { Gantt } from 'frappe-gantt';
import React, { FC, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
// eslint-disable-next-line import/no-cycle
import {
  DropdownSelect,
  FavoritesSwitch,
  IconSvg,
  ScrollCss,
  SearchInput,
} from '..';
import { colorHexToRgba } from '../../../helpers';
import { AddButton } from '../AddtButton';

const StyledFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.pearl40};
  padding: 8px 24px 0px 24px;

  .ant-select-dropdown.ant-select-dropdown--single {
    ul {
      ${ScrollCss}

      li.ant-select-dropdown-menu-item {
        padding-left: 0;

        svg {
          width: 24px;
          height: 24px;
          margin-left: 8px;
        }

        span {
          font-size: 14px;
          margin-left: 8px !important;
        }
      }
    }
  }

  .row {
    display: flex;
    justify-content: space-between;

    div:first-child {
      flex: 1;
    }

    div {
      align-items: center;
    }
  }

  .filter-panel-wrap {
    min-width: 216px !important;

    .ant-col-6 {
      width: 216px !important;
    }
  }

  @media (max-width: 1150px) {
    .filter-panel-wrap {
      min-width: 180px !important;

      .ant-col-6 {
        width: 180px !important;
      }
    }
  }
`;

const StyledSearchInput = styled(props => <SearchInput {...props} />)`
  width: 250px !important;
  height: 36px;
  margin-bottom: 8px !important;

  input {
    padding-left: 38px !important;
    border: 1px solid ${({ theme }) => theme.color.gray2} !important;

    :hover {
      border: 1px solid ${({ theme }) => theme.color.purple} !important;
    }
    :focus {
      border: 1px solid ${({ theme }) => theme.color.purple} !important;
    }
  }

  svg {
    font-size: 20px;
    color: ${({ theme }) => theme.color.purple} !important;
  }

  @media (max-width: 1150px) {
    width: 200px !important;
  }
`;

const StyledWrap = styled.div`
  display: flex;

  @media (max-width: 1150px) {
    flex-wrap: wrap;
  }
`;

const StyledDropdownSelect = styled(props => <DropdownSelect {...props} />)`
  && {
    margin-right: 23px;

    .ant-select-selection {
      margin-bottom: 8px;
      height: 36px;
      min-width: 216px;
      border: 1px solid ${({ theme }) => theme.color.gray2} !important;

      :focus {
        border: 1px solid ${({ theme }) => theme.color.purple} !important;
        box-shadow: 0 0 0 2px ${({ theme }) => theme.color.purple40};
      }

      :hover {
        border: 1px solid ${({ theme }) => theme.color.purple} !important;
      }
    }

    .dropdown-select-popup-container {
      margin-right: 8px;
    }

    .ant-select-selection__rendered {
      span,
      .ant-select-selection-selected-value {
        font-size: 14px !important;
        color: ${({ theme }) => theme.color.violet};
      }
    }

    .filter-icon {
      color: ${({ theme }) => theme.color.purple};
    }

    @media (max-width: 1150px) {
      width: 200px;
      margin-right: 8px;

      .ant-select-selection {
        width: 200px !important;
        min-width: 200px;
      }
    }
  }
`;

const StyledFavoritToggleWrap = styled.div<{ isChecked: boolean }>`
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-bottom: 8px;

  button {
    margin-left: 8px;
  }

  @media (max-width: 1150px) {
    margin-left: 0;
  }
`;

const StyledDropdownMultiSelect = styled(props => (
  <DropdownSelect {...props} />
))`
  ${ScrollCss}
  && {
    .ant-select-selection-selected-value {
      font-size: 14px;
    }
    height: 36px;
    min-width: 250px;
    margin-bottom: 8px;

    .ant-select-selection {
      height: 36px;
      min-width: 202px;
      min-height: 36px;
      border: 1px solid ${({ theme }) => theme.color.gray2} !important;

      :focus {
        border: 1px solid ${({ theme }) => theme.color.purple} !important;
        box-shadow: 0 0 0 2px ${({ theme }) => theme.color.purple40};
      }

      :hover {
        border: 1px solid ${({ theme }) => theme.color.purple} !important;
      }
    }

    .filter-icon {
      color: ${({ theme }) => theme.color.purple};
    }

    @media (max-width: 1150px) {
      min-width: 200px;

      .ant-select-selection {
        width: 200px;
      }
    }
    .ant-select-selection__placeholder {
      color: ${({ theme }) => theme.color.violet};
      font-size: 14px;
    }
  }
`;

const StyledViewTypeWrap = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: baseline !important;

  .radio-wrap {
    display: flex;
    align-items: center;
    margin-left: 40px;
    cursor: pointer;

    .ant-radio-checked .ant-radio-inner {
      border-color: ${({ theme }) => theme.color.purple} !important;
    }

    .ant-radio-inner::after {
      background-color: ${({ theme }) => theme.color.purple} !important;
    }
  }
  svg {
    margin-left: 8px;
  }
`;

export const StyledViewIconSvg = styled(props => <IconSvg {...props} />)<{
  selected?: boolean;
}>`
  background: ${({ theme, selected }) =>
    selected ? theme.color.purple : theme.color.white} !important;
  border: 1px solid ${({ theme }) => theme.color.purple} !important;
  width: 40px;
  padding: 12px;
  border-radius: 4px;
  margin-left: 8px;

  svg {
    color: ${({ theme, selected }) =>
      !selected ? theme.color.purple : theme.color.white} !important;
    margin: 0 !important;
    font-size: 15px !important;
  }
`;

export const StyledSelectDisplay = styled.div`
  background: white;
  margin-top: 6px;
  margin-bottom: 8px;
  border: 1px solid ${({ theme }) => theme.color.gray2};
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  display: flex;
  justify-content: space-between;

  div:first-child {
    display: flex;
    p {
      margin: 0;
      width: 103px;
      left: 0px;
      top: 0px;
      bottom: 0px;
      background: ${({ theme }) => theme.color.gray8};
      height: -webkit-fill-available;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      line-height: 20px;
      border-radius: 4px 0px 0px 4px;
      border-right: 1px solid ${({ theme }) => theme.color.dark20};
    }
  }
  div:last-child {
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;

    p {
      margin: 0;
      margin-right: 5px;
    }
  }
`;

export const StyledTagContainer = styled.div`
  float: right;
  display: flex;
  align-items: center;
`;

export const StyledTag = styled(Tag)<{ type: 'status' | 'category' }>`
  && {
    border-radius: 15px;
    height: 28px;
    line-height: 28px;
    padding: 0 15px;
    padding-right: 30px;
    position: relative;
    color: ${({ theme }) => theme.color.dark};
    border: none;
    font-size: 1rem;
    min-width: 100px;
    background: ${({ type, theme }) =>
      type === 'category'
        ? theme.color.purple20
        : colorHexToRgba(theme.vars.primaryColor, 0.3)};
    margin: 0 0 0 4px;

    .anticon {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      font-size: 16px !important;
      color: ${({ theme }) => colorHexToRgba(theme.color.dark, 0.4)};
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: ${({ theme }) => theme.color.gray9};
      height: 16px;
      width: 16px;
      margin-top: 6px;
      margin-right: 5px;

      svg {
        color: ${({ theme }) => theme.color.white};
        font-size: 10px;
      }
    }
  }
`;

const StyledAddButton = styled(AddButton)`
  margin-left: 10px;
  width: 185px;
  margin-bottom: 0px;
`;

export type viewtypeType = 'list' | 'grid';
type activeKeyType = 'sharedProducts' | 'kanbanBoard' | 'roadMap';

interface SortByOptionsTypes {
  [keys: string]: {
    key: string;
    sortBy: DTO.GetProductsRequest['sortBy'] | '-productName';
    sort: DTO.GetProductsRequest['sort'];
  };
}

export interface FilterBarProps {
  selectedItems: string[];
  handleSetSelectedItems: (props: string[]) => void;
  searchTerm: string;
  handleSetSearchTerm: (prop: string) => void;
  productCategories: DTO.ProductCategory[];
  categoryFilterText?: string;
  showFavorites: boolean;
  handleFavToggle: () => void;
  categoryIcons?: object;
  activeKey: 'sharedProducts' | 'kanbanBoard' | 'roadMap';
  handleSetActiveKey: (prop: activeKeyType) => void;
  viewType: 'list' | 'grid';
  handleSetViewType: (prop: viewtypeType) => void;
  sortByText?: string;
  filterRoadmapBy: Gantt.viewMode;
  handlelSetFilterRoadmapBy: (prop: Gantt.viewMode) => void;
  SortByOptions: SortByOptionsTypes;
  handleSortByChange: (prop: string) => void;
  onSelectFilterCategory: (prop: string) => void;
  className?: string;
  openNewProduct?: () => void;
}

const FilterBar: FC<FilterBarProps> = ({
  selectedItems,
  handleSetSelectedItems,
  searchTerm,
  handleSetSearchTerm,
  productCategories,
  categoryFilterText,
  showFavorites,
  handleFavToggle,
  categoryIcons,
  activeKey,
  viewType,
  handleSetViewType,
  sortByText,
  filterRoadmapBy,
  handlelSetFilterRoadmapBy,
  SortByOptions,
  handleSortByChange,
  onSelectFilterCategory,
  className,
  openNewProduct,
}) => {
  const intl = useIntl();
  const filterBarRef = useRef<HTMLDivElement>(null);

  const onViewModeChange = (viewMode: Gantt.viewMode) => {
    handlelSetFilterRoadmapBy(viewMode);
  };

  const filterTags = useMemo(() => {
    if (!selectedItems) {
      return null;
    }

    return selectedItems.map((value, index) => (
      <StyledTagContainer>
        <StyledTag
          closable
          type="category"
          visible={!!selectedItems}
          onClose={() => {
            const categories = [...selectedItems];
            categories.splice(index, 1);
            handleSetSelectedItems(categories);
          }}
        >
          {value}
        </StyledTag>
      </StyledTagContainer>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  return (
    <StyledFilterContainer ref={filterBarRef} className={className || ''}>
      <Row className="row">
        <StyledWrap>
          <StyledSearchInput
            data-testid="search-input"
            placeholder="ProductCatalogue.search.placeholder.productName"
            value={searchTerm}
            prefix
            onChange={value => handleSetSearchTerm(value)}
            size="large"
          />

          {activeKey === 'sharedProducts' && viewType === 'grid' && (
            <StyledDropdownSelect
              suffixIcon={
                <IconSvg type="CaretDownFilled" className="filter-icon" />
              }
              data-testid="sortby-select"
              size="large"
              width={202}
              value={sortByText}
              onChange={value => handleSortByChange(value as string)}
              placeholder="ProductCatalogue.dropdown.sort.placeholder"
              options={Object.values(SortByOptions).map(
                ({ key, sortBy: value }) => ({
                  key,
                  value: value || '',
                  text: key,
                })
              )}
            />
          )}

          <StyledDropdownMultiSelect
            suffixIcon={
              <IconSvg type="CaretDownFilled" className="filter-icon" />
            }
            showCheckbox
            size="large"
            width={216}
            value={categoryFilterText}
            className="multi-dropdown-select"
            onChange={value => onSelectFilterCategory(value as string)}
            placeholder="ProductCatalogue.dropdown.sort.placeholder.category"
            options={productCategories.map(({ key, value, icon }) => ({
              key,
              value: key,
              text: value,
              categoryIcon: icon,
              selected: selectedItems?.includes(value),
            }))}
            categoryIcons={categoryIcons}
            productCategories={productCategories}
          />

          <StyledFavoritToggleWrap isChecked={showFavorites}>
            {intl.formatMessage({ id: 'ProductCatalogue.favorite.products' })}
            <FavoritesSwitch
              checked={showFavorites}
              onChange={() => handleFavToggle()}
            />
          </StyledFavoritToggleWrap>
        </StyledWrap>
        <StyledViewTypeWrap>
          {activeKey === 'roadMap' ? (
            <>
              <div
                role="button"
                className="radio-wrap"
                tabIndex={0}
                onKeyDown={e => {
                  e.preventDefault();
                }}
                onClick={() => onViewModeChange('Month')}
              >
                <Radio checked={filterRoadmapBy === 'Month'} />
                {intl.formatMessage({ id: 'ProductCatalogue.monthly' })}
              </div>
              <div
                role="button"
                className="radio-wrap"
                tabIndex={0}
                onKeyDown={e => {
                  e.preventDefault();
                }}
                onClick={() => onViewModeChange('Quarter')}
              >
                <Radio checked={filterRoadmapBy === 'Quarter'} />
                {intl.formatMessage({ id: 'ProductCatalogue.quarterly' })}
              </div>
              <div
                role="button"
                className="radio-wrap"
                tabIndex={0}
                onKeyDown={e => {
                  e.preventDefault();
                }}
                onClick={() => onViewModeChange('Year')}
              >
                <Radio checked={filterRoadmapBy === 'Year'} />
                {intl.formatMessage({ id: 'ProductCatalogue.annual' })}
              </div>
            </>
          ) : (
            <>
              {openNewProduct && (
                <StyledAddButton
                  buttonLabel="ProductBoard.menu.addNewProduct"
                  onClick={openNewProduct}
                />
              )}
              <StyledViewIconSvg
                onClick={() => handleSetViewType('grid')}
                selected={viewType === 'grid'}
                type="GridView"
              />
              <StyledViewIconSvg
                onClick={() => handleSetViewType('list')}
                selected={viewType === 'list'}
                type="ListView"
              />
            </>
          )}
        </StyledViewTypeWrap>
      </Row>
      {!!selectedItems?.length && (
        <StyledSelectDisplay>
          <div>
            <p>{intl.formatMessage({ id: 'ProductCatalogue.activeFilter' })}</p>
            {filterTags}
          </div>
          <div
            role="button"
            onClick={() => handleSetSelectedItems([])}
            className="clear-all"
            tabIndex={0}
            onKeyDown={e => {
              e.preventDefault();
            }}
          >
            <p>{intl.formatMessage({ id: 'ProductCatalogue.clearAll' })}</p>
            <IconSvg type="CloseIcon" />
          </div>
        </StyledSelectDisplay>
      )}
    </StyledFilterContainer>
  );
};

export { FilterBar };
