import { PerformanceMetricsAction } from '../actions/types';
import { PerformanceMetricsConstants } from '../constants';

export const initialState: STATES.PerformanceMetricsState = {
  isLoading: false,
  logs: [],
  enginesOnLogs: [],
};

export const performanceMetrics = (
  state = initialState,
  action: PerformanceMetricsAction
): STATES.PerformanceMetricsState => {
  switch (action.type) {
    case PerformanceMetricsConstants.GET_PERFORMANCE_METRICS_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        isLoading: false,
        logs: data,
      };
    }
    case PerformanceMetricsConstants.GET_PERFORMANCE_METRICS_REQUEST: {
      const { enginesOnLogs } = action.payload;
      return {
        ...state,
        enginesOnLogs,
        logs: [],
        isLoading: true,
      };
    }
    case PerformanceMetricsConstants.GET_PERFORMANCE_METRICS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case PerformanceMetricsConstants.PERFORMANCE_METRICS_RESET: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};
