import extractJson from 'extract-json-string';

const parseCurlReqData = (curlCommand: string) => {
  curlCommand = curlCommand.replace(/\\\r|\\\n/g, '');
  curlCommand = curlCommand.replace(/\s+/g, ' ');
  curlCommand = curlCommand.trim();
  const data = extractJson.extract(curlCommand)?.[0];

  return {
    status: data ? 'SUCCESS' : 'FAIL',
    data,
  };
};

export { parseCurlReqData };
