export const RUN_INGENIUM_UTILITY_REQUEST = 'RUN_INGENIUM_UTILITY_REQUEST';
export const RUN_INGENIUM_UTILITY_SUCCESS = 'RUN_INGENIUM_UTILITY_SUCCESS';
export const RUN_INGENIUM_UTILITY_FAILURE = 'RUN_INGENIUM_UTILITY_FAILURE';

export const GET_INGENIUM_RUN_STATUS_REQUEST =
  'GET_INGENIUM_RUN_STATUS_REQUEST';
export const GET_INGENIUM_RUN_STATUS_SUCCESS =
  'GET_INGENIUM_RUN_STATUS_SUCCESS';
export const GET_INGENIUM_RUN_STATUS_FAILURE =
  'GET_INGENIUM_RUN_STATUS_FAILURE';

export const GET_INGENIUM_LOGS_REQUEST = 'GET_INGENIUM_LOGS_REQUEST';
export const GET_INGENIUM_LOGS_SUCCESS = 'GET_INGENIUM_LOGS_SUCCESS';
export const GET_INGENIUM_LOGS_FAILURE = 'GET_INGENIUM_LOGS_FAILURE';

export const CLEAR_INGENIUM_JOB = 'CLEAR_INGENIUM_JOB';
export const RESET_INGENIUM_STATE = 'RESET_INGENIUM_STATE';

export const INTERVAL_TIME = 3000;

export const EXPORT_ERROR_PREFIX = 'ERROR';
export const EXPORT_PROCESS_ERROR_PREFIX = 'Import Processing Error';
export const EXPORT_WARNIG_PREFIX = 'WARNING';
