export const CONSTRUCT_TUTORIAL_FORMSPEC_ACTION =
  'CONSTRUCT_TUTORIAL_FORMSPEC_ACTION';

export const GET_FORMSPEC_REQUEST = 'GET_TUTORIAL_FORMSPEC_REQUEST';
export const GET_FORMSPEC_SUCCESS = 'GET_TUTORIAL_FORMSPEC_SUCCESS';
export const GET_FORMSPEC_FAILURE = 'GET_TUTORIAL_FORMSPEC_FAILURE';

export const GET_FORMSTATE_REQUEST = 'GET_TUTORIAL_FORMSTATE_REQUEST';
export const GET_FORMSTATE_SUCCESS = 'GET_TUTORIAL_FORMSTATE_SUCCESS';
export const GET_FORMSTATE_FAILURE = 'GET_TUTORIAL_FORMSTATE_FAILURE';

export const RESET_FORMBUILDER = 'RESET_TUTORIAL_FORMBUILDER';
