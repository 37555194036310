import { Button, Icon, Progress } from 'antd';
import moment from 'moment';
import React, { memo, NamedExoticComponent, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TestbedActions, FileManagerActions } from '../../actions';
import { colorHexToRgba, formatToLongDateTime } from '../../helpers';
import { useAppDispatch } from '../../hooks';
import { IconSvg } from '../Common';
import { BackgroundJobsConstants } from '../../constants';

type BackgroundJobItemProps = {
  job: DTO.BackgroundJob;
  onCancel: (job: DTO.BackgroundJob) => void;
};

const Container = styled.div`
  font-size: 12px;
  div.heading {
    margin: 0 43px;
    padding: 0 16px;
    word-break: break-all;
    color: ${({ theme }) => theme.color.gray1};
  }
  div.description {
    margin: 0 43px;
    padding: 0 16px;
    color: ${({ theme }) => theme.color.gray1};
  }
  color: ${({ theme }) => colorHexToRgba(theme.color.gray1, 0.6)};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray5};
  padding: 5px 5px 5px 0;

  .title-link {
    &,
    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
      color: ${({ theme }) =>
        colorHexToRgba(theme.color.gray1, 0.6)} !important;

      .anticon {
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }

  :last-child {
    border-bottom: none;
  }

  .progress-container {
    display: flex;
    align-items: center;

    .icon-container {
      width: 14px;
      height: 12px;
      margin: 0 22px;
      line-height: 21px;
      text-align: center;

      .anticon {
        font-size: 12px;

        &.anticon-close-circle {
          color: ${({ theme }) => theme.color.red};

          &.inprogress {
            color: ${({ theme }) => colorHexToRgba(theme.color.dark, 0.6)};
          }
          &.success {
            color: rgba(0, 0, 0, 0.65);
            cursor: pointer;

            :hover,
            :focus,
            :active {
              color: ${({ theme }) => theme.color.success};
            }
          }
        }

        &.anticon-check-circle {
          &.success {
            color: ${({ theme }) => theme.color.success};
            cursor: pointer;

            :hover,
            :focus,
            :active {
              color: ${({ theme }) => theme.color.success};
            }
          }

          color: ${({ theme }) => theme.color.purple};
        }
      }
    }

    .ant-progress {
      width: auto;
      flex: 1;

      .ant-progress-bg {
        height: 6px !important;
        border-radius: 22px !important;
      }

      .ant-progress-text {
        visibility: collapse;
      }

      .ant-progress-success-bg,
      .ant-progress-bg {
        background-color: ${({ theme }) => theme.color.dark};
      }
    }

    .ant-progress-status-normal .ant-progress-bg {
      background-color: ${({ theme }) => theme.color.purple} !important;
    }

    .ant-progress-status-success .ant-progress-bg {
      background-color: ${({ theme }) => theme.color.success} !important;
    }

    .ant-progress-status-exception .ant-progress-bg {
      background-color: ${({ theme }) => theme.color.red} !important;
    }
  }
`;

const StyledButton = styled(Button)`
  margin-top: 0px !important;
  font-size: 14px !important;
  height: 16px !important;
`;

const mapStateToProps = ({
  testbeds: { generatingTestResult, testbedResultUrl, testbedResultFileName },
}: STATES.AppState) => ({
  generatingTestResult,
  testbedResultUrl,
  testbedResultFileName,
});

const BackgroundJobItem: NamedExoticComponent<BackgroundJobItemProps> = memo(
  ({ job, onCancel }) => {
    const intl = useIntl();
    const {
      progress,
      id,
      status,
      type,
      completedAt,
      createdAt,
      result,
      data,
    } = job;
    const {
      generatingTestResult,
      testbedResultUrl,
      testbedResultFileName,
    } = useSelector(mapStateToProps);

    const dispatch = useAppDispatch();

    const progressStatus = useMemo(() => {
      switch (status) {
        case 'Fail':
        case 'Cancelled': {
          return 'exception';
        }
        case 'Success': {
          return 'success';
        }
        default: {
          return 'normal';
        }
      }
    }, [status]);

    const downloadCompareResult = async request => {
      await dispatch(
        TestbedActions.generateTestCompare(
          request.SourceTestRunId,
          request.TargetTestRunId
        )
      );
      if (!generatingTestResult && testbedResultUrl && testbedResultFileName) {
        dispatch(
          FileManagerActions.downloadBlobAction(
            testbedResultUrl,
            testbedResultFileName
          )
        );
      }
    };

    const desc = useMemo(() => {
      if (result === BackgroundJobsConstants.FILE_SIZE_LIMIT_EXCEEDED) {
        return intl.formatMessage({
          id: BackgroundJobsConstants.FILE_SIZE_LIMIT_EXCEEDED,
        });
      }
      let builtMessage = intl.formatMessage(
        {
          id: `BackgroundJobItem.${status}.${type}`,
        },
        {
          progress,
          duration: moment(createdAt).fromNow(true),
          completedAt: formatToLongDateTime(completedAt || ''),
        }
      );

      if (
        status === 'Fail' &&
        type === 'UploadFolder' &&
        typeof result === 'string'
      ) {
        const additionError = intl.formatMessage({ id: result });

        builtMessage += ` (${additionError})`;
      }

      return builtMessage;
    }, [status, completedAt, progress, createdAt, type, result, intl]);

    const renderTitle = () => {
      const name = data?.Name || id;

      if (
        status !== 'Success' &&
        (type === 'UploadFolder' || type === 'DownloadFolder')
      ) {
        return name;
      }

      switch (type) {
        case 'UploadFolder':
          return (
            <Link to={`/products/${name}`} className="title-link">
              <FormattedMessage
                id="BackgroundJobsPopover.popup.desciption.UploadFolder"
                values={{ productName: name }}
              />
            </Link>
          );
        case 'DownloadFolder':
          return (
            <a
              className="title-link"
              href={result || ''}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="BackgroundJobsPopover.popup.desciption.DownloadFolder"
                values={{ productName: name }}
              />
              <IconSvg type="download" />
            </a>
          );
        case 'JsSheetGenerate':
          return (
            <>
              <FormattedMessage
                id={`BackgroundJobsPopover.popup.desciption.${type}`}
                values={data}
              />
              {status === 'Success' && (
                <StyledButton
                  size="small"
                  type="link"
                  onClick={() => downloadCompareResult(data)}
                >
                  <IconSvg type="download" />
                </StyledButton>
              )}
            </>
          );
        case 'DownloadLogs':
        case 'TestbedResultCSV':
        case 'TestbedResultExcel':
          return (
            <>
              <FormattedMessage
                id={`BackgroundJobsPopover.popup.desciption.${type}`}
              />
              {status === 'Success' && (
                <a
                  className="title-link"
                  href={data.Url || ''}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconSvg type="download" />
                </a>
              )}
            </>
          );
        default:
          return (
            <FormattedMessage
              id={`BackgroundJobsPopover.popup.desciption.${type}`}
              values={data}
            />
          );
      }
    };

    return (
      <Container data-testid="job-item">
        <div className="heading">{renderTitle()}</div>
        <div className="progress-container">
          <div className="icon-container">
            <Icon type="edit" />
          </div>
          <Progress
            percent={progressStatus === 'success' ? 100 : progress}
            showInfo={false}
            status={progressStatus}
          />
          <div className="icon-container">
            <Icon
              data-testid={`cancel-job-${id}`}
              type={
                progressStatus === 'success' ? 'check-circle' : 'close-circle'
              }
              className={progressStatus}
              onClick={() => progressStatus !== 'success' && onCancel(job)}
            />
          </div>
        </div>
        <div className="description">{desc}</div>
      </Container>
    );
  }
);

export { BackgroundJobItem };
