import { IngeniumConstant } from '../constants';
import { IngeniumThunkAction, IngeniumThunkActionSync } from './types';
import { ApiError, downloadBlob } from '../helpers';
import { AlertActions } from './alert.actions';
import { FileManagerService, IngeniumService } from '../services';

const runutility = (
  product: string,
  engine: string,
  version: string,
  smartLevel: string
): IngeniumThunkAction => async dispatch => {
  try {
    dispatch({
      type: IngeniumConstant.RUN_INGENIUM_UTILITY_REQUEST,
    });

    const { payload, status } = await IngeniumService.runutility(
      product,
      engine,
      version,
      smartLevel
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: IngeniumConstant.RUN_INGENIUM_UTILITY_SUCCESS,
      payload: {
        taskQueueId: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: IngeniumConstant.RUN_INGENIUM_UTILITY_FAILURE,
      payload: { error: msg },
    });
  }
};

const clearJob = (): IngeniumThunkAction => dispatch => {
  dispatch({
    type: IngeniumConstant.CLEAR_INGENIUM_JOB,
  });
};

const resetState = (): IngeniumThunkAction => dispatch => {
  dispatch({
    type: IngeniumConstant.RESET_INGENIUM_STATE,
  });
};

const getRunStatus = (
  taskQueueId: string
): IngeniumThunkAction => async dispatch => {
  try {
    dispatch({
      type: IngeniumConstant.GET_INGENIUM_RUN_STATUS_REQUEST,
    });

    const { payload } = await IngeniumService.getRunStatus(taskQueueId);

    if (payload.status === 'Error' || payload.data.status === 'Fail') {
      if (payload.data.outputData) {
        // in case of error as well if utility generated output, it should we shown on UI
        dispatch({
          type: IngeniumConstant.GET_INGENIUM_RUN_STATUS_SUCCESS,
          payload: {
            taskQueue: payload.data,
          },
        });
      }
      if (payload.data.failureReason) {
        payload.errorCode = payload.data.failureReason;
        throw new ApiError(payload);
      }
      throw new ApiError(payload);
    }

    dispatch({
      type: IngeniumConstant.GET_INGENIUM_RUN_STATUS_SUCCESS,
      payload: {
        taskQueue: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: IngeniumConstant.GET_INGENIUM_RUN_STATUS_FAILURE,
      payload: { error: msg },
    });
  }
};

const downloadIngeniumLogFile = (
  fileId: string,
  fileName: string,
  openInNewWindow = false
): IngeniumThunkActionSync => async () => {
  const downloadUrl = IngeniumService.getDownloadIngeniumLogFileUrl(
    fileId,
    fileName
  );

  const blob = await FileManagerService.downloadBlob(downloadUrl);
  if (blob.payload.blob)
    downloadBlob(blob.payload.blob, blob.payload.blobName, openInNewWindow);
};

const getLogs = (
  logRequest: DTO.GetIngeniumLogRequest
): IngeniumThunkAction => async dispatch => {
  try {
    dispatch({
      type: IngeniumConstant.GET_INGENIUM_LOGS_REQUEST,
      payload: { logRequest },
    });

    const { payload, status } = await IngeniumService.getLogs(logRequest);

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: IngeniumConstant.GET_INGENIUM_LOGS_SUCCESS,
      payload: {
        ingeniumLogs: payload.data,
        total: payload.count,
        logRequest,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: IngeniumConstant.GET_INGENIUM_LOGS_FAILURE,
      payload: { error: msg },
    });
  }
};

export const IngeniumActions = {
  runutility,
  downloadIngeniumLogFile,
  getRunStatus,
  clearJob,
  getLogs,
  resetState,
};
