import { UserManagerConstants } from '../constants';
import { UserManagerAction } from '../actions/types';
import { getValidRoleAndGroup } from '../helpers';

const initialUserManagerState: STATES.UserManagerState = {
  isLoading: false,
  users: [],
  selectedUser: null,
  refreshTrigger: 0,
};

const mapResponseToUser = ({
  id,
  is_active,
  email,
  first_name,
  last_name,
  groups,
  data,
}: DTO.UserResponse): DTO.User => {
  const { role: permissionLevel } = getValidRoleAndGroup(
    groups.map(g => g.name)
  );

  const groupNames = groups.map(
    group => group.name.split(':')[1] || group.name
  );

  return {
    email: email || (data && data.email) || '',
    fullName:
      data && data.FullName
        ? data.FullName
        : `${first_name || ''} ${last_name || ''}`.trim(),
    id,
    position: (data && data.Position) || '',
    groups: groupNames,
    joinedGroupNames: groupNames.join(', '),
    permissionLevel,
    isActive: is_active,
  };
};

export const userManager = (
  state = initialUserManagerState,
  action: UserManagerAction
): STATES.UserManagerState => {
  switch (action.type) {
    case UserManagerConstants.GET_USERS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UserManagerConstants.GET_USERS_SUCCESS: {
      const { users } = action.payload;

      return {
        ...state,
        isLoading: false,
        users: users.map<DTO.User>(mapResponseToUser),
      };
    }
    case UserManagerConstants.GET_USERS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case UserManagerConstants.GET_USER_SUCCESS: {
      const { user } = action.payload;

      return {
        ...state,
        selectedUser: mapResponseToUser(user),
      };
    }
    case UserManagerConstants.SELECT_USER: {
      const { user } = action.payload;

      return {
        ...state,
        selectedUser: user,
      };
    }
    case UserManagerConstants.ADD_USER_SUCCESS:
    case UserManagerConstants.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        refreshTrigger: state.refreshTrigger + 1,
      };
    }
    default:
      return state;
  }
};
