import moment, { Moment } from 'moment';
import { BackgroundJobsConstants, EngineLogsConstant } from '../constants';
import { ApiError, downloadBlob } from '../helpers';
import { EngineService, FileManagerService } from '../services';
import { AlertActions } from './alert.actions';
import { EngineLogsAction, ViewLogsThunkAction } from './types';

const getProductEngineLogs = (
  productName: string,
  serviceName: string,
  versionId: string,
  data: DTO.GetProductEngineLogsRequest
): ViewLogsThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineLogsConstant.GET_ENGINE_VIEW_LOGS,
      payload: { ...data },
    });

    const requestData = { ...data };

    const { payload, status } = await EngineService.getProductEngineLogs(
      productName,
      serviceName,
      versionId,
      requestData
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineLogsConstant.GET_ENGINE_VIEW_LOGS_SUCCESS,
      payload: {
        ...data,
        logs: payload.data,
        total: payload.count,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineLogsConstant.GET_ENGINE_VIEW_LOGS_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const resetEngineLogs = (): EngineLogsAction => ({
  type: EngineLogsConstant.ENGINE_LOGS_RESET,
});

const downloadMatchesEngineLogs = (
  productName: string,
  serviceName: string,
  versionId: string,
  startDate: string,
  endDate: string
): ViewLogsThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineLogsConstant.DOWNLOAD_ENGINE_LOGS_REQUEST,
    });
    const url = EngineService.getDownloadMatchesEngineLogsFileUrl(
      productName,
      serviceName,
      versionId,
      startDate,
      endDate
    );

    const blob = await FileManagerService.downloadBlob(url);
    if (blob.payload.blob) {
      downloadBlob(blob.payload.blob, blob.payload.blobName);
    }
    dispatch({
      type: EngineLogsConstant.DOWNLOAD_ENGINE_LOGS_SUCCESS,
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineLogsConstant.DOWNLOAD_ENGINE_LOGS_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const downloadEngineLogFile = (
  productName: string,
  serviceName: string,
  startDate: Moment | undefined,
  endDate: Moment | undefined,
  engineCallId: string
): ViewLogsThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineLogsConstant.DOWNLOAD_ENGINE_LOGS_REQUEST,
    });
    const url = EngineService.getDownloadEngineLogFileUrl(
      productName,
      serviceName,
      startDate ? moment(startDate).toISOString() : undefined,
      endDate ? moment(endDate).toISOString() : undefined,
      engineCallId
    );

    const blob = await FileManagerService.downloadBlob(url);
    if (blob.payload.blob) {
      downloadBlob(blob.payload.blob, blob.payload.blobName);
    }
    dispatch({
      type: EngineLogsConstant.DOWNLOAD_ENGINE_LOGS_SUCCESS,
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineLogsConstant.DOWNLOAD_ENGINE_LOGS_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const downloadmultipleLogs = (
  productName: string,
  serviceName: string,
  versionId: string,
  request: DTO.DownloadMultipleLogsRequest
) => async dispatch => {
  dispatch({ type: EngineLogsConstant.DOWNLOAD_MULTIPLE_LOGS_REQUEST });

  try {
    const { payload } = await EngineService.DownloadMultipleLogs(
      productName,
      serviceName,
      versionId,
      request
    );
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineLogsConstant.DOWNLOAD_MULTIPLE_LOGS_SUCCESS,
      payload: {
        list: payload.data,
      },
    });
    dispatch({
      type: EngineLogsConstant.ADD_DOWNLOAD_LOGS_IN_QUEUE,
      payload: {
        jobId: payload.data,
      },
    });
    dispatch({
      type: BackgroundJobsConstants.TRIGGER_REFRESH_GBJOBS,
    });
    await dispatch(AlertActions.info('TestLogs.download.successMessage'));
    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineLogsConstant.DOWNLOAD_MULTIPLE_LOGS_FAILURE,
      payload: { error: msg },
    });
    return false;
  }
};

export const EngineLogsActions = {
  getProductEngineLogs,
  resetEngineLogs,
  downloadMatchesEngineLogs,
  downloadEngineLogFile,
  downloadmultipleLogs,
};
