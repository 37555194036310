import { useLocation } from 'react-router-dom';

const useVersionOverviewParams = () => {
  const pathname = useLocation().pathname;
  const isTimeline = pathname.endsWith('/timeline');
  const isHistory = pathname.endsWith('/history');

  return {
    isTimeline,
    isHistory,
  };
};

export { useVersionOverviewParams };
