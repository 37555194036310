export const GET_SHARED_PRODUCTS_REQUEST = 'GET_SHARED_PRODUCTS_REQUEST';
export const GET_SHARED_PRODUCTS_SUCCESS = 'GET_SHARED_PRODUCTS_SUCCESS';
export const GET_SHARED_PRODUCTS_FAILURE = 'GET_SHARED_PRODUCTS_FAILURE';

export const SHARE_PRODUCT_DETAIL_REQUEST = 'SHARE_PRODUCT_DETAIL_REQUEST';
export const SHARE_PRODUCT_DETAIL_SUCCESS = 'SHARE_PRODUCT_DETAIL_SUCCESS';
export const SHARE_PRODUCT_DETAIL_FAILURE = 'SHARE_PRODUCT_DETAIL_FAILURE';

export const PRODUCT_SHARE_STATE_RESET = 'PRODUCT_SHARE_STATE_RESET';
export const UPDATE_PRODUCT_SHARE_REQUEST = 'UPDATE_PRODUCT_SHARE_REQUEST';
export const UPDATE_PRODUCT_SHARE_SUCCESS = 'UPDATE_PRODUCT_SHARE_SUCCESS';
export const UPDATE_PRODUCT_SHARE_FAILURE = 'UPDATE_PRODUCT_SHARE_FAILURE';
