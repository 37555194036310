export const GET_USERS_REQUEST = 'ADMIN_GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'ADMIN_GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'ADMIN_GET_USERS_FAILURE';

export const GET_USER_REQUEST = 'ADMIN_GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'ADMIN_GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'ADMIN_GET_USER_FAILURE';

export const SELECT_USER = 'ADMIN_SELECT_USER';

export const ADD_USER_REQUEST = 'ADMIN_ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADMIN_ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADMIN_ADD_USER_FAILURE';

export const UPDATE_USER_REQUEST = 'ADMIN_UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'ADMIN_UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'ADMIN_UPDATE_USER_FAILURE';
