import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactJson from 'react-json-view';
import { CustomModal } from '../CustomModal';
import { ScrollContainer } from '../ScrollContainer';

interface MetadataModalProps {
  title: string;
  metadata: object | null;
  onClose: () => void;
}

const MetadataModal: FC<MetadataModalProps> = ({
  onClose,
  title,
  metadata,
}) => {
  return (
    <>
      <CustomModal
        visible={!!metadata}
        closable
        title={<FormattedMessage id={title} />}
        onCancel={onClose}
        getContainer={document.body}
        content={
          <ScrollContainer flexFill>
            <ReactJson
              style={{ width: '100%' }}
              src={metadata || {}}
              name="Metadata"
              collapsed={1}
            />
          </ScrollContainer>
        }
      />
    </>
  );
};

export { MetadataModal };
