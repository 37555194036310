import React, { FC } from 'react';
import styled from 'styled-components';
import { Alert } from 'antd';
import { IconSvg } from '../Icons/IconSvg';

const StyledAlert = styled(Alert)`
  &.ant-alert {
    padding: 12px 16px 12px 42px;
    line-height: 1.5;
    text-align: left;

    .ant-alert-icon {
      font-size: 16px;
      line-height: 1.5;
    }

    &.ant-alert-closable {
      padding-right: 16px;
    }

    &.ant-alert-no-icon {
      padding: 12px 16px !important;
    }

    &.ant-alert-icon {
      top: 12px;
      left: 16px;
    }

    &.ant-alert-with-description .ant-alert-icon {
      top: 16px;
      left: 16px;
      font-size: 24px;
    }

    &.ant-alert-with-description {
      padding: 16px 16px 16px 56px;
    }

    .ant-alert-close-icon {
      font-size: 12px;
    }

    &.ant-alert-with-description .ant-alert-close-icon {
      top: 10px;
    }

    .ant-alert-message {
      color: ${({ theme }) => theme.color.dark};
    }

    .ant-alert-description {
      color: ${({ theme }) => theme.color.dark};
    }
  }

  &.ant-alert-info {
    background-color: ${({ theme }) => theme.color.azureBlue20};
    border-color: ${({ theme }) => theme.color.azureBlue60};

    .anticon {
      color: ${({ theme }) => theme.color.blue6};
    }

    .ant-alert-close-icon {
      .ant-alert-close-text {
        color: ${({ theme }) => theme.color.dark60};
      }

      .anticon {
        color: ${({ theme }) => theme.color.dark60};
      }
    }
  }

  &.ant-alert-warning {
    background-color: ${({ theme }) => theme.color.warning20};
    border-color: ${({ theme }) => theme.color.warning};

    .anticon {
      color: ${({ theme }) => theme.color.orange};
    }

    .ant-alert-close-icon {
      .ant-alert-close-text {
        color: ${({ theme }) => theme.color.dark60};
      }

      .anticon {
        color: ${({ theme }) => theme.color.dark60};
      }
    }
  }

  &.ant-alert-success {
    background-color: ${({ theme }) => theme.color.success15};
    border-color: ${({ theme }) => theme.color.success};

    .anticon {
      color: ${({ theme }) => theme.color.success};
    }

    .ant-alert-close-icon {
      .ant-alert-close-text {
        color: ${({ theme }) => theme.color.dark60};
      }

      .anticon {
        color: ${({ theme }) => theme.color.dark60};
      }
    }
  }

  &.ant-alert-error {
    background-color: ${({ theme }) => theme.color.attention20};
    border-color: ${({ theme }) => theme.color.dangerColor};

    .anticon {
      color: ${({ theme }) => theme.color.dangerColor};
    }

    .ant-alert-close-icon {
      .ant-alert-close-text {
        color: ${({ theme }) => theme.color.dark60};
      }

      .anticon {
        color: ${({ theme }) => theme.color.dark60};
      }
    }
  }
`;

interface AlertProps {
  type?: 'success' | 'info' | 'warning' | 'error';
  message: string | JSX.Element;
  description?: string | JSX.Element;
  showIcon?: boolean;
  icon?: JSX.Element;
  closable?: boolean;
  closeText?: string | JSX.Element;
  onClose?: () => void;
  isBanner?: boolean;
}

const CustomAlert: FC<AlertProps> = ({
  type = 'info',
  message,
  description,
  showIcon = false,
  icon,
  closable = false,
  closeText = '',
  onClose,
  isBanner = false,
}) => {
  const iconRender = () => {
    if (showIcon) {
      if (icon) {
        return icon;
      }
      return (
        <IconSvg type={description ? 'InfoCircleOutlined' : 'InfoCircleFill'} />
      );
    }
    return undefined;
  };

  return (
    <StyledAlert
      type={type || 'info'}
      message={message}
      description={description}
      showIcon={showIcon}
      icon={iconRender()}
      closable={closable}
      closeText={closeText}
      onClose={onClose}
      banner={isBanner}
    />
  );
};

export { CustomAlert };
