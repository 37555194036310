export default class XmlSerializerWrapper {
  readonly serializer: XMLSerializer;

  constructor() {
    this.serializer = new XMLSerializer();
  }

  public serializeToString(doc: Document | HTMLElement) {
    return this.serializer.serializeToString(doc);
  }
}
