/* eslint-disable @typescript-eslint/no-explicit-any */
class ApiError extends Error {
  codes: string[];

  message: string;

  data: any;

  reason: any;

  constructor(response: {
    error_code?: string;
    error_codes?: string[];
    errorCode?: string;
    message?: string;
    Message?: string;
    data?: any;
    Data?: any;
  }) {
    super(response.errorCode || response.error_code || '');
    const {
      error_code,
      error_codes,
      errorCode,
      message,
      Message,
      data,
      Data,
    } = response;

    this.codes = error_codes || [errorCode || error_code || ''];
    this.message = message || Message || '';
    this.data = data || Data;
    this.reason = this.data && this.data?.Error?.errors[0];

    // Added this to make **instanceof** work in Jest
    Object.setPrototypeOf(this, ApiError.prototype);
  }

  public toString = (): string => {
    return this.message;
  };
}

export { ApiError };
