export const GET_TEMPLATES_REQUEST = 'GET_TEMPLATES_REQUEST';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_FAILURE = 'GET_TEMPLATES_FAILURE';

export const DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_FAILURE = 'DELETE_TEMPLATE_FAILURE';

export const UPDATE_TEMPLATE_FAVORITE_REQUEST =
  'UPDATE_TEMPLATE_FAVORITE_REQUEST';
export const UPDATE_TEMPLATE_FAVORITE_SUCCESS =
  'UPDATE_TEMPLATE_FAVORITE_SUCCESS';
export const UPDATE_TEMPLATE_FAVORITE_FAILURE =
  'UPDATE_TEMPLATE_FAVORITE_FAILURE';

export const CREATE_TEMPLATE_REQUEST = 'CREATE_TEMPLATE_REQUEST';
export const CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS';
export const CREATE_TEMPLATE_FAILURE = 'CREATE_TEMPLATE_FAILURE';

export const UPDATE_TEMPLATE_REQUEST = 'UPDATE_TEMPLATE_REQUEST';
export const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS';
export const UPDATE_TEMPLATE_FAILURE = 'UPDATE_TEMPLATE_FAILURE';

export const SELECT_TEMPLATE = 'SELECT_TEMPLATE';
export const SELECTED_TEMPLATE_RESET = 'SELECTED_TEMPLATE_RESET';
