import { getConfig } from '../helpers/config';
import makeRequest from '../helpers/make-request';

const getEntityPermissions = (entityId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/entitypermission/getentitypermission/${entityId}`;

  return makeRequest<{
    data: DTO.EntityPermissionResponse[];
  }>('GET', url);
};

const setEntityPermissions = (
  entityPermissionRequest: DTO.EntityPermissionRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/entitypermission/setentitypermission`;

  return makeRequest('POST', url, entityPermissionRequest);
};

export const EntityPermissionService = {
  getEntityPermissions,
  setEntityPermissions,
};
