import { EntityPermissionAction } from '../actions/types';
import { EntityPermissionConstant } from '../constants';

export const initialEntityPermissionState: STATES.EntityPermissionState = {
  entityPermissions: [],
  gettingEntityPermissions: false,
  updatingEntityPermission: false,
  setPermissionSuccess: false,
  totalPrincipalsAdded: 0,
};

export const entityPermission = (
  state = initialEntityPermissionState,
  action: EntityPermissionAction
): STATES.EntityPermissionState => {
  switch (action.type) {
    case EntityPermissionConstant.GET_ENTITY_PERMISSION_REQUEST: {
      return {
        ...state,
        entityPermissions: [],
        gettingEntityPermissions: true,
        updatingEntityPermission: false,
        setPermissionSuccess: false,
      };
    }
    case EntityPermissionConstant.GET_ENTITY_PERMISSION_SUCCESS: {
      const { entityPermissions } = action.payload;

      return {
        ...state,
        entityPermissions,
        gettingEntityPermissions: false,
      };
    }
    case EntityPermissionConstant.GET_ENTITY_PERMISSION_FAILURE: {
      return {
        ...state,
        gettingEntityPermissions: false,
      };
    }
    case EntityPermissionConstant.SET_ENTITY_PERMISSION_FAILURE: {
      return {
        ...state,
        updatingEntityPermission: false,
      };
    }
    case EntityPermissionConstant.SET_ENTITY_PERMISSION_REQUEST: {
      return {
        ...state,
        gettingEntityPermissions: false,
        updatingEntityPermission: true,
        setPermissionSuccess: false,
        totalPrincipalsAdded: 0,
      };
    }
    case EntityPermissionConstant.SET_ENTITY_PERMISSION_SUCCESS: {
      const { totalPrincipalsAdded } = action.payload;
      return {
        ...state,
        updatingEntityPermission: false,
        setPermissionSuccess: true,
        totalPrincipalsAdded,
      };
    }
    case EntityPermissionConstant.RESET_ENTITY_PERMISSION: {
      return {
        ...initialEntityPermissionState,
      };
    }

    default:
      return state;
  }
};
